import { gql } from '@apollo/client'

export const CHATS_BY_FIELDS_QUERY = gql`
	query getChatsByField(
		$fields: [FieldInput!]!
		$operatorBetweenFields: String
		$options: Options
		$sort: Sort
		$byUserId: String!
	) {
		findChatsByField(
			fields: $fields
			operatorBetweenFields: $operatorBetweenFields
			options: $options
			sort: $sort
			byUserId: $byUserId
		) {
			entities {
				_id
				message
				is_read
				files
				type
				createdAt
				senderId {
					_id
					title
					email
					type
					user {
						_id
					}
				}
				receiverId {
					_id
					title
					email
					type
					user {
						_id
					}
				}
			}
			countsNotRead
			countsNotReadAdmin {
				_id
				countsNotRead
				count
			}
			totalCount
			totalPages
			page
			limit
			hasNextPage
			hasPreviousPage
		}
	}
`

export const CREATE_CHAT_QUERY = gql`
	mutation createChat(
		$message: String
		$is_read: Boolean
		$senderId: String!
		$receiverId: String
		$files: [String!]
		$type: TypeMessage
		$uploadFiles: [Upload!]
	) {
		createChat(
			input: {
				message: $message
				is_read: $is_read
				senderId: $senderId
				receiverId: $receiverId
				files: $files
				type: $type
			}
			uploadFiles: $uploadFiles
		) {
			_id
			message
			type
			files
			is_read
			createdAt
			senderId {
				_id
				title
				email
				type
				user {
					_id
				}
			}
			receiverId {
				_id
				title
				email
				type
				user {
					_id
				}
			}
		}
	}
`
export const UPDATE_CHATS_BY_IDS_QUERY = gql`
	mutation updateChatsByIds($_ids: [String!]!) {
		updateChatsByIds(input: { ids: $_ids })
	}
`
