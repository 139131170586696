import React, { useContext, useEffect, useState } from 'react'
import {
	Avatar,
	Badge,
	Card,
	Col,
	Descriptions,
	Divider,
	Form,
	Grid,
	List,
	Row,
	Typography,
} from 'antd'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { TYPE_OFFER } from '../../../constants/type_offer'
import { CheckOutlined, HighlightOffOutlined } from '@material-ui/icons'
import { STATUS } from '../../../constants/status'
import { ROLES } from '../../../constants/roles'
import GroupeButtons from './GroupeButtons'
import EditInput from './EditInput'
import Contexts from '../../../context'
import { isEmpty } from 'lodash'
import { useForm } from 'antd/lib/form/Form'
import moment from 'moment'

const ListCommandes = ({
	currentKeyTab,
	currentKeySubTab,
	status,
	confirmCommande = () => {},
	deleteCommande = () => {},
}) => {
	const [form] = useForm()
	const { editingItem, setEditingItem } = useContext(
		Contexts.EditingInputContext
	)
	const { currentsConverIds, setCurrentsConverIds } = useContext(
		Contexts.CurrentsConverIdsContext
	)
	const { lineOrders, loadingGetLineOrders, user, companyByUserId } =
		useSelector(
			(state) => ({
				loadingGetLineOrders: state.lineOrders.loadingGetLineOrders,
				lineOrders: state.lineOrders.entities,
				user: state.auth?.user,
				companyByUserId: state.auth?.companyByUserId,
			}),
			shallowEqual
		)
	const { useBreakpoint } = Grid
	const { lg } = useBreakpoint()
	const handleCancel = (item) => {
		setEditingItem()
	}
	const handleOk = (cmd) => {
		form.submit()
		form.validateFields()
			.then((values) => {
				const { quantity, unitPrice } = values
				cmd = {
					...cmd,
					quantity,
					unitPrice,
				}
				confirmCommande(cmd, status)
				setEditingItem()
			})
			.catch((errorInfo) => {
				console.log({ errorInfo })
			})
	}
	useEffect(() => {
		editingItem &&
			form.setFieldsValue({
				quantity: editingItem?.quantity,
				unitPrice: editingItem?.unitPrice,
			})
	}, [editingItem])
	return (
		<>
			<List
				loading={loadingGetLineOrders}
				bordered
				style={{
					margin: '1rem',
					backgroundColor: 'white',
				}}
				itemLayout='horizontal'
				dataSource={lineOrders}
				renderItem={(item) => (
					<List.Item
						key={item?._id}
						actions={[
							<>
								{item?.type !== TYPE_OFFER.PURCHASE ? (
									<Row
										align='middle'
										gutter={[4, 4]}
										wrap={true}
									>
										<Col>
											<Descriptions
												bordered
												size='small'
												style={{
													backgroundColor: '#fff',
												}}
											>
												<Descriptions.Item label='Qte-dem'>
													{item?.quantity}
												</Descriptions.Item>
												<Descriptions.Item label='Prix'>
													{item?.unitPriceFinal}
												</Descriptions.Item>
											</Descriptions>
										</Col>
										<Col>
											<CheckOutlined htmlColor='green' />
										</Col>
									</Row>
								) : (
									<>
										<Row
											align='middle'
											gutter={[4, 4]}
											wrap={true}
										>
											{!isEmpty(editingItem) &&
											editingItem?._id == item?._id ? (
												<>
													<Col
														style={{
															display: 'flex',
															flexDirection:
																'column',
															justifyContent:
																'center',
															alignItems:
																'flex-start',
														}}
													>
														<Form form={form}>
															<EditInput />
														</Form>
													</Col>
													<Col>
														<GroupeButtons
															confirmCommande={
																handleOk
															}
															deleteCommande={
																handleCancel
															}
															item={item}
															isAdmin={user?.roles.includes(
																ROLES.ADMIN
															)}
														/>
													</Col>
												</>
											) : (
												<>
													<Col>
														<Descriptions
															bordered
															size='small'
															style={{
																backgroundColor:
																	'#fff',
															}}
														>
															<Descriptions.Item
																label='Qte'
																span={
																	lg ? 0 : 2
																}
															>
																{item?.quantity}
															</Descriptions.Item>
															<Descriptions.Item label='PU.TTC'>
																{
																	item?.unitPrice
																}
																dt
															</Descriptions.Item>
															<Descriptions.Item label='TOTAL'>
																{item?.unitPrice *
																	item?.quantity}
																dt
															</Descriptions.Item>
														</Descriptions>
													</Col>
													{currentKeyTab == '4' &&
													status ==
														STATUS.CONFIRMED ? (
														<Col>
															<GroupeButtons
																confirmCommande={
																	confirmCommande
																}
																deleteCommande={
																	deleteCommande
																}
																item={item}
																isAdmin={user?.roles.includes(
																	ROLES.ADMIN
																)}
															/>
														</Col>
													) : (
														<>
															<Col>
																{currentKeySubTab ==
																'ANNULER' ? (
																	<HighlightOffOutlined htmlColor='#ff4d4f' />
																) : (
																	<CheckOutlined htmlColor='green' />
																)}
															</Col>
															<Typography.Text
																disabled
																style={{
																	width: '100%',
																	fontSize:
																		'1em',
																}}
															>
																{moment(
																	item?.createdAt
																).format(
																	'L [à] HH:mm'
																)}
															</Typography.Text>
														</>
													)}
												</>
											)}
										</Row>
									</>
								)}
							</>,
							user?.roles?.includes(ROLES.ADMIN) && (
								<Badge.Ribbon text='Acheteur' color='green'>
									<Card size='small'>
										<Card.Meta
											description={
												<>
													<h3
														style={{
															paddingTop: '0.1em',
															cursor: 'pointer',
														}}
														onClick={() => {
															setCurrentsConverIds(
																{
																	...currentsConverIds,
																	[`${item?.orderedBy?._id}_${companyByUserId?._id}`]:
																		!currentsConverIds[
																			`${item?.orderedBy?._id}_${companyByUserId?._id}`
																		],
																}
															)
														}}
													>
														{item?.orderedBy?.title}
													</h3>
													{
														item?.orderedBy
															?.phoneNumber
													}
												</>
											}
										/>
									</Card>
								</Badge.Ribbon>
							),
						]}
					>
						<List.Item.Meta
							avatar={
								user?.roles?.includes(ROLES.ADMIN) && (
									<>
										<Badge.Ribbon
											text='Vendeur'
											color='volcano'
										>
											<Card size='small'>
												<Card.Meta
													description={
														<>
															<h3
																style={{
																	paddingTop:
																		'0.1em',
																	cursor: 'pointer',
																}}
																onClick={() => {
																	setCurrentsConverIds(
																		{
																			...currentsConverIds,
																			[`${item?.company?._id}_${companyByUserId?._id}`]:
																				!currentsConverIds[
																					`${item?.company?._id}_${companyByUserId?._id}`
																				],
																		}
																	)
																}}
															>
																{
																	item
																		?.company
																		?.title
																}
															</h3>
															{
																item?.company
																	?.phoneNumber
															}
														</>
													}
												/>
											</Card>
										</Badge.Ribbon>

										<Divider type='vertical' />
									</>
								)
							}
							title={
								<Row gutter={[4, 4]} wrap={false}>
									<Col>
										<Avatar
											style={{
												backgroundColor:
													item?.type ==
													TYPE_OFFER.SELL
														? '#f56a00'
														: '#08a908',
											}}
										>
											{item?.type == TYPE_OFFER.SELL
												? 'V'
												: 'A'}
										</Avatar>
									</Col>
									<Col>
										<Typography.Title level={5}>
											{item?.article?.marque}-
											{item?.article?.description}
										</Typography.Title>
									</Col>
								</Row>
							}
							description={item?.article?.ref}
						/>
					</List.Item>
				)}
			/>
		</>
	)
}

export default ListCommandes
