import { UserOutlined } from '@ant-design/icons'
import { Avatar, Typography } from 'antd'
import { isEmpty } from 'lodash'
import { useContext, useEffect } from 'react'
import { useTheme } from 'styled-components'
import { detectURL } from '../../../constants/fn'
import Contexts from '../../../context'
import moment from 'moment'

const MessageItem = ({
	ownerId,
	sender,
	senderAvatar,
	message,
	files,
	date,
}) => {
	const { gray_1 } = useTheme()

	const dateChat = moment(date).format('Do MMMM YYYY [à] HH:mm')
	const { setFocusMessage, focusMessage } = useContext(
		Contexts.FocusMessageContext
	)
	let messagePosition =
		ownerId == sender?._id
			? 'chatApp__convMessageItem--right'
			: 'chatApp__convMessageItem--left'
	useEffect(() => {
		setFocusMessage({ receiverId: ownerId, ...focusMessage })
	}, [ownerId])

	return (
		<div
			className={
				'chatApp__convMessageItem ' + messagePosition + ' clearfix'
			}
			style={{ display: 'flex', flexDirection: 'column' }}
		>
			<div>
				{senderAvatar ? (
					<img
						src={senderAvatar}
						alt={sender?.title}
						className='chatApp__convMessageAvatar'
					/>
				) : (
					<Avatar
						className='chatApp__convMessageAvatar'
						style={{
							backgroundColor: gray_1,
						}}
						size={40}
						icon={<UserOutlined />}
					/>
				)}
				{message ? (
					<div
						className='chatApp__convMessageValue'
						dangerouslySetInnerHTML={{ __html: message }}
					></div>
				) : null}
			</div>
			<div
				style={{
					alignSelf:
						ownerId == sender?._id ? 'flex-end' : 'flex-start',
				}}
			>
				{!isEmpty(files)
					? files.map((file) => (
							<>
								<div
									className='chatApp__convMessageValue'
									dangerouslySetInnerHTML={{
										__html: detectURL('https://' + file),
									}}
								></div>
								{/* 	<Image width={100} src={'https://' + file} /> */}
							</>
					  ))
					: ''}
				<Typography.Text disabled>{dateChat}</Typography.Text>
			</div>
		</div>
	)
}

export default MessageItem
