import { useQuery, gql, useMutation, useApolloClient } from '@apollo/client'
import { GraphQLFloat, GraphQLInputObjectType, GraphQLNonNull } from 'graphql'

export const LOGIN_QUERY = gql`
	mutation login($codeClient: Float, $password: String!) {
		login(codeClient: $codeClient, password: $password) {
			accessToken
			user {
				fullName
				firstName
				lastName
				email
				phone
				status
				codeClient
				roles
				_id
			}
			companyByUserId {
				_id
				matFiscal
				title
				address
				phoneNumber
				email
				activity
				marques
				type
				user {
					_id
				}
			}
		}
	}
`

export const REGISTRE_QUERY = gql`
	mutation register($user: CreateUserInput, $company: CreateCompanyInput) {
		register(input: { user: $user, company: $company }) {
			accessToken
			user {
				_id
				email
				firstName
				lastName
				status
				roles
				codeClient
			}
			companyByUserId {
				_id
				matFiscal
				title
				address
				phoneNumber
				email
				activity
				marques
				type
				user {
					_id
				}
			}
		}
	}
`

export const CURRENT_USER = gql`
	query getCurrentUser {
		getCurrentUser {
			fullName
			firstName
			lastName
			email
			phone
			status
			roles
			_id
		}
	}
`
export const DELETE_USER_BY_ID_QUERY = gql`
	mutation deleteUser($_id: String!) {
		deleteUser(input: { _id: $_id })
	}
`
export const USER_BY_ID_QUERY = gql`
	query findOneUser($_id: String) {
		findOneUser(_id: $_id) {
			_id
			email
			phone
			firstName
			lastName
			status
			codeClient
			roles
		}
	}
`
export const UPDATE_USER_BY_ID_QUERY = gql`
	mutation updateUser(
		$_id: String
		$email: String
		$firstName: String
		$lastName: String
		$phone: String
		$codeClient: Float
		$roles: [Role!]
		$status: Statuss
		$connected: Boolean
		$password: String
	) {
		updateUser(
			input: {
				_id: $_id
				email: $email
				firstName: $firstName
				lastName: $lastName
				phone: $phone
				roles: $roles
				status: $status
				connected: $connected
				codeClient: $codeClient
				password: $password
			}
		) {
			fullName
			firstName
			lastName
			email
			phone
			status
			roles
			codeClient
			connected
			_id
		}
	}
`
export const USERS_BY_FIELDS_QUERY = gql`
	query findUsersByField(
		$fields: [FieldInput!]!
		$operatorBetweenFields: String
		$options: Options
		$sort: Sort
	) {
		findUsersByField(
			fields: $fields
			operatorBetweenFields: $operatorBetweenFields
			options: $options
			sort: $sort
		) {
			entities {
				_id
				email
				phone
				status
				roles
				firstName
				lastName
				connected
				codeClient
				createdAt
			}
			totalCount
			totalPages
			page
			limit
			hasNextPage
			hasPreviousPage
		}
	}
`
export const CREATE_USER_QUERY = gql`
	mutation createUser(
		$firstName: String!
		$lastName: String!
		$email: String!
		$phone: String!
		$password: String!
		$codeClient: Float
		$roles: [Role!]!
		$status: Statuss!
	) {
		createUser(
			input: {
				firstName: $firstName
				lastName: $lastName
				email: $email
				phone: $phone
				password: $password
				roles: $roles
				status: $status
				codeClient: $codeClient
			}
		) {
			_id
			email
			phone
			status
			roles
			codeClient
			firstName
			lastName
		}
	}
`
export const CREATE_PROF_QUERY = gql`
	mutation createProf(
		$user: CreateUserInput!
		$company: CreateCompanyInput!
	) {
		createProf(input: { user: $user, company: $company }) {
			_id
			email
			phone
			status
			roles
			firstName
			lastName
			codeClient
		}
	}
`

var GeoPoint = new GraphQLInputObjectType({
	name: 'GeoPoint',
	fields: {
		lat: { type: new GraphQLNonNull(GraphQLFloat) },
		lon: { type: new GraphQLNonNull(GraphQLFloat) },
		alt: { type: GraphQLFloat, defaultValue: 0 },
	},
})

export const USERS_QUERY = gql`
	query HomeQuery {
		findAllUsers {
			_id
			email
			firstName
		}
	}
`
