import { concat, merge, sortBy, uniqBy } from 'lodash'
import { initialStatePagination } from '../../constants/variables'
import {
	START_LOADING_COMPANIES,
	FETCH_COMPANIES_SUCCESS,
	FETCH_COMPANIES_ERROR,
	START_LOADING_CREATE_COMPANY,
	CREATE_COMPANY_SUCCESS,
	CREATE_COMPANY_ERROR,
	START_LOADING_DELETE_COMPANY,
	DELETE_COMPANY_SUCCESS,
	DELETE_COMPANY_ERROR,
	START_LOADING_UPDATE_COMPANY_BY_ID,
	UPDATE_COMPANY_BY_ID_SUCCESS,
	UPDATE_COMPANY_BY_ID_ERROR,
	START_LOADING_COMPANY_BY_USER_ID,
	FETCH_COMPANY_BY_USER_ID_SUCCESS,
	FETCH_COMPANY_BY_USER_ID_ERROR,
	ADD_TO_COMPANIES_SUCCESS,
} from './types'
const initialState = merge(
	{
		loadingGetCompanies: false,
		loadingCreateCompany: false,
		loadingDeleteCompany: false,
		loadingUpdateCompany: false,
		loadingGetCompanyByUserId: false,
		companyByUserId: {},
		companyToEdit: {},
		error: '',
	},
	initialStatePagination
)

export default function companyReducer(state = initialState, action) {
	switch (action.type) {
		/* ****************** GET COMPANIES ************************ */
		case START_LOADING_COMPANIES:
			return {
				...state,
				loadingGetCompanies: true,
			}
		case FETCH_COMPANIES_SUCCESS:
			let { entities, ...rest } = action.payload
			/* const exist = state.entities?.find(
				(company) => company?._id == action.payload?._id
			) */
			return {
				...state,
				entities:
					rest.page == 1
						? entities
						: sortBy(
								uniqBy([...state.entities, ...entities], '_id'),
								function (dateObj) {
									return new Date(dateObj.createdAtChat)
								}
						  ).reverse(),
				...rest,
				loadingGetCompanies: false,
				error: '',
			}
		case FETCH_COMPANIES_ERROR:
			return {
				...state,
				loadingGetCompanies: false,
				error: action.payload,
			}
		/* ****************** Add TO COMPANIES ************************ */
		case ADD_TO_COMPANIES_SUCCESS:
			return {
				...state,
				loadingGetCompanies: false,
				entities: sortBy(
					uniqBy([action.payload, ...state.entities], '_id'),
					function (dateObj) {
						return new Date(dateObj.createdAtChat)
					}
				).reverse(),
			}

		/* ****************** CREATE COMPANY ************************ */
		case START_LOADING_CREATE_COMPANY:
			return {
				...state,
				loadingCreateCompany: true,
			}
		case CREATE_COMPANY_SUCCESS:
			return {
				...state,
				entities: [action.payload, ...state.entities],
				loadingCreateCompany: false,
				error: '',
			}
		case CREATE_COMPANY_ERROR:
			return {
				...state,
				loadingCreateCompany: false,
				error: action.payload,
			}
		/* ****************** UPDATE COMPANY BY ID ************************ */
		case START_LOADING_UPDATE_COMPANY_BY_ID:
			return {
				...state,
				loadingUpdateCompany: true,
			}
		case UPDATE_COMPANY_BY_ID_SUCCESS:
			return {
				...state,
				entities: [
					...state.entities.map((company) => {
						if (company._id === action.payload._id)
							return action.payload
						else return company
					}),
				],
				loadingUpdateCompany: false,
				error: '',
			}
		case UPDATE_COMPANY_BY_ID_ERROR:
			return {
				...state,
				data: [],
				loadingUpdateCompany: false,
				error: action.payload,
			}
		/* ****************** DELETE COMPANY ************************ */
		case START_LOADING_DELETE_COMPANY:
			return {
				...state,
				loadingDeleteCompany: true,
			}
		case DELETE_COMPANY_SUCCESS:
			return {
				...state,
				loadingDeleteCompany: false,
				entities: [
					...state.entities.filter(
						(company) => company._id !== action.payload._id
					),
				],
				error: '',
			}
		case DELETE_COMPANY_ERROR:
			return {
				...state,
				data: [],
				loadingDeleteCompany: false,
				error: action.payload,
			}
		/* ****************** GET COMPANY BY USER ID ************************ */
		case START_LOADING_COMPANY_BY_USER_ID:
			return {
				...state,
				loadingGetCompanyByUserId: true,
			}
		case FETCH_COMPANY_BY_USER_ID_SUCCESS:
			return {
				...state,
				loadingGetCompanyByUserId: false,
				companyByUserId: !action.payload?.companyToEdit
					? action.payload?.data
					: state.companyByUserId,
				companyToEdit: action.payload?.companyToEdit
					? action.payload?.data
					: {},
				error: '',
			}
		case FETCH_COMPANY_BY_USER_ID_ERROR:
			return {
				...state,
				loadingGetCompanyByUserId: false,
				error: action.payload,
			}
		default:
			return state
	}
}
