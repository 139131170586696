import styled from 'styled-components'
export const PanierContainer = styled.div`
	.group-addon {
		&:active {
			transform: scale(0.65);
		}
	}
	.input-green input {
		background-color: #08e708;
	}
	.ant-descriptions-row th {
		font-weight: 600;
	}
	.ant-descriptions-bordered .ant-descriptions-item-label {
		padding: 0px 7px;
	}
	.ant-list-item-action {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.ant-form-item {
		margin-bottom: 0px;
	}
	/* .ant-input-number {
		width: 100%;
	} */
	.ant-card-meta-title {
		text-align: left;
	}

	.ant-list-item-meta {
		align-items: center;
	}
	.ant-card-meta-description {
		text-align: left;
	}

	.ant-list-item-meta-avatar {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.ant-ribbon {
		top: -8px;
	}
	.ant-descriptions-view table {
		//width: auto;
	}
	.ventes .ant-collapse-content {
		max-height: 60vh;
		overflow: auto;
	}
	.ventes .ant-list-items {
		display: flex;
		flex-wrap: wrap;
	}
	@media only screen and (max-width: 550px) {
		.ant-list-item-action {
			flex-direction: column;
		}
	}
	@media only screen and (max-width: 1024px) {
		.ant-list-item-meta {
			width: 100%;
		}
		.ant-list-item {
			flex-direction: column;
		}
	}
`
