export const START_LOADING_NOTIFICATIONS = 'START_LOADING_NOTIFICATIONS'
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS'
export const FETCH_NOTIFICATIONS_ERROR = 'FETCH_NOTIFICATIONS_ERROR'

export const START_LOADING_CREATE_NOTIFICATION =
	'START_LOADING_CREATE_NOTIFICATION'
export const CREATE_NOTIFICATION_SUCCESS = 'CREATE_NOTIFICATION_SUCCESS'
export const CREATE_NOTIFICATION_ERROR = 'CREATE_NOTIFICATION_ERROR'

export const START_LOADING_CREATE_PROF = 'START_LOADING_CREATE_PROF'
export const CREATE_PROF_SUCCESS = 'CREATE_PROF_SUCCESS'
export const CREATE_PROF_ERROR = 'CREATE_PROF_ERROR'

export const START_LOADING_DELETE_NOTIFICATION =
	'START_LOADING_DELETE_NOTIFICATION'
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS'
export const DELETE_NOTIFICATION_ERROR = 'DELETE_NOTIFICATION_ERROR'

export const START_LOADING_UPDATE_NOTIFICATIONS =
	'START_LOADING_UPDATE_NOTIFICATIONS'
export const UPDATE_NOTIFICATIONS_SUCCESS = 'UPDATE_NOTIFICATIONS_SUCCESS'
export const UPDATE_NOTIFICATIONS_ERROR = 'UPDATE_NOTIFICATIONS_ERROR'
