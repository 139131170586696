import { apolloClient } from '../../graphql'
import {
	CREATE_COMPANY_QUERY,
	//DELETE_COMPANY_BY_ID,
	UPDATE_COMPANY_BY_ID_QUERY,
	//COMPANIES_BY_FIELDS_QUERY,
	COMPANY_BY_USER_ID_QUERY,
	COMPANIES_BY_FIELDS_QUERY,
} from '../../queries/company'

import {
	START_LOADING_COMPANIES,
	FETCH_COMPANIES_SUCCESS,
	FETCH_COMPANIES_ERROR,
	ADD_TO_COMPANIES_SUCCESS,
	START_LOADING_CREATE_COMPANY,
	CREATE_COMPANY_SUCCESS,
	CREATE_COMPANY_ERROR,
	START_LOADING_DELETE_COMPANY,
	DELETE_COMPANY_SUCCESS,
	DELETE_COMPANY_ERROR,
	START_LOADING_UPDATE_COMPANY_BY_ID,
	UPDATE_COMPANY_BY_ID_SUCCESS,
	UPDATE_COMPANY_BY_ID_ERROR,
	START_LOADING_COMPANY_BY_USER_ID,
	FETCH_COMPANY_BY_USER_ID_SUCCESS,
	FETCH_COMPANY_BY_USER_ID_ERROR,
} from './types'

export const getCompaniesByFileds = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_COMPANIES,
		})
		const res = await apolloClient.query({
			query: COMPANIES_BY_FIELDS_QUERY,
			variables,
			fetchPolicy: 'network-only',
		})
		dispatch({
			type: FETCH_COMPANIES_SUCCESS,
			payload: res.data?.findCompaniesByField,
		})
	} catch (error) {
		dispatch({
			type: FETCH_COMPANIES_ERROR,
			payload: error.message,
		})
	}
}

export const addToCompaniesListChat = (company) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_COMPANIES,
		})
		dispatch({
			type: ADD_TO_COMPANIES_SUCCESS,
			payload: company,
		})
	} catch (error) {
		dispatch({
			type: FETCH_COMPANIES_ERROR,
			payload: error.message,
		})
	}
}
/* *************** CREATE COMPANY ************** */
export const createCompany = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_CREATE_COMPANY,
		})

		const res = await apolloClient.mutate({
			mutation: CREATE_COMPANY_QUERY,
			variables,
		})

		dispatch({
			type: CREATE_COMPANY_SUCCESS,
			payload: res.data.createCompany,
		})
	} catch (error) {
		dispatch({
			type: CREATE_COMPANY_ERROR,
			payload: error.message,
		})
	}
}

/* *************** UPDATE COMPANY ************** */
export const updateCompanyById = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_UPDATE_COMPANY_BY_ID,
		})
		const res = await apolloClient.mutate({
			mutation: UPDATE_COMPANY_BY_ID_QUERY,
			variables,
		})
		dispatch({
			type: UPDATE_COMPANY_BY_ID_SUCCESS,
			payload: res.data?.updateCompanyById,
		})
	} catch (error) {
		dispatch({
			type: UPDATE_COMPANY_BY_ID_ERROR,
			payload: error.message,
		})
	}
}
/* *************** DELETE COMPANY ************** */
/* export const deleteCompany = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_DELETE_COMPANY,
		})
		const res = await apolloClient.mutate({
			mutation: DELETE_COMPANY_BY_ID,
			variables,
		})

		dispatch({
			type: DELETE_COMPANY_SUCCESS,
			payload: variables,
		})
	} catch (error) {
		dispatch({
			type: DELETE_COMPANY_ERROR,
			payload: error.message,
		})
	}
} */

/* *************** GET COMPANY BY USER ID************** */
export const getCompanyByIdUser =
	(variables, companyToEdit = false) =>
	async (dispatch) => {
		try {
			dispatch({
				type: START_LOADING_COMPANY_BY_USER_ID,
			})
			const res = await apolloClient.query({
				query: COMPANY_BY_USER_ID_QUERY,
				variables,
				fetchPolicy: 'network-only',
			})
			dispatch({
				type: FETCH_COMPANY_BY_USER_ID_SUCCESS,
				payload: { data: res.data?.getCompanyByIdUser, companyToEdit },
			})
		} catch (error) {
			dispatch({
				type: FETCH_COMPANY_BY_USER_ID_ERROR,
				payload: error.message,
			})
		}
	}
