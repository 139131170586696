import React from 'react'
import { ROLES } from '../../constants/roles'
import { STATUS } from '../../constants/status'
import NotFound from './404'
import WelcomeClient from './WelcomeClient'
import WelcomeProf from './WelcomeProf'
function CustomResult({ status }) {
	const render = {
		[STATUS.PROCESSING]: <WelcomeProf />,
		['404']: <NotFound />,
		[ROLES.CLIENT]: <WelcomeClient />,
	}
	return <>{render[status]}</>
}

export default CustomResult
