import styled from 'styled-components'
export const SearchBarContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	//margin-bottom: 1rem;
	position: ${(props) => (props.withoutSticky ? 'initial' : 'sticky')};
	top: ${(props) => props.top};
	z-index: 999;
	.search-content {
		width: 70%;
		display: flex;
		align-items: center;
	}
	@media screen and (max-width: 700px) {
		margin-bottom: 1rem;
		display: flex;
		top: 48px;
		width: 100%;
	}
	@media screen and (max-width: 991px) {
		.search-content {
			width: 80%;
		}
	}
	@media screen and (max-width: 576px) {
		.search-content {
			width: 100%;
		}
	}
`
