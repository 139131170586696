import { gql } from '@apollo/client'
export const LINEORDERS_BY_FIELDS_QUERY = gql`
	query getLineOrdersByFields(
		$fields: [FieldInput!]!
		$operatorBetweenFields: String
		$options: Options
		$sort: Sort
		$byUserId: String!
		$byNotUserId: String
	) {
		findLineOrdersByField(
			fields: $fields
			operatorBetweenFields: $operatorBetweenFields
			options: $options
			sort: $sort
			byUserId: $byUserId
			byNotUserId: $byNotUserId
		) {
			entities {
				... on ByLineOffers {
					_id
					total
					data {
						_id
						quantity
						unitPrice
						unitPriceFinal
						type
						article {
							marque
							ref
							refOrigin
							description
						}
						lineOffer {
							_id
							offer {
								_id
							}
						}
						order {
							numRef
							company {
								_id
							}
							_id
						}
						company {
							title
							phoneNumber
							email
							_id
						}
						orderedBy {
							title
							phoneNumber
							email
							_id
						}
					}
				}
				... on LineOrder {
					_id
					quantity
					unitPrice
					unitPriceFinal
					type
					status
					article {
						marque
						ref
						refOrigin
						description
					}
					lineOffer {
						_id
						offer {
							_id
						}
					}
					order {
						numRef
						company {
							_id
						}
						_id
					}
					company {
						title
						phoneNumber
						email
						_id
					}
					orderedBy {
						title
						phoneNumber
						email
						_id
					}
				}
			}
			totalCount
			totalPages
			page
			limit
			hasNextPage
			hasPreviousPage
		}
	}
`
export const LINEORDER_BY_ID_QUERY = gql`
	query getLineOrderById($id: String!) {
		getLineOrderById(id: $id) {
			_id
			ref
			refOrigin
			marque
			description
			img
			company {
				title
				matFiscal
			}
		}
	}
`

export const CREATE_LINESORDER_MUTATION = gql`
	mutation createLinesOrder($lineOrders: [LineOrderInput!]!) {
		createLinesOrder(input: { lineOrders: $lineOrders }) {
			_id
			quantity
			unitPrice
			unitPriceFinal
			status
			type
		}
	}
`

export const DELETE_LINESORDER_BY_ID_MUTATION = gql`
	mutation deleteLinesOrder(
		$ids: [String!]!
		$orderedBy: String
		$numRef: Float
	) {
		deleteLinesOrder(
			input: { ids: $ids, orderedBy: $orderedBy, numRef: $numRef }
		) {
			_id
			quantity
			unitPrice
			unitPriceFinal
			type
			article {
				marque
				ref
				refOrigin
				description
			}
			lineOffer {
				_id
				offer {
					_id
				}
			}
			order {
				numRef
				company {
					_id
				}
				_id
			}
			company {
				title
				phoneNumber
				email
				_id
			}
			orderedBy {
				title
				phoneNumber
				email
				_id
			}
		}
	}
`

export const DELETE_LINEORDERS_BY_ID_MUTATION = gql`
	mutation deleteLinesOrder($_id: String!) {
		deleteLineOrder(input: { _id: $_id })
	}
`

export const LINEORDERSS_QUERY = gql`
	query getLineOrders {
		findAllLineOrders {
			_id
			title
			numRef
			type
			status
			company {
				title
				matFiscal
			}
		}
	}
`

export const UPLOAD_MULTIPLE_LINEORDERSS_MUTATION = gql`
	mutation uploadMultipleLineOrders($file: Upload!) {
		uploadMultipleLineOrders(file: $file)
	}
`

export const UPDATE_LINEORDERS_BY_ID_MUTATION = gql`
	mutation updateLineOrder(
		$_id: String!
		$quantity: Float
		$unitPrice: Float
		$unitPriceFinal: Float
		$type: TypeOfferOrder
		$status: Statuss
		$order: String
		$lineOffer: String
		$orderedBy: String
		$company: String
	) {
		updateLineOrder(
			input: {
				_id: $_id
				quantity: $quantity
				unitPrice: $unitPrice
				unitPriceFinal: $unitPriceFinal
				type: $type
				status: $status
				order: $order
				lineOffer: $lineOffer
				orderedBy: $orderedBy
				company: $company
			}
		) {
			_id
			quantity
			unitPrice
			unitPriceFinal
			type
			status
			article {
				marque
				ref
				refOrigin
				description
			}
			lineOffer {
				_id
				offer {
					_id
				}
			}
			order {
				numRef
				company {
					_id
				}
				_id
			}
			company {
				title
				phoneNumber
				email
				_id
			}
			orderedBy {
				title
				phoneNumber
				email
				_id
			}
		}
	}
`
