import { ROLES } from './roles'

export const HOME_URL = '/'
export const INSCRIPTION_URL = '/inscription'
export const LOGIN_URL = '/login'
export const REGISTER_URL = '/register'

export const USER_EDIT_URL = '/users/:userId/edit'
export const USER_CREATE_URL = '/users/create'
export const USER_URL = '/users/:userId'
export const USER_NOT_ACTIVE_URL = '/not-active'
export const USER_CLIENT_DETAIL_URL = '/users/:_id'
export const ARTICLE_DETAIL_URL = '/articles/:_id'
export const RESULT_WELCOME_PROF_URL = '/welcome'
/* Dashboard Paths */
export const DASHBOARD_URL = '/dash/dashboard'
export const OFFERS_URL = '/dash/offres/:param'
export const PURSHASES_URL = '/dash/achats/:param'
export const ARTICLES_URL = '/dash/articles'
export const OFFERS_BY_USER_ID_URL = '/dash/offres/:_id'
export const USERS_URL = '/dash/users'
export const USER_PROF_DETAIL_URL = '/dash/users/:_id'
export const NOTIFICATIONS_URL = '/dash/notifications'
export const NOTIFICATIONS_CLIENT_URL = '/notifications'
export const CHAT_URL = '/dash/chat'

export const PANIER_URL = '/dash/panier'

export const titlesPath = (role, pathname) => {
	const paths = {
		[DASHBOARD_URL]: 'Tableau de bord',
		['/dash/offres/1']: 'Offres',
		['/dash/offres/2']: 'Vos Offres',
		['/dash/offres/3']: role == ROLES.ADMIN ? 'Offres' : 'Vos Offres',
		['/dash/achats/1']: 'Achats',
		['/dash/achats/2']: 'Vos Achats',
		['/dash/achats/3']: role == ROLES.ADMIN ? 'Achats' : 'Vos Achats',
		[ARTICLES_URL]: 'Article',
		[USERS_URL]: 'Utilisateurs',
		[USER_PROF_DETAIL_URL]: 'Profile',
		[NOTIFICATIONS_URL]: 'Notifications',
		[USER_PROF_DETAIL_URL]: 'Profile',
		[CHAT_URL]: 'Chat',
	}

	return paths[pathname]
}
