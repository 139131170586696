import { Button, Table } from 'antd'
import styled from 'styled-components'

export const FormContainer = styled.div`
	width: 100%;
	margin: 0 auto;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: center;

	> .form-box-shadow {
		background-color: #fff;
		width: ${(props) => (props.Width ? props.Width : '100%')};
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		border-radius: 10px;
		padding: 0 2%;
		padding-top: 0;
		box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.17);
	}
	.ant-space,
	.ant-space-item {
		width: 100%;
	}
	.ant-form-item {
		margin-bottom: 1%;
	}
`

export const PrimaryButton = styled(Button)`
	color: ${(props) => props.theme.white} !important;
	background-color: ${(props) => props.theme.secondary} !important;
	border: 1px solid ${(props) => props.theme.secondary} !important;
	border-radius: 10px;
	font-weight: 500;
	font-size: 1rem;
	&:hover {
		background-color: ${(props) => props.theme.primary} !important;
		color: ${(props) => props.theme.white} !important;
		border: 1px solid ${(props) => props.theme.secondary} !important;
	}
	&:active {
		transform: scale(0.97);
	}
`

export const CustomButton = styled(Button)`
	margin: ${(props) => props.margin} !important;
	color: ${(props) => props.colorText} !important;
	background-color: ${(props) => props.bgColor} !important;
	border: 1px solid ${(props) => props.colorText} !important;
	border-radius: ${(props) => props.borderRadius} !important;
	font-weight: 500;
	font-size: 0.9rem;
	letter-spacing: 1.3px;
	box-shadow: 2px 2px 15px -7px #4c4c4c;
	&:hover {
		background-color: ${(props) => props.colorText} !important;
		color: ${(props) => props.bgColor} !important;
		border: 1px solid ${(props) => props.bgColor} !important;
	}
	&:hover svg {
		color: ${(props) => props.colorHoverIcon};
	}
	&:active {
		transform: scale(0.97);
	}
`

export const CustomTableStyle = styled(Table)`
	tbody tr td {
		padding: ${(props) => (props.padding ? props.padding : '16px 16px')};
	}
`

export const Scale = styled.div`
	//width: fit-content;
	&:hover {
		transform: translateY(10%) scale(1.2);
	}
`
