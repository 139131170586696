import { Card, Col, Divider, Modal, Row, Space, Tag, Typography } from 'antd'
import React, { useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useTheme } from 'styled-components'
import { withDigits } from '../../../constants/fn'
import images from '../../../constants/images'
import { CustomButton } from '../../../global/GlobalStyles'
import '../detail-offer.css'

function DetailOffer({
	lineOffer,
	isModalDetailVisible,
	setIsModalDetailVisible,
}) {
	const theme = useTheme()
	return (
		<Modal
			className='modal-detail-offer'
			title={`Offre #${withDigits(lineOffer?.numRef)}`}
			visible={isModalDetailVisible}
			cancelText='Quitter'
			width={'60em'}
			onCancel={() => setIsModalDetailVisible(false)}
			footer={[
				<CustomButton
					size='large'
					colorText={theme.white}
					bgColor={'#ff4d4f'}
					borderRadius='7px'
					className='buy--btn'
					onClick={() => setIsModalDetailVisible(false)}
				>
					<strong>Quitter</strong>
				</CustomButton>,
			]}
		>
			<Card
				className='card-product'
				headStyle={{ minHeight: '0', padding: '0' }}
				bodyStyle={{ padding: '0', minHeight: '50vh' }}
			>
				<Row justify='space-around' className='product'>
					<Col className='product__photo'>
						<div className='photo-container'>
							<div className='photo-main'>
								<div className='controls'>
									<Tag color='#f50'>
										<Space>
											<Typography.Text
												strong
												style={{ color: 'white' }}
											>
												REF:
											</Typography.Text>
											<Typography.Text
												strong
												copyable={{
													text: lineOffer?.ref,
													tooltips: [
														'Copier',
														'Copié',
													],
												}}
												style={{ color: 'white' }}
											>
												{lineOffer?.ref}
											</Typography.Text>
										</Space>
									</Tag>
									{/* <i className='material-icons'>
										favorite_border
									</i> */}
								</div>
								<div>
									<LazyLoadImage
										height={'75%'}
										/* afterLoad={() => {
												setLoading(false)
											}}
											beforeLoad={() => setLoading(true)} */
										effect='blur'
										src={
											lineOffer?.img
												? `https://fad.tn/images/logo/${lineOffer?.marque}.png`
												: images.DEFAULT_ARTICLE_PHOTO
										}
										width={'100%'}
									/>
								</div>
							</div>
							{/* <Row
									gutter={8}
									justify='center'
									className='photo-album'
								>
									<Col>
										<Image
											width={90}
											height={70}
											src='https://www.caaquebec.com/fileadmin/images/Sur_la_route/Conseil/485-Pieces-autos-expliquees-Calipers.jpg'
											alt='green apple'
										/>
									</Col>
									<Col>
										<Image
											width={90}
											height={70}
											src='https://www.caaquebec.com/fileadmin/images/Sur_la_route/Conseil/485-Pieces-autos-expliquees-Calipers.jpg'
											alt='green apple'
										/>
									</Col>
								</Row> */}
						</div>
					</Col>
					<Col className='product__info'>
						<div className='title'>
							<Tag
								color='green'
								style={{
									marginLeft: '0',
									fontWeight: 'bold',
									float: 'right',
									position: 'relative',
									top: '-10px',
									right: '-34px',
								}}
							>
								<Typography.Text
									style={{
										color: '#333',
										fontSize: '14px',
									}}
								>
									{lineOffer?.marque}
								</Typography.Text>
							</Tag>
							<h1>{lineOffer?.description}</h1>
							<span>REF: {lineOffer?.ref}</span>
							<Divider type='vertical' />
							<span>REF ORIGIN: {lineOffer?.refOrigin}</span>
						</div>
						<div className='price'>
							<span>{lineOffer?.unitPriceFinal}</span>
							TND
						</div>
						<div className='description'>
							<h3>Quantité disponible</h3>
							<Typography.Paragraph>
								{lineOffer?.quantity}
							</Typography.Paragraph>
						</div>
						{/* <div className='variant'>
							<h3>SELECT A COLOR</h3>
							<ul>
								<li>
									<img
										src='https://res.cloudinary.com/john-mantas/image/upload/v1537302064/codepen/delicious-apples/green-apple2.png'
										alt='green apple'
									/>
								</li>
								<li>
									<img
										src='https://res.cloudinary.com/john-mantas/image/upload/v1537302752/codepen/delicious-apples/yellow-apple.png'
										alt='yellow apple'
									/>
								</li>
								<li>
									<img
										src='https://res.cloudinary.com/john-mantas/image/upload/v1537302427/codepen/delicious-apples/orange-apple.png'
										alt='orange apple'
									/>
								</li>
								<li>
									<img
										src='https://res.cloudinary.com/john-mantas/image/upload/v1537302285/codepen/delicious-apples/red-apple.png'
										alt='#ed3b57 apple'
									/>
								</li>
							</ul>
						</div> */}
						<div className='description'>
							{/* <h3>DESCRIPTION:</h3>
								<Typography.Paragraph>
									lineOffer?.description}
								</Typography.Paragraph> */}
							{/* <ul>
								<li>Apples are nutricious</li>
								<li>Apples may be good for weight loss</li>
								<li>Apples may be good for bone health</li>
								<li>
									They're linked to a lowest risk of diabetes
								</li>
							</ul> */}
						</div>
						{/* <CustomButton
								size='large'
								colorText='#fff'
								bgColor='#333'
								borderRadius='7px'
								className='buy--btn'
							>
								ADD TO CART
							</CustomButton> */}
					</Col>
				</Row>
			</Card>
		</Modal>
	)
}

export default DetailOffer
