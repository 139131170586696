import React from 'react'
import { Button, Result } from 'antd'
import { useHistory } from 'react-router'
import { ROLES } from '../../constants/roles'
import { useSelector, shallowEqual } from 'react-redux'
import { DASHBOARD_URL, HOME_URL } from '../../constants/paths'

function NotFound() {
	const history = useHistory()
	const user = useSelector((state) => state.auth?.user, shallowEqual)

	const isAdmin = user?.roles?.includes(ROLES.ADMIN)
	const isProf = user?.roles?.includes(ROLES.PROF)
	return (
		<Result
			status='404'
			style={{
				height: '100%',
				background: '#fff',
			}}
			title='404 NOT FOUND'
			subTitle='Sorry, your page Not found.'
			extra={
				<>
					<Button type='primary' onClick={() => history.push('/')}>
						Back Home
					</Button>
					{isAdmin || isProf ? (
						<Button
							type='primary'
							onClick={() => {
								isAdmin
									? history.push(DASHBOARD_URL)
									: isProf
									? history.push('/dash/offres/1')
									: history.push(HOME_URL)
							}}
						>
							Back Dashboard
						</Button>
					) : (
						''
					)}
				</>
			}
		/>
	)
}

export default NotFound
