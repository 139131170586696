import styled from 'styled-components'

export const Title = styled.div`
	width: 150px;
	font-size: 25px;
	font-weight: 600;
	color: ${(props) => props.theme.white};
	padding-left: 0.5em;
	@media only screen and (max-width: 500px) {
		font-size: 18px;
		width: 110px;
	}
`

export const HeaderContainer = styled.div`
	//background: white;
	background: ${(props) => props.theme.secondary};
	margin-bottom: 0.5em;
	.site-page-header {
		flex-wrap: nowrap;
		background: white;
		padding: 1%;
	}
	.ant-page-header-heading-left {
		//width: auto;
	}
	.ant-page-header-heading-extra {
		flex-grow: 1;
	}
	.ant-page-header-heading-left button {
		height: 54px;
		background: ${(props) => props.theme.secondary};
		border-radius: 20px;
	}
	.ant-page-header-heading-left button span {
		font-weight: 700;
	}
	.ant-page-header-heading-left button > span:last-child {
		font-size: 20px;
	}
	@media only screen and (max-width: 650px) {
		.ant-page-header-heading-extra {
			flex-grow: unset;
		}
	}
`

export const RestaurantTitle = styled.div`
	align-self: center;
	display: flex;
	flex-direction: column;
	//osition: absolute;
	h3 {
		font-size: 22px;
		font-weight: 700;
	}
	h1,
	h3 {
		margin: auto;
	}
	img {
		align-self: center;
		border-radius: 18px;
		height: 81px;
	}
	@media only screen and (max-width: 650px) {
		h3 {
			font-size: 18px;
		}
	}
	@media only screen and (max-width: 422px) {
		h3 {
			font-size: 16px;
		}
	}
	@media only screen and (max-width: 396px) {
		h3 {
			font-size: 14px;
		}
	}
	@media only screen and (max-width: 422px) {
		h3 {
			font-size: 12px;
		}
	}
	@media only screen and (max-width: 345px) {
		h3 {
			font-size: 10px;
		}
	}
	@media only screen and (max-width: 302px) {
		h3 {
			font-size: 8px;
		}
	}
`
