import { apolloClient } from '../../graphql'
import { LOGIN_QUERY, REGISTRE_QUERY } from '../../queries/user'

import {
	LOGIN_SUCCESS,
	LOGIN_ERROR,
	LOGOUT_SUCCESS,
	REGISTER_SUCCESS,
	REGISTER_ERROR,
	START_LOADING_REGISTER,
	START_LOADING_LOGIN,
	START_LOADING_UPDATE_PROFILE,
	UPDATE_PROFILE_SUCCESS,
	UPDATE_PROFILE_ERROR,
} from './types'

/* ****************** LOGIN  ************************ */
export const login =
	({ setIsModalVisible, ...variables }) =>
	async (dispatch) => {
		try {
			dispatch({
				type: START_LOADING_LOGIN,
			})
			const res = await apolloClient.mutate({
				mutation: LOGIN_QUERY,
				variables,
			})
			dispatch({
				type: LOGIN_SUCCESS,
				payload: res.data.login,
			})
			setIsModalVisible(false)
		} catch (error) {
			dispatch({
				type: LOGIN_ERROR,
				payload: error.message,
			})
		}
	}

/* ****************** REGISTER  ************************ */
export const register =
	({ confirm, ...variables }) =>
	async (dispatch) => {
		try {
			dispatch({
				type: START_LOADING_REGISTER,
			})
			const res = await apolloClient.mutate({
				mutation: REGISTRE_QUERY,
				variables,
			})

			dispatch({
				type: REGISTER_SUCCESS,
				payload: res.data.register,
			})
		} catch (error) {
			dispatch({
				type: REGISTER_ERROR,
				payload: error.message,
			})
		}
	}

/* ****************** LOGOUT  ************************ */
export const logout = () => async (dispatch) => {
	const res = await localStorage.clear()
	dispatch({
		type: LOGOUT_SUCCESS,
		payload: res,
	})
}

export const updateProfile =
	({ user, companyByUserId }) =>
	async (dispatch) => {
		try {
			dispatch({
				type: START_LOADING_UPDATE_PROFILE,
			})
			dispatch({
				type: UPDATE_PROFILE_SUCCESS,
				payload: { user, companyByUserId },
			})
		} catch (error) {
			dispatch({
				type: UPDATE_PROFILE_ERROR,
				payload: error.message,
			})
		}
	}
