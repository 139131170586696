import { AutoComplete, Form, Input, InputNumber, Col, Row, Tag } from 'antd'
import React from 'react'
import { TYPE_OFFER } from '../../constants/type_offer'

const renderItem = (ref, refOrigin, key) => ({
	key,
	value: ref,
	label: (
		<Row justify='space-between' align='center'>
			<Col>{ref}</Col>
			<Col>
				<Tag color={'orange'}>
					<span>{refOrigin}</span>
				</Tag>
			</Col>
		</Row>
	),
})

function RenderInputEditRowListOffers({
	type = 'text',
	propsForm = {},
	record,
	dataAuto,
	currentEditFormRow,
	onSearch = () => {},
}) {
	let render = ''
	switch (type) {
		case 'number':
			render = (
				<Form.Item
					style={{
						margin: 0,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
					name={propsForm.name}
					{...propsForm}
				>
					<InputNumber
						style={{
							width: propsForm.name !== 'quantity' ? 130 : 100,
						}}
						precision={propsForm.name == 'quantity' ? 0 : 3}
						defaultValue={record[propsForm.name]}
						keyboard={true}
						addonAfter={propsForm.name == 'quantity' ? null : 'dt'}
						min={1}
					/>
				</Form.Item>
			)
			break
		case 'autoComplete':
			render = (
				<Form.Item
					style={{
						margin: 0,
					}}
					{...propsForm}
				>
					<AutoComplete
						options={dataAuto?.map(({ ref, _id, refOrigin }, key) =>
							renderItem(ref, refOrigin, _id)
						)}
						dropdownMatchSelectWidth={280}
						onSelect={(data, options) => {
							const article = dataAuto?.find(
								(item) => item._id == options?.key
							)
							currentEditFormRow.setFieldsValue({
								...article,
							})
						}}
						onSearch={onSearch}
						placeholder='input here'
						defaultValue={record?.ref}
					/>
				</Form.Item>
			)
			break
		case 'text':
			render = (
				<Form.Item
					style={{
						margin: 0,
					}}
					{...propsForm}
				>
					<Input />
				</Form.Item>
			)

			break
		case 'textArea':
			render = (
				<Form.Item
					style={{
						margin: 0,
					}}
					{...propsForm}
				>
					<Input.TextArea />
				</Form.Item>
			)

			break
		default:
			break
	}
	return render
}

export default RenderInputEditRowListOffers
