import { gql } from '@apollo/client'
export const ORDERS_BY_FIELDS_QUERY = gql`
	query getOrdersByFields(
		$fields: [FieldInput!]!
		$operatorBetweenFields: String
		$options: Options
		$sort: Sort
		$byUserId: String!
	) {
		findOrdersByField(
			fields: $fields
			operatorBetweenFields: $operatorBetweenFields
			options: $options
			sort: $sort
			byUserId: $byUserId
		) {
			entities {
				_id
				numRef
				type
				marque
				ref
				description
			}
			totalCount
			totalPages
			page
			limit
			hasNextPage
			hasPreviousPage
		}
	}
`

export const ORDERS_BY_FIELDS_MORE_QUERY = gql`
	query getOrdersByFieldsMore(
		$fields: [FieldInput!]!
		$operatorBetweenFields: String
		$options: Options
		$sort: Sort
		$byUserId: String!
	) {
		findOrdersByFieldMore(
			fields: $fields
			operatorBetweenFields: $operatorBetweenFields
			options: $options
			sort: $sort
			byUserId: $byUserId
		) {
			entities {
				_id
				title
				numRef
				type
				company {
					title
					matFiscal
				}
			}
			totalCount
			totalPages
			page
			limit
			hasNextPage
			hasPreviousPage
		}
	}
`
export const ORDER_BY_ID_QUERY = gql`
	query getOrderById($id: String!) {
		getOrderById(id: $id) {
			_id
			ref
			marque
			description
			img
			company {
				title
				matFiscal
			}
		}
	}
`

export const CREATE_ORDER_MUTATION = gql`
	mutation createOrder(
		$numRef: Float
		$comment: String
		$company: String!
		$orderedBy: String!
		$numRefOffer: Float
		$ref: String
		$unitPrice: Float
	) {
		createOrder(
			input: {
				numRef: $numRef
				company: $company
				orderedBy: $orderedBy
				comment: $comment
				numRefOffer: $numRefOffer
				ref: $ref
				unitPrice: $unitPrice
			}
		) {
			_id
			numRef
			exist
			company {
				matFiscal
			}
			orderedBy {
				matFiscal
			}
		}
	}
`

export const DELETE_ORDER_BY_ID_MUTATION = gql`
	mutation deleteOrder($_id: String!) {
		deleteOrder(input: { _id: $_id })
	}
`

export const ORDERS_QUERY = gql`
	query getOrders {
		findAllOrders {
			_id
			title
			numRef
			type
			company {
				title
				matFiscal
			}
		}
	}
`

export const UPLOAD_MULTIPLE_ORDERS_MUTATION = gql`
	mutation uploadMultipleOrders($file: Upload!) {
		uploadMultipleOrders(file: $file)
	}
`
