export const START_LOADING_LINEORDERS = 'START_LOADING_LINEORDERS'
export const FETCH_LINEORDERS_SUCCESS = 'FETCH_LINEORDERS_SUCCESS'
export const FETCH_LINEORDERS_ERROR = 'FETCH_LINEORDERS_ERROR'

export const UPDATE_LIST_LINEORDERS = 'UPDATE_LIST_LINEORDERS'

export const START_LOADING_CREATE_LINESORDER = 'START_LOADING_CREATE_LINESORDER'
export const CREATE_LINESORDER_SUCCESS = 'CREATE_LINESORDER_SUCCESS'
export const CREATE_LINESORDER_ERROR = 'CREATE_LINESORDER_ERROR'

export const START_LOADING_LINEORDER_BY_ID = 'START_LOADING_LINEORDER_BY_ID'
export const LINEORDER_BY_ID_SUCCESS = 'LINEORDER_BY_ID_SUCCESS'
export const LINEORDER_BY_ID_ERROR = 'LINEORDER_BY_ID_ERROR'

export const START_LOADING_DELETE_LINESORDER = 'START_LOADING_DELETE_LINESORDER'
export const DELETE_LINESORDER_SUCCESS = 'DELETE_LINESORDER_SUCCESS'
export const DELETE_LINESORDER_ERROR = 'DELETE_LINESORDER_ERROR'

export const START_LOADING_UPDATE_LINEORDER = 'START_LOADING_UPDATE_LINEORDER'
export const UPDATE_LINEORDER_SUCCESS = 'UPDATE_LINEORDER_SUCCESS'
export const UPDATE_LINEORDER_ERROR = 'UPDATE_LINEORDER_ERROR'
