export const START_LOADING_USERS = 'START_LOADING_USERS'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR'

export const START_LOADING_USER_BY_ID = 'START_LOADING_USER_BY_ID'
export const USER_BY_ID_SUCCESS = 'USER_BY_ID_SUCCESS'
export const USER_BY_ID_ERROR = 'USER_BY_ID_ERROR'

export const START_LOADING_CREATE_USER = 'START_LOADING_CREATE_USER'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR'

export const START_LOADING_CREATE_PROF = 'START_LOADING_CREATE_PROF'
export const CREATE_PROF_SUCCESS = 'CREATE_PROF_SUCCESS'
export const CREATE_PROF_ERROR = 'CREATE_PROF_ERROR'

export const START_LOADING_DELETE_USER = 'START_LOADING_DELETE_USER'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR'

export const START_LOADING_UPDATE_USER = 'START_LOADING_UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'
