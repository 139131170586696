import { TYPE_OFFER } from '../../constants/type_offer'
import { apolloClient } from '../../graphql'
import {
	CREATE_ORDER_MUTATION,
	DELETE_ORDER_BY_ID_MUTATION,
	UPDATE_ORDER_BY_ID_QUERY,
	ORDERS_BY_FIELDS_QUERY,
	ORDER_BY_ID_QUERY,
} from '../../queries/order'
import { createLinesOrder, updateLineOrder } from '../lineOrder/actions'

import {
	START_LOADING_ORDERS,
	FETCH_ORDERS_SUCCESS,
	FETCH_ORDERS_ERROR,
	START_LOADING_CREATE_ORDER,
	CREATE_ORDER_SUCCESS,
	CREATE_ORDER_ERROR,
	START_LOADING_DELETE_ORDER,
	DELETE_ORDER_SUCCESS,
	DELETE_ORDER_ERROR,
	START_LOADING_UPDATE_ORDER,
	UPDATE_ORDER_SUCCESS,
	UPDATE_ORDER_ERROR,
	START_LOADING_ORDER_BY_ID,
	ORDER_BY_ID_SUCCESS,
	ORDER_BY_ID_ERROR,
} from './types'

/* *************** ORDERS BY FIELDS ************** */
export const getOrdersByFields = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_ORDERS,
		})
		const res = await apolloClient.query({
			query: ORDERS_BY_FIELDS_QUERY,
			variables,
			fetchPolicy: 'network-only',
		})
		dispatch({
			type: FETCH_ORDERS_SUCCESS,
			payload: res.data?.findOrdersByField,
		})
	} catch (error) {
		dispatch({
			type: FETCH_ORDERS_ERROR,
			payload: error.message,
		})
	}
}
/* *************** CREATE ORDER ************** */
export const createOrder =
	({ lineOrders, ...variables }, type) =>
	async (dispatch) => {
		try {
			await dispatch({
				type: START_LOADING_CREATE_ORDER,
			})

			const res = await apolloClient.mutate({
				mutation: CREATE_ORDER_MUTATION,
				variables,
			})

			await dispatch({
				type: CREATE_ORDER_SUCCESS,
				payload: res.data.createOrder,
			})
			lineOrders.map((line) => {
				line.order = res.data.createOrder._id
				line.type = type
					? type
					: line.type == TYPE_OFFER.SELL
					? TYPE_OFFER.PURCHASE
					: TYPE_OFFER.SELL
				return line
			})
			if (!res.data.createOrder?.exist) {
				if (!type) {
					await dispatch(createLinesOrder(lineOrders))
				}
			}
		} catch (error) {
			await dispatch({
				type: CREATE_ORDER_ERROR,
				payload: error.message,
			})
		}
	}

/* *************** ORDER BY ID ************** */
export const getOrderById = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_ORDER_BY_ID,
		})
		const res = await apolloClient.query({
			query: ORDER_BY_ID_QUERY,
			variables,
		})
		dispatch({
			type: ORDER_BY_ID_SUCCESS,
			payload: res.data.getOrderById,
		})
	} catch (error) {
		dispatch({
			type: ORDER_BY_ID_ERROR,
			payload: error.message,
		})
	}
}

/* *************** UPDATE ORDER ************** */
/* export const updateOrder = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_UPDATE_ORDER,
		})
		const res = await apolloClient.mutate({
			mutation: UPDATE_ORDER_BY_ID_QUERY,
			variables,
		})
		dispatch({
			type: UPDATE_ORDER_SUCCESS,
			payload: res.data.updateOrder,
		})
	} catch (error) {
		dispatch({
			type: UPDATE_ORDER_ERROR,
			payload: error.message,
		})
	}
} */
/* *************** DELETE ORDER ************** */
export const deleteOrder = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_DELETE_ORDER,
		})
		const res = await apolloClient.mutate({
			mutation: DELETE_ORDER_BY_ID_MUTATION,
			variables,
		})

		dispatch({
			type: DELETE_ORDER_SUCCESS,
			payload: variables,
		})
	} catch (error) {
		dispatch({
			type: DELETE_ORDER_ERROR,
			payload: error.message,
		})
	}
}
