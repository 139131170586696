import { STATUS } from '../../constants/status'
import { TYPE_OFFER } from '../../constants/type_offer'
import { apolloClient } from '../../graphql'
import {
	CREATE_LINESORDER_MUTATION,
	DELETE_LINEORDER_BY_ID_MUTATION,
	UPDATE_LINEORDER_BY_ID_QUERY,
	LINEORDERS_BY_FIELDS_QUERY,
	LINEORDER_BY_ID_QUERY,
	UPDATE_LINEORDERS_BY_ID_MUTATION,
	DELETE_LINESORDER_BY_ID_MUTATION,
} from '../../queries/lineOrder'
import { UPDATE_CART_SUCCESS } from '../cart/types'
import { UPDATE_LINEOFFER_SUCCESS } from '../lineOffer/types'

import {
	START_LOADING_LINEORDERS,
	FETCH_LINEORDERS_SUCCESS,
	FETCH_LINEORDERS_ERROR,
	START_LOADING_CREATE_LINESORDER,
	CREATE_LINESORDER_SUCCESS,
	CREATE_LINESORDER_ERROR,
	START_LOADING_DELETE_LINESORDER,
	DELETE_LINESORDER_SUCCESS,
	DELETE_LINESORDER_ERROR,
	START_LOADING_UPDATE_LINEORDER,
	UPDATE_LINEORDER_SUCCESS,
	UPDATE_LINEORDER_ERROR,
	START_LOADING_LINEORDER_BY_ID,
	LINEORDER_BY_ID_SUCCESS,
	LINEORDER_BY_ID_ERROR,
	UPDATE_LIST_LINEORDERS,
} from './types'

/* *************** LINEORDERS BY FIELDS ************** */
export const getLineOrdersByFields =
	(variables, status = STATUS.ACTIVE) =>
	async (dispatch) => {
		/* variables?.fields.push({
			field: 'status',
			fieldValue: STATUS.PROCESSING,
			specific: true, 
		}) */
		try {
			dispatch({
				type: START_LOADING_LINEORDERS,
			})
			const res = await apolloClient.query({
				query: LINEORDERS_BY_FIELDS_QUERY,
				variables,
				fetchPolicy: 'network-only',
			})
			dispatch({
				type: FETCH_LINEORDERS_SUCCESS,
				payload: { data: res.data?.findLineOrdersByField, status },
			})
		} catch (error) {
			dispatch({
				type: FETCH_LINEORDERS_ERROR,
				payload: error.message,
			})
		}
	}

/* *************** Update List Line Orders  ************** */
export const updateListLineOrders =
	(order, deleted = false) =>
	async (dispatch) => {
		try {
			dispatch({
				type: UPDATE_LIST_LINEORDERS,
				payload: { order, deleted },
			})
		} catch (error) {
			/* dispatch({
			type: FETCH_LINEORDERS_ERROR,
			payload: error.message,
		}) */
		}
	}
/* *************** CREATE LINEORDER ************** */
export const createLinesOrder = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_CREATE_LINESORDER,
		})

		const res = await apolloClient.mutate({
			mutation: CREATE_LINESORDER_MUTATION,
			variables: {
				lineOrders: variables,
			},
		})
		dispatch({
			type: CREATE_LINESORDER_SUCCESS,
			payload: res.data.createLineOrder,
		})
		if (variables[0]?.type == TYPE_OFFER.SELL) {
			dispatch({
				type: UPDATE_LINEOFFER_SUCCESS,
				payload: { lineOffer: variables[0]?.lineOffer, reserved: true },
			})
			dispatch({
				type: UPDATE_CART_SUCCESS,
				payload: {
					_id: variables[0]?.lineOffer,
					qtePriceCommande: variables[0]?.unitPrice,
				},
			})
		}
	} catch (error) {
		dispatch({
			type: CREATE_LINESORDER_ERROR,
			payload: error.message,
		})
	}
}

/* *************** LINEORDER BY ID ************** */
export const getLineOrderById = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_LINEORDER_BY_ID,
		})
		const res = await apolloClient.query({
			query: LINEORDER_BY_ID_QUERY,
			variables,
		})
		dispatch({
			type: LINEORDER_BY_ID_SUCCESS,
			payload: res.data.getLineOrderById,
		})
	} catch (error) {
		dispatch({
			type: LINEORDER_BY_ID_ERROR,
			payload: error.message,
		})
	}
}

/* *************** UPDATE LINEORDER ************** */
export const updateLineOrder = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_UPDATE_LINEORDER,
		})
		const res = await apolloClient.mutate({
			mutation: UPDATE_LINEORDERS_BY_ID_MUTATION,
			variables,
		})
		dispatch({
			type: UPDATE_LINEORDER_SUCCESS,
			payload: res.data.updateLineOrder,
		})
	} catch (error) {
		dispatch({
			type: UPDATE_LINEORDER_ERROR,
			payload: error.message,
		})
	}
}
/* *************** DELETE LINEORDER ************** */
/* export const deleteLineOrder = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_DELETE_LINESORDER,
		})
		const res = await apolloClient.mutate({
			mutation: DELETE_LINEORDER_BY_ID_MUTATION,
			variables,
		})

		dispatch({
			type: DELETE_LINESORDER_SUCCESS,
			payload: variables,
		})
	} catch (error) {
		dispatch({
			type: DELETE_LINESORDER_ERROR,
			payload: error.message,
		})
	}
} */

/* *************** DELETE LINESORDER ************** */
export const deleteLinesOrder = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_DELETE_LINESORDER,
		})
		const res = await apolloClient.mutate({
			mutation: DELETE_LINESORDER_BY_ID_MUTATION,
			variables,
		})

		dispatch({
			type: DELETE_LINESORDER_SUCCESS,
			payload: res.data.deleteLinesOrder,
		})
	} catch (error) {
		dispatch({
			type: DELETE_LINESORDER_ERROR,
			payload: error.message,
		})
	}
}
