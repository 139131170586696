import { Form, InputNumber } from 'antd'
import React from 'react'

function EditInput() {
	return (
		<>
			<Form.Item
				style={{
					margin: '0.1em',
					width: 160,
				}}
				label='Qte-Dispo'
				name={'quantity'}
			>
				<InputNumber precision={0} keyboard={true} min={1} />
			</Form.Item>
			<Form.Item label='PU.TTC' name={'unitPrice'}>
				<InputNumber
					style={{
						margin: '0.1em',
						width: 150,
					}}
					precision={3}
					addonAfter='dt'
					keyboard={true}
					min={1}
				/>
			</Form.Item>
		</>
	)
}

export default EditInput
