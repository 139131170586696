import React from 'react'
import { notification, Button } from 'antd'
import { ROLES } from '../../constants/roles'
import { NOTIFICATIONS_URL } from '../../constants/paths'

const cartNotification = ({ roles, ...rest }) => {
	const key = `open${Date.now()}`
	const btn = roles?.includes(ROLES.ADMIN) ? (
		<a href={NOTIFICATIONS_URL}>
			<Button
				type='primary'
				size='small'
				onClick={() => notification.close(key)}
			>
				Go To Notifications
			</Button>
		</a>
	) : null

	notification.open({
		...rest,
		top: 50,
		duration: 5,
		btn,
		key,
	})
}

export default cartNotification

/* export const CartNotif = ()=>{
	const [api, contextHolder] = notification.useNotification();

	const openNotification = placement => {
	  api.info({
		message: `Notification ${placement}`,
		description: <Context.Consumer>{({ name }) => `Hello, ${name}!`}</Context.Consumer>,
		placement,
	  });
	};

	return contextHolder
} */
