import { Spin } from 'antd'
import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'

function CustomSpin({ height, bgColor }) {
	const antIcon = <LoadingOutlined spin />
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				marginTop: '64px',
				marginBottom: '64px',
				borderRadius: '50px',
				border: 'transparent',
				height,
				backgroundColor: bgColor ? bgColor : 'unset',
			}}
		>
			<Spin indicator={antIcon} size='large' />
		</div>
	)
}

export default CustomSpin
