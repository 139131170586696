import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux'
import { STATUS } from '../constants/status'
import { isEmpty } from 'lodash'
import CustomResult from '../pages/Results'
import { HOME_URL } from '../constants/paths'

export const withAuth =
	({ ComposedComponent, roles, isPublicAfterAuth }) =>
	(props) => {
		const history = useHistory()
		const { user, isAuthenticated } = useSelector(
			(state) => ({
				isAuthenticated: state.auth.isAuthenticated,
				user: state.auth.user,
			}),
			shallowEqual
		)
		const authorized = !isEmpty(user)
			? roles?.includes(user?.roles[0])
			: false
		const isProcessing = user?.status == STATUS.PROCESSING
		useEffect(() => {
			if (!isAuthenticated) {
				history.push(HOME_URL)
			}
		}, [isAuthenticated, history])

		return (
			isAuthenticated &&
			(isPublicAfterAuth ? (
				<ComposedComponent {...props} />
			) : isProcessing ? (
				<CustomResult status={STATUS.PROCESSING} />
			) : (
				<ComposedComponent {...props} />
			))
		)
	}
