import {
	ApolloClient,
	ApolloLink,
	HttpLink,
	InMemoryCache,
	split,
} from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { wslink } from '../wslink'
import { createUploadLink } from 'apollo-upload-client'
import { WebSocketLink } from '@apollo/client/link/ws'
import { createClient } from 'graphql-ws'

/* const wslink = new WebSocketLink(
	new createClient({
		url: 'ws://localhost:3030/graphql',
		connectionParams: () => {
			const token = localStorage.getItem('token')
			return {
				authorization: token ? `Bearer ${token}` : '',
			}
		},
	})
) */

const authLink = new ApolloLink((operation, forward) => {
	operation.setContext(({ headers }) => {
		const token = localStorage.getItem('token')
		return {
			headers: {
				authorization: token ? `Bearer ${token}` : '',
				...headers,
			},
		}
	})
	return forward(operation)
})

const roundTripLink = new ApolloLink((operation, forward) => {
	// Called before operation is sent to server
	operation.setContext({ start: new Date() })
	return forward(operation).map((data) => {
		// Called after server responds
		const date = new Date()
		const time = date - operation.getContext().start
		return data
	})
})
const httpLink = new createUploadLink({
	uri: process.env.REACT_APP_API_URL
		? process.env.REACT_APP_API_URL
		: process.env.REACT_APP_API_URL_PROD,
	credentials: 'same-origin',
})
const splitLink = split(
	({ query }) => {
		const definition = getMainDefinition(query)
		return (
			definition.kind === 'OperationDefinition' &&
			definition.operation === 'subscription'
		)
	},
	wslink.concat(roundTripLink),
	authLink.concat(httpLink)
)

export const apolloClient = new ApolloClient({
	cache: new InMemoryCache(),
	link: splitLink,
	connectToDevTools: true,
})
