import React from 'react'

const ClearCacheContext = React.createContext({
	clearCache: () => {},
})

const ShowChatContext = React.createContext({
	toggleChat: () => {},
	openChat: false,
})
const EditingInputContext = React.createContext({
	setEditingItem: () => {},
	editingItem: {},
})

const FocusMessageContext = React.createContext({
	setFocusMessage: () => {},
	focusMessage: {
		status: false,
		senderId: '',
		receiverId: '',
	},
})
const VariablesContext = React.createContext({
	setVariables: () => {},
	variables: {},
})

const CurrentsConverIdsContext = React.createContext({
	setCurrentsConverIds: () => {},
	currentsConverIds: {},
})

const Contexts = {
	ClearCacheContext,
	ShowChatContext,
	EditingInputContext,
	FocusMessageContext,
	VariablesContext,
	CurrentsConverIdsContext,
}
export default Contexts
