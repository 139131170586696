import React, { useEffect, useState, useRef } from 'react'
import CustomTable from '../../../components/CustomTable'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Badge, Col, Empty, Popconfirm, Row, Tooltip } from 'antd'
import { CustomButton, PrimaryButton } from '../../../global/GlobalStyles'
import { Edit, Add, Delete } from '@material-ui/icons'
import { ROLES } from '../../../constants/roles'
import Register from '../../Register'
import { statusList } from '../../../constants/variables'
import { withDigits, withHighlighter } from '../../../constants/fn'
import {
	deleteUser,
	getUserById,
	getUsersByFileds,
} from '../../../redux/user/actions'
import UserDetail from '../../UserDetail'

function ListUsers() {
	const dispatch = useDispatch()
	//const users = useSelector((state) => state.users)
	const {
		userById,
		loadingGetUsers,
		loadingDeleteUser,
		loadingGetUserByID,
		...users
	} = useSelector(
		(state) => ({
			userById: state.users.userById,
			loadingGetUserByID: state.users.loadingGetUserByID,
			loadingGetUsers: state.users.loadingGetUsers,
			loadingDeleteUser: state.users.loadingDeleteUser,
			...state.users,
		}),
		shallowEqual
	)
	let [filteredInfo, setFilteredInfo] = useState(null)
	const [sortedInfo, setSortedInfo] = useState(null)
	const [searchText, setSearchText] = useState('')
	const [searchedColumn, setSearchedColumn] = useState('')
	const [messageTooltipOrder, setMessageTooltipOrder] = useState({
		title: 'descendant',
	})
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [mode, setMode] = useState('')

	useEffect(() => {
		setFilteredInfo(null)
		setSortedInfo(null)
		dispatch(getUsersByFileds())
	}, [])

	const showModal = () => {
		setIsModalVisible(true)
	}

	const handleCancel = () => {
		setIsModalVisible(false)
	}

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm()
	}

	const handleReset = (clearFilters) => {
		clearFilters()
	}

	const filterClick = (selectedKeys, dataIndex, confirm) => (e) => {
		confirm({ closeDropdown: false })
	}

	const onChange = (page) => {
		//setCurrentPage(page);
	}

	function onShowSizeChange(current, pageSize) {
		//setLimit(pageSize);
	}
	const handleChangeTable = (pagination, filters, sorter) => {
		const currentPage = pagination?.current // currentPage
		//const defaultPageSize = pagination?.defaultPageSize // limit default
		const limit = pagination?.pageSize //limit
		const sortField = sorter.order ? sorter.columnKey : 'createdAt'
		const sortOrder = sorter.order ? sorter.order : 'descend'
		let fields = []
		Object.keys(filters).map((field) => {
			if (filters[field]) {
				const isStatusField = field == 'status' ? true : false
				filters[field].map((searchText) => {
					fields.push({
						field,
						fieldValue: searchText ? searchText : '',
						specific: isStatusField,
					})
				})
			}
		})
		const variables = {
			fields,
			operatorBetweenFields: 'and',
			options: { all: false, page: currentPage, limit },
			sort: { sortOrder: sortOrder.replace('end', ''), sortField },
		}
		if (sorter.order) {
			sorter.order == 'descend'
				? setMessageTooltipOrder({ title: 'Descendant' })
				: setMessageTooltipOrder({ title: 'Annuler' })
		} else {
			setMessageTooltipOrder({ title: 'Ascendant' })
		}
		filteredInfo = filters
		setFilteredInfo(filters)
		setSortedInfo(sorter)
		dispatch(getUsersByFileds(variables))
	}
	const columnsData = [
		/* {
			title: 'Role',
			dataIndex: 'roles',
			key: 'roles',
			width: 200,
			filterMultiple: false,
			filters: [
				{
					text: 'PROFESSIONEL',
					value: ROLES.PROF,
					key: 'prof',
				},
				{
					text: 'PARTICULIER',
					value: ROLES.CLIENT,
					key: 'client',
				},
			],
			filteredValue: filteredInfo?.roles || null,
			render: (row) => (
				<>{row[0] == ROLES.PROF ? 'professionel' : 'particulier'}</>
			),
		}, */
		{
			title: '#code',
			dataIndex: 'codeClient',
			key: 'codeClient',
			width: 60,
			//withSearchColumn: true,
			//filteredValue: filteredInfo?.codeClient || null,
			render: (row) =>
				withHighlighter({
					text: withDigits(row, 5),
					searchText: filteredInfo?.codeClient,
				}),
		},
		{
			title: 'Nom',
			dataIndex: 'firstName',
			key: 'firstName',
			width: 150,
			withSearchColumn: true,
			filteredValue: filteredInfo?.firstName || null,
			render: (row) =>
				withHighlighter({
					text: row,
					searchText: filteredInfo?.firstName,
				}),
		},
		{
			title: 'Tel',
			dataIndex: 'phone',
			key: 'phone',
			width: 150,
			withSearchColumn: true,
			filteredValue: filteredInfo?.phone || null,
			render: (row) =>
				withHighlighter({
					text: row,
					searchText: filteredInfo?.phone,
				}),
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			width: 150,
			withSearchColumn: true,
			filteredValue: filteredInfo?.email || null,
			render: (row) =>
				withHighlighter({
					text: row,
					searchText: filteredInfo?.email,
				}),
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			width: 200,
			filterMultiple: false,
			filters: [
				{
					text: 'ACTIVÉ',
					value: 'ACTIVE',
					key: 'published',
				},
				{
					text: 'DESACTIVÉ',
					value: 'INACTIVE',
					key: 'inactive',
				},
				{
					text: 'EN ATTENTE',
					value: 'PROCESSING',
					key: 'processing',
				},
				{
					text: 'REFUSÉ',
					value: 'REFUSED',
					key: 'refused',
				},
			],
			filteredValue: filteredInfo?.status || null,
			render: (row) => (
				<>
					<span>
						<Badge status={statusList[row]?.type} />
						{statusList[row]?.text}
					</span>
				</>
			),
		},
		{
			title: 'Action',
			key: 'operation',
			dataIndex: '_id',
			width: 150,
			fixed: 'right',
			render: (_id) => (
				<Row gutter={[4, 4]} justify='center'>
					<Col>
						<Tooltip title='Modifier'>
							<CustomButton
								colorText='#3f51b5'
								colorHoverIcon='#fff'
								shape='circle'
								icon={<Edit htmlColor='#3f51b5' />}
								onClick={() => {
									//findOneUser({ variables: { _id } })
									dispatch(getUserById({ _id }))
									setMode('edit')
									showModal()
								}}
							/>
						</Tooltip>
					</Col>
					<Col>
						<Tooltip title='Supprimer'>
							<Popconfirm
								title='Are you sure？'
								okText='Yes'
								cancelText='No'
								onConfirm={() => {
									dispatch(deleteUser({ _id }))
									dispatch(getUsersByFileds())
								}}
							>
								<CustomButton
									margin='2%'
									colorText='#ed3b57'
									colorHoverIcon='#fff'
									shape='circle'
									icon={<Delete htmlColor='#ed3b57' />}
								/>
							</Popconfirm>
						</Tooltip>
					</Col>
				</Row>
			),
		},
	]
	return (
		<>
			<Row
				type='flex'
				justify='space-between'
				align='center'
				style={{ marginBottom: '10px' }}
			>
				<Col>
					<PrimaryButton
						onClick={() => {
							setFilteredInfo(null)
							setSortedInfo(null)
							dispatch(getUsersByFileds())
						}}
					>
						Réinitialiser les recherches
					</PrimaryButton>
				</Col>
				<Col>
					<Tooltip title='Ajouter un utilisateur'>
						<CustomButton
							onClick={async () => {
								setMode('add')
								showModal()
							}}
							colorText='#08a908'
							colorHoverIcon='#fff'
							shape='circle'
							icon={<Add htmlColor='#08a908' />}
						/>
					</Tooltip>
				</Col>
			</Row>
			<CustomTable
				showSorterTooltip={
					loadingGetUsers ? false : messageTooltipOrder
				}
				data={users?.entities}
				loading={loadingGetUsers}
				handleChangeTable={handleChangeTable}
				handleSearch={handleSearch}
				handleReset={handleReset}
				filterClick={filterClick}
				searchText={searchText}
				searchedColumn={searchedColumn}
				locale={{
					emptyText: (
						<Empty
							imageStyle={{
								height: 100,
							}}
							description={<span>Aucun Utilisateurs</span>}
						/>
					),
				}}
				pagination={{
					total: users?.totalCount,
					defaultPageSize: 10,
					showSizeChanger: true,
					showQuickJumper: true,
					pageSizeOptions: [5, 10, 20, 100],
					onShowSizeChange,
					showTotal: (total) => `Total ${total} users`,
					onChange,
				}}
				columnsData={columnsData}
			/>
			{mode == 'add' ? (
				<Register
					textButton={mode == 'edit' ? 'Modifier' : 'Ajouter'}
					isModalVisible={isModalVisible}
					setIsModalVisible={setIsModalVisible}
					userToEdit={userById}
					mode={mode}
					onCompleted={() => {
						setFilteredInfo(null)
						setSortedInfo(null)
						dispatch(getUsersByFileds())
					}}
				/>
			) : (
				<UserDetail
					userToEdit={userById}
					setModalUserDetail={setIsModalVisible}
					modalUserDetail={isModalVisible}
				/>
			)}
			{/* <UserDetail
				userToEdit={userById}
				setModalUserDetail={setIsModalVisible}
				modalUserDetail={isModalVisible}
			/> */}
		</>
	)
}

export default ListUsers
