import { gql } from '@apollo/client'

export const UPLOAD_IMAGE_MUTATION = gql`
	mutation uploadFileImage($file: Upload!) {
		uploadFileImage(file: $file)
	}
`

export const UPLOAD_EXCEL_MUTATION = gql`
	mutation createMultipleArticles($file: Upload!) {
		createMultipleArticles(file: $file)
	}
`
