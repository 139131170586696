import { AttachFile, Send } from '@material-ui/icons'
import { Upload } from 'antd'
import { isEmpty } from 'lodash'
import { useContext, useRef } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { detectURL, isNotEmptyString } from '../../../constants/fn'
import { ROLES } from '../../../constants/roles'
import Contexts from '../../../context'
import { createChat, updateChatsByIds } from '../../../redux/chat/actions'
const InputMessage = ({
	owner,
	ownerId,
	ownerAvatar,
	chatOne,
	list,
	setList,
	currentConverId,
	currentUserConver,
}) => {
	const { countsNotRead, countsNotReadAdmin, loadingCreateChat, user } =
		useSelector(
			(state) => ({
				user: state.auth?.user,
				countsNotRead: state.chats.countsNotRead,
				countsNotReadAdmin: state.chats.countsNotReadAdmin,
				loadingCreateChat: state.chats.loadingCreateChat,
			}),
			shallowEqual
		)
	const messageInput = useRef()
	const ownerInput = useRef()
	const ownerAvatarInput = useRef()
	const dispatch = useDispatch()
	const { setFocusMessage, focusMessage } = useContext(
		Contexts.FocusMessageContext
	)
	const handleSendMessage = (event) => {
		event.preventDefault()
		let messageFormat = detectURL(messageInput.current.value)
		if (isEmpty(list)) {
			isNotEmptyString(messageFormat) &&
				dispatch(
					createChat({
						message: messageFormat,
						senderId: ownerId,
						receiverId: chatOne
							? currentUserConver
								? currentUserConver?._id
								: null
							: currentConverId?.split('_')[0],
						uploadFiles: list,
					})
				)
		} else {
			dispatch(
				createChat({
					message: messageFormat,
					senderId: ownerId,
					receiverId: chatOne
						? currentUserConver
							? currentUserConver?._id
							: null
						: currentConverId?.split('_')[0],
					uploadFiles: list,
				})
			)
		}
		messageInput.current.value = ''
		setList([])
	}
	/* const handleTyping = (event) => {
		// Tell users when another user has at least started to write 
		if (messageInput.current.value.length > 0) {
			typing(ownerInput.current.value)
		} else {
			// When there is no more character, the user no longer writes 
			resetTyping(ownerInput.current.value)
		}
	} */
	/* If the chatbox state is loading, loading class for display */
	var loadingClass = loadingCreateChat ? 'chatApp__convButton--loading' : ''

	return (
		<form onSubmit={handleSendMessage}>
			<input type='hidden' ref={ownerInput} value={owner} />
			<input type='hidden' ref={ownerAvatarInput} value={ownerAvatar} />
			<input
				type={'text'}
				ref={messageInput}
				className={'chatApp__convInput'}
				placeholder='Text message'
				//onKeyDown={handleTyping}
				//onKeyUp={handleTyping}
				tabIndex='0'
				//onPressEnter={handleSendMessage}
				onFocus={() => {
					setFocusMessage({ status: true, ...focusMessage })
					//countsNotRead?.length > 0 &&
					dispatch(
						updateChatsByIds({
							_ids: user?.roles.includes(ROLES.ADMIN)
								? countsNotReadAdmin[
										chatOne
											? currentUserConver
												? currentUserConver?._id
												: null
											: currentConverId?.split('_')[0]
								  ]
								: countsNotRead,
						})
					)
				}}
				onBlur={() =>
					setFocusMessage({ status: false, ...focusMessage })
				}
			/>
			<Upload
				name='file'
				multiple={true}
				onChange={({ file: { originFileObj, status } }) => {
					if (status !== 'uploading') {
						list.push(originFileObj)
						setList([...list])
					}
				}}
				showUploadList={false}
				maxCount={1}
				type='file'
				//accept='.xlsx,.xls,.csv'
			>
				<div className={'chatApp__convButton ' + loadingClass}>
					<AttachFile fontSize='small' />
				</div>
			</Upload>
			<div
				className={'chatApp__convButton ' + loadingClass}
				onClick={handleSendMessage}
			>
				<Send fontSize='small' />
			</div>
		</form>
	)
}

export default InputMessage
