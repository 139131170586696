import styled from 'styled-components'

export const UserFormContainer = styled.div`
	.ant-form-horizontal .ant-form-item-label {
		white-space: pre-wrap;
	}
	.ant-typography-copy svg {
		fill: ${(props) => props.theme.copyColor};
	}
`
