import { ACTIVITY_COMPANY } from './activity_company'
import { STATUS } from './status'
import { TYPE_OFFER } from './type_offer'
import { Type_COMPANY } from './type_company'

export const statusList = {
	[STATUS.ACTIVE]: { type: 'success', text: 'activé' },
	[STATUS.INACTIVE]: { type: 'processing', text: 'désactivé' },
	[STATUS.PROCESSING]: { type: 'warning', text: 'en attente' },
	[STATUS.REFUSED]: { type: 'error', text: 'refusé' },
}

export const TypeOfferFr = {
	[TYPE_OFFER.SELL]: `À vendre`,
	[TYPE_OFFER.PURCHASE]: `Demande d'achat`,
}

export const statusArticle = {
	ACTIVE: { type: 'success', text: 'publié' },
	INACTIVE: { type: 'warning', text: 'non publié' },
	PROCESSING: { type: 'processing', text: 'en attente' },
	REFUSED: { type: 'error', text: 'refusé' },
}

export const initialStatePagination = {
	entities: [],
	hasNextPage: false,
	hasPreviousPage: false,
	limit: 3,
	page: 1,
	totalCount: 0,
	totalPages: 0,
}

export const marques = [
	{
		value: 'alfa-romeo',
		key: 'alfa-romeo',
		title: 'Alfa romeo',
	},
	{
		value: 'audi',
		key: 'audi',
		title: 'Audi',
	},
	{
		value: 'baic-yx',
		key: 'baic-yx',
		title: 'BAIC YX',
	},
	{
		value: 'bmw',
		key: 'bmw',
		title: 'BMW',
	},
	{
		value: 'chery',
		key: 'chery',
		title: 'Chery',
	},
	{
		value: 'chevrolet',
		key: 'chevrolet',
		title: 'Chevrolet',
	},
	{
		value: 'citroen',
		key: 'citroen',
		title: 'Citroën',
	},
	{
		value: 'dacia',
		key: 'dacia',
		title: 'Dacia',
	},
	{
		value: 'dongfeng',
		key: 'dongfeng',
		title: 'DongFeng',
	},
	{
		value: 'fiat',
		key: 'fiat',
		title: 'Fiat',
	},
	{
		value: 'ford',
		key: 'ford',
		title: 'Ford',
	},
	{
		value: 'foton',
		key: 'foton',
		title: 'Foton',
	},
	{
		value: 'geely',
		key: 'geely',
		title: 'Geely',
	},
	{
		value: 'great-wall',
		key: 'great-wall',
		title: 'GREAT WALL',
	},
	{
		value: 'haval',
		key: 'haval',
		title: 'HAVAL',
	},
	{
		value: 'honda',
		key: 'honda',
		title: 'Honda',
	},
	{
		value: 'hyundai',
		key: 'hyundai',
		title: 'Hyundai',
	},
	{
		value: 'isuzu',
		key: 'isuzu',
		title: 'ISUZU',
	},
	{
		value: 'iveco',
		key: 'iveco',
		title: 'Iveco',
	},
	{
		value: 'jaguar',
		key: 'jaguar',
		title: 'Jaguar',
	},
	{
		value: 'jeep',
		key: 'jeep',
		title: 'Jeep',
	},
	{
		value: 'kia',
		key: 'kia',
		title: 'KIA',
	},
	{
		value: 'lada',
		key: 'lada',
		title: 'Lada',
	},
	{
		value: 'land_rover',
		key: 'land_rover',
		title: 'Land Rover',
	},
	{
		value: 'mahindra',
		key: 'mahindra',
		title: 'Mahindra',
	},
	{
		value: 'mazda',
		key: 'mazda',
		title: 'Mazda',
	},
	{
		value: 'mercedes-benz',
		key: 'mercedes-benz',
		title: 'Mercedes-Benz',
	},
	{
		value: 'mg-motors',
		key: 'mg-motors',
		title: 'MG Motors',
	},
	{
		value: 'mini',
		key: 'mini',
		title: 'MINI',
	},
	{
		value: 'mitsubishi2',
		title: 'Mitsubishi - Sam -',
	},
	{
		value: 'nissan',
		key: 'nissan',
		title: 'Nissan',
	},
	{
		value: 'opel',
		key: 'opel',
		title: 'Opel',
	},
	{
		value: 'peugeot',
		key: 'peugeot',
		title: 'Peugeot',
	},
	{
		value: 'porsche',
		key: 'porsche',
		title: 'Porsche',
	},
	{
		value: 'renault',
		key: 'renault',
		title: 'Renault',
	},
	{
		value: 'seat',
		key: 'seat',
		title: 'Seat',
	},
	{
		value: 'skoda',
		key: 'skoda',
		title: 'Škoda',
	},
	{
		value: 'ssangyong',
		key: 'ssangyong',
		title: 'SsangYong',
	},
	{
		value: 'suzuki',
		key: 'suzuki',
		title: 'SUZUKI',
	},
	{
		value: 'toyota',
		key: 'toyota',
		title: 'Toyota',
	},
	{
		value: 'volkswagen',
		key: 'volkswagen',
		title: 'Volkswagen',
	},
	{
		value: 'volkswagen-utilitaires',
		title: 'Volkswagen Utilitaires',
	},
	{ value: 'wallyscar', key: 'wallyscar', title: 'Wallyscar' },
]

const { MECANIQUE, ELECTRIQUE, PNEUMATIQUE, LAVAGE } = ACTIVITY_COMPANY
export const activitiesGarage = [
	{ title: 'Mécanique', value: MECANIQUE, key: MECANIQUE },
	{ title: 'Electrique', value: ELECTRIQUE, key: ELECTRIQUE },
	{ title: 'Pneumatique', value: PNEUMATIQUE, key: PNEUMATIQUE },
	{ title: 'Lavage', value: LAVAGE, key: LAVAGE },
]

const { VENDEUR, EXPERT, GARAGE, ASSUREUR, FERAILLEUR } = Type_COMPANY
export const typesCompany = [
	{ title: 'Vendeur', value: VENDEUR, key: VENDEUR },
	/* { title: 'Ferailleur', value: FERAILLEUR, key: FERAILLEUR },
	{ title: 'Garage', value: GARAGE, key: GARAGE },
	{ title: 'Assureur', value: ASSUREUR, key: ASSUREUR },
	{ title: 'Expert', value: EXPERT, key: EXPERT }, */
]
