import { ROLES } from '../../constants/roles'
import {
	LOGOUT_SUCCESS,
	LOGIN_ERROR,
	LOGIN_SUCCESS,
	FETCH_AUTH_BY_ID_SUCCESS,
	FETCH_AUTH_BY_ID_ERROR,
	START_LOADING_REGISTER,
	REGISTER_SUCCESS,
	REGISTER_ERROR,
	START_LOADING_LOGIN,
	START_LOADING_UPDATE_PROFILE,
	UPDATE_PROFILE_SUCCESS,
	UPDATE_PROFILE_ERROR,
} from './types'

const initialState = {
	isAdmin: false,
	isAuthenticated: false,
	accessToken: null,
	user: {},
	companyByUserId: {},
	error: '',
	loadingLogin: false,
	loadingRegister: false,
	loadingUpdateProfile: false,
}

export default function authReducer(state = initialState, action) {
	switch (action.type) {
		/* ****************** LOGIN  ************************ */
		case START_LOADING_LOGIN:
			return {
				...state,
				loadingLogin: true,
			}
		case LOGIN_SUCCESS:
			localStorage.setItem('token', action.payload.accessToken)
			return {
				...state,
				isAuthenticated: true,
				isAdmin: action.payload.user?.roles?.includes(ROLES.ADMIN),
				accessToken: action.payload.accessToken,
				user: action.payload.user,
				companyByUserId: action.payload.companyByUserId,
				loadingLogin: false,
				error: '',
			}
		case LOGIN_ERROR:
			return {
				...state,
				isAuthenticated: false,
				accessToken: '',
				user: {},
				error: action.payload,
				loadingLogin: false,
			}
		/* ****************** LOGOUT  ************************ */
		case LOGOUT_SUCCESS:
			return {
				...state,
				isAuthenticated: false,
				accessToken: '',
				user: {},
				error: '',
			}

		/* ****************** REGISTER  ************************ */
		case START_LOADING_REGISTER:
			return {
				...state,
				loadingRegister: true,
			}
		case REGISTER_SUCCESS:
			localStorage.setItem('token', action.payload.accessToken)
			return {
				...state,
				isAdmin: action.payload.user?.roles?.includes(ROLES.ADMIN),
				isAuthenticated: true,
				accessToken: action.payload.accessToken,
				user: action.payload.user,
				companyByUserId: action.payload.companyByUserId,
				loadingRegister: false,
				error: '',
			}
		case REGISTER_ERROR:
			return {
				...state,
				data: [],
				loadingRegister: false,
				error: action.payload,
			}
		/* ****************** UPDATE PROFILE  ************************ */
		case START_LOADING_UPDATE_PROFILE:
			return {
				...state,
				loadingUpdateProfile: true,
			}
		case UPDATE_PROFILE_SUCCESS:
			return {
				...state,
				user: { ...state.user, ...action.payload?.user },
				companyByUserId: {
					...state.companyByUserId,
					...action.payload?.companyByUserId,
				},
				loadingUpdateProfile: false,
				error: '',
			}
		case UPDATE_PROFILE_ERROR:
			return {
				...state,
				error: action.payload,
				loadingUpdateProfile: false,
			}
		default:
			return state
	}
}
