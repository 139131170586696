import {
	ShoppingCartOutlined,
	NotificationsActiveOutlined,
	NotificationsNoneOutlined,
	NotificationImportantOutlined,
	SettingsOverscanOutlined,
} from '@material-ui/icons'
import { find, has, isEmpty, map, reduce } from 'lodash'
//import { MessengerChat } from 'react-messenger-chat-plugin'
import $ from 'jquery'
import moment from 'moment'
import {
	Avatar,
	Badge,
	Col,
	Image,
	Layout,
	Menu,
	Row,
	Typography,
	List,
	Button,
	Affix,
	Divider,
} from 'antd'
import {
	Container,
	CustomContent,
	CustomFooter,
	CustomHeader,
	CustomLayout,
	HamburgerButton,
} from './styles'
import { PANIER_URL, titlesPath } from '../../constants/paths'
import {
	MenuUnfoldOutlined,
	UserOutlined,
	SmileOutlined,
} from '@ant-design/icons'
import React, { useContext, useEffect, useState } from 'react'
import CustomDrawer from './Drawer'
import { ROLES } from '../../constants/roles'
import Sidebar from './SideBar'
import { useHistory, useRouteMatch } from 'react-router'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useTheme } from 'styled-components'
import {
	UPDATED_LINEORDER_SUBSCRIPTION,
	REFUSED_LINEORDER_SUBSCRIPTION,
	NEW_LINEORDER_SUBSCRIPTION,
	NEW_MESSAGE_SUBSCRIPTION,
	NEW_OFFER_SUBSCRIPTION,
	NEW_USER_PROF_SUBSCRIPTION,
	UPDATE_LINEOFFER_SUBSCRIPTION,
	UPDATE_USER_PASSWORD_SUBSCRIPTION,
	UPDATE_USER_SUBSCRIPTION,
	DELETE_LINESOFFER_SUBSCRIPTION,
} from '../../queries/subscriptions'
import Contexts from '../../context'
import { useApolloClient, useLazyQuery, useSubscription } from '@apollo/client'
import { CustomButton, PrimaryButton } from '../../global/GlobalStyles'
import { Link } from 'react-router-dom'
import images from '../../constants/images'
import LoginForm from '../../pages/Login'
import Register from '../../pages/Register'
import { STATUS } from '../../constants/status'
import cartNotification from '../CartNotification'
import { logout, updateProfile } from '../../redux/auth/actions'
import {
	getNotificationsByFields,
	updateNotificationsByIds,
} from '../../redux/notification/actions'
import { getUsersByFileds } from '../../redux/user/actions'
import { getLinkNotif, getSidebarLinks, withDigits } from '../../constants/fn'
import CustomSpin from '../CustomSpin'
import { TYPE_OFFER } from '../../constants/type_offer'
//import { getCompanyByIdUser } from '../../redux/company/actions'
import {
	deleteLinesOffer,
	updateListLinesOffer,
} from '../../redux/lineOffer/actions'
import { Title } from '../HeaderTitle/styles'
import { updateListConversation } from '../../redux/chat/actions'
import { updateListLineOrders } from '../../redux/lineOrder/actions'
import UserDetail from '../../pages/UserDetail'
import { TYPE_NOTIF } from '../../constants/type_notif'
/* import ChatLauncher from '../ChatLauncher' */
import ChatRoom from '../ChatRoom'
import { addToCompaniesListChat } from '../../redux/company/actions'

const { SubMenu } = Menu
function Layouts({ children }) {
	const { LOGO } = images
	const dispatch = useDispatch()
	const { setFocusMessage, focusMessage } = useContext(
		Contexts.FocusMessageContext
	)
	const auth = useSelector((state) => state.auth, shallowEqual)
	const {
		loadingLogin,
		linesOfferOrder,
		companyByUserId,
		currentConvId,
		chatBoxes,
		countsNotReadAdmin,
		notifs,
		currentKeyTab,
		companies,
	} = useSelector(
		(state) => ({
			currentConvId: state.chats.currentConvId,
			companies: state.companies.entities,
			chatBoxes: state.chats.chatBoxes,
			countsNotReadAdmin: state.chats.countsNotReadAdmin,
			companyByUserId: state.auth.companyByUserId,
			loadingGetNotifications: state.notifs.loadingGetNotifications,
			linesOfferOrder: state.cart.entities,
			count: state.cart.count,
			loadingLogin: state.auth.loadingLogin,
			notifs: state.notifs,
			currentKeyTab: state.lineOffers?.currentKeyTab,
		}),
		shallowEqual
	)
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [modalUserDetail, setModalUserDetail] = useState(false)
	const [mode, setMode] = useState('login')
	const match = useRouteMatch('/dash/*')
	const location = useLocation()
	const client = useApolloClient()
	const { gray_1, white } = useTheme()
	const { clearCache } = useContext(Contexts.ClearCacheContext)
	const [visible, setVisible] = useState(false)
	let history = useHistory()
	const [windowSize, setWindowSize] = useState(window.innerWidth)
	const { data: newUserProf, loading: loadingNewUserProf } = useSubscription(
		NEW_USER_PROF_SUBSCRIPTION
	)
	const { data: updatedUser, loading: loadingUserUpdatedStatus } =
		useSubscription(UPDATE_USER_SUBSCRIPTION)
	const { data: newMessage, loading: loadingNewMessage } = useSubscription(
		NEW_MESSAGE_SUBSCRIPTION
	)
	const { data: addedNewLinesOffer, loading: loadingaddedNewLinesOffer } =
		useSubscription(NEW_OFFER_SUBSCRIPTION)
	const { data: addedLineOrder, loading: loadingAddedNewLineOrder } =
		useSubscription(NEW_LINEORDER_SUBSCRIPTION)
	const { data: deletedLinesOffer, loading: loadingDeletedLinesOrder } =
		useSubscription(DELETE_LINESOFFER_SUBSCRIPTION)
	const { data: updatedLineOffer, loading: loadingLineOfferUpdatedStatus } =
		useSubscription(UPDATE_LINEOFFER_SUBSCRIPTION)
	const { data: updatedLineOrder, loading: loadingUpdatedLineOrder } =
		useSubscription(UPDATED_LINEORDER_SUBSCRIPTION)
	const { data: refusedLineOrder, loading: loadingrefusedLineOrder } =
		useSubscription(REFUSED_LINEORDER_SUBSCRIPTION)
	//const [form] = Form.useForm()
	const showDrawer = () => {
		setVisible(true)
	}

	const onClose = () => {
		setVisible(false)
	}
	const handleCancel = () => {
		setIsModalVisible(false)
	}
	useEffect(() => {
		if (!isEmpty(addedLineOrder)) {
			const { data, message, receiverId } = addedLineOrder?.addedLineOrder
			console.log({ data, message, receiverId })
			if (companyByUserId?._id == receiverId && auth?.isAuthenticated) {
				cartNotification({
					roles: auth?.user?.roles,
					description: message,
					type: 'success',
				})
				dispatch(updateListLineOrders(data))
			}
			if (auth?.user?.roles?.includes(ROLES.ADMIN)) {
				dispatch(updateListLineOrders(data))
			}
			//dispatch(getLine())
		}
	}, [addedLineOrder])

	useEffect(() => {
		if (!isEmpty(deletedLinesOffer)) {
			const { data, message, receiverId } =
				deletedLinesOffer?.deletedLinesOffer
			if (companyByUserId?._id == receiverId && auth?.isAuthenticated) {
				cartNotification({
					roles: auth?.user?.roles,
					description: message,
					type: 'success',
				})
				dispatch(deleteLinesOffer(data))
			}
		}
	}, [deletedLinesOffer])

	useEffect(() => {
		if (!isEmpty(updatedLineOrder)) {
			console.log({ updatedLineOrder })
			const { data, message, receiverId } =
				updatedLineOrder?.updatedLineOrder
			if (companyByUserId?._id == receiverId && auth?.isAuthenticated) {
				cartNotification({
					roles: auth?.user?.roles,
					description: message,
					type: 'success',
				})
				dispatch(updateListLineOrders(data))
			}
			if (auth?.user?.roles?.includes(ROLES.ADMIN)) {
				dispatch(updateListLineOrders(data))
			}
		}
	}, [updatedLineOrder])
	useEffect(() => {
		if (!isEmpty(addedNewLinesOffer)) {
			const { data, message, receiverId } =
				addedNewLinesOffer?.addedNewLinesOffer
			const { numRef } = data
			if (companyByUserId?._id == receiverId && auth?.isAuthenticated) {
				cartNotification({
					roles: auth?.user?.roles,
					description: message,
					type: 'success',
				})

				if (currentKeyTab != '1') {
					dispatch(updateListLinesOffer(data))
				}
			}
			if (companyByUserId?._id != receiverId && currentKeyTab == '1') {
				dispatch(updateListLinesOffer(data))
			}
		}
	}, [addedNewLinesOffer])
	useEffect(() => {
		if (!isEmpty(newMessage)) {
			let message = newMessage?.newMessage
			const { senderId, receiverId } = message
			/* const existInChatboxes =
				has(chatBoxes, `${senderId?._id}_${receiverId?._id}`) ||
				has(chatBoxes, `${receiverId?._id}_${senderId?._id}`) */
			if (auth?.user?.roles?.includes(ROLES.PROF)) {
				if (
					[senderId?._id, receiverId?._id].includes(
						companyByUserId?._id
					)
				) {
					dispatch(updateListConversation(message, focusMessage))
				}
			}
			if (auth?.user?.roles?.includes(ROLES.ADMIN)) {
				setFocusMessage({
					receiverId: auth?.companyByUserId?._id,
					...focusMessage,
				})
				const exist = find(companies, ['_id', senderId._id])
				companyByUserId?._id !== senderId._id &&
					dispatch(
						addToCompaniesListChat({
							...senderId,
							createdAtChat: message.dateChat,
						})
					)
				dispatch(
					updateListConversation(message, {
						receiverId: auth?.companyByUserId?._id,
						isAdmin: auth?.user?.roles?.includes(ROLES.ADMIN),
						...focusMessage,
					})
				)
			}
		}
	}, [newMessage])

	useEffect(() => {
		if (!isEmpty(updatedLineOffer)) {
			const { data, message, receiverId } =
				updatedLineOffer?.updatedLineOffer
			const { status, numRef, ref, type } = data
			if (companyByUserId?._id == receiverId && auth?.isAuthenticated) {
				cartNotification({
					roles: auth?.user?.roles,
					description: message,
					type: status == STATUS.ACTIVE ? 'success' : 'warning',
				})
				if (currentKeyTab != '1') {
					dispatch(updateListLinesOffer(data))
				}
			}
			if (companyByUserId?._id != receiverId && currentKeyTab == '1') {
				dispatch(updateListLinesOffer(data))
			}
		}
	}, [updatedLineOffer])

	useEffect(() => {
		if (!isEmpty(newUserProf)) {
			const { data, message, receiverId } = newUserProf?.userProfAdded
			const { email, firstName } = data
			if (companyByUserId?._id == receiverId && auth?.isAuthenticated) {
				dispatch(getUsersByFileds())
				cartNotification({
					roles: auth?.user?.roles,
					description: message,
					icon: <SmileOutlined style={{ color: '#108ee9' }} />,
				})
			}
		}
	}, [newUserProf])

	useEffect(() => {
		if (!isEmpty(updatedUser)) {
			const { data, message, receiverId, type } = updatedUser?.updatedUser
			const { status, email } = data
			const { user, ...company } = data
			if (companyByUserId?._id == receiverId && auth?.isAuthenticated) {
				cartNotification({
					roles: auth?.user?.roles,
					description: message,
					type: status == STATUS.ACTIVE ? 'success' : 'warning',
					roles: auth?.user?.roles,
				})

				type == TYPE_NOTIF.CHANGE_STATUS_USER &&
					dispatch(
						updateProfile({
							user,
							companyByUserId: { ...company },
						})
					)
			}
		}
	}, [updatedUser])

	useEffect(() => {
		const handleResize = () => {
			setWindowSize(window.innerWidth)
		}

		window.addEventListener('resize', handleResize)

		return () => window.removeEventListener('resize', handleResize)
	})
	useEffect(() => {
		if (companyByUserId && auth.isAuthenticated) {
			setFocusMessage({
				receiverId: auth?.companyByUserId?._id,
				...focusMessage,
			})
			dispatch(
				getNotificationsByFields({
					fields: [],
					operatorBetweenFields: 'or',
					options: { all: false, page: 1, limit: 10 },
					sort: { sortOrder: 'desc', sortField: 'createdAt' },
					byUserId: companyByUserId?._id,
				})
			)
		}
	}, [
		newUserProf,
		updatedLineOffer,
		updatedUser,
		auth,
		companyByUserId,
		addedNewLinesOffer,
		addedLineOrder,
		deletedLinesOffer,
		updatedLineOrder,
		refusedLineOrder,
	])
	const getTotalNotRead = () => {
		let total = 0
		map(countsNotReadAdmin, (value, key) => {
			total = value ? value.length + total : total
		})
		return total
	}
	useEffect(() => {
		auth?.isAuthenticated ? $('#fb-root').hide() : $('#fb-root').show()
	}, [auth])
	useEffect(() => {
		auth?.isAuthenticated ? $('#fb-root').hide() : $('#fb-root').show()
	})
	return (
		<Layout>
			{loadingLogin ? (
				<>
					<CustomSpin bgColor={'#fff'} height={'100vh'} />
				</>
			) : (
				<>
					{auth?.user?.status == STATUS.ACTIVE &&
					match &&
					!auth?.user?.roles?.includes(ROLES.CLIENT) ? (
						windowSize > 650 ? (
							<Sidebar
								windowSize={windowSize}
								breakpointWidth={'xxl'}
								items={getSidebarLinks(auth?.user)}
								totalNotRead={getTotalNotRead()}
							/>
						) : (
							<CustomDrawer visible={visible} onClose={onClose}>
								<Sidebar
									windowSize={windowSize}
									items={getSidebarLinks(auth?.user)}
									totalNotRead={getTotalNotRead()}
								/>
							</CustomDrawer>
						)
					) : null}

					<CustomLayout className='site-layout'>
						<CustomHeader>
							<Row justify='space-between'>
								{auth?.isAuthenticated == true &&
								auth?.user?.status == STATUS.ACTIVE ? (
									<Col style={{ display: 'flex' }}>
										{true ? (
											<HamburgerButton
												type='ghost'
												onClick={showDrawer}
												icon={
													<MenuUnfoldOutlined
														size={'large'}
													/>
												}
												style={{
													marginRight: 'auto',
													marginLeft: '1%',
													color: white,
												}}
												shape='circle'
												size={'large'}
											/>
										) : null}
										<Title>
											{titlesPath(
												auth?.user?.roles,
												location.pathname
											)}
										</Title>
									</Col>
								) : (
									<Col
										style={{
											width: '60px',
											height: '60px',
											background: 'white',
											borderRadius: '62%',
											display: 'flex',
											justifyContent: 'center',
											margin: '0.2%',
										}}
									>
										{isEmpty(match) ||
										auth?.user?.status ==
											STATUS.PROCESSING ? (
											<Link to={'/'}>
												<Image
													preview={false}
													alt='logo'
													src={LOGO}
													width={45}
												/>
											</Link>
										) : null}
									</Col>
								)}
								<Col style={{ textAlign: 'right' }}>
									<Row justify='center' align='center'>
										{/* {match ? (
									<Link to='/' target='_blank'>
										<CustomButton
											borderRadius='10px'
											margin='0 5px'
											bgColor='white'
											colorText='#0854a5'
											// onClick={() => history.push("/")}
										>
											Visite le site
										</CustomButton>
									</Link>
								) : intersection(
										[ROLES.PROF, ROLES.ADMIN],
										auth?.user?.roles
								  ).length > 0 ? (
									<Link to='/dash/offres'>
										<CustomButton
											borderRadius='10px'
											margin='0 5px'
											bgColor='white'
											colorText='#0854a5'
											// onClick={() => history.push("/")}
										>
											Votre Espace
										</CustomButton>
									</Link>
								) : null} */}
										{/* {!auth?.isAuthenticated ? (
											<>
												<Col>
													<Typography.Title
														strong
														level={5}
														style={{
															color: '#fff',
														}}
													>
														Info: 22 080 225
													</Typography.Title>
												</Col>
												<Divider
													type='vertical'
													style={{
														borderLeftColor: '#fff',
													}}
												/>
											</>
										) : (
											''
										)} */}
										{!isEmpty(auth?.user) ? (
											<>
												{/* auth?.user.status ==
												STATUS.ACTIVE ? (
													<Col>
														<Typography.Text
															style={{
																color: '#fff',
															}}
															strong
															keyboard
														>
															CLI:
															{withDigits(
																auth?.user
																	?.codeClient,
																5
															)}
														</Typography.Text>
													</Col>
												) : null */}
												{/* <Divider
													type='vertical'
													style={{
														borderLeftColor: '#fff',
													}}
												/> */}
												<Menu
													mode='horizontal'
													triggerSubMenuAction='click'
													/* onOpenChange={(
														openKeys
													) => {
														if (
															openKeys.includes(
																'notifs'
															)
														) {
															alert('rr')
														}
													}} */
												>
													<SubMenu
														key={'notifs'}
														title={
															<>
																<Badge
																	className='badge-notif'
																	count={
																		notifs
																			?.countsNotRead
																			?.length
																	}
																>
																	{notifs
																		?.entities
																		?.length &&
																	notifs
																		?.countsNotRead
																		?.length >
																		0 ? (
																		<NotificationsActiveOutlined
																			fontSize='medium'
																			style={{
																				color: 'white',
																			}}
																		/>
																	) : (
																		<NotificationsNoneOutlined
																			fontSize='medium'
																			style={{
																				color: 'white',
																			}}
																		/>
																	)}
																</Badge>
															</>
														}
														onTitleClick={({
															key,
														}) => {
															dispatch(
																updateNotificationsByIds(
																	{
																		_ids: notifs?.countsNotRead,
																	}
																)
															)
														}}
													>
														{notifs?.entities
															?.length ? (
															<List
																style={{
																	width: '30em',
																	height: '15em',
																	backgroundColor:
																		'white',
																}}
																itemLayout='horizontal'
																dataSource={
																	notifs?.entities
																}
																renderItem={(
																	item
																) => (
																	<List.Item>
																		<List.Item.Meta
																			key={
																				item._id
																			}
																			avatar={
																				<NotificationImportantOutlined
																					style={{
																						color: '#ff4d4f',
																					}}
																				/>
																			}
																			title={
																				<Link
																					to={getLinkNotif(
																						item?.type,
																						item?.subject
																					)}
																				>
																					<Typography.Text>
																						{
																							item.message
																						}
																					</Typography.Text>
																				</Link>
																			}
																			description={
																				<Typography.Text
																					disabled
																				>
																					{moment(
																						item?.createdAt
																					).format(
																						'L [à] HH:mm'
																					)}
																				</Typography.Text>
																			}
																		/>
																	</List.Item>
																)}
															/>
														) : null}
													</SubMenu>
													{auth?.user?.roles?.includes(
														ROLES.PROF
													) ? (
														<SubMenu
															key={'cart'}
															title={
																<>
																	<Badge
																		className='badge-cart'
																		count={
																			linesOfferOrder?.length
																		}
																	>
																		<ShoppingCartOutlined
																			fontSize='medium'
																			style={{
																				color: 'white',
																			}}
																		/>
																	</Badge>
																</>
															}
														>
															{linesOfferOrder?.length ? (
																<List
																	header={
																		<Link
																			to={
																				PANIER_URL
																			}
																		>
																			<Affix
																				offsetTop={
																					60
																				}
																			>
																				<Button type='primary'>
																					Voir
																					Panier
																				</Button>
																			</Affix>
																		</Link>
																	}
																	style={{
																		padding:
																			'2%',
																		width: '30em',
																		height: '15em',
																		backgroundColor:
																			'white',
																	}}
																	itemLayout='horizontal'
																	dataSource={
																		linesOfferOrder
																	}
																	renderItem={(
																		item
																	) => (
																		<List.Item>
																			<List.Item.Meta
																				key={
																					item._id
																				}
																				avatar={
																					<Badge
																						//size='small'
																						color={
																							item?.type ==
																							TYPE_OFFER.SELL
																								? '#f56a00'
																								: '#08a908'
																						}
																						count={
																							item?.type ==
																							TYPE_OFFER.SELL
																								? parseInt(
																										item.qtePriceCommande
																								  )
																								: `${item?.qtePriceCommande} dt`
																						}
																					>
																						<Avatar
																							style={{
																								backgroundColor:
																									item?.type ==
																									TYPE_OFFER.PURCHASE
																										? '#f56a00'
																										: '#08a908',
																							}}
																						>
																							{item?.type ==
																							TYPE_OFFER.PURCHASE
																								? 'V'
																								: 'A'}
																						</Avatar>
																						{/* <ShoppingBasketOutlined
																						fontSize='medium'
																						style={{
																							color: '#333',
																						}}
																					/> */}
																					</Badge>
																				}
																				title={
																					</* to={
																				auth?.user?.roles?.includes(
																					ROLES.CLIENT
																				)
																					? NOTIFICATIONS_CLIENT_URL
																					: NOTIFICATIONS_URL
																			} */>
																						<Link
																							to={
																								PANIER_URL
																							}
																						>
																							<Typography.Text
																								ellipsis
																							>
																								{
																									item.marque
																								}

																								-
																								{
																									item.description
																								}
																							</Typography.Text>
																						</Link>
																					</>
																				}
																				description={
																					<Typography.Text
																						ellipsis
																						disabled
																					>
																						{
																							item.ref
																						}
																					</Typography.Text>
																				}
																			/>
																		</List.Item>
																	)}
																/>
															) : null}
														</SubMenu>
													) : null}
													<SubMenu
														key={'submenu'}
														title={
															<>
																<Avatar
																	style={{
																		backgroundColor:
																			gray_1,
																	}}
																	size={40}
																	icon={
																		<UserOutlined />
																	}
																/>
															</>
														}
													>
														{auth?.user?.status ==
															STATUS.ACTIVE && (
															<Menu.Item
																key={'profile'}
																onClick={() => {
																	setModalUserDetail(
																		true
																	)
																}}
															>
																Profile
															</Menu.Item>
														)}
														<Menu.Divider />

														<Menu.Item
															key={'déconnecter'}
															onClick={async () => {
																client.cache.reset()
																clearCache()
																await localStorage.clear()
																history.push(
																	'/'
																)
																dispatch(
																	logout()
																)
															}}
														>
															Se déconnecter
														</Menu.Item>
													</SubMenu>
												</Menu>
											</>
										) : (
											<>
												<CustomButton
													borderRadius='10px'
													margin='0 5px'
													bgColor='white'
													colorText='#0854a5'
													onClick={() => {
														setMode('login')
														setIsModalVisible(true)
													}}
												>
													Connexion
												</CustomButton>
												<CustomButton
													borderRadius='10px'
													margin='0 5px'
													bgColor='white'
													colorText='#0854a5'
													onClick={() => {
														setMode('inscription')
														setIsModalVisible(true)
													}}
												>
													Inscription
												</CustomButton>
											</>
										)}
									</Row>
								</Col>
							</Row>
						</CustomHeader>

						<CustomContent
							className='site-layout-background'
							style={{
								height: 'calc(100vh - 4.9em)',
								width: '100%',
								alignSelf: 'center',
								//overflow: 'auto',
							}}
						>
							<Container>{children}</Container>
						</CustomContent>
						{/* <CustomFooter>
							<Typography.Title
								strong
								level={4}
								style={{ color: '#fff' }}
							>
								pour plus d'info : 22 080 225
							</Typography.Title>
						</CustomFooter> */}
					</CustomLayout>
				</>
			)}

			{mode == 'login' ? (
				<LoginForm
					isModalVisible={isModalVisible}
					setIsModalVisible={setIsModalVisible}
				/>
			) : (
				<Register
					withGetCurrentUser={true}
					textButton="S'inscrire"
					isModalVisible={isModalVisible}
					setIsModalVisible={setIsModalVisible}
					mode='add'
				/>
			)}
			<UserDetail
				setModalUserDetail={setModalUserDetail}
				modalUserDetail={modalUserDetail}
			/>
			{auth?.isAuthenticated ? (
				<>
					{/* 	<ChatLauncher /> */}
					<div
						className='chat-box'
						style={{
							position: 'fixed',
							bottom: 10,
							right: '0',
							zIndex: 99,
						}}
					>
						{auth?.user?.roles?.includes(ROLES.PROF) ? (
							<ChatRoom chatOne />
						) : (
							<ChatRoom />
						)}
					</div>
				</>
			) : null}
		</Layout>
	)
}

export default Layouts
