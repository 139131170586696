import { combineReducers } from 'redux'
import authReducer from './auth/reducers'
import companyReducer from './company/reducers'
import userReducer from './user/reducers'
import articleReducer from './article/reducers'
import offerReducer from './offer/reducers'
import lineOfferReducer from './lineOffer/reducers'
import notificationReducer from './notification/reducers'
import orderReducer from './order/reducers'
import lineOrderReducer from './lineOrder/reducers'
import cartReducer from './cart/reducers'
import chatReducer from './chat/reducers'

export default combineReducers({
	auth: authReducer,
	users: userReducer,
	companies: companyReducer,
	articles: articleReducer,
	offers: offerReducer,
	lineOffers: lineOfferReducer,
	orders: orderReducer,
	lineOrders: lineOrderReducer,
	cart: cartReducer,
	notifs: notificationReducer,
	chats: chatReducer,
})
