import styled from 'styled-components'

export const CustomtableContainer = styled.div`
	.ant-badge-status-processing {
		position: absolute;
	}
	.ant-switch-inner .MuiSvgIcon-fontSizeSmall {
		font-size: 1rem;
	}
	.ant-table-thead > tr > th {
		background: ${(props) => props.theme.secondary} !important;
		color: ${(props) => props.theme.white};
	}
	.ant-table-filter-trigger {
		color: ${(props) => props.theme.white};
	}
	.ant-table-column-title {
		font-size: 1rem;
	}
	.ant-table-filter-trigger svg {
		font-size: 1rem;
	}
	.purchase-color-row {
		background-color: ${(props) => props.theme.purchaseRow};
	}
	.ant-descriptions-row th {
		background-color: ${(props) => props.theme.secondary};
		color: ${(props) => props.theme.white};
		font-weight: 600;
	}
	/* td.ant-table-cell-fix-left,
	td.ant-table-cell-fix-right {
		background: inherit;
	} */
	.ant-descriptions-bordered .ant-descriptions-view {
		border: 1px solid ${(props) => props.theme.secondary};
	}
`
