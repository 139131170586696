import { concat, fill, has, isEmpty, merge } from 'lodash'
import { TYPE_OFFER } from '../../constants/type_offer'
import { initialStatePagination } from '../../constants/variables'
import {
	FETCH_LINEORDERS_SUCCESS,
	FETCH_LINEORDERS_ERROR,
	CREATE_LINESORDER_SUCCESS,
	CREATE_LINESORDER_ERROR,
	UPDATE_LINEORDER_SUCCESS,
	UPDATE_LINEORDER_ERROR,
	DELETE_LINESORDER_SUCCESS,
	DELETE_LINESORDER_ERROR,
	START_LOADING_LINEORDERS,
	START_LOADING_CREATE_LINESORDER,
	START_LOADING_UPDATE_LINEORDER,
	START_LOADING_DELETE_LINESORDER,
	START_LOADING_LINEORDER_BY_ID,
	LINEORDER_BY_ID_SUCCESS,
	LINEORDER_BY_ID_ERROR,
	UPDATE_LIST_LINEORDERS,
} from './types'
const initialState = merge(
	{
		lineOrderById: {},
		loadingGetLineOrders: false,
		loadingCreateLinedOrder: false,
		loadingGetLineOrderById: false,
		loadingDeleteLinesOrder: false,
		loadingUpdateLineOrder: false,
		statusData: '',
		error: '',
	},
	initialStatePagination
)

export default function lineOrderReducer(state = initialState, action) {
	switch (action.type) {
		/* ****************** GET LINEORDERS ************************ */
		case START_LOADING_LINEORDERS:
			return {
				...state,
				loadingGetLineOrders: true,
			}
		case FETCH_LINEORDERS_SUCCESS:
			const { data, status } = action.payload

			return {
				...state,
				...data,
				statusData: status,
				loadingGetLineOrders: false,
				error: '',
			}
		case FETCH_LINEORDERS_ERROR:
			return {
				...state,
				loadingGetLineOrders: false,
				error: action.payload,
			}
		/* ****************** CREATE LINEORDER ************************ */
		case START_LOADING_CREATE_LINESORDER:
			return {
				...state,
				loadingCreateLinedOrder: true,
			}
		case CREATE_LINESORDER_SUCCESS:
			return {
				...state,
				//entities: [action.payload, ...state.entities],
				//entities: [action.payload, ...state.entities],
				loadingCreateLinedOrder: false,
				error: '',
			}
		case CREATE_LINESORDER_ERROR:
			return {
				...state,
				loadingCreateLinedOrder: false,
				error: action.payload,
			}

		/* ****************** LINEORDER BY ID ************************ */
		case START_LOADING_LINEORDER_BY_ID:
			return {
				...state,
				loadingGetLineOrderById: true,
			}
		case LINEORDER_BY_ID_SUCCESS:
			return {
				...state,
				lineOrderById: action.payload,
				loadingGetLineOrderById: false,
				error: '',
			}
		case LINEORDER_BY_ID_ERROR:
			return {
				...state,
				loadingGetLineOrderById: false,
				error: action.payload,
			}
		/* ****************** UPDATE LINEORDER ************************ */
		case START_LOADING_UPDATE_LINEORDER:
			return {
				...state,
				loadingUpdateLineOrder: true,
			}
		case UPDATE_LINEORDER_SUCCESS:
			if (state.statusData != action?.payload?.status) {
				state.entities = [
					...state.entities.filter(
						(lineOrder) => lineOrder?._id !== action?.payload?._id
					),
				]
			} else {
				const exist = state.entities?.find(
					(lineOrder) => lineOrder?._id == action?.payload?._id
				)
				if (isEmpty(exist)) {
					state.entities = [order, ...state.entities]
				} else {
					const entities = []
					state.entities.map((lineOrder) => {
						if (lineOrder._id === action?.payload?._id) {
							entities.push(action?.payload)
						} else entities.push(lineOrder)
					})
					state.entities = entities
				}
			}
			return {
				...state,
				loadingUpdateLineOrder: false,
				error: '',
			}
		case UPDATE_LINEORDER_ERROR:
			return {
				...state,
				loadingUpdateLineOrder: false,
				error: action.payload,
			}
		/* ****************** Update linesOffer List ************************ */
		case UPDATE_LIST_LINEORDERS:
			const { order, deleted } = action.payload
			console.log({ order, deleted })
			if (order?.type == TYPE_OFFER.PURCHASE) {
				if (!has(state.entities[0], 'data')) {
					if (state.statusData != order?.status || deleted) {
						state.entities = [
							...state.entities.filter(
								(lineOrder) => lineOrder?._id !== order?._id
							),
						]
					} else {
						const exist = state.entities?.find(
							(lineOrder) => lineOrder?._id == order?._id
						)
						if (isEmpty(exist)) {
							state.entities = [order, ...state.entities]
						} else {
							const entities = []
							state.entities.map((lineOrder) => {
								if (lineOrder._id === order?._id) {
									entities.push(order)
								} else entities.push(lineOrder)
							})
							state.entities = entities
						}
					}
				}
			} else if (order?.type == TYPE_OFFER.SELL) {
				if (
					has(state.entities[0], 'data') ||
					state.entities?.length == 0
				) {
					let entities = []
					const exist = state.entities?.find(
						(line) => line?._id == order?.lineOffer?._id
					)
					if (isEmpty(exist)) {
						entities = [
							{
								data: [order],
								total: 1,
								_id: order?.lineOffer?._id,
							},
							...state.entities,
						]
					} else {
						state.entities.map((lineOrder, index) => {
							let data = []
							const exist = lineOrder.data?.find(
								(line) => line?._id === order?._id
							)
							if (lineOrder?._id == order?.lineOffer?._id) {
								if (isEmpty(exist)) {
									data = [order, ...lineOrder.data]
								} else {
									lineOrder.data.map((val) => {
										if (val._id === order?._id) {
											data.push(order)
										} else data.push(val)
									})
								}
								lineOrder = { ...lineOrder, data }
							} else {
								lineOrder = { ...lineOrder }
							}
							entities.push(lineOrder)
						})
						console.log({ entities })
					}
					state.entities = entities
				}
			}

			return {
				...state,
				error: '',
			}
		/* ****************** DELETE LINEORDER ************************ */
		case START_LOADING_DELETE_LINESORDER:
			return {
				...state,
				loadingDeleteLinesOrder: true,
			}
		case DELETE_LINESORDER_SUCCESS:
			return {
				...state,
				loadingDeleteLinesOrder: false,
				entities: [
					...state.entities.filter(
						(lineOrder) => lineOrder._id !== action.payload._id
					),
				],
				error: '',
			}
		case DELETE_LINESORDER_ERROR:
			return {
				...state,
				loadingDeleteLinesOrder: false,
				error: action.payload,
			}
		default:
			return state
	}
}
