import { has } from 'lodash'
import { apolloClient } from '../../graphql'
import {
	CREATE_CHAT_QUERY,
	UPDATE_CHATS_BY_IDS_QUERY,
} from '../../queries/chat'
import {
	//CREATE_CHAT_QUERY,
	//CREATE_PROF_QUERY,
	//DELETE_CHAT_BY_ID_QUERY,
	//UPDATE_CHATS_BY_IDS_QUERY,
	CHATS_BY_FIELDS_QUERY,
} from '../../queries/chat'

import {
	START_LOADING_CHATS,
	FETCH_CHATS_SUCCESS,
	FETCH_CHATS_ERROR,
	START_LOADING_CREATE_CHAT,
	CREATE_CHAT_SUCCESS,
	CREATE_CHAT_ERROR,
	START_LOADING_UPDATE_CHATS,
	UPDATE_CHATS_SUCCESS,
	UPDATE_CHATS_ERROR,
	START_LOADING_UPDATE_LIST_CHAT,
	UPDATE_LIST_CHAT_SUCCESS,
	UPDATE_LIST_CHAT_ERROR,
	UPDATE_CURRENT_CONV_SUCCESS,
	UPDATE_CHAT_BOXES_SUCCESS,
	ADD_CHAT_BOXES_SUCCESS,
	REMOVE_CHAT_BOXES_SUCCESS,
} from './types'

/* *************** CHATS BY FIELDS ************** */
export const getChatsByFields = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_CHATS,
		})
		const res = await apolloClient.query({
			query: CHATS_BY_FIELDS_QUERY,
			variables,
			fetchPolicy: 'network-only',
		})
		dispatch({
			type: FETCH_CHATS_SUCCESS,
			payload: res.data?.findChatsByField,
		})
	} catch (error) {
		dispatch({
			type: FETCH_CHATS_ERROR,
			payload: error.message,
		})
	}
}

/* *************** Update List Conversation  ************** */
export const updateListConversation =
	(newMessage, focusMessage = {}) =>
	async (dispatch) => {
		try {
			if (has(newMessage, 'dateChat')) {
				newMessage = { ...newMessage, createdAt: newMessage?.dateChat }
			}
			dispatch({
				type: START_LOADING_UPDATE_LIST_CHAT,
			})
			dispatch({
				type: UPDATE_LIST_CHAT_SUCCESS,
				payload: { newMessage, focusMessage },
			})
		} catch (error) {
			dispatch({
				type: UPDATE_LIST_CHAT_ERROR,
				payload: error.message,
			})
		}
	}
/* *************** Add ChatBoxes  ************** */
export const addChatBoxes =
	(currentConverId, variables) => async (dispatch) => {
		variables.byUserId = currentConverId?.split('_')[0]
		try {
			const res = await apolloClient.query({
				query: CHATS_BY_FIELDS_QUERY,
				variables,
				fetchPolicy: 'network-only',
			})
			dispatch({
				type: ADD_CHAT_BOXES_SUCCESS,
				payload: {
					currentConverId,
					data: res.data?.findChatsByField,
				},
			})
		} catch (error) {
			console.log(error)
		}
	}

/* *************** Remove ChatBoxes  ************** */
export const removeChatBoxes = (currentConverId) => async (dispatch) => {
	try {
		dispatch({
			type: REMOVE_CHAT_BOXES_SUCCESS,
			payload: currentConverId,
		})
	} catch (error) {
		console.log(error)
	}
}
/* *************** CREATE CHAT ************** */
export const createChat = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_CREATE_CHAT,
		})

		const res = await apolloClient.mutate({
			mutation: CREATE_CHAT_QUERY,
			variables,
		})

		dispatch({
			type: CREATE_CHAT_SUCCESS,
			payload: res.data.createChat,
		})
	} catch (error) {
		dispatch({
			type: CREATE_CHAT_ERROR,
			payload: error.message,
		})
	}
}
/* *************** CREATE CHAT ************** */
export const updateCurrentConv = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: UPDATE_CURRENT_CONV_SUCCESS,
			payload: variables,
		})
	} catch (error) {
		console.log(error)
	}
}

/* *************** CREATE Prof ************** */
/* export const createProf = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_CREATE_PROF,
		})

		const res = await apolloClient.mutate({
			mutation: CREATE_PROF_QUERY,
			variables,
		})

		dispatch({
			type: CREATE_PROF_SUCCESS,
			payload: res.data.createProf,
		})
	} catch (error) {
		dispatch({
			type: CREATE_PROF_ERROR,
			payload: error.message,
		})
	}
} */

/* *************** UPDATE CHAT ************** */
export const updateChatsByIds = (variables) => async (dispatch) => {
	try {
		if (variables?._ids.length > 0) {
			dispatch({
				type: START_LOADING_UPDATE_CHATS,
			})
			const res = await apolloClient.mutate({
				mutation: UPDATE_CHATS_BY_IDS_QUERY,
				variables,
			})
			dispatch({
				type: UPDATE_CHATS_SUCCESS,
				payload: res.data.updateChatsByIds,
			})
		}
	} catch (error) {
		dispatch({
			type: UPDATE_CHATS_ERROR,
			payload: error.message,
		})
	}
}
/* *************** DELETE CHAT ************** */
/* export const deleteChat = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_DELETE_CHAT,
		})
		const res = await apolloClient.mutate({
			mutation: DELETE_CHAT_BY_ID_QUERY,
			variables,
		})

		dispatch({
			type: DELETE_CHAT_SUCCESS,
			payload: variables,
		})
	} catch (error) {
		dispatch({
			type: DELETE_CHAT_ERROR,
			payload: error.message,
		})
	}
} */
