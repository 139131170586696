import { gql } from '@apollo/client'

export const NEW_USER_PROF_SUBSCRIPTION = gql`
	subscription userProfAdded {
		userProfAdded {
			receiverId
			message
			data {
				accessToken
				user {
					_id
					email
					roles
					lastName
					firstName
				}
			}
		}
	}
`

export const UPDATE_USER_SUBSCRIPTION = gql`
	subscription updatedUser {
		updatedUser {
			receiverId
			message
			type
			data {
				_id
				matFiscal
				title
				address
				phoneNumber
				email
				activity
				marques
				type
				user {
					_id
					email
					roles
					phone
					lastName
					firstName
					status
				}
			}
		}
	}
`

export const UPDATE_LINEOFFER_SUBSCRIPTION = gql`
	subscription updatedLineOffer {
		updatedLineOffer {
			receiverId
			message
			data {
				_id
				numRef
				type
				marque
				ref
				refOrigin
				description
				unitPrice
				unitPriceFinal
				quantity
				status
				statusArticle
				idArticle
				company {
					_id
					title
					email
					phoneNumber
				}
			}
		}
	}
`

export const NEW_MESSAGE_SUBSCRIPTION = gql`
	subscription newMessage {
		newMessage {
			_id
			message
			type
			files
			is_read
			dateChat
			senderId {
				_id
				title
				matFiscal
				address
				type
				email
			}
			receiverId {
				_id
				title
				matFiscal
				address
				type
				email
				createdAtChat
			}
		}
	}
`

export const NEW_OFFER_SUBSCRIPTION = gql`
	subscription addedNewLinesOffer {
		addedNewLinesOffer {
			receiverId
			message
			data {
				_id
				numRef
				type
				marque
				ref
				refOrigin
				description
				unitPrice
				quantity
				status
				statusArticle
				idArticle
				company {
					_id
					title
					email
					phoneNumber
				}
			}
		}
	}
`
export const DELETE_LINESOFFER_SUBSCRIPTION = gql`
	subscription deletedLinesOffer {
		deletedLinesOffer {
			receiverId
			message
			data
		}
	}
`

export const NEW_LINEORDER_SUBSCRIPTION = gql`
	subscription addedLineOrder {
		addedLineOrder {
			receiverId
			message
			data {
				_id
				quantity
				unitPrice
				unitPriceFinal
				type
				status
				article {
					marque
					ref
					refOrigin
					description
				}
				lineOffer {
					_id
				}
				order {
					numRef
					_id
				}
				company {
					title
					phoneNumber
					email
					_id
				}
				orderedBy {
					title
					phoneNumber
					email
					_id
				}
			}
		}
	}
`

export const UPDATED_LINEORDER_SUBSCRIPTION = gql`
	subscription updatedLineOrder {
		updatedLineOrder {
			receiverId
			message
			data {
				_id
				quantity
				unitPrice
				unitPriceFinal
				type
				status
				article {
					marque
					ref
					refOrigin
					description
				}
				lineOffer {
					_id
				}
				order {
					numRef
					_id
				}
				company {
					title
					phoneNumber
					email
					_id
				}
				orderedBy {
					title
					phoneNumber
					email
					_id
				}
			}
		}
	}
`
export const REFUSED_LINEORDER_SUBSCRIPTION = gql`
	subscription refusedLineOrder {
		refusedLineOrder {
			receiverId
			message
			data {
				_id
				quantity
				unitPrice
				unitPriceFinal
				type
				status
				article {
					marque
					ref
					refOrigin
					description
				}
				lineOffer {
					_id
				}
				order {
					numRef
					_id
				}
				company {
					title
					phoneNumber
					email
					_id
				}
				orderedBy {
					title
					phoneNumber
					email
					_id
				}
			}
		}
	}
`

/* export const NEW_OFFER_SUBSCRIPTION = gql`
	subscription addedNewOffer()
` */
