import { gql } from '@apollo/client'

export const NOTIFICATIONS_BY_FIELDS_QUERY = gql`
	query getNotificationsByField(
		$fields: [FieldInput!]!
		$operatorBetweenFields: String
		$options: Options
		$sort: Sort
		$byUserId: String!
	) {
		findNotificationsByField(
			fields: $fields
			operatorBetweenFields: $operatorBetweenFields
			options: $options
			sort: $sort
			byUserId: $byUserId
		) {
			entities {
				_id
				message
				onModel
				type
				is_read
				createdAt
				senderId {
					_id
				}
				subject {
					... on User {
						firstName
						lastName
						email
						status
					}
					... on Article {
						marque
						ref
						description
						img
					}
					... on LineOffer {
						_id
						status
						type
					}
					... on LineOrder {
						_id
						status
						type
					}
				}
			}
			countsNotRead
			totalCount
			totalPages
			page
			limit
			hasNextPage
			hasPreviousPage
		}
	}
`

export const UPDATE_NOTIFICATIONS_BY_ID_QUERY = gql`
	mutation updateNotificationsByIds($_ids: [String!]!) {
		updateNotificationsByIds(input: { ids: $_ids })
	}
`
