import { apolloClient } from '../../graphql'
import {
	CREATE_USER_QUERY,
	CREATE_PROF_QUERY,
	DELETE_USER_BY_ID_QUERY,
	UPDATE_USER_BY_ID_QUERY,
	USERS_BY_FIELDS_QUERY,
	USER_BY_ID_QUERY,
} from '../../queries/user'

import {
	START_LOADING_USERS,
	FETCH_USERS_SUCCESS,
	FETCH_USERS_ERROR,
	START_LOADING_USER_BY_ID,
	USER_BY_ID_SUCCESS,
	USER_BY_ID_ERROR,
	START_LOADING_CREATE_USER,
	CREATE_USER_SUCCESS,
	CREATE_USER_ERROR,
	START_LOADING_CREATE_PROF,
	CREATE_PROF_SUCCESS,
	CREATE_PROF_ERROR,
	START_LOADING_DELETE_USER,
	DELETE_USER_SUCCESS,
	DELETE_USER_ERROR,
	START_LOADING_UPDATE_USER,
	UPDATE_USER_SUCCESS,
	UPDATE_USER_ERROR,
} from './types'

/* *************** USERS BY FIELDS ************** */
export const getUsersByFileds =
	(
		variables = {
			fields: [{ field: 'firstName', fieldValue: '', specific: false }],
			operatorBetweenFields: 'and',
			options: { all: false, page: 1, limit: 10 },
			sort: { sortOrder: 'desc', sortField: 'createdAt' },
		}
	) =>
	async (dispatch) => {
		try {
			dispatch({
				type: START_LOADING_USERS,
			})
			const res = await apolloClient.query({
				query: USERS_BY_FIELDS_QUERY,
				variables,
				fetchPolicy: 'network-only',
			})
			dispatch({
				type: FETCH_USERS_SUCCESS,
				payload: res.data?.findUsersByField,
			})
		} catch (error) {
			dispatch({
				type: FETCH_USERS_ERROR,
				payload: error.message,
			})
		}
	}
/* *************** User BY ID ************** */
export const getUserById = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_USER_BY_ID,
		})
		const res = await apolloClient.query({
			query: USER_BY_ID_QUERY,
			variables,
			fetchPolicy: 'network-only',
		})
		dispatch({
			type: USER_BY_ID_SUCCESS,
			payload: res.data.findOneUser,
		})
	} catch (error) {
		dispatch({
			type: USER_BY_ID_ERROR,
			payload: error.message,
		})
	}
}
/* *************** CREATE USER ************** */
export const createUser = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_CREATE_USER,
		})

		const res = await apolloClient.mutate({
			mutation: CREATE_USER_QUERY,
			variables,
		})

		dispatch({
			type: CREATE_USER_SUCCESS,
			payload: res.data.createUser,
		})
	} catch (error) {
		dispatch({
			type: CREATE_USER_ERROR,
			payload: error.message,
		})
	}
}

/* *************** CREATE Prof ************** */
export const createProf = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_CREATE_PROF,
		})

		const res = await apolloClient.mutate({
			mutation: CREATE_PROF_QUERY,
			variables,
		})

		dispatch({
			type: CREATE_PROF_SUCCESS,
			payload: res.data.createProf,
		})
	} catch (error) {
		dispatch({
			type: CREATE_PROF_ERROR,
			payload: error.message,
		})
	}
}

/* *************** UPDATE USER ************** */
export const updateUser = (variables) => async (dispatch) => {
	try {
		variables.codeClient = parseInt(variables.codeClient)
		dispatch({
			type: START_LOADING_UPDATE_USER,
		})
		const res = await apolloClient.mutate({
			mutation: UPDATE_USER_BY_ID_QUERY,
			variables,
		})
		dispatch({
			type: UPDATE_USER_SUCCESS,
			payload: res.data.updateUser,
		})
	} catch (error) {
		dispatch({
			type: UPDATE_USER_ERROR,
			payload: error.message,
		})
	}
}
/* *************** DELETE USER ************** */
export const deleteUser = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_DELETE_USER,
		})
		const res = await apolloClient.mutate({
			mutation: DELETE_USER_BY_ID_QUERY,
			variables,
		})
		dispatch({
			type: DELETE_USER_SUCCESS,
			payload: variables,
		})
	} catch (error) {
		dispatch({
			type: DELETE_USER_ERROR,
			payload: error.message,
		})
	}
}
