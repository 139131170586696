import React, { useState } from 'react'
import {
	Form,
	Button,
	Space,
	Input,
	Upload,
	//InputNumber, AutoComplete,
} from 'antd'
import {
	MinusCircleOutlined,
	PlusOutlined,
	UploadOutlined,
} from '@ant-design/icons'
import {
	//getArticlesByFields,
	resetArticlesAutoComplete,
} from '../../../redux/article/actions'
import {
	//shallowEqual, useSelector,
	useDispatch,
} from 'react-redux'
import { AttachFile } from '@material-ui/icons'

const ArticleForm = ({
	form,
	//setIdsArticles, idsArticles
}) => {
	const dispatch = useDispatch()
	const [list, setList] = useState([])
	var loadingClass = true ? 'chatApp__convButton--loading' : ''
	//const [fieldsEdit, setFieldsEdit] = useState([])
	/* const { loadingGetArticles, articlesAutoComplete } = useSelector(
		(state) => ({
			loadingGetArticles: state.articles.loadingGetArticles,
			articlesAutoComplete: state.articles.articlesAutoComplete,
		}),
		shallowEqual
	) */
	/* const onSearch = (currentSearch) => {
		currentSearch &&
			dispatch(
				getArticlesByFields(
					{
						fields: [
							{
								field: 'ref',
								fieldValue: currentSearch,
								specific: false,
							},
						],
						operatorBetweenFields: 'or',
						options: { all: true, page: 1, limit: 3 },
						sort: { sortOrder: 'desc', sortField: 'createdAt' },
						byUserId: '',
					},
					true
				)
			)
	} */
	return (
		<Form form={form} name='dynamic_form_nest_item' autoComplete='off'>
			<Form.List name='newArticles'>
				{(fields, { add, remove }) => {
					return (
						<>
							{fields?.map((field) => (
								<Space
									key={field.key}
									align='baseline'
									style={{ width: '100%' }}
								>
									<Form.Item
										{...field}
										label='Marque'
										name={[field.name, 'marque']}
										rules={[
											{
												required: true,
												message:
													'Veuillez saisir marque',
											},
										]}
									>
										<Input />
									</Form.Item>
									<Form.Item
										{...field}
										label='Référence'
										name={[field.name, 'ref']}
										rules={[
											{
												required: true,
												message:
													'Veuillez saisir référence!',
											},
										]}
									>
										<Input />
									</Form.Item>
									<Form.Item
										{...field}
										label='Réference Origin'
										name={[field.name, 'refOrigin']}
										rules={[
											{
												required: true,
												message:
													'Veuillez saisir réference origin',
											},
										]}
									>
										<Input />
									</Form.Item>
									<Form.Item
										{...field}
										label='Designation'
										name={[field.name, 'description']}
										rules={[
											{
												required: true,
												message:
													'Veuillez saisir designation!',
											},
										]}
									>
										<Input />
									</Form.Item>
									{/* <Form.Item
										{...field}
										label='Photo'
										name={[field.name, 'file']}
									>
										<Upload
											name='file'
											maxCount={1}
											type='file'
										>
											<div
												className={
													'chatApp__convButton ' +
													loadingClass
												}
											>
												<Button
													icon={<UploadOutlined />}
												>
													Upload
												</Button>
											</div>
										</Upload>
									</Form.Item> */}
									<MinusCircleOutlined
										onClick={() => remove(field.name)}
									/>
								</Space>
							))}

							<Form.Item>
								<Button
									type='dashed'
									onClick={() => {
										add()
										dispatch(resetArticlesAutoComplete())
									}}
									block
									icon={<PlusOutlined />}
								>
									Ajouter
								</Button>
							</Form.Item>
						</>
					)
				}}
			</Form.List>
		</Form>
	)
}

export default ArticleForm
