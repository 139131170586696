import {
	Form,
	Input,
	Radio,
	Modal,
	Row,
	Typography,
	message,
	InputNumber,
} from 'antd'
import { FormContainer, PrimaryButton } from '../../global/GlobalStyles'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../../redux/auth/actions'
import { withDigits } from '../../constants/fn'
function LoginForm({ isModalVisible, setIsModalVisible }) {
	const [form] = Form.useForm()
	const loadingLogin = useSelector((state) => state.auth?.loadingLogin)
	const error = useSelector((state) => state.auth?.error)
	let history = useHistory()
	let [isProfSelected, setIsProfSelected] = useState(true)
	const dispatch = useDispatch()
	const handleOk = () => {
		form.submit()
		form.validateFields()
			.then((values) => {
				const { codeClient, password } = values
				dispatch(
					login({
						codeClient: parseInt(codeClient),
						password,
						setIsModalVisible,
					})
				)
				history.push('/dash/offres/1')
			})
			.catch((errorInfo) => {
				console.log({ errorInfo })
			})
	}
	return (
		<Modal
			bodyStyle={{ overflowY: 'scroll', padding: '2%' }}
			title={
				<div
					style={{
						textAlign: 'center',
					}}
				>
					<Typography.Title level={4}>
						Connectez-Vous
					</Typography.Title>
					<Typography.Text type='danger'>{error}</Typography.Text>
					{/* <Radio.Group
						onChange={(e) => {
							setIsProfSelected(true)
							e.target.value == 'PROF'
								? setIsProfSelected(true)
								: setIsProfSelected(false)
						}}
						value={isProfSelected ? 'PROF' : 'CLIENT'}
						size='large'
					>
						<Radio value='CLIENT'>Particulier</Radio>
						<Radio value='PROF'>Professionel</Radio>
					</Radio.Group> */}
				</div>
			}
			onCancel={() => setIsModalVisible(false)}
			visible={isModalVisible}
			footer={
				<PrimaryButton
					htmlType='submit'
					block
					size='large'
					loading={loadingLogin}
					onClick={handleOk}
				>
					Se connecter
				</PrimaryButton>
			}
		>
			<Row type='flex' justify='center' align='center'>
				<FormContainer>
					<div className='form-box-shadow'>
						<Form
							form={form}
							layout='vertical'
							name='normal_login'
							className='login-form'
							initialValues={{ remember: true }}
							style={{
								width: '100%',
							}}
						>
							<Form.Item
								name='codeClient'
								label='Code-Client'
								rules={[
									{
										required: true,
										message:
											'Veuillez entrer votre codeClient',
									},
									{
										max: 5,
										min: 5,
										message:
											'Code Client doit être à 5 nombres',
									},
								]}
							>
								<Input
									size='large'
									style={{ width: '100%' }}
									prefix={
										<UserOutlined className='site-form-item-icon' />
									}
									max={5}
									min={5}
									placeholder='Code Client'
								/>
							</Form.Item>

							<Form.Item
								label='Mot de passe'
								name='password'
								rules={[
									{
										required: true,
										message: `Veuillez entrer votre mot de passe`,
									},
									({ getFieldValue }) => ({
										validator(rule, value) {
											var error = ''
											if (value === '') {
												error =
													"Vous n'avez pas entrer de code d'accès.\n"
												return Promise.reject(error)
											}
											return Promise.resolve()
										},
									}),
								]}
							>
								<Input.Password
									size='large'
									prefix={
										<LockOutlined className='site-form-item-icon' />
									}
									placeholder='mot de passe'
									onPressEnter={handleOk}
								/>
							</Form.Item>
							{/* <Form.Item>
						<PrimaryButton
							htmlType='submit'
							block
							size='large'
							loading={loading}
						>
							Se connecter
						</PrimaryButton>
					</Form.Item> */}
						</Form>
						<Radio.Group
							style={{
								width: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						></Radio.Group>
					</div>
				</FormContainer>
			</Row>
		</Modal>
	)
}

export default LoginForm
