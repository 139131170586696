import { gql } from '@apollo/client'

export const COMPANY_BY_ID = gql`
	query getCompanyById($_id: String) {
		getCompanyById(user: $_id) {
			_id
			user {
				_id
			}
		}
	}
`
export const COMPANY_BY_USER_ID_QUERY = gql`
	query getCompanyByIdUser($user: String) {
		getCompanyByIdUser(user: $user) {
			_id
			matFiscal
			title
			address
			phoneNumber
			email
			activity
			marques
			type
			user {
				_id
			}
		}
	}
`

export const CREATE_COMPANY_QUERY = gql`
	mutation createCompany(
		$matFiscal: String
		$title: String!
		$address: String!
		$phoneNumber: String!
		$email: String
		$website: String
		$activity: ActivityCompany
		$marques: [String!]
		$type: TypeCompany!
		$photo: String
		$user: String!
	) {
		createCompany(
			input: {
				matFiscal: $matFiscal
				title: $title
				address: $address
				phoneNumber: $phoneNumber
				email: $email
				website: $website
				activity: $activity
				marques: $marques
				type: $type
				photo: $photo
				user: $user
			}
		) {
			_id
			matFiscal
			title
			address
			phoneNumber
			email
			photo
			type
			user {
				lastName
			}
		}
	}
`

export const UPDATE_COMPANY_BY_ID_QUERY = gql`
	mutation updateCompanyById(
		$_id: String!
		$matFiscal: String
		$title: String
		$address: String
		$phoneNumber: String
		$email: String
		$website: String
		$activity: ActivityCompany
		$marques: [String!]
		$type: TypeCompany
		$photo: String
		$user: String
	) {
		updateCompanyById(
			input: {
				_id: $_id
				matFiscal: $matFiscal
				title: $title
				address: $address
				phoneNumber: $phoneNumber
				email: $email
				website: $website
				activity: $activity
				marques: $marques
				type: $type
				photo: $photo
				user: $user
			}
		) {
			_id
			matFiscal
			title
			address
			phoneNumber
			email
			website
			photo
			type
			marques
			user {
				lastName
			}
		}
	}
`
export const COMPANIES_BY_FIELDS_QUERY = gql`
	query getComapniesByFields(
		$fields: [FieldInput!]!
		$operatorBetweenFields: String
		$options: Options
		$sort: Sort
	) {
		findCompaniesByField(
			fields: $fields
			operatorBetweenFields: $operatorBetweenFields
			options: $options
			sort: $sort
		) {
			entities {
				_id
				matFiscal
				title
				address
				phoneNumber
				email
				website
				photo
				type
				createdAtChat
				createdAt
				user {
					lastName
					firstName
					email
					phone
				}
			}
			totalCount
			totalPages
			page
			limit
			hasNextPage
			hasPreviousPage
		}
	}
`
