export const theme = {
	primary: '#001529',
	secondary: '#1890ff',
	dark: '#001529',
	white: '#fff',
	sellRow: '#fff',
	purchaseRow: '#abff97',
	purchaseRowHover: '#d8ffcf',
	sellTag: '#08a908',
	purchaseTag: '#EE6400',
	refTag: '#5800FF',
	refOriginTag: '#5800FF',
	copyColor: '#000',
	/* gray: '#e1cece',
	gray_1: '#f0f0f0',
	black01: '#2a3845',
	blue01: '#6558f5',
	darkolivegreen: 'darkolivegreen', */
	//primary: '#EE6400',
	//secondary: '#FDC913',
	//dark: "#333333",
	//dark: '#3ca1eb',
	//light: "#FFF",
	//gray: "#f8f9fb",
}

/* 
primary : #001529

	white: '#fff',
*/
