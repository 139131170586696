import { gql } from '@apollo/client'
export const LINEOFFERS_BY_FIELDS_QUERY = gql`
	query getLineOffersByFields(
		$fields: [FieldInput!]
		$othersField: [FieldInput!]
		$anotherFields: [[FieldInput!]!]
		$globalFields: [[FieldInput!]!]
		$operatorBetweenFields: String
		$operatorBetweenGlobalFields: String
		$options: Options
		$sort: Sort
		$byUserId: String!
		$byType: String
		$byNotUserId: String
		$byStatus: String
	) {
		findLineOffersByField(
			fields: $fields
			othersField: $othersField
			anotherFields: $anotherFields
			globalFields: $globalFields
			operatorBetweenFields: $operatorBetweenFields
			operatorBetweenGlobalFields: $operatorBetweenGlobalFields
			options: $options
			sort: $sort
			byUserId: $byUserId
			byType: $byType
			byNotUserId: $byNotUserId
			byStatus: $byStatus
		) {
			entities {
				_id
				numRef
				type
				marque
				ref
				refOrigin
				description
				img
				unitPrice
				unitPriceFinal
				quantity
				reserved
				status
				statusArticle
				idArticle
				four
				createdAt
				company {
					_id
					title
					email
					phoneNumber
				}
			}
			totalCount
			totalPages
			page
			limit
			hasNextPage
			hasPreviousPage
		}
	}
`
export const LINEOFFER_BY_ID_QUERY = gql`
	query getLineOfferById($id: String!) {
		getLineOfferById(id: $id) {
			_id
			ref
			refOrigin
			marque
			description
			img
			company {
				title
				matFiscal
			}
		}
	}
`

export const CREATE_LINESOFFER_MUTATION = gql`
	mutation createLinesOffer($lineOffers: [LineOfferInput!]!) {
		createLinesOffer(input: { lineOffers: $lineOffers }) {
			_id
			numRef
			type
			marque
			ref
			refOrigin
			description
			unitPrice
			unitPriceFinal
			quantity
			status
			reserved
			statusArticle
			idArticle
			createdAt
			company {
				_id
				title
				email
				phoneNumber
			}
		}
	}
`

export const DELETE_LINESOFFER_BY_ID_MUTATION = gql`
	mutation deleteLinesOffer($ids: [String!]!, $companyId: String!) {
		deleteLinesOffer(input: { ids: $ids, companyId: $companyId })
	}
`

export const DELETE_LINEOFFER_BY_ID_MUTATION = gql`
	mutation deleteLinesOffer($_id: String!) {
		deleteLineOffer(input: { _id: $_id })
	}
`

export const LINEOFFERS_QUERY = gql`
	query getLineOffers {
		findAllLineOffers {
			_id
			title
			numRef
			type
			status
			company {
				title
				matFiscal
			}
		}
	}
`

export const UPLOAD_MULTIPLE_LINEOFFERS_MUTATION = gql`
	mutation uploadMultipleLineOffers($file: Upload!) {
		uploadMultipleLineOffers(file: $file)
	}
`

export const UPDATE_LINEOFFERS_BY_ID_MUTATION = gql`
	mutation updateLineOffer(
		$_id: String!
		$quantity: Float
		$unitPrice: Float
		$unitPriceFinal: Float
		$status: Statuss
		$offer: String
		$article: String
		$newArticle: UpdateArticleInput
	) {
		updateLineOffer(
			input: {
				_id: $_id
				quantity: $quantity
				unitPrice: $unitPrice
				unitPriceFinal: $unitPriceFinal
				status: $status
				offer: $offer
				article: $article
				newArticle: $newArticle
			}
		) {
			_id
			numRef
			type
			marque
			ref
			refOrigin
			description
			unitPrice
			unitPriceFinal
			quantity
			status
			statusArticle
			idArticle
			company {
				_id
				title
				email
				phoneNumber
			}
		}
	}
`
