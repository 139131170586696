import React, { useContext, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ChatBox from './components/ChatBox'
import { find, has, isEmpty, map } from 'lodash'
import { ChatOneContainer } from './style'
import Contexts from '../../context'
import { addChatBoxes, removeChatBoxes } from '../../redux/chat/actions'
import { Badge } from 'antd'
import images from '../../constants/images'
import $ from 'jquery'
import { ROLES } from '../../constants/roles'

const ChatRoom = ({ chatOne, currentUserConver }) => {
	const dispatch = useDispatch()
	const { currentsConverIds, setCurrentsConverIds } = useContext(
		Contexts.CurrentsConverIdsContext
	)
	const {
		user,
		chatBoxes,
		companyByUserId,
		loadingGetChats,
		lineOrders,
		countsNotRead,
		countsNotReadAdmin,
	} = useSelector(
		(state) => ({
			chatBoxes: state.chats.chatBoxes,
			user: state.auth?.user,
			countsNotRead: state.chats.countsNotRead,
			countsNotReadAdmin: state.chats.countsNotReadAdmin,
			companyByUserId: state.auth.companyByUserId,
			lineOrders: state.lineOrders.entities,
			loadingGetChats: state.chats.loadingGetChats,
		}),
		shallowEqual
	)
	const toggleChatBox = () => {
		//$(reduce.current).slideToggle('fast')
		$('.chatApp__conv').toggle('fast')
	}
	useEffect(
		() => user?.roles.includes(ROLES.PROF) && $('.chatApp__conv').hide(),
		[]
	)
	useEffect(() => {
		map(currentsConverIds, (val, key) => {
			if (val) {
				if (!has(chatBoxes, key)) {
					dispatch(
						addChatBoxes(key, {
							fields: [],
							operatorBetweenFields: 'or',
							options: { all: false, page: 1, limit: 10 },
							sort: { sortOrder: 'desc', sortField: 'createdAt' },
							byUserId: key,
						})
					)
				}
			} else {
				dispatch(removeChatBoxes(key))
			}
		})
	}, [currentsConverIds])
	return (
		<ChatOneContainer
			style={{ marginRight: chatOne ? '10px' : '' }}
			margin={chatOne ? '0 0 10% 0' : '1%'}
			Width={chatOne ? '30vw' : '20vw'}
			chatList={chatOne && currentUserConver?._id}
		>
			{chatOne ? (
				<>
					<ChatBox
						chatOne
						currentUserConver={currentUserConver}
						ownerId={companyByUserId._id}
						owner={companyByUserId.title}
						ownerAvatar={'https://i.pravatar.cc/150?img=14'}
						loadingGetChats={loadingGetChats}
					/>
					{(chatOne && !isEmpty(currentUserConver)) || (
						<a id='prime' className='fab' onClick={toggleChatBox}>
							<Badge
								size='small'
								//count={5}
								count={
									countsNotRead?.length > 0
										? countsNotRead?.length
										: null
								}
							>
								<img src={images.MESSENGER} width='40' />
								{/* <i className='prime zmdi zmdi-comment-outline' /> */}
							</Badge>
						</a>
					)}
				</>
			) : (
				map(chatBoxes, (value, key) => {
					const test = key.split('_')[0]
					let currentUserConver = {}
					lineOrders.map((val) => {
						if (isEmpty(currentUserConver)) {
							if (val?.orderedBy?._id == test) {
								currentUserConver = val?.orderedBy
							}
							if (val?.company?._id == test) {
								currentUserConver = val?.company
							}
						}
					})
					return (
						currentsConverIds[key] && (
							<>
								<ChatBox
									key={key}
									ownerId={companyByUserId._id}
									owner={companyByUserId.title}
									ownerAvatar={
										'https://i.pravatar.cc/150?img=14'
									}
									loadingGetChats={loadingGetChats}
									currentConverId={key}
									currentUserConver={currentUserConver}
									setCurrentsConverIds={setCurrentsConverIds}
									currentsConverIds={currentsConverIds}
									countsNotReadAdmin={countsNotReadAdmin}
								/>
							</>
						)
					)
				})
			)}
		</ChatOneContainer>
	)
}
export default ChatRoom
