import { Result } from 'antd'
import React, { useContext, useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { STATUS } from '../../constants/status'
//import { WechatOutlined } from '@ant-design/icons'
import Contexts from '../../context'
//import { Scale } from '../../global/GlobalStyles'
import { Phone } from '@material-ui/icons'
function WelcomeClient() {
	const history = useHistory()
	const user = useSelector((state) => state.auth?.user, shallowEqual)
	const { openChat, toggleChat } = useContext(Contexts.ShowChatContext)

	useEffect(() => {
		user && user?.status == STATUS.ACTIVE && history.push('/dash/offres/1')
	})

	return (
		<Result
			style={{
				background: 'aliceblue',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				height: '100%',
			}}
			status='success'
			title={
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<p>
						Vous avez besoin d'aide ou des questions pour trouver
						votre piéces, n'hésitez pas à contactez-vous par
						téléphone :
					</p>
					<div>
						<a href='tel:+21620100001'>
							<Phone /> 22 080 225
						</a>
					</div>
				</div>
			}
			/* subTitle={
				<div>
					ou par téléphone: <a href='tel:+21620100001'>22 080 225</a>
				</div>
			} */
			/* extra={[
				<Button
					key={Date.now()}
					type='primary'
					onClick={() => history.push('/')}
				>
					Retour à la page d'acceuil
				</Button>,
			]} */
		/>
	)
}

export default WelcomeClient
