import React from 'react'
import { UsersContainer } from './styles'
import HeaderTitle from '../../components/HeaderTitle'
import ListUsers from './components/ListUsers'

export const Users = () => {
	return (
		<UsersContainer>
			{/* <HeaderTitle title='Utilisateurs' withoutBreak /> */}
			<div
				style={{
					padding: '1rem',
					margin: '1rem',
					backgroundColor: 'white',
					minHeight: '40vh',
				}}
			>
				<ListUsers />
			</div>
		</UsersContainer>
	)
}

export default Users
