import React, { useEffect, useState } from 'react'
import HeaderTitle from '../../components/HeaderTitle'
import { Pagination, Tabs } from 'antd'
import { PanierContainer } from './styles'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ListEnCours from './components/ListEnCours'
import { TYPE_OFFER } from '../../constants/type_offer'
import { ROLES } from '../../constants/roles'
import ListCommandes from './components/ListCommandes'
import { getLineOrdersByFields } from '../../redux/lineOrder/actions'
import { STATUS } from '../../constants/status'
import Contexts from '../../context'

function Panier() {
	const { EditingInputContext } = Contexts
	const [editingItem, setEditingItem] = useState(false)
	const [page, setPage] = useState(1)
	const dispatch = useDispatch()
	const user = useSelector((state) => state.auth?.user, shallowEqual)
	const { companyByUserId } = useSelector(
		(state) => ({
			companyByUserId: state.auth.companyByUserId,
		}),
		shallowEqual
	)
	let [type, setType] = useState(TYPE_OFFER.PURCHASE)
	const [currentKeyTab, setCurrentKeyTab] = useState(
		user?.roles.includes(ROLES.PROF) ? '2' : '4'
	)
	let [byNotUserId, setByNotUserId] = useState()

	/* let [status, setStatus] = useState({
		[TYPE_OFFER.SELL]: STATUS.PROCESSING,
		[TYPE_OFFER.PURCHASE]: STATUS.PROCESSING,
	}) */
	const getLinesOrder = (companyId, type, status) => {
		let variables = {
			fields: [
				{
					field: 'type',
					fieldValue: type,
				},
				{
					field: 'status',
					fieldValue: status,
				},
			],
			operatorBetweenFields: 'and',
			options: { all: true, page, limit: 10 },
			sort: { sortOrder: 'desc', sortField: 'updatedAt' },
			byUserId: companyId,
		}
		if (byNotUserId) {
			variables = { ...variables, byUserId: '', byNotUserId }
		}
		if (type == TYPE_OFFER.SELL && status == STATUS.PROCESSING) {
			variables = { ...variables, byUserId: '', byLineOffer: true }
		}

		dispatch(getLineOrdersByFields(variables, status))
	}
	function changeTab(key) {
		setCurrentKeyTab(key)
		switch (key) {
			case '1':
				setType(TYPE_OFFER.SELL)
				byNotUserId = null
				getLinesOrder(
					companyByUserId?._id,
					TYPE_OFFER.SELL,
					STATUS.PROCESSING
				)
				setByNotUserId()
				break
			case '2':
				setType(TYPE_OFFER.PURCHASE)
				byNotUserId = null
				getLinesOrder(
					companyByUserId?._id,
					TYPE_OFFER.PURCHASE,
					STATUS.PROCESSING
				)
				setByNotUserId()
				break
			case '3':
				setType(TYPE_OFFER.SELL)
				setByNotUserId(companyByUserId?._id)
				break
			case '4':
				setType(TYPE_OFFER.PURCHASE)
				setByNotUserId(companyByUserId?._id)
				break
			default:
				break
		}
	}
	useEffect(() => {
		switch (currentKeyTab) {
			case '3':
				byNotUserId = companyByUserId?._id
				getLinesOrder(
					companyByUserId?._id,
					TYPE_OFFER.SELL,
					STATUS.PROCESSING
				)
				break
			case '4':
				getLinesOrder(
					companyByUserId?._id,
					TYPE_OFFER.PURCHASE,
					STATUS.CONFIRMED
				)
				setByNotUserId(companyByUserId?._id)
				break
			default:
				getLinesOrder(companyByUserId?._id, type, STATUS.PROCESSING)
				break
		}
	}, [byNotUserId, currentKeyTab, page])

	return (
		<EditingInputContext.Provider value={{ editingItem, setEditingItem }}>
			<PanierContainer>
				{/* <HeaderTitle
				title='Panier'
				withoutBreak
				renderAfter={() => (
					
				)} 
			/> */}
				<Tabs onChange={changeTab} type='card' defaultActiveKey='2'>
					{user?.roles.includes(ROLES.PROF) && (
						<Tabs.TabPane tab={`Vos ventes`} key='1'>
							<ListEnCours
								type={type}
								getLinesOrder={getLinesOrder}
								currentKeyTab={currentKeyTab}
							/>
						</Tabs.TabPane>
					)}
					{user?.roles.includes(ROLES.PROF) && (
						<Tabs.TabPane tab='Vos achats' key='2'>
							<ListEnCours
								type={type}
								getLinesOrder={getLinesOrder}
								currentKeyTab={currentKeyTab}
							/>
						</Tabs.TabPane>
					)}

					{user?.roles.includes(ROLES.ADMIN) && (
						<>
							<Tabs.TabPane tab='Achats en attente' key='4'>
								<ListEnCours
									type={type}
									getLinesOrder={getLinesOrder}
									currentKeyTab={currentKeyTab}
								/>
							</Tabs.TabPane>
							<Tabs.TabPane tab='Ventes en attente' key='3'>
								<ListEnCours
									type={type}
									getLinesOrder={getLinesOrder}
									currentKeyTab={currentKeyTab}
									page={page}
									setPage={setPage}
								/>
							</Tabs.TabPane>
						</>
					)}
				</Tabs>
			</PanierContainer>
		</EditingInputContext.Provider>
	)
}

export default Panier
