import {
	Form,
	Radio,
	Button,
	Upload,
	Input,
	message,
	Row,
	Select,
	Badge,
	Space,
	Col,
	Typography,
	InputNumber,
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import React, { useState } from 'react'
import {
	UserOutlined,
	LockOutlined,
	MailOutlined,
	PhoneOutlined,
	CopyOutlined,
} from '@ant-design/icons'
import { UserFormContainer } from './styles'
import { STATUS } from '../../../constants/status'
import { ROLES } from '../../../constants/roles'
import * as generator from 'generate-password'
import { withDigits } from '../../../constants/fn'

const UserForm = ({
	form,
	mode,
	formItemLayout,
	roles,
	withoutPassword,
	isProfSelected,
	matFiscal,
}) => {
	const [copyPassword, setCopyPassword] = useState('')
	const status = [
		{ value: STATUS.ACTIVE, type: 'success', text: 'activé' },
		{ value: STATUS.INACTIVE, type: 'warning', text: 'désactivé' },
		{ value: STATUS.PROCESSING, type: 'processing', text: 'en attente' },
		{ value: STATUS.REFUSED, type: 'error', text: 'refusé' },
	]

	return (
		<UserFormContainer>
			<Form
				form={form}
				name='validate_other'
				layout='horizontal'
				{...formItemLayout}
				style={{
					width: '100%',
				}}
			>
				<Row gutter={[4, 4]} wrap>
					{/* 	<Col sm={12} xs={24}>
						<Form.Item
							name='lastName'
							label='Prénom'
							rules={[
								{
									required: true,
									message: 'Veuillez saisir votre prénom',
									type: 'string',
								},
							]}
						>
							<Input
								size='large'
								prefix={
									<UserOutlined className='site-form-item-icon' />
								}
								placeholder='Prénom'
							/>
						</Form.Item>
					</Col> */}
					<Col sm={12} xs={24}>
						<Form.Item
							name='firstName'
							label='Nom'
							/* rules={[
								{
									required: true,
									message: 'Veuillez saisir votre nom',
									type: 'string',
								},
							]} */
						>
							<Input
								autoComplete='nope'
								size='large'
								prefix={
									<UserOutlined className='site-form-item-icon' />
								}
								placeholder='Nom'
							/>
						</Form.Item>
					</Col>
					<Col sm={12} xs={24}>
						<Form.Item
							name='email'
							label='Email'

							/* rules={[
								{
									required: true,
									message:
										'Veuillez saisir votre adresse email',
									type: 'email',
								},
							]} */
						>
							<Input
								autoComplete='off'
								size='large'
								prefix={
									<MailOutlined className='site-form-item-icon' />
								}
								placeholder='Email'
							/>
						</Form.Item>
					</Col>
					<Col sm={12} xs={24}>
						<Form.Item
							name='phone'
							label='Tel'
							/* rules={[
								{
									required: true,
									message: 'Veuillez saisir votre tel',
									type: 'string',
								},
							]} */
						>
							<Input
								size='large'
								prefix={
									<PhoneOutlined className='site-form-item-icon' />
								}
								placeholder='Téléphone'
							/>
						</Form.Item>
					</Col>
					{roles?.includes(ROLES.ADMIN) && (
						<>
							<Col sm={12} xs={24}>
								<Form.Item
									label='Status'
									name='status'
									rules={[
										{
											required: true,
											message: 'Veuillez saisir status',
											type: 'string',
										},
									]}
								>
									<Select size='large'>
										{status.map(
											({ type, text, value }, index) => (
												<Select.Option
													key={index}
													value={value}
												>
													<Badge status={type} />
													{text}
												</Select.Option>
											)
										)}
									</Select>
								</Form.Item>
							</Col>
							{isProfSelected && (
								<Col sm={12} xs={24}>
									<Input.Group compact>
										<Form.Item
											label={'Mot de passe'}
											style={{
												width: 'calc(100% - 35%)',
											}}
											name='password'
											type='string'
											rules={
												mode == 'edit'
													? []
													: [
															{
																required: true,
																message:
																	'Veuillez créer un mot de passe!',
															},
															({
																getFieldValue,
															}) => ({
																validator(
																	rule,
																	value
																) {
																	var error =
																		''
																	if (
																		value ==
																		''
																	) {
																		error =
																			"Vous n'avez pas saisi votre mot de passe.\n"
																		return Promise.reject(
																			error
																		)
																	} else if (
																		value.length <
																		6
																	) {
																		error =
																			'Le mot de passe doit avoir au moins 6 charactères!'
																		return Promise.reject(
																			error
																		)
																	}

																	return Promise.resolve()
																},
															}),
													  ]
											}
										>
											<Input.Password
												autoComplete='new-password'
												size='large'
												//value={passGenerate}
												prefix={
													<>
														<Typography.Text
															copyable={{
																text: copyPassword,
																tooltips: [
																	'Copier',
																	'Copié',
																],
															}}
														></Typography.Text>
													</>
												}
												placeholder='Mot de passe'
											/>
										</Form.Item>
										<Button
											style={{
												width: '35%',
												verticalAlign: 'bottom',
												marginBottom: '1%',
											}}
											size='large'
											type='primary'
											onClick={() => {
												const password =
													generator.generate({
														length: 5,
														numbers: true,
													})
												setCopyPassword(
													matFiscal.slice(0, 7) +
														password
												)

												form.setFieldsValue({
													password:
														matFiscal.slice(0, 7) +
														password,
												})
											}}
										>
											generate
										</Button>
									</Input.Group>
								</Col>
							)}
							<Col sm={12} xs={24}>
								<Form.Item
									name='codeClient'
									label='Code-Client'
								>
									<InputNumber
										disabled
										size='large'
										style={{ width: '100%' }}
										placeholder='Code-Client'
										formatter={(value) =>
											`${withDigits(value, 5)}`
										}
										parser={(value) => parseInt(value)}
									/>
								</Form.Item>
							</Col>
						</>
					)}
					{withoutPassword || (
						<>
							<Col sm={12} xs={24}>
								<Form.Item
									label={'Mot de passe'}
									name='password'
									type='string'
									rules={
										mode == 'edit'
											? []
											: [
													{
														required: true,
														message:
															'Veuillez créer un mot de passe!',
													},
													({ getFieldValue }) => ({
														validator(rule, value) {
															var error = ''
															if (value == '') {
																error =
																	"Vous n'avez pas saisi votre mot de passe.\n"
																return Promise.reject(
																	error
																)
															} else if (
																value.length < 6
															) {
																error =
																	'Le mot de passe doit avoir au moins 6 charactères!'
																return Promise.reject(
																	error
																)
															}

															return Promise.resolve()
														},
													}),
											  ]
									}
								>
									<Input.Password
										autoComplete='new-password'
										size='large'
										prefix={
											<LockOutlined className='site-form-item-icon' />
										}
										placeholder='Mot de passe'
									/>
								</Form.Item>
							</Col>
							{mode == 'add' && (
								<Col sm={12} xs={24}>
									<Form.Item
										name='confirm'
										label='Confirmez votre mot de passe'
										type='string'
										dependencies={['password']}
										hasFeedback
										rules={
											mode == 'edit'
												? []
												: [
														{
															required: true,
															message:
																'Veuillez confirmer votre mot de passe',
														},
														({
															getFieldValue,
														}) => ({
															validator(
																_,
																value
															) {
																if (
																	!value ||
																	getFieldValue(
																		'password'
																	) === value
																) {
																	return Promise.resolve()
																}
																return Promise.reject(
																	new Error(
																		'Les deux mots de passes ne correspondent pas!'
																	)
																)
															},
														}),
												  ]
										}
									>
										<Input.Password
											autoComplete='new-password'
											size='large'
											prefix={
												<LockOutlined className='site-form-item-icon' />
											}
											placeholder='Confirmation'
										/>
									</Form.Item>
								</Col>
							)}
						</>
					)}
				</Row>
			</Form>
		</UserFormContainer>
	)
}

export default UserForm
