import { concat, merge } from 'lodash'
import { initialStatePagination } from '../../constants/variables'
import {
	FETCH_ORDERS_SUCCESS,
	FETCH_ORDERS_ERROR,
	CREATE_ORDER_SUCCESS,
	CREATE_ORDER_ERROR,
	UPDATE_ORDER_SUCCESS,
	UPDATE_ORDER_ERROR,
	DELETE_ORDER_SUCCESS,
	DELETE_ORDER_ERROR,
	START_LOADING_ORDERS,
	START_LOADING_CREATE_ORDER,
	START_LOADING_UPDATE_ORDER,
	START_LOADING_DELETE_ORDER,
	START_LOADING_ORDER_BY_ID,
	ORDER_BY_ID_SUCCESS,
	ORDER_BY_ID_ERROR,
} from './types'
const initialState = merge(
	{
		orderById: {},
		loadingGetOrders: false,
		loadingCreateOrder: false,
		loadingGetOrderById: false,
		loadingDeleteOrder: false,
		loadingUpdateOrder: false,
		error: '',
	},
	initialStatePagination
)

export default function orderReducer(state = initialState, action) {
	switch (action.type) {
		/* ****************** GET ORDERS ************************ */
		case START_LOADING_ORDERS:
			return {
				...state,
				loadingGetOrders: true,
			}
		case FETCH_ORDERS_SUCCESS:
			return {
				...state,
				...action.payload,
				loadingGetOrders: false,
				error: '',
			}
		case FETCH_ORDERS_ERROR:
			return {
				...state,
				loadingGetOrders: false,
				error: action.payload,
			}
		/* ****************** CREATE ORDER ************************ */
		case START_LOADING_CREATE_ORDER:
			return {
				...state,
				loadingCreateOrder: true,
			}
		case CREATE_ORDER_SUCCESS:
			return {
				...state,
				entities: [action.payload, ...state.entities],
				//entities: [action.payload, ...state.entities],
				loadingCreateOrder: false,
				error: '',
			}
		case CREATE_ORDER_ERROR:
			return {
				...state,
				loadingCreateOrder: false,
				error: action.payload,
			}

		/* ****************** ORDER BY ID ************************ */
		case START_LOADING_ORDER_BY_ID:
			return {
				...state,
				loadingGetOrderById: true,
			}
		case ORDER_BY_ID_SUCCESS:
			return {
				...state,
				orderById: action.payload,
				loadingGetOrderById: false,
				error: '',
			}
		case ORDER_BY_ID_ERROR:
			return {
				...state,
				loadingGetOrderById: false,
				error: action.payload,
			}
		/* ****************** UPDATE ORDER ************************ */
		case START_LOADING_UPDATE_ORDER:
			return {
				...state,
				loadingUpdateOrder: true,
			}
		case UPDATE_ORDER_SUCCESS:
			return {
				...state,
				entities: [
					...state.entities.map((order) => {
						if (order._id === action.payload._id)
							return action.payload
						else return order
					}),
				],
				loadingUpdateOrder: false,
				error: '',
			}
		case UPDATE_ORDER_ERROR:
			return {
				...state,
				loadingUpdateOrder: false,
				error: action.payload,
			}
		/* ****************** DELETE ORDER ************************ */
		case START_LOADING_DELETE_ORDER:
			return {
				...state,
				loadingDeleteOrder: true,
			}
		case DELETE_ORDER_SUCCESS:
			return {
				...state,
				loadingDeleteOrder: false,
				entities: [
					...state.entities.filter(
						(order) => order._id !== action.payload._id
					),
				],
				error: '',
			}
		case DELETE_ORDER_ERROR:
			return {
				...state,
				loadingDeleteOrder: false,
				error: action.payload,
			}
		default:
			return state
	}
}
