import { Button, Result } from 'antd'
import React, { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { STATUS } from '../../constants/status'

function WelcomeProf() {
	const history = useHistory()
	const user = useSelector((state) => state.auth?.user, shallowEqual)

	useEffect(() => {
		user && user?.status == STATUS.ACTIVE && history.push('/dash/offres/1')
	})

	return (
		<Result
			style={{
				background: 'aliceblue',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				height: '100%',
			}}
			status='success'
			title='Veuillez patienter pour vérifier et accepter votre demande par administrateur'
			subTitle={`Si vous avez des questions, n'hésitez pas à nous contacter 22 080 225`}
			extra={[
				<Button
					key={Date.now()}
					type='primary'
					onClick={() => history.push('/')}
				>
					Retour à la page d'acceuil
				</Button>,
			]}
		/>
	)
}

export default WelcomeProf
