import styled from 'styled-components'

export const ChatContainer = styled.div`
	.chat-message {
		padding: 10px;
	}
	.chat-message textarea {
		width: 100%;
		border: none;
		padding: 10px 20px;
		font: 14px/22px 'Lato', Arial, sans-serif;
		margin-bottom: 10px;
		border-radius: 5px;
		resize: none;
	}

	/* ****END Chat CSS**** */
`
export const ListProfContainer = styled.div`
	overflow: auto;
	padding: 0 16px;
	border: 1px solid rgba(140, 140, 140, 0.35);
	min-height: 88vh;
	max-height: 88vh;
	.ant-list-item-meta-content {
		display: ${(props) => (props.collapsed ? 'none' : 'block')};
	}

	.ant-list-item:hover {
		cursor: pointer;
		background-color: #142231;
	}
`

export const ConversationContainer = styled.div`
	margin-top: 9vh;
	.align-right {
		text-align: right;
	}

	.chat-history {
		padding: 3px 30px 0px;
		//border-bottom: 2px solid white;
		overflow-y: scroll;
		//height: 65vh;
	}
	.chat-history .message-data {
		margin-bottom: 15px;
	}
	.chat-history .message-data-time {
		color: #41a4ff;
		padding-left: 6px;
	}
	.chat-history .message {
		color: white;
		padding: 18px 20px;
		line-height: 26px;
		font-size: 16px;
		border-radius: 7px;
		//margin-bottom: 30px;
		//width: 90%;
		position: relative;
	}
	.chat-history .message:after {
		bottom: 100%;
		left: 7%;
		border: solid transparent;
		content: ' ';
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-bottom-color: ${(props) => props.theme.primary};
		border-width: 10px;
		margin-left: -10px;
	}
	.chat-history .my-message {
		background: ${(props) => props.theme.primary};
		width: 45vw;
	}
	.chat-history .other-message {
		background: ${(props) => props.theme.secondary};
		width: 45vw;
		text-align: end;
		float: right;
	}
	.chat-history .other-message:after {
		border-bottom-color: ${(props) => props.theme.secondary};
		left: 93%;
	}
`
export const HeaderChatContainer = styled.div`
	position: fixed;
	z-index: 1;
	width: 100%;
	background-color: #000;
	height: 8vh;
	display: flex;
	align-items: center;
	padding: 1%;
`
export const FooterChatContainer = styled.div`
	position: relative;
	z-index: 1;
	width: 100%;
	bottom: 0px;
	right: 0;
`
