import React, { useContext, useEffect, useState } from 'react'

import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
	Col,
	Row,
	Tabs,
	Tooltip,
	Avatar,
	Descriptions,
	Form,
	InputNumber,
	List,
	message,
	Typography,
	Collapse,
	Badge,
	Card,
	Pagination,
	Popconfirm,
} from 'antd'
import { TYPE_OFFER } from '../../../constants/type_offer'
import { addToCart, deleteCart } from '../../../redux/cart/actions'
import { has } from 'lodash'
import { CustomButton, PrimaryButton } from '../../../global/GlobalStyles'
import { createOrder } from '../../../redux/order/actions'
import { STATUS } from '../../../constants/status'
import { ROLES } from '../../../constants/roles'
import ListCommandes from './ListCommandes'
import {
	createLinesOrder,
	deleteLinesOrder,
	updateLineOrder,
} from '../../../redux/lineOrder/actions'
import GroupeButtons from './GroupeButtons'
import Contexts from '../../../context'

function ListEnCours({ type, getLinesOrder, currentKeyTab, page, setPage }) {
	const { currentsConverIds, setCurrentsConverIds } = useContext(
		Contexts.CurrentsConverIdsContext
	)
	const dispatch = useDispatch()
	const {
		linesOfferOrder,
		totalPagesOrders,
		qtePriceCommandes,
		companyByUserId,
		loadingGetLineOrders,
		lineOrders,
	} = useSelector(
		(state) => ({
			linesOfferOrder: state.cart.entities,
			lineOrders: state.lineOrders.entities,
			totalPagesOrders: state.lineOrders.totalPages,
			loadingGetLineOrders: state.lineOrders.loadingGetLineOrders,
			qtePriceCommandes: state.cart.qtePriceCommandes,
			companyByUserId: state.auth.companyByUserId,
		}),
		shallowEqual
	)
	let [qtePriceComds, setQtePriceComds] = useState(qtePriceCommandes)
	let [status, setStatus] = useState(STATUS.PROCESSING)
	let [unitPriceFinal, setUnitPriceFinal] = useState()
	let [qteFinal, setQteFinal] = useState()
	const user = useSelector((state) => state.auth?.user)

	function changeTab(key) {
		setStatus(key)

		switch (key) {
			case STATUS.PROCESSING:
				getLinesOrder(companyByUserId?._id, type, STATUS.PROCESSING)
				break
			case STATUS.CONFIRMED:
				getLinesOrder(companyByUserId?._id, type, STATUS.CONFIRMED)
				break
			case STATUS.FINISHED:
				currentKeyTab == '2' && user?.roles.includes(ROLES.PROF)
					? getLinesOrder(
							companyByUserId?._id,
							type,
							STATUS.CONFIRMED
					  )
					: getLinesOrder(companyByUserId?._id, type, STATUS.FINISHED)
				break
			case 'TERMINER':
				getLinesOrder(companyByUserId?._id, type, STATUS.FINISHED)
				break
			case 'ANNULER':
				getLinesOrder(companyByUserId?._id, type, STATUS.REFUSED)
				break

			default:
				break
		}
	}
	const confirmCommande = (cmd, statusCurrent = STATUS.FINISHED) => {
		if (!has(cmd, 'order')) {
			const variables = {
				lineOrders: [
					{
						quantity:
							cmd?.type == TYPE_OFFER.SELL
								? qtePriceComds[cmd?._id]
								: cmd?.quantity,
						unitPrice:
							cmd?.type == TYPE_OFFER.PURCHASE
								? qtePriceComds[cmd?._id]
								: cmd?.unitPriceFinal,
						/* unitPriceFinal:
							cmd?.type == TYPE_OFFER.PURCHASE
								? qtePriceComds[cmd?._id]
								: cmd?.unitPrice, */
						type: cmd?.type,
						status:
							currentKeyTab !== '4'
								? user?.roles.includes(ROLES.PROF)
									? STATUS.CONFIRMED
									: STATUS.FINISHED
								: currentKeyTab == '4' &&
								  status == STATUS.CONFIRMED
								? STATUS.FINISHED
								: STATUS.CONFIRMED,
						lineOffer: cmd?._id,
						company: cmd?.company,
						orderedBy: companyByUserId?._id,
					},
				],
				company: cmd?.company,
				orderedBy: companyByUserId?._id,
			}
			dispatch(createOrder(variables))
			dispatch(deleteCart(variables?.lineOrders[0].lineOffer))
		} else {
			let { article, ...lineOrder } = cmd
			//lineOrder.unitPriceFinal = qtePriceComds[lineOrder?._id]
			lineOrder.order = lineOrder.order._id
			lineOrder.lineOffer = lineOrder.lineOffer._id
			lineOrder.company = lineOrder.company._id
			lineOrder.orderedBy = lineOrder.orderedBy?._id
			lineOrder.status = statusCurrent
			dispatch(updateLineOrder(lineOrder))
			dispatch(deleteCart(lineOrder?.lineOffer))
		}
	}
	const deleteCommande = (cmd) => {
		if (has(cmd, 'orderedBy')) {
			let { article, ...lineOrder } = cmd
			lineOrder.unitPriceFinal = qtePriceComds[lineOrder?._id]
			lineOrder.order = lineOrder.order._id
			lineOrder.lineOffer = lineOrder.lineOffer._id
			lineOrder.company = lineOrder.company._id
			lineOrder.orderedBy = lineOrder.orderedBy?._id
			lineOrder.status = STATUS.REFUSED
			dispatch(updateLineOrder(lineOrder))

			/* dispatch(
				deleteLinesOrder({
					ids: [cmd._id],
					orderedBy: cmd?.orderedBy?._id,
					numRef: cmd?.order?.numRef,
				})
			) */
			dispatch(deleteCart(lineOrder.lineOffer._id))
			//getLinesOrder(companyByUserId?._id, type, status)
		} else {
			dispatch(deleteCart(cmd._id))
		}
		/*  */
	}

	const addToCartList = (item) => {
		const {
			_id,
			description,
			marque,
			numRef,
			quantity,
			ref,
			refOrigin,
			type,
			unitPrice,
			company,
		} = item
		message.config({
			maxCount: 1,
		})
		message.success(
			type == TYPE_OFFER.SELL
				? quantity == 0
					? `Supprimer référence #${ref} depuis panier`
					: `Modifier la quantité demandée de #${ref} à ${qtePriceComds[_id]}`
				: unitPrice == 0
				? `Supprimer référence #${ref} depuis panier`
				: `Modifier le prix proposer à ${qtePriceComds[_id]}dt`
		)
		dispatch(
			addToCart({
				_id,
				description,
				marque,
				numRef,
				qtePriceCommande: qtePriceComds[_id],
				quantity,
				ref,
				refOrigin,
				type,
				unitPrice,
				company,
				companyByUserId,
			})
		)
	}
	const addonAfter = (item) => (
		<Row gutter={[4, 4]} justify='center' align='middle' wrap={false}>
			{/* <Col style={{ display: 'flex' }}>
                <Tooltip title='Reset'>
                    <Button
                        className='group-addon'
                        size='small'
                        style={{
                            backgroundColor: '#ed3b57',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        shape='circle'
                        icon={<Refresh fontSize={'small'} htmlColor='#fff' />}
                        onClick={() => alert('reset')}
                    />
                </Tooltip>
            </Col> */}
			{item?.type == TYPE_OFFER.PURCHASE ? <Col>TND</Col> : ''}
			{/* <Col style={{ display: 'flex' }} onClick={addToCartList(item)}>
                <Tooltip title='Comfirmer'>
                    <PrimaryButton
                        className='group-addon'
                        size='small'
                        style={{
                            //backgroundColor: '#08e708',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        icon={
                            <CheckOutlined
                                fontSize={'small'}
                                htmlColor='#fff'
                            />
                        }
                    />
                </Tooltip>
            </Col> */}
		</Row>
	)
	useEffect(() => {
		currentKeyTab != '4'
			? setStatus(STATUS.PROCESSING)
			: setStatus(STATUS.CONFIRMED)
	}, [currentKeyTab])
	const renderExtra = (index) => {
		// If you don't want click extra trigger collapse, you can prevent this:
		const _id = lineOrders[index]?.data[0]?._id
		const company = lineOrders[index]?.data[0]?.company
		const lineOffer = lineOrders[index]?.data[0]?.lineOffer
		const qteDemande = lineOrders[index]?.data[0]?.quantity
		return (
			<Form
				onClick={(event) => {
					event.stopPropagation()
				}}
			>
				<Form.Item>
					<InputNumber
						addonBefore='Prix'
						size='small'
						value={
							has(unitPriceFinal, _id) ? unitPriceFinal[_id] : 0
						}
						precision={3}
						onClick={(event) => {
							event.stopPropagation()
						}}
						onChange={(value) => {
							setUnitPriceFinal({
								...unitPriceFinal,
								[_id]: value,
							})
						}}
						min={1}
					/>
				</Form.Item>
				<Form.Item>
					<InputNumber
						addonBefore='Qte'
						size='small'
						value={has(qteFinal, _id) ? qteFinal[_id] : 0}
						precision={0}
						onClick={(event) => {
							event.stopPropagation()
						}}
						onChange={(value) => {
							setQteFinal({
								...qteFinal,
								[_id]: value,
							})
						}}
						min={1}
					/>
				</Form.Item>
				<Form.Item>
					<Popconfirm
						title='Vous êtes sûr de vouloir confirmer ?'
						onConfirm={async (event) => {
							event.stopPropagation()
							let variables = {
								lineOrders: [
									{
										quantity: qteFinal[_id],
										unitPriceFinal: unitPriceFinal[_id],
										unitPrice: unitPriceFinal[_id],
										type: TYPE_OFFER.SELL,
										lineOffer: lineOffer?._id,
										orderedBy: company?._id,
										company: companyByUserId?._id,
										status: STATUS.FINISHED,
									},
								],
								orderedBy: company?._id,
								company: companyByUserId?._id,
							}
							await dispatch(
								createOrder(variables, TYPE_OFFER.SELL)
							)
							await dispatch(
								updateLineOrder({
									_id,
									...variables.lineOrders[0],
								})
							)
						}}
						okText='Oui'
						cancelText='Non'
					>
						<PrimaryButton
							className='group-addon'
							size='small'
							/* onClick={confirmCommande(
								item
							)} */

							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							confirmer
						</PrimaryButton>
					</Popconfirm>
				</Form.Item>
			</Form>
		)
	}
	return (
		<>
			<Tabs
				onChange={changeTab}
				//defaultActiveKey={currentKeyTab}
				activeKey={status}
				centered
			>
				{currentKeyTab != '4' ? (
					currentKeyTab == '3' ? (
						<>
							<Tabs.TabPane
								className='ventes'
								tab='En attente'
								key={STATUS.PROCESSING}
							>
								{lineOrders.map((lineOrder, index) => (
									<div style={{ marginBottom: '1em' }}>
										<Badge.Ribbon
											placement='start'
											style={{ margin: ' 0 2%' }}
											text={
												<Typography.Title
													style={{
														color: '#fff',
													}}
													level={5}
													//code
												>
													{lineOrder?.data &&
														`${lineOrders[index]?.data[0]?.company?.title}`}
												</Typography.Title>
											}
											color='green'
										>
											<Badge.Ribbon
												//placement='start'
												style={{ margin: ' 0 2%' }}
												text={
													<Typography.Title
														style={{
															color: '#fff',
														}}
														level={5}
														//code
													>
														{lineOrder?.data &&
															`Qte-dem:${lineOrders[index]?.data[0]?.quantity}`}
													</Typography.Title>
												}
												color='blue'
											>
												<Collapse
													style={{ margin: ' 1% 2%' }}
													//defaultActiveKey={['1']}
													//onChange={callback}
													expandIconPosition={'left'}
												>
													<Collapse.Panel
														header={
															lineOrder?.data && (
																<div>
																	<Typography.Title
																		level={
																			5
																		}
																	>
																		{`${lineOrders[index]?.data[0]?.article?.marque}-${lineOrders[index]?.data[0]?.article?.description}`}
																	</Typography.Title>
																	<Typography.Paragraph
																		disabled
																	>
																		<strong>
																			Ref:
																		</strong>
																		{
																			lineOrders[
																				index
																			]
																				?.data[0]
																				?.article
																				?.ref
																		}
																		<br />
																		<strong>
																			RefOrigin:
																		</strong>
																		{
																			lineOrders[
																				index
																			]
																				?.data[0]
																				?.article
																				?.refOrigin
																		}
																	</Typography.Paragraph>
																</div>
															)
														}
														key='1'
														extra={
															lineOrder?.data ? (
																renderExtra(
																	index
																)
															) : (
																<div></div>
															)
														}
													>
														<List
															loading={
																loadingGetLineOrders
															}
															style={{
																backgroundColor:
																	'white',
															}}
															itemLayout='horizontal'
															dataSource={
																lineOrder?.data
															}
															renderItem={(
																item
															) => {
																const actions =
																	[
																		<Form
																			key={
																				item?._id
																			}
																		>
																			<>
																				<Descriptions
																					bordered
																					size='small'
																					style={{
																						backgroundColor:
																							'#fff',
																					}}
																				>
																					{/* <Descriptions.Item label='Qte-dem'>
																			{
																				item?.quantity
																			}
																		</Descriptions.Item> */}
																					<Descriptions.Item label='Prix Proposer'>
																						{
																							item?.unitPrice
																						}
																						dt
																					</Descriptions.Item>
																				</Descriptions>
																			</>
																		</Form>,
																	]
																return (
																	<div
																		style={{
																			margin: '0.5em',
																		}}
																	>
																		<Badge.Ribbon
																			key={
																				item?._id
																			}
																			text={
																				<Typography.Text
																					style={{
																						color: '#fff',
																					}}
																					strong
																					//code
																				>
																					{`${item?.unitPrice}
																		TND`}
																				</Typography.Text>
																			}
																			color='red'
																			onClick={() => {
																				setCurrentsConverIds(
																					{
																						...currentsConverIds,
																						[`${item?.orderedBy?._id}_${companyByUserId?._id}`]:
																							!currentsConverIds[
																								`${item?.orderedBy?._id}_${companyByUserId?._id}`
																							],
																					}
																				)
																			}}
																		>
																			<Card size='small'>
																				<Card.Meta
																					description={
																						<>
																							<Typography.Title
																								style={{
																									paddingTop:
																										'0.1em',
																									cursor: 'pointer',
																								}}
																								level={
																									5
																								}
																							>
																								{`${item?.orderedBy?.title}`}
																							</Typography.Title>
																							{
																								item
																									?.orderedBy
																									?.phoneNumber
																							}
																						</>
																					}
																				/>
																			</Card>
																		</Badge.Ribbon>
																	</div>
																)
															}}
														/>
													</Collapse.Panel>
												</Collapse>
											</Badge.Ribbon>
										</Badge.Ribbon>
									</div>
								))}
								{/* <Pagination
									defaultPageSize={1}
									onChange={(page, pageSize) => setPage(page)}
									current={page}
									total={totalPagesOrders}
								/> */}
							</Tabs.TabPane>
						</>
					) : (
						currentKeyTab == '1' || (
							<Tabs.TabPane
								tab='En attente'
								key={STATUS.PROCESSING}
							>
								<List
									loading={loadingGetLineOrders}
									bordered
									style={{
										margin: '1rem',
										backgroundColor: 'white',
									}}
									itemLayout='horizontal'
									dataSource={
										type == TYPE_OFFER.SELL
											? lineOrders
											: linesOfferOrder.filter(
													(line) =>
														line?.type !== type
											  )
									}
									renderItem={(item) => {
										const actions = [
											<Form key={item?._id}>
												{item?.type ==
												TYPE_OFFER.PURCHASE ? (
													<>
														<Descriptions
															bordered
															size='small'
															style={{
																backgroundColor:
																	'#fff',
															}}
														>
															<Descriptions.Item label='Qte-dem'>
																{item?.quantity}
															</Descriptions.Item>
														</Descriptions>
													</>
												) : type == TYPE_OFFER.SELL ? (
													<>
														<Descriptions
															bordered
															size='small'
															style={{
																backgroundColor:
																	'#fff',
															}}
														>
															<Descriptions.Item label='Qte-dem'>
																{item?.quantity}
															</Descriptions.Item>
															<Descriptions.Item label='Prix Proposer'>
																{
																	item?.unitPrice
																}{' '}
																dt
															</Descriptions.Item>
														</Descriptions>
													</>
												) : (
													<>
														<Descriptions
															bordered
															size='small'
															style={{
																backgroundColor:
																	'#fff',
															}}
														>
															<Descriptions.Item label='Qte-dispo'>
																{item?.quantity}
															</Descriptions.Item>
															<Descriptions.Item label='PU.TTC'>
																{
																	item?.unitPriceFinal
																}
																dt
															</Descriptions.Item>
															{/* 
														<Descriptions.Item label='Total'>
															{qtePriceComds[
																item?._id
															] *
																item?.unitPriceFinal}
															dt
														</Descriptions.Item> */}
														</Descriptions>
													</>
												)}
												{type == TYPE_OFFER.SELL ? (
													''
												) : (
													<div
														style={{
															marginTop: '1%',
															display: 'flex',
															alignItems:
																'center',
															justifyContent:
																'space-between',
														}}
													>
														<Form.Item
															label={
																item?.type ==
																TYPE_OFFER.PURCHASE
																	? 'Prix à proposer'
																	: 'Qte à demander'
															}
														>
															<InputNumber
																disabled={
																	user?.roles.includes(
																		ROLES.PROF
																	) &&
																	type ==
																		TYPE_OFFER.SELL
																}
																/*  className={
											has(qtePriceCommandes, item?._id)
												? qtePriceCommandes[item?._id] >
												  0
													? item?.type ==
													  TYPE_OFFER.PURCHASE
														? 'input-purchase'
														: 'input-sell'
													: ''
												: ''
										} */
																min={0}
																keyboard={true}
																defaultValue={
																	type ==
																	TYPE_OFFER.SELL
																		? item?.unitPriceFinal
																		: has(
																				qtePriceComds,
																				item?._id
																		  )
																		? qtePriceComds[
																				item
																					?._id
																		  ]
																		: 0
																}
																/* onStep={(val, info) => {
													qtePriceCommande = val
													addToCartList(item)
												}} */

																onChange={(
																	val
																) => {
																	//alert(val)
																	/* 	qtePriceComds[
																item?._id
															] = val */
																	setQtePriceComds(
																		{
																			...qtePriceComds,
																			[item?._id]:
																				val,
																		}
																	)
																	//addToCartList(item)
																}}
																addonAfter={
																	type ==
																	TYPE_OFFER.SELL
																		? 'TND'
																		: item?.type ==
																		  TYPE_OFFER.PURCHASE
																		? addonAfter(
																				item
																		  )
																		: null
																}
															/>
														</Form.Item>
														<Descriptions
															bordered
															size='small'
															style={{
																width: '200px',
																marginLeft:
																	'5px',
																backgroundColor:
																	'#fff',
															}}
														>
															<Descriptions.Item label='Total'>
																{qtePriceComds[
																	item?._id
																] *
																	item?.unitPriceFinal}
																dt
															</Descriptions.Item>
														</Descriptions>
													</div>
												)}
											</Form>,
										]
										if (
											(type !== TYPE_OFFER.SELL &&
												item?.type == TYPE_OFFER.SELL &&
												user?.roles.includes(
													ROLES.PROF
												)) ||
											user?.roles.includes(ROLES.ADMIN)
										) {
											actions.push(
												<GroupeButtons
													confirmCommande={
														confirmCommande
													}
													deleteCommande={
														deleteCommande
													}
													item={item}
												/>
											)
										}
										return (
											<List.Item actions={actions}>
												<List.Item.Meta
													key={item?._id}
													avatar={
														<Avatar
															style={{
																backgroundColor:
																	type !=
																	TYPE_OFFER.SELL
																		? '#08a908'
																		: item?.type ==
																		  TYPE_OFFER.SELL
																		? '#f56a00'
																		: '#08a908',
															}}
														>
															{type !=
															TYPE_OFFER.SELL
																? 'A'
																: item?.type ==
																  TYPE_OFFER.SELL
																? 'V'
																: 'A'}
														</Avatar>
													}
													title={
														<Typography.Title
															level={5}
														>
															{type ==
															TYPE_OFFER.SELL
																? `${item?.article?.marque}-${item?.article?.description}`
																: `${item?.marque}-${item?.description}`}
														</Typography.Title>
													}
													description={
														type == TYPE_OFFER.SELL
															? item?.article?.ref
															: item.ref
													}
												/>
											</List.Item>
										)
									}}
								/>
							</Tabs.TabPane>
						)
					)
				) : (
					<>
						<Tabs.TabPane tab='En attente' key={STATUS.CONFIRMED}>
							<ListCommandes
								type={type}
								currentKeyTab={currentKeyTab}
								status={status}
								confirmCommande={confirmCommande}
								deleteCommande={deleteCommande}
							/>
						</Tabs.TabPane>
					</>
				)}
				<Tabs.TabPane tab='Confirmée' key={STATUS.FINISHED}>
					<ListCommandes type={type} currentKeyTab={currentKeyTab} />
				</Tabs.TabPane>
				{user?.roles.includes(ROLES.PROF) && currentKeyTab == '2' && (
					<>
						<Tabs.TabPane tab='Terminée' key={'TERMINER'}>
							<ListCommandes
								type={type}
								currentKeyTab={currentKeyTab}
								currentKeySubTab={'TERMINER'}
							/>
						</Tabs.TabPane>
						<Tabs.TabPane tab='Annulée' key={'ANNULER'}>
							<ListCommandes
								type={type}
								currentKeyTab={currentKeyTab}
								currentKeySubTab={'ANNULER'}
							/>
						</Tabs.TabPane>
					</>
				)}
			</Tabs>
		</>
	)
}

export default ListEnCours
