import React, { useState } from 'react'
import 'antd/dist/antd.css'
import './index.css'
import { GlobalContainer } from './global/GlobalContainer'
import GlobalStore from './global/GlobalStore'
import { BrowserRouter } from 'react-router-dom'
import Routes from './routes'
import Contexts from './context'
import { ApolloProvider } from '@apollo/client'
import { apolloClient } from './graphql'
import moment from 'moment'
require('moment/locale/fr.js')

moment.locale('fr')
const App = () => {
	const {
		ShowChatContext,
		FocusMessageContext,
		VariablesContext,
		CurrentsConverIdsContext,
	} = Contexts
	const [openChat, setOpenChat] = useState(false)
	let [variables, setVariables] = useState({})
	let [currentsConverIds, setCurrentsConverIds] = useState({})
	const [focusMessage, setFocusMessage] = useState({
		status: false,
		senderId: '',
	})
	const toggleChat = () => {
		setOpenChat(!openChat)
	}
	return (
		<GlobalStore>
			<GlobalContainer>
				{/* 	<StartApollo> */}
				<ShowChatContext.Provider value={{ openChat, toggleChat }}>
					<FocusMessageContext.Provider
						value={{ focusMessage, setFocusMessage }}
					>
						<VariablesContext.Provider
							value={{ variables, setVariables }}
						>
							<CurrentsConverIdsContext.Provider
								value={{
									currentsConverIds,
									setCurrentsConverIds,
								}}
							>
								<ApolloProvider client={apolloClient}>
									<BrowserRouter>
										<Routes />
									</BrowserRouter>
								</ApolloProvider>
							</CurrentsConverIdsContext.Provider>
						</VariablesContext.Provider>
					</FocusMessageContext.Provider>
				</ShowChatContext.Provider>

				{/* </StartApollo> */}
			</GlobalContainer>
		</GlobalStore>
	)
}
export default App
