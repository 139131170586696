import { concat, merge } from 'lodash'
import { initialStatePagination } from '../../constants/variables'
import {
	FETCH_NOTIFICATIONS_SUCCESS,
	FETCH_NOTIFICATIONS_ERROR,
	CREATE_NOTIFICATION_SUCCESS,
	CREATE_NOTIFICATION_ERROR,
	CREATE_PROF_SUCCESS,
	CREATE_PROF_ERROR,
	UPDATE_NOTIFICATIONS_SUCCESS,
	UPDATE_NOTIFICATIONS_ERROR,
	DELETE_NOTIFICATION_SUCCESS,
	DELETE_NOTIFICATION_ERROR,
	START_LOADING_NOTIFICATIONS,
	START_LOADING_CREATE_NOTIFICATION,
	START_LOADING_CREATE_PROF,
	START_LOADING_UPDATE_NOTIFICATIONS,
	START_LOADING_DELETE_NOTIFICATION,
} from './types'
const initialState = merge(
	{
		loadingGetNotifications: false,
		loadingCreateNotification: false,
		loadingCreateProf: false,
		loadingDeleteNotification: false,
		loadingUpdateNotification: false,
		countsNotRead: [],
		error: '',
	},
	initialStatePagination
)

export default function notificationReducer(state = initialState, action) {
	switch (action.type) {
		/* ****************** GET NOTIFICATIONS ************************ */
		case START_LOADING_NOTIFICATIONS:
			return {
				...state,
				loadingGetNotifications: true,
			}
		case FETCH_NOTIFICATIONS_SUCCESS:
			return {
				...state,
				...action.payload,
				loadingGetNotifications: false,
				error: '',
			}
		case FETCH_NOTIFICATIONS_ERROR:
			return {
				...state,
				data: [],
				loadingGetNotifications: false,
				error: action.payload,
			}
		/* ****************** CREATE NOTIFICATION ************************ */
		case START_LOADING_CREATE_NOTIFICATION:
			return {
				...state,
				loadingCreateNotification: true,
			}
		case CREATE_NOTIFICATION_SUCCESS:
			return {
				...state,
				entities: [action.payload, ...state.entities],
				newNotification: action.payload,
				loadingCreateNotification: false,
				error: '',
			}
		case CREATE_NOTIFICATION_ERROR:
			return {
				...state,
				loadingCreateNotification: false,
				error: action.payload,
			}
		/* ****************** CREATE PROF ************************ */
		case START_LOADING_CREATE_PROF:
			return {
				...state,
				loadingCreateProf: true,
			}
		case CREATE_PROF_SUCCESS:
			return {
				...state,
				entities: [action.payload, ...state.entities],
				newNotification: action.payload,
				loadingCreateProf: false,
				error: '',
			}
		case CREATE_PROF_ERROR:
			return {
				...state,
				loadingCreateProf: false,
				error: action.payload,
			}
		/* ****************** UPDATE NOTIFICATION ************************ */
		case START_LOADING_UPDATE_NOTIFICATIONS:
			return {
				...state,
				loadingUpdateNotification: true,
			}
		case UPDATE_NOTIFICATIONS_SUCCESS:
			return {
				...state,
				entities: [
					...state.entities.map((notification) => {
						if (action.payload?.includes(notification._id))
							return { ...notification, is_read: true }
						else return notification
					}),
				],
				countsNotRead: [],
				loadingUpdateNotification: false,
				error: '',
			}
		case UPDATE_NOTIFICATIONS_ERROR:
			return {
				...state,
				data: [],
				loadingUpdateNotification: false,
				error: action.payload,
			}
		/* ****************** DELETE NOTIFICATION ************************ */
		case START_LOADING_DELETE_NOTIFICATION:
			return {
				...state,
				loadingDeleteNotification: true,
			}
		case DELETE_NOTIFICATION_SUCCESS:
			return {
				...state,
				loadingDeleteNotification: false,
				entities: [
					...state.entities.filter(
						(notification) =>
							notification._id !== action.payload._id
					),
				],
				error: '',
			}
		case DELETE_NOTIFICATION_ERROR:
			return {
				...state,
				data: [],
				loadingDeleteNotification: false,
				error: action.payload,
			}
		default:
			return state
	}
}
