import styled from 'styled-components'
export const StepsContainer = styled.div`
	margin: 1rem;
	min-height: 40vh;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	.steps-content {
		width: 100%;
		min-height: 200px;
		margin-top: 16px;
		padding: 0.5em;
		text-align: center;
		background-color: ${(props) =>
			props.current == 2 ? '#fff' : '#fafafa'};
		border: 1px dashed #e9e9e9;
		border-radius: 2px;
	}

	.steps-action {
		margin-top: 0.4em;
	}
	h3 {
		background-color: aliceblue;
		padding: 1rem;
		border-radius: 10px;
	}
	.ant-divider-horizontal.ant-divider-with-text::before,
	.ant-divider-horizontal.ant-divider-with-text::after {
		border-top-color: #2a8ff7;
	}
`
