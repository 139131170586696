import { Link } from 'react-router-dom'
import { Menu } from 'antd'
import styled from 'styled-components'

export const SiderContainer = styled.div`
	height: 100vh;
	.ant-layout-sider {
		//background-image: url('https://www.fad.tn/lib/NvTemplate/css/images/overlay.png');
		background: #ecf6ff;
		height: 100%;
	}
	.logo .ant-row {
		height: 4.4em;
		background-image: url('https://www.fad.tn/lib/NvTemplate/css/images/overlay.png');
	}
	.logo img {
		width: 70px;
	}
	.ant-layout-sider-trigger {
		height: auto;
	}
`
export const CustomMenu = styled(Menu)`
	//background-color: ${(props) => props.theme.primary};
	height: inherit;
	border-right: unset;
	background-image: url('https://www.fad.tn/lib/NvTemplate/css/images/overlay.png');
	border-radius: 10px;
	border-top-right-radius: unset;
	border-top-left-radius: unset;
	box-shadow: 1px 5px 5px rgba(0, 0, 0, 0.4) !important;

	.ant-menu-item-selected,
	.ant-menu-item-selected .ant-menu-inline .ant-menu-item::after {
		background-color: ${(props) => props.theme.secondary} !important;
	}

	.ant-menu-item-selected svg,
	.ant-menu-item-selected a {
		color: ${(props) => props.theme.white} !important;
	}

	.ant-menu-item-active svg,
	.ant-menu-item-active a {
		color: ${(props) => props.theme.white} !important;
	}
	.ant-menu-item:hover {
		background-color: ${(props) => props.theme.secondary} !important;
	}
	svg,
	a {
		color: ${(props) => props.theme.dark} !important;
	}
	a {
		font-size: 15px;
	}
	.ant-menu-inline,
	.ant-menu-vertical,
	.ant-menu-vertical-left {
		border-right: unset;
	}
	.ant-menu-item {
		overflow: unset;
	}
	.ant-badge {
		line-height: normal;
	}
`

export const CustomMenuItem = styled(Menu.Item)`
	display: flex;
	align-items: center;
`

export const CustomLink = styled(Link)`
	font-weight: 600;
	font-size: medium;
`
