import {
	SiderContainer,
	CustomLink,
	CustomMenu,
	CustomMenuItem,
} from './styles'

import { Badge, Grid, Layout, Row, Tooltip, Typography } from 'antd'
import React, { useState } from 'react'
import images from '../../../constants/images'
import { isEmpty } from 'lodash'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { Phone } from '@material-ui/icons'

const { Sider } = Layout

function Sidebar({ items, breakpointWidth = '', totalNotRead, windowSize }) {
	const { LOGO } = images
	const { pathname } = useLocation()
	const [collapsed, setCollapsed] = useState(true)
	const onCollapse = () => setCollapsed(!collapsed)
	const Content = () => (
		<>
			<Link to={'/'} className='logo'>
				<Row
					justify='center'
					align='middle'
					style={{ backgroundColor: '#d6ebff' }}
				>
					<img alt='logo' src={LOGO} width='56' />
					{/* <h2 style={{ color: 'white' }}>
								{useBreakpoint()[breakpointWidth] && 'DistriPark'}
							</h2> */}
				</Row>
			</Link>
			<CustomMenu mode='inline'>
				{!isEmpty(items) &&
					items?.map(({ title, Icon, url }, key) => (
						<CustomMenuItem
							className={
								pathname == url ? 'ant-menu-item-selected' : ''
							}
							key={key}
							icon={
								title == 'Chat' && totalNotRead > 0 ? (
									<Badge count={totalNotRead}>
										<Icon style={{ fontSize: '1.9rem' }} />
									</Badge>
								) : (
									<Icon style={{ fontSize: '1.9rem' }} />
								)
							}
							color='#fff'
						>
							<CustomLink to={url}>{title}</CustomLink>
						</CustomMenuItem>
					))}
			</CustomMenu>
		</>
	)
	return (
		<SiderContainer>
			{windowSize > 650 ? (
				<Sider
					collapsible
					collapsed={collapsed}
					onCollapse={onCollapse}
					breakpoint={breakpointWidth}
					trigger={
						<Tooltip placement='right' title='22 080 225'>
							<div
								style={{ padding: '1em 0' }}
								onClick={(event) => event.stopPropagation()}
							>
								<a
									href='tel:+21622080225'
									style={{
										color: '#fff',
										lineHeight: 1.5,
										display: collapsed ? 'block' : 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									{collapsed ? (
										''
									) : (
										<Typography.Title
											level={4}
											strong
											style={{
												color: '#fff',
												paddingRight: '5px ',
											}}
										>
											Info:
										</Typography.Title>
									)}
									<Phone fontSize='small' />
									<Typography.Title
										level={5}
										strong
										style={{
											color: '#fff',
											paddingLeft: '5px',
										}}
									>
										22 {collapsed ? <br /> : ''} 080 225
									</Typography.Title>
								</a>
							</div>
						</Tooltip>
					}
				>
					<Content />
				</Sider>
			) : (
				<Content />
			)}
		</SiderContainer>
	)
}

export default Sidebar
