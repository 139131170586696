import { STATUS } from '../../constants/status'
import { TYPE_OFFER } from '../../constants/type_offer'
import { apolloClient } from '../../graphql'
import {
	CREATE_LINESOFFER_MUTATION,
	DELETE_LINEOFFER_BY_ID_MUTATION,
	UPDATE_LINEOFFER_BY_ID_QUERY,
	LINEOFFERS_BY_FIELDS_QUERY,
	LINEOFFER_BY_ID_QUERY,
	UPDATE_LINEOFFERS_BY_ID_MUTATION,
	DELETE_LINESOFFER_BY_ID_MUTATION,
} from '../../queries/lineOffer'
import { UPDATE_CART_SUCCESS } from '../cart/types'

import {
	START_LOADING_LINEOFFERS,
	FETCH_LINEOFFERS_SUCCESS,
	FETCH_LINEOFFERS_ERROR,
	START_LOADING_CREATE_LINESOFFER,
	CREATE_LINESOFFER_SUCCESS,
	CREATE_LINESOFFER_ERROR,
	START_LOADING_DELETE_LINESOFFER,
	DELETE_LINESOFFER_SUCCESS,
	DELETE_LINESOFFER_ERROR,
	START_LOADING_UPDATE_LINEOFFER,
	UPDATE_LINEOFFER_SUCCESS,
	UPDATE_LINEOFFER_ERROR,
	START_LOADING_LINEOFFER_BY_ID,
	LINEOFFER_BY_ID_SUCCESS,
	LINEOFFER_BY_ID_ERROR,
	UPDATE_LIST_LINEOFFERS_SUCCESS,
} from './types'

/* *************** LINEOFFERS BY FIELDS ************** */
export const getLineOffersByFields =
	({ status, currentKeyTab }, variables) =>
	async (dispatch) => {
		variables = {
			...variables,
			//othersField: variables?.fields,
			fields: [],
			byStatus: status,
		}
		try {
			dispatch({
				type: START_LOADING_LINEOFFERS,
			})
			const res = await apolloClient.query({
				query: LINEOFFERS_BY_FIELDS_QUERY,
				variables,
				fetchPolicy: 'network-only',
			})
			dispatch({
				type: FETCH_LINEOFFERS_SUCCESS,
				payload: {
					data: res.data?.findLineOffersByField,
					param: { status, currentKeyTab },
				},
			})

			if (
				res.data?.findLineOffersByField?.entities[0]?.type ==
				TYPE_OFFER.PURCHASE
			) {
				res.data?.findLineOffersByField?.entities.map((line) => {
					if (line?.reserved) {
						dispatch({
							type: UPDATE_CART_SUCCESS,
							payload: {
								_id: line?._id,
								qtePriceCommande: line?.unitPrice,
							},
						})
					}
				})
			}
		} catch (error) {
			dispatch({
				type: FETCH_LINEOFFERS_ERROR,
				payload: error.message,
			})
		}
	}
/* *************** Update list LinesOffer ************** */
export const updateListLinesOffer = (lineOffer) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_LINEOFFERS,
		})
		dispatch({
			type: UPDATE_LIST_LINEOFFERS_SUCCESS,
			payload: lineOffer,
		})
	} catch (error) {
		dispatch({
			type: FETCH_LINEOFFERS_ERROR,
			payload: error.message,
		})
	}
}

/* *************** CREATE LINES OFFER ************** */
export const createLinesOffer = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_CREATE_LINESOFFER,
		})
		const res = await apolloClient.mutate({
			mutation: CREATE_LINESOFFER_MUTATION,
			variables: {
				lineOffers: variables,
			},
		})

		dispatch({
			type: CREATE_LINESOFFER_SUCCESS,
			payload: res.data.createLineOffer,
		})
	} catch (error) {
		dispatch({
			type: CREATE_LINESOFFER_ERROR,
			payload: error.message,
		})
	}
}

/* *************** LINEOFFER BY ID ************** */
export const getLineOfferById = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_LINEOFFER_BY_ID,
		})
		const res = await apolloClient.query({
			query: LINEOFFER_BY_ID_QUERY,
			variables,
		})
		dispatch({
			type: LINEOFFER_BY_ID_SUCCESS,
			payload: res.data.getLineOfferById,
		})
	} catch (error) {
		dispatch({
			type: LINEOFFER_BY_ID_ERROR,
			payload: error.message,
		})
	}
}

/* *************** UPDATE LINEOFFER ************** */
export const updateLineOffer = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_UPDATE_LINEOFFER,
		})
		const res = await apolloClient.mutate({
			mutation: UPDATE_LINEOFFERS_BY_ID_MUTATION,
			variables,
		})
		dispatch({
			type: UPDATE_LINEOFFER_SUCCESS,
			payload: res.data.updateLineOffer,
		})
	} catch (error) {
		dispatch({
			type: UPDATE_LINEOFFER_ERROR,
			payload: error.message,
		})
	}
}
/* *************** DELETE LINEOFFER ************** */
/* export const deleteLineOffer = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_DELETE_LINESOFFER,
		})
		const res = await apolloClient.mutate({
			mutation: DELETE_LINEOFFER_BY_ID_MUTATION,
			variables,
		})

		dispatch({
			type: DELETE_LINESOFFER_SUCCESS,
			payload: variables,
		})
	} catch (error) {
		dispatch({
			type: DELETE_LINESOFFER_ERROR,
			payload: error.message,
		})
	}
} */

/* *************** DELETE LINESOFFER ************** */
export const deleteLinesOffer =
	(ids, companyId = '') =>
	async (dispatch) => {
		try {
			dispatch({
				type: START_LOADING_DELETE_LINESOFFER,
			})
			let res = ''
			if (companyId) {
				res = await apolloClient.mutate({
					mutation: DELETE_LINESOFFER_BY_ID_MUTATION,
					variables: {
						ids,
						companyId,
					},
				})
			} else {
				res = ids
			}
			dispatch({
				type: DELETE_LINESOFFER_SUCCESS,
				payload: companyId ? res.data.deleteLinesOffer : res,
			})
		} catch (error) {
			dispatch({
				type: DELETE_LINESOFFER_ERROR,
				payload: error.message,
			})
		}
	}
