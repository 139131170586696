import React, { useRef, useEffect } from 'react'
import {
	AccountCircleOutlined,
	DashboardOutlined,
	NotificationsPausedOutlined,
	ListAltOutlined,
	Dehaze,
	ChatBubbleOutline,
	ListOutlined,
	ShoppingCartOutlined,
	HomeOutlined,
} from '@material-ui/icons'
import { isArray, union } from 'lodash'
import Highlighter from 'react-highlight-words'
import {
	ARTICLES_URL,
	DASHBOARD_URL,
	NOTIFICATIONS_URL,
	USERS_URL,
	PANIER_URL,
	CHAT_URL,
	OFFERS_URL,
} from './paths'
import { ROLES } from './roles'
import { TYPE_NOTIF } from './type_notif'
import { STATUS } from './status'

export const withHighlighter = ({ text, searchText }) => {
	let searchWords = ['']
	if (searchText) {
		if (isArray(searchText)) searchWords = [...searchWords, ...searchText]
		else searchWords.push(searchText)
	}
	return (
		<Highlighter
			highlightStyle={{
				backgroundColor: '#ffc069',
				padding: 0,
			}}
			searchWords={searchWords}
			autoEscape
			textToHighlight={text ? text.toString() : ''}
		/>
	)
}

export const getSidebarLinks = (user) => {
	let sidebarLinks = []
	sidebarLinks.push(
		{
			title: user?.roles?.includes(ROLES.ADMIN)
				? 'Tableau de bord'
				: 'Offres',
			Icon: user?.roles?.includes(ROLES.ADMIN)
				? DashboardOutlined
				: HomeOutlined,
			url: user?.roles?.includes(ROLES.ADMIN)
				? DASHBOARD_URL
				: '/dash/offres/1',
		}
		/* {
			title: user?.roles?.includes(ROLES.ADMIN)
				? 'Utilisateurs'
				: 'Mon Profile',
			Icon: AccountCircleOutlined,
			url: user?.roles?.includes(ROLES.ADMIN)
				? USERS_URL
				: `${USER_PROF_DETAIL_URL.replace(':_id', user?._id)}`,
		} */
	)
	if (user?.roles?.includes(ROLES.PROF)) {
		sidebarLinks.push({
			title: 'Achats',
			Icon: ListAltOutlined,
			url: '/dash/achats/1',
		})
	}

	if (user?.roles?.includes(ROLES.ADMIN)) {
		sidebarLinks = [
			...sidebarLinks,
			{
				title: 'Utilisateurs',
				Icon: AccountCircleOutlined,
				url: USERS_URL,
			},
			{
				title: 'Offres',
				Icon: ListOutlined,
				url: '/dash/offres/1',
			},
			{
				title: 'Achats',
				Icon: ListAltOutlined,
				url: '/dash/achats/1',
			},
			{
				title: 'Articles',
				Icon: Dehaze,
				url: ARTICLES_URL,
			},
			{
				title: 'Notifications',
				Icon: NotificationsPausedOutlined,
				url: NOTIFICATIONS_URL,
			},
			{
				title: 'Chat',
				Icon: ChatBubbleOutline,
				url: CHAT_URL,
			},
		]
	}
	sidebarLinks.push({
		title: user?.roles?.includes(ROLES.ADMIN)
			? 'Les Commandes'
			: 'Votre Panier',
		Icon: ShoppingCartOutlined,
		url: PANIER_URL,
	})
	return sidebarLinks
}

export const withDigits = (number, length = 4) => {
	var str = '' + number
	while (str.length < length) {
		str = '0' + str
	}

	return str
}

export const advanceSearch = (
	search,
	fields = [],
	fieldsToSearch,
	eachOne = true
) => {
	let fieldsValue = advanceWord(search, eachOne)
	fields.map((field) => {
		fieldsValue.map((val) => {
			fieldsToSearch.push({
				field,
				fieldValue: val,
				specific: false,
			})
		})
	})
}

export const advanceWord = (search, eachOne = true) => {
	let wordsList = [search]
	if (search.includes(' ')) {
		const words = search.match(/^(\S+)\s(.*)/).slice(1)
		wordsList.push(words[0] + words[1])
		wordsList.push(search.replace(' ', '_'))
	}
	if (search.includes('_')) {
		wordsList.push(
			search.substr(0, search.indexOf('_')) +
				search.slice(search.indexOf('_') + 1)
		)
		wordsList.push(search.replace('_', ' '))
	}
	if (search.includes('-')) {
		wordsList.push(
			search.substr(0, search.indexOf('-')) +
				search.slice(search.indexOf('-') + 1)
		)
		wordsList.push(search.replace('-', ' '))
	}
	if (eachOne) {
		search?.split(' ').map((val) => {
			wordsList.push(val)
		})
		search?.split('_').map((val) => {
			wordsList.push(val)
		})
		search?.split('-').map((val) => {
			wordsList.push(val)
		})
	}
	return union(wordsList, wordsList)
}

export function usePrevious(value) {
	const ref = useRef()
	useEffect(() => {
		ref.current = value
	})
	return ref.current
}

export function detectURL(message) {
	var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g
	return message.replace(urlRegex, function (urlMatch) {
		return '<a href="' + urlMatch + '" target="_blank">' + urlMatch + '</a>'
	})
}

export const getLinkNotif = (type, subject) => {
	if (type?.split('_').includes('LINEOFFER')) {
		return OFFERS_URL.replace(':param', 1)
	} else if (type?.split('_').includes('LINEORDER')) {
		return PANIER_URL
	} else {
		return '#'
	}
}

export const isNotEmptyString = (message) =>
	message?.replace(/\s/g, '').length > 0

export const getOS = () => {
	var userAgent = window.navigator.userAgent,
		platform =
			window.navigator?.userAgentData?.platform ??
			window.navigator.platform,
		macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
		windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
		iosPlatforms = ['iPhone', 'iPad', 'iPod'],
		os = null

	if (macosPlatforms.indexOf(platform) !== -1) {
		os = 'Mac OS'
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		os = 'iOS'
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		os = 'Windows'
	} else if (/Android/.test(userAgent)) {
		os = 'Android'
	} else if (!os && /Linux/.test(platform)) {
		os = 'Linux'
	}

	return os
}

export const detectBrowser = () => {
	if (
		(navigator.userAgent.indexOf('Opera') ||
			navigator.userAgent.indexOf('OPR')) != -1
	) {
		return 'Opera'
	} else if (navigator.userAgent.indexOf('Chrome') != -1) {
		return 'Chrome'
	} else if (navigator.userAgent.indexOf('Safari') != -1) {
		return 'Safari'
	} else if (navigator.userAgent.indexOf('Firefox') != -1) {
		return 'Firefox'
	} else if (
		navigator.userAgent.indexOf('MSIE') != -1 ||
		!!document.documentMode == true
	) {
		return 'IE' //crap
	} else {
		return 'Unknown'
	}
}
