import styled from 'styled-components'

export const EditableCellContainer = styled.td`
	.editable-cell {
		position: relative;
	}

	.editable-cell-value-wrap {
		padding: 5px 12px;
		cursor: text;
	}

	/* .editable-cell-value-wrap:hover {
		padding: 4px 11px;
		border: 1px solid #d9d9d9;
		border-radius: 2px;
	} */
	.ant-input-number-group-addon {
		padding: 0 2px;
	}
	/* 	.editable-row:hover .editable-cell-value-wrap {
		border: 1px solid #434343;
	} */
`
