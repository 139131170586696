import { concat, has, isArray, isEmpty, merge } from 'lodash'
import { initialStatePagination } from '../../constants/variables'
import {
	FETCH_LINEOFFERS_SUCCESS,
	FETCH_LINEOFFERS_ERROR,
	CREATE_LINESOFFER_SUCCESS,
	CREATE_LINESOFFER_ERROR,
	UPDATE_LINEOFFER_SUCCESS,
	UPDATE_LINEOFFER_ERROR,
	DELETE_LINESOFFER_SUCCESS,
	DELETE_LINESOFFER_ERROR,
	START_LOADING_LINEOFFERS,
	START_LOADING_CREATE_LINESOFFER,
	START_LOADING_UPDATE_LINEOFFER,
	START_LOADING_DELETE_LINESOFFER,
	START_LOADING_LINEOFFER_BY_ID,
	LINEOFFER_BY_ID_SUCCESS,
	LINEOFFER_BY_ID_ERROR,
	UPDATE_LIST_LINEOFFERS_SUCCESS,
} from './types'
const initialState = merge(
	{
		lineOfferById: {},
		loadingGetLineOffers: false,
		loadingCreateLinesOffer: false,
		loadingGetLineOfferById: false,
		loadingDeleteLinesOffer: false,
		loadingUpdateLineOffer: false,
		statusData: '',
		currentKeyTab: '',
		error: '',
	},
	initialStatePagination
)

export default function lineOfferReducer(state = initialState, action) {
	switch (action.type) {
		/* ****************** GET LINEOFFERS ************************ */
		case START_LOADING_LINEOFFERS:
			return {
				...state,
				loadingGetLineOffers: true,
			}
		case FETCH_LINEOFFERS_SUCCESS:
			const {
				data,
				param: { status, currentKeyTab },
			} = action.payload
			return {
				...state,
				...data,
				loadingGetLineOffers: false,
				statusData: status,
				currentKeyTab,
				error: '',
			}
		case FETCH_LINEOFFERS_ERROR:
			return {
				...state,
				loadingGetLineOffers: false,
				error: action.payload,
			}
		/* ****************** CREATE LINEOFFER ************************ */
		case START_LOADING_CREATE_LINESOFFER:
			return {
				...state,
				loadingCreateLinesOffer: true,
			}
		case CREATE_LINESOFFER_SUCCESS:
			return {
				...state,
				entities: [action.payload, ...state.entities],
				loadingCreateLinesOffer: false,
				error: '',
			}
		case CREATE_LINESOFFER_ERROR:
			return {
				...state,
				loadingCreateLinesOffer: false,
				error: action.payload,
			}

		/* ****************** Update linesOffer List ************************ */
		case UPDATE_LIST_LINEOFFERS_SUCCESS:
			if (isArray(action.payload)) {
				if (state.statusData == action.payload[0]?.status) {
					state.entities = [...action.payload, ...state.entities]
				}
			} else {
				if (state.statusData != action.payload?.status) {
					state.entities = [
						...state.entities.filter(
							(lineOffer) =>
								lineOffer?._id !== action.payload?._id
						),
					]
				} else {
					const exist = state.entities?.find(
						(lineOffer) => lineOffer?._id == action.payload?._id
					)
					if (isEmpty(exist)) {
						state.entities = [action.payload, ...state.entities]
					} else {
						state.entities = [
							...state.entities.filter(
								(lineOffer) =>
									lineOffer?._id !== action.payload?._id
							),
						]
						state.entities.unshift(action.payload)
						/* const entities = []
						state.entities.map((lineOffer) => {
							if (lineOffer._id === action.payload._id) {
								entities.push(action.payload)
							} else entities.push(lineOffer)
						})
						state.entities = entities */
					}
				}
			}

			return {
				...state,
				loadingGetLineOffers: false,
				error: '',
			}
		/* ****************** LINEOFFER BY ID ************************ */
		case START_LOADING_LINEOFFER_BY_ID:
			return {
				...state,
				loadingGetLineOfferById: true,
			}
		case LINEOFFER_BY_ID_SUCCESS:
			return {
				...state,
				lineOfferById: action.payload,
				loadingGetLineOfferById: false,
				error: '',
			}
		case LINEOFFER_BY_ID_ERROR:
			return {
				...state,
				loadingGetLineOfferById: false,
				error: action.payload,
			}
		/* ****************** UPDATE LINEOFFER ************************ */
		case START_LOADING_UPDATE_LINEOFFER:
			return {
				...state,
				loadingUpdateLineOffer: true,
			}
		case UPDATE_LINEOFFER_SUCCESS:
			if (action.payload?.status != state.statusData) {
				state.entities = [
					...state.entities.filter(
						(lineOffer) => lineOffer._id !== action.payload._id
					),
				]
			}
			console.log(action.payload)
			if (has(action.payload, 'lineOffer')) {
				state.entities = [
					...state.entities.map((lineOffer) => {
						if (lineOffer._id === action.payload.lineOffer) {
							return {
								...lineOffer,
								reserved: action.payload?.reserved,
							}
						} else return lineOffer
					}),
				]
			} else {
				state.entities = [
					...state.entities.map((lineOffer) => {
						if (lineOffer._id === action.payload._id) {
							return action.payload
						} else return lineOffer
					}),
				]
			}
			return {
				...state,
				entities: state.entities,
				loadingUpdateLineOffer: false,
				error: '',
			}
		case UPDATE_LINEOFFER_ERROR:
			return {
				...state,
				loadingUpdateLineOffer: false,
				error: action.payload,
			}
		/* ****************** DELETE LINEOFFER ************************ */
		case START_LOADING_DELETE_LINESOFFER:
			return {
				...state,
				loadingDeleteLinesOffer: true,
			}
		case DELETE_LINESOFFER_SUCCESS:
			return {
				...state,
				loadingDeleteLinesOffer: false,
				entities: [
					...state.entities.filter(
						(lineOffer) => !action.payload.includes(lineOffer._id)
					),
				],
				error: '',
			}
		case DELETE_LINESOFFER_ERROR:
			return {
				...state,
				loadingDeleteLinesOffer: false,
				error: action.payload,
			}
		default:
			return state
	}
}
