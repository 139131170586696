export const START_LOADING_LINEOFFERS = 'START_LOADING_LINEOFFERS'
export const FETCH_LINEOFFERS_SUCCESS = 'FETCH_LINEOFFERS_SUCCESS'
export const FETCH_LINEOFFERS_ERROR = 'FETCH_LINEOFFERS_ERROR'

export const UPDATE_LIST_LINEOFFERS_SUCCESS = 'UPDATE_LIST_LINEOFFERS_SUCCESS'

export const START_LOADING_CREATE_LINESOFFER = 'START_LOADING_CREATE_LINESOFFER'
export const CREATE_LINESOFFER_SUCCESS = 'CREATE_LINESOFFER_SUCCESS'
export const CREATE_LINESOFFER_ERROR = 'CREATE_LINESOFFER_ERROR'

export const START_LOADING_LINEOFFER_BY_ID = 'START_LOADING_LINEOFFER_BY_ID'
export const LINEOFFER_BY_ID_SUCCESS = 'LINEOFFER_BY_ID_SUCCESS'
export const LINEOFFER_BY_ID_ERROR = 'LINEOFFER_BY_ID_ERROR'

export const START_LOADING_DELETE_LINESOFFER = 'START_LOADING_DELETE_LINESOFFER'
export const DELETE_LINESOFFER_SUCCESS = 'DELETE_LINESOFFER_SUCCESS'
export const DELETE_LINESOFFER_ERROR = 'DELETE_LINESOFFER_ERROR'

export const START_LOADING_UPDATE_LINEOFFER = 'START_LOADING_UPDATE_LINEOFFER'
export const UPDATE_LINEOFFER_SUCCESS = 'UPDATE_LINEOFFER_SUCCESS'
export const UPDATE_LINEOFFER_ERROR = 'UPDATE_LINEOFFER_ERROR'
