export const TYPE_NOTIF = {
	NEW_USER_PROF: 'NEW_USER_PROF',
	CHANGE_STATUS_USER: 'CHANGE_STATUS_USER',
	CHANGE_STATUS_LINEOFFER: 'CHANGE_STATUS_LINEOFFER',
	MODIFIED_LINEOFFER: 'MODIFIED_LINEOFFER',
	NEW_LINEORDER_FROM_USER: 'NEW_LINEORDER_FROM_USER',
	MODIFIED_LINEORDER: 'MODIFIED_LINEORDER',
	CONFIRMED_LINEORDER: 'CONFIRMED_LINEORDER',
	ANNULED_LINEORDER: 'ANNULED_LINEORDER',
	CHANGE_USER_PASSWORD: 'CHANGE_USER_PASSWORD',
	NEW_LINEOFFER_FROM_USER: 'NEW_LINEOFFER_FROM_USER',
}
