import { withAuth } from '../HOCs/withAuth'
import { ROLES } from '../constants/roles'
import {
	LOGIN_URL,
	USERS_URL,
	OFFERS_URL,
	DASHBOARD_URL,
	HOME_URL,
	OFFERS_BY_USER_ID_URL,
	USER_PROF_DETAIL_URL,
	USER_CLIENT_DETAIL_URL,
	ARTICLE_DETAIL_URL,
	RESULT_WELCOME_PROF_URL,
	NOTIFICATIONS_URL,
	NOTIFICATIONS_CLIENT_URL,
	ARTICLES_URL,
	PANIER_URL,
	CHAT_URL,
	INSCRIPTION_URL,
	PURSHASES_URL,
} from '../constants/paths'
import Dashboard from '../pages/Dashboard'
import Users from '../pages/Users'
import Advertisement from '../pages/Offers'
import PurshasesList from '../pages/PurshasesList'
import Home from '../pages/Home'
import UserDetail from '../pages/UserDetail'
import SingleArticle from '../pages/SingleArticle'
import WelcomeProf from '../pages/Results/WelcomeProf'
import Notifications from '../pages/Notifications'
import Articles from '../pages/Articles'
import Panier from '../pages/Panier'
import Chat from '../pages/Chat'
import Register from '../pages/Register'

export const routes = [
	/***** Routes Public ******/
	/* {
		path: LOGIN_URL,
		component: LoginForm,
	}, */
	{
		path: HOME_URL,
		component: Home,
	},
	{
		path: INSCRIPTION_URL,
		component: () => <Register isPage mode={'add'} />,
	},
	{
		path: ARTICLE_DETAIL_URL,
		component: SingleArticle,
	},
	{
		path: NOTIFICATIONS_CLIENT_URL,
		component: withAuth({
			ComposedComponent: Notifications,
			roles: [ROLES.CLIENT],
			isPublicAfterAuth: true,
		}),
	},
	/***** Routes Dashboard ******/
	{
		path: DASHBOARD_URL,
		component: withAuth({
			ComposedComponent: Dashboard,
			roles: [ROLES.ADMIN],
		}),
	},
	{
		path: USERS_URL,
		component: withAuth({
			ComposedComponent: Users,
			roles: [ROLES.ADMIN],
		}),
	},
	{
		path: USER_PROF_DETAIL_URL,
		component: withAuth({
			ComposedComponent: UserDetail,
			roles: [ROLES.PROF],
		}),
	},
	{
		path: USER_CLIENT_DETAIL_URL,
		component: withAuth({
			ComposedComponent: UserDetail,
			roles: [ROLES.CLIENT],
		}),
	},

	/* {
		path: OFFERS_BY_USER_ID_URL,
		component: withAuth({
			ComposedComponent: Advertisement,
			roles: [ROLES.ADMIN, ROLES.PROF],
		}),
	}, */
	{
		path: OFFERS_URL,
		component: withAuth({
			ComposedComponent: Advertisement,
			roles: [ROLES.ADMIN, ROLES.PROF],
		}),
	},
	{
		path: PURSHASES_URL,
		component: withAuth({
			ComposedComponent: PurshasesList,
			roles: [ROLES.ADMIN, ROLES.PROF],
		}),
	},
	{
		path: ARTICLES_URL,
		component: withAuth({
			ComposedComponent: Articles,
			roles: [ROLES.ADMIN, ROLES.PROF],
		}),
	},
	{
		path: NOTIFICATIONS_URL,
		component: withAuth({
			ComposedComponent: Notifications,
			roles: [ROLES.ADMIN, ROLES.PROF],
			//isPublicAfterAuth: true,
		}),
	},
	{
		path: CHAT_URL,
		component: withAuth({
			ComposedComponent: Chat,
			roles: [ROLES.ADMIN],
			//isPublicAfterAuth: true,
		}),
	},
	{
		path: PANIER_URL,
		component: withAuth({
			ComposedComponent: Panier,
			roles: [ROLES.ADMIN, ROLES.PROF],
			//isPublicAfterAuth: true,
		}),
	},
]
