import { STATUS } from '../../constants/status'
import { apolloClient } from '../../graphql'
import {
	CREATE_OFFER_MUTATION,
	DELETE_OFFER_BY_ID_MUTATION,
	UPDATE_OFFER_BY_ID_QUERY,
	OFFERS_BY_FIELDS_QUERY,
	OFFER_BY_ID_QUERY,
} from '../../queries/offer'
import { createLinesOffer } from '../lineOffer/actions'

import {
	START_LOADING_OFFERS,
	FETCH_OFFERS_SUCCESS,
	FETCH_OFFERS_ERROR,
	START_LOADING_CREATE_OFFER,
	CREATE_OFFER_SUCCESS,
	CREATE_OFFER_ERROR,
	START_LOADING_DELETE_OFFER,
	DELETE_OFFER_SUCCESS,
	DELETE_OFFER_ERROR,
	START_LOADING_UPDATE_OFFER,
	UPDATE_OFFER_SUCCESS,
	UPDATE_OFFER_ERROR,
	START_LOADING_OFFER_BY_ID,
	OFFER_BY_ID_SUCCESS,
	OFFER_BY_ID_ERROR,
} from './types'

/* *************** OFFERS BY FIELDS ************** */
export const getOffersByFields = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_OFFERS,
		})
		const res = await apolloClient.query({
			query: OFFERS_BY_FIELDS_QUERY,
			variables,
			fetchPolicy: 'network-only',
		})
		dispatch({
			type: FETCH_OFFERS_SUCCESS,
			payload: res.data?.findOffersByField,
		})
	} catch (error) {
		dispatch({
			type: FETCH_OFFERS_ERROR,
			payload: error.message,
		})
	}
}
/* *************** CREATE OFFER ************** */
export const createOffer =
	({ lineOffers, ...variables }) =>
	async (dispatch) => {
		try {
			await dispatch({
				type: START_LOADING_CREATE_OFFER,
			})

			const res = await apolloClient.mutate({
				mutation: CREATE_OFFER_MUTATION,
				variables,
			})
			lineOffers.map((line) => {
				line.offer = res.data.createOffer._id
				line.type = res.data.createOffer?.type
				line.quantity = line.quantity ? line.quantity : null
				line.unitPrice = line.unitPrice ? line.unitPrice : null
				line.unitPriceFinal = line.unitPrice ? line.unitPrice : null
				return line
			})
			await dispatch({
				type: CREATE_OFFER_SUCCESS,
				payload: res.data.createOffer,
			})
			await dispatch(createLinesOffer(lineOffers))
		} catch (error) {
			await dispatch({
				type: CREATE_OFFER_ERROR,
				payload: error.message,
			})
		}
	}

/* *************** OFFER BY ID ************** */
export const getOfferById = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_OFFER_BY_ID,
		})
		const res = await apolloClient.query({
			query: OFFER_BY_ID_QUERY,
			variables,
		})
		dispatch({
			type: OFFER_BY_ID_SUCCESS,
			payload: res.data.getOfferById,
		})
	} catch (error) {
		dispatch({
			type: OFFER_BY_ID_ERROR,
			payload: error.message,
		})
	}
}

/* *************** UPDATE OFFER ************** */
/* export const updateOffer = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_UPDATE_OFFER,
		})
		const res = await apolloClient.mutate({
			mutation: UPDATE_OFFER_BY_ID_QUERY,
			variables,
		})
		dispatch({
			type: UPDATE_OFFER_SUCCESS,
			payload: res.data.updateOffer,
		})
	} catch (error) {
		dispatch({
			type: UPDATE_OFFER_ERROR,
			payload: error.message,
		})
	}
} */
/* *************** DELETE OFFER ************** */
export const deleteOffer = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_DELETE_OFFER,
		})
		const res = await apolloClient.mutate({
			mutation: DELETE_OFFER_BY_ID_MUTATION,
			variables,
		})

		dispatch({
			type: DELETE_OFFER_SUCCESS,
			payload: variables,
		})
	} catch (error) {
		dispatch({
			type: DELETE_OFFER_ERROR,
			payload: error.message,
		})
	}
}
