export const START_LOADING_LOGIN = 'START_LOADING_LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'

export const START_LOADING_REGISTER = 'START_LOADING_REGISTER'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_ERROR = 'REGISTER_ERROR'

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_ERROR = 'LOGOUT_ERROR'

export const FETCH_AUTH_BY_ID_SUCCESS = 'FETCH_AUTH_BY_ID_SUCCESS'
export const FETCH_AUTH_BY_ID_ERROR = 'FETCH_AUTH_BY_ID_ERROR'

export const START_LOADING_UPDATE_PROFILE = 'START_LOADING_UPDATE_PROFILE'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR'
