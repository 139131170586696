import React, { useState, useEffect } from 'react'
import { Form, Modal, Typography, Radio, message, Row, Col, Select } from 'antd'
import { ROLES } from '../../constants/roles'
import { RegisterContainer } from './styles'
import { FormContainer, PrimaryButton } from '../../global/GlobalStyles'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { STATUS } from '../../constants/status'
import StepsCreateCompany from '../StepsCreateCompany'
import UserForm from '../../components/Forms/UserForm'
import { isEmpty, omit } from 'lodash'
import { register } from '../../redux/auth/actions'
import { createProf, createUser, updateUser } from '../../redux/user/actions'
import {
	getCompanyByIdUser,
	updateCompanyById,
} from '../../redux/company/actions'
import { typesCompany } from '../../constants/variables'
import { useHistory } from 'react-router'

export const Register = ({
	isModalVisible,
	setIsModalVisible = () => {},
	textButton,
	mode,
	userToEdit,
	isPage,
}) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const [currentType, setCurrentType] = useState()

	const {
		loadingRegister,
		user,
		isAuthenticated,
		loadingUpdateUser,
		loadingGetCompanyByUserId,
		loadingUpdateCompany,
		loadingCreateCompany,
		companyToEdit,
	} = useSelector(
		(state) => ({
			user: state.auth.user,
			isAuthenticated: state.auth.isAuthenticated,
			loadingRegister: state.auth.loadingRegister,
			loadingUpdateUser: state.users.loadingUpdateUser,
			loadingGetCompanyByUserId:
				state.companies.loadingGetCompanyByUserId,
			companyToEdit: state.companies.companyToEdit,
			loadingUpdateCompany: state.companies.loadingUpdateCompany,
			loadingCreateCompany: state.companies.loadingCreateCompany,
		}),
		shallowEqual
	)

	const [current, setCurrent] = useState(0)
	let [stepsData, setStepsData] = useState({})
	const isAdmin = user?.roles && user?.roles?.includes(ROLES.ADMIN)
	const [form] = Form.useForm()
	let [isProfSelected, setIsProfSelected] = useState(true)
	const resetToInial = () => {
		setCurrent(0)
		setStepsData({})
		setIsProfSelected(true)
		setCurrentType('')
		form.resetFields()
	}

	/* ******* Register User  *****/
	const registerClient = async () => {
		form.submit()
		form.validateFields()
			.then(async (values) => {
				const {
					firstName,
					lastName,
					email,
					phone,
					password,
					codeClient,
				} = values
				const newUser = {
					firstName,
					lastName,
					email,
					phone,
					password,
					codeClient,
					roles: [ROLES.CLIENT],
					status: values?.status ? values?.status : STATUS.ACTIVE,
				}
				isAdmin
					? await dispatch(createUser(newUser))
					: await dispatch(
							register({
								user: newUser,
							})
					  )
				setIsModalVisible(false)
				message.success('Vous êtes inscrit avec succé')
				history.push('/dash/offres/1')
			})
			.catch((errorInfo) => {
				console.log({ errorInfo })
			})
	}
	/* ******* Register Professionel  *****/
	const registerPro = async (data) => {
		if (!isAuthenticated) {
			stepsData = data
			delete stepsData?.company?.confirm
			delete stepsData?.company?.password
		}
		stepsData.user = {
			...stepsData.user,
			codeClient: null,
			password: stepsData.user?.password,
			/* password: isAdmin
				? stepsData.user?.password
				: stepsData.company?.matFiscal, */
			roles: [ROLES.PROF],
			status: stepsData.user?.status
				? stepsData.user?.status
				: STATUS.PROCESSING,
		}
		stepsData.company = {
			...stepsData.company,
			type: currentType,
		}
		delete stepsData?.user?.confirm
		isAdmin
			? await dispatch(createProf(stepsData))
			: await dispatch(register(stepsData))
		setIsModalVisible(false)
		message.success('Vous êtes inscrit avec succé')
		history.push('/dash/offres/1')
	}

	/* ******* Update User  *****/
	const updateClient = () => {
		form.submit()
		form.validateFields()
			.then((values) => {
				const {
					firstName,
					lastName,
					email,
					phone,
					password,
					codeClient,
				} = values
				dispatch(
					updateUser({
						_id: userToEdit?._id,
						firstName,
						lastName,
						email,
						phone,
						password,
						roles: [ROLES.CLIENT],
						status: values?.status ? values?.status : STATUS.ACTIVE,
					})
				)
				setIsModalVisible(false)
				message.success('Utilisateur a été modifié')
			})
			.catch((errorInfo) => {
				console.log({ errorInfo })
			})
	}
	/* ******* Update Professionel  *****/
	const updatePro = () => {
		stepsData.company = {
			...stepsData.company,
			type: currentType,
		}
		let variables = {}
		if (isEmpty(stepsData.user?.password)) {
			variables = {
				...omit(stepsData.user, ['password']),
				_id: userToEdit?._id,
				status: stepsData.user?.status
					? stepsData.user?.status
					: STATUS.PROCESSING,
			}
		} else {
			variables = {
				_id: userToEdit?._id,
				...stepsData.user,
				password: stepsData.user?.password
					? stepsData.user?.password
					: null,
			}
		}
		dispatch(updateUser(variables))
		dispatch(
			updateCompanyById({
				_id: companyToEdit._id,
				...stepsData?.company,
			})
		)
		setIsModalVisible(false)
	}
	const FormInscription = () => (
		<div
			style={
				isPage
					? {
							display: 'flex',
							height: '80vh',
							flexDirection: 'column',
							justifyContent: 'flex-start',
							alignItems: 'center',
							margin: '2%',
					  }
					: { margin: '0%' }
			}
		>
			{isPage ? (
				<Typography.Title style={{ marginBottom: '2%' }} level={2}>
					Inscrivez-Vous
				</Typography.Title>
			) : null}
			<Row gutter={[4, 4]} style={{ width: '100%' }}>
				<Col offset={6} xs={12}>
					<Form>
						<Form.Item
							style={{
								marginBottom: '0.3em',
							}}
							label={
								isPage ? 'Choisissez votre status' : 'Status'
							}
							rules={[
								{
									required: true,
									message: 'Veuillez saisir status!',
								},
							]}
						>
							{mode == 'edit' ? (
								loadingGetCompanyByUserId || (
									<Select
										style={{
											width: '100%',
										}}
										size='large'
										placeholder='Please select'
										defaultValue={currentType}
										value={currentType}
										onChange={(val) => {
											setCurrentType(val)
										}}
									>
										{typesCompany.map(
											({ value, key, title }) => (
												<Select.Option
													key={key}
													value={value}
												>
													{title}
												</Select.Option>
											)
										)}
									</Select>
								)
							) : (
								<Select
									style={{
										width: '100%',
									}}
									size='large'
									placeholder='Please select'
									value={currentType}
									onChange={(val) => {
										setCurrentType(val)
									}}
								>
									{typesCompany.map(
										({ value, key, title }) => (
											<Select.Option
												key={key}
												value={value}
											>
												{title}
											</Select.Option>
										)
									)}
								</Select>
							)}
						</Form.Item>
					</Form>
				</Col>
			</Row>
			<FormContainer>
				<div className='form-box-shadow' style={{ minHeight: '30vh' }}>
					<Form
						form={form}
						layout='vertical'
						name='normal_register'
						className='register-form'
						initialValues={{
							role: 'CLIENT',
						}}
						style={{
							width: '100%',
						}}
					>
						{isProfSelected ? (
							!currentType ? (
								''
							) : (
								<StepsCreateCompany
									form={form}
									current={current}
									setCurrent={setCurrent}
									currentType={currentType}
									setCurrentType={setCurrentType}
									stepsData={stepsData}
									setStepsData={setStepsData}
									loading={loadingGetCompanyByUserId}
									onSubmit={
										mode == 'add' ? registerPro : updatePro
									}
									roles={user?.roles}
									loadinConfirm={
										loadingCreateCompany ||
										loadingRegister ||
										//loadingCreateSpeciality ||
										loadingUpdateUser ||
										//loadingUpdateSpeciality ||
										loadingUpdateCompany
									}
									mode={mode}
								/>
							)
						) : (
							<UserForm
								form={form}
								mode={mode}
								isProfSelected={isProfSelected}
								roles={user?.roles}
							/>
						)}
					</Form>
				</div>
			</FormContainer>
		</div>
	)
	useEffect(() => {
		isModalVisible &&
			dispatch(getCompanyByIdUser({ user: userToEdit?._id }, true))
	}, [isModalVisible, userToEdit])
	useEffect(() => {
		if (mode == 'edit') {
			setStepsData({
				...stepsData,
				user: userToEdit,
				company: companyToEdit,
			})
			setCurrentType(companyToEdit?.type)
			setIsProfSelected(true)

			/* if (userToEdit?.roles?.includes(ROLES.PROF)) {
				isProfSelected = true
				setIsProfSelected(true)
			} else {
				isProfSelected = false
				setIsProfSelected(false)
			} */

			/* isProfSelected &&
				dispatch(getCompanyByIdUser({ user: userToEdit?._id }), true) */
		}
		if (mode == 'add') {
			if (isModalVisible) {
				resetToInial()
			}
		}
		if (!isModalVisible) {
			resetToInial()
		}
	}, [userToEdit, mode, isModalVisible, companyToEdit])

	useEffect(() => {
		const keys = userToEdit?.roles?.includes(ROLES.PROF)
			? ['company', 'user']
			: ['user']

		if (mode == 'edit' && !isEmpty(stepsData)) {
			form.setFieldsValue(stepsData[keys[current]])
		}
	}, [stepsData, current, userToEdit])
	useEffect(() => {
		isPage && setCurrentType('VENDEUR')
	}, [])

	return (
		<RegisterContainer>
			{isPage ? (
				<FormInscription />
			) : (
				<Modal
					centered
					bodyStyle={{
						overflowY: 'scroll',
						padding: '1% 2%',
						height: 'inherit',
					}}
					width='90%'
					title={
						<div
							style={{
								textAlign: 'center',
							}}
						>
							<Typography.Title level={4}>
								{isAdmin
									? mode == 'add'
										? 'Ajouter un Utilisateur'
										: 'Modifier un Utilisateur'
									: 'Inscrivez-Vous'}
							</Typography.Title>
							{/* {mode == 'add' && (
						<Radio.Group
							onChange={(e) => {
								e.target.value == 'PROF'
									? setIsProfSelected(true)
									: setIsProfSelected(false)
							}}
							value={isProfSelected ? 'PROF' : 'CLIENT'}
							size='large'
						>
							<Radio value='CLIENT'>Particulier</Radio>
							<Radio value='PROF'>Professionel</Radio>
						</Radio.Group>
					)} */}
						</div>
					}
					onCancel={() => setIsModalVisible(false)}
					visible={isModalVisible}
					footer={
						isProfSelected || (
							<PrimaryButton
								onClick={
									mode == 'add'
										? registerClient
										: updateClient
								}
								block
								size='large'
								loading={loadingRegister}
							>
								{textButton}
							</PrimaryButton>
						)
					}
				>
					<FormInscription />
				</Modal>
			)}
		</RegisterContainer>
	)
}

export default Register
