import 'antd/dist/antd.css'
import React from 'react'
import { CustomDrawerContainer } from './styles'
import { Drawer, Grid } from 'antd'

const CustomDrawer = ({ children, onClose, visible }) => {
	const { useBreakpoint } = Grid
	const { xs } = useBreakpoint()
	return (
		<CustomDrawerContainer>
			<Drawer
				title=''
				placement='left'
				closable={false}
				onClose={onClose}
				visible={visible}
				destroyOnClose={true}
				width={xs ? '240px' : '378px'}
				bodyStyle={{ padding: 0 }}
			>
				<div onClick={onClose}>{children}</div>
			</Drawer>
		</CustomDrawerContainer>
	)
}
export default CustomDrawer
