import React, { useEffect, useState } from 'react'
import { Form, Button, Upload, Input, message, Col, Row, Select } from 'antd'
import { UploadOutlined, MailOutlined, LockOutlined } from '@ant-design/icons'

import {
	activitiesGarage,
	marques,
	typesCompany,
} from '../../../constants/variables'
import { Type_COMPANY } from '../../../constants/type_company'
import CustomtreeSelect from '../../CostumTreeSelect'
import { shallowEqual, useSelector } from 'react-redux'
import { ROLES } from '../../../constants/roles'

const CompanyForm = ({
	form,
	formItemLayout,
	stepsData,
	currentType,
	setCurrentType,
}) => {
	const [selectedValues, setSelectedValues] = useState([])
	const { isAuthenticated, user } = useSelector(
		(state) => ({
			isAuthenticated: state.auth.isAuthenticated,
			user: state.auth.user,
		}),
		shallowEqual
	)

	useEffect(() => {
		//setCurrentType(currentType ? currentType : stepsData?.company?.type)
		form.setFieldsValue({
			...stepsData?.company,
			//type: currentType,
		})
	}, [currentType])

	useEffect(() => {
		stepsData?.company?.type && setCurrentType(stepsData?.company?.type)
		form.setFieldsValue({
			...stepsData?.company,
		})
		stepsData?.company?.marques &&
			setSelectedValues(stepsData?.company?.marques)
	}, [stepsData])

	useEffect(() => {
		form.setFieldsValue({
			...stepsData?.company,
			type: currentType,
			marques: selectedValues,
		})
	}, [selectedValues])
	/* 	const normFile = (e) => {
		if (Array.isArray(e)) {
			return e
		}
		return e && e.fileList
	}
	const getResolution = (file) => {
		return new Promise((resolve) => {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.addEventListener('load', (event) => {
				const _loadedImageUrl = event.target.result
				const image = document.createElement('img')
				image.src = _loadedImageUrl
				image.addEventListener('load', () => {
					const { width, height } = image
					resolve({ width: width, height: height })
				})
			})
		})
	}

	async function beforeUpload(file) {
		const isJpgOrPng =
			file.type === 'image/jpeg' || file.type === 'image/png'
		if (!isJpgOrPng) {
			message.error(
				'Vous ne pouvez que télécharger des fichiers jpeg ou png'
			)
		}
		const isLt2M = file.size / 1024 / 1024 < 2
		if (!isLt2M) {
			message.error("L'image doit avoir une taille inférieure à 2MB")
		}
		const result = await getResolution(file)
		const validateSize = result.width <= 1000 && result.height <= 1000
		if (!validateSize) {
			message.error(
				"Veuillez redimensionner la largeur ou la hauteur de l'image à moins de 1000px."
			)
		}
		//mutate({ variables: { file } });
		return isJpgOrPng && isLt2M && validateSize
	} */
	return (
		<Form form={form} name='validate_other' {...formItemLayout}>
			<Row gutter={[4, 4]} wrap>
				{/* <Col offset={6} xs={12}>
					<Form.Item
						label='Status'
						name='type'
						rules={[
							{
								required: true,
								message: 'Veuillez saisir status!',
							},
						]}
					>
						<Select
							size='large'
							placeholder='Please select'
							onChange={(val) => {
								setCurrentType(val)
							}}
						>
							{typesCompany.map(({ value, key, title }) => (
								<Select.Option key={key} value={value}>
									{title}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				</Col> */}
				{currentType && (
					<>
						<Col sm={12} xs={24}>
							<Form.Item
								label={`Nom / Société`}
								name='title'
								rules={[
									{
										required: true,
										message: 'Veuillez saisir Nom',
									},
								]}
							>
								<Input size='large' />
							</Form.Item>
						</Col>
						<Col sm={12} xs={24}>
							<Form.Item
								label='MatFiscal (optionnel)'
								name='matFiscal'
								rules={[
									/* {
										required: true,
										message: 'Veuillez saisir matFiscal!',
									}, */
									{
										message:
											'Matricule Fiscal doit être supérieur à 6 caractéres',
										min: 7,
									},
								]}
							>
								<Input size='large' />
							</Form.Item>
						</Col>
						<Col sm={12} xs={24}>
							<Form.Item
								label='Tel'
								name='phoneNumber'
								rules={[
									{
										required: true,
										message: 'Veuillez saisir reference!',
									},
								]}
							>
								<Input size='large' />
							</Form.Item>
						</Col>
						{currentType != Type_COMPANY.ASSUREUR &&
							currentType != Type_COMPANY.EXPERT && (
								<Col sm={12} xs={24}>
									<Form.Item
										label='Marques'
										name='marques'
										rules={[
											{
												required: true,
												message:
													'Veuillez saisir les marques!',
											},
										]}
									>
										<CustomtreeSelect
											selectedValues={selectedValues}
											setSelectedValues={
												setSelectedValues
											}
											data={marques}
										/>
									</Form.Item>
								</Col>
							)}
						{isAuthenticated ? (
							<>
								<Col sm={12} xs={24}>
									<Form.Item
										name='email'
										label='Email'
										rules={[
											{
												type: 'email',
											},
										]}
									>
										<Input
											size='large'
											prefix={
												<MailOutlined className='site-form-item-icon' />
											}
											placeholder='Email'
										/>
									</Form.Item>
								</Col>
								<Col sm={12} xs={24}>
									<Form.Item
										label='Adresse'
										name='address'
										/* rules={[
											{
												required: true,
												message:
													'Veuillez saisir adresse!',
											},
										]} */
									>
										<Input size='large' />
									</Form.Item>
								</Col>
							</>
						) : (
							<>
								<Col sm={12} xs={24}>
									<Form.Item
										label={'Mot de passe'}
										name='password'
										type='string'
										rules={[
											{
												required: true,
												message:
													'Veuillez créer un mot de passe!',
											},
											({ getFieldValue }) => ({
												validator(rule, value) {
													var error = ''
													if (value == '') {
														error =
															"Vous n'avez pas saisi votre mot de passe.\n"
														return Promise.reject(
															error
														)
													} else if (
														value.length < 6
													) {
														error =
															'Le mot de passe doit avoir au moins 6 charactères!'
														return Promise.reject(
															error
														)
													}

													return Promise.resolve()
												},
											}),
										]}
									>
										<Input.Password
											size='large'
											prefix={
												<LockOutlined className='site-form-item-icon' />
											}
											placeholder='Mot de passe'
										/>
									</Form.Item>
								</Col>
								<Col sm={12} xs={24}>
									<Form.Item
										name='confirm'
										label='Confirmez votre mot de passe'
										type='string'
										dependencies={['password']}
										hasFeedback
										rules={[
											{
												required: true,
												message:
													'Veuillez confirmer votre mot de passe',
											},
											({ getFieldValue }) => ({
												validator(_, value) {
													if (
														!value ||
														getFieldValue(
															'password'
														) === value
													) {
														return Promise.resolve()
													}
													return Promise.reject(
														new Error(
															'Les deux mots de passes ne correspondent pas!'
														)
													)
												},
											}),
										]}
									>
										<Input.Password
											size='large'
											prefix={
												<LockOutlined className='site-form-item-icon' />
											}
											placeholder='Confirmation'
										/>
									</Form.Item>
								</Col>
							</>
						)}

						{currentType == Type_COMPANY.GARAGE && (
							<Col sm={12} xs={24}>
								<Form.Item
									label='Activité'
									name='activity'
									rules={[
										{
											required: true,
											message:
												'Veuillez saisir activitié!',
										},
									]}
								>
									<Select
										size='large'
										placeholder='Please select'
									>
										{activitiesGarage.map(
											({ value, key, title }) => (
												<Select.Option
													key={key}
													value={value}
												>
													{title}
												</Select.Option>
											)
										)}
									</Select>
								</Form.Item>
							</Col>
						)}

						{/* 	<Col xs={24}>
							<Form.Item
								name='photo'
								//label='Photo'
								valuePropName='fileList'
								getValueFromEvent={normFile}
								// extra="longgggggggggggggggggggggggggggggggggg"
								rules={[
									{
										required: false,
										message: 'Please select photo!',
									},
								]}
							>
								<Upload
									name='photo'
									type='file'
									beforeUpload={beforeUpload}
								>
									<Button
										size='large'
										icon={<UploadOutlined />}
									>
										Télécharger logo
									</Button>
								</Upload>
							</Form.Item>
						</Col> */}
					</>
				)}
			</Row>
		</Form>
	)
}

export default CompanyForm
