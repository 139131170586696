import styled from 'styled-components'

export const OffersContainer = styled.div`
	.actions-btn {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
	.actions-btn > button {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
	.ant-typography-copy svg {
		fill: ${(props) => props.theme.copyColor};
	}
	.table-advs {
		padding: 1rem;
		padding-top: 0rem;
		background-color: #fff;
	}
	.group-addon {
		&:active {
			transform: scale(0.65);
		}
	}

	.sell-color-row {
		background-color: ${(props) => props.theme.sellRow};
	}

	.purchase-color-row {
		background-color: ${(props) => props.theme.purchaseRow};
	}
	.purchase-color-row .ant-table-cell-row-hover {
		background: ${(props) => props.theme.purchaseRowHover} !important;
	}
	.ant-descriptions-row th {
		background-color: ${(props) => props.theme.secondary};
		color: ${(props) => props.theme.white};
		font-weight: 600;
	}
	td.ant-table-cell-fix-left,
	td.ant-table-cell-fix-right {
		background: inherit;
	}
	.ant-descriptions-bordered .ant-descriptions-view {
		border: 1px solid ${(props) => props.theme.secondary};
	}
	/* .ant-table-thead > tr > th {
		padding: 2px 4px;
	} */
	@media only screen and (max-width: 500px) {
		.sell-table .input-qtePrCm .ant-input-number-group {
			width: 60%;
		}
		.purchase-table .input-qtePrCm .ant-input-number-group {
			width: 90%;
		}
	}
`
export const DetailOffreContainer = styled.div`
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`
