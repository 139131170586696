import React from 'react'

import { DashboardContainer } from './styles'
import HeaderTitle from '../../components/HeaderTitle'

function Dashboard() {
	return (
		<DashboardContainer>
			{/* <HeaderTitle title='Tableau de bord' withoutBreak /> */}
			<h1>Dashboard</h1>
		</DashboardContainer>
	)
}
export default Dashboard
