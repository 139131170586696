import { useQuery } from '@apollo/client'
import { Card, Col, Image, Row, Space, Tag, Typography } from 'antd'
import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import CustomSpin from '../../components/CustomSpin'
import images from '../../constants/images'
import { CustomButton } from '../../global/GlobalStyles'
import { getArticleById } from '../../redux/article/actions'
//import './style.css'
import { SingleArticleContainer } from './styles'
function SingleArticle() {
	const { _id } = useParams()
	const dispatch = useDispatch()
	const { loadingGetArticleById, articleById } = useSelector(
		(state) => ({
			articleById: state.articles.articleById,
			loadingGetArticleById: state.articles.loadingGetArticleById,
			...state.articles,
		}),
		shallowEqual
	)

	useEffect(() => {
		dispatch(getArticleById({ id: _id }))
	}, [_id])
	return (
		<SingleArticleContainer>
			{/* <Badge.Ribbon
				style={{ fontSize: '20px' }}
				text={
					<Typography.Text strong style={{ color: 'white' }}>
						PeuGeot 407
					</Typography.Text>
				}
				color='purple'
			> */}
			<Card
				className='card-product'
				headStyle={{ minHeight: '0', padding: '0' }}
				bodyStyle={{ padding: '0', minHeight: '70vh' }}
			>
				{loadingGetArticleById ? (
					<CustomSpin height={'50vh'} />
				) : (
					<Row justify='space-around' className='product'>
						<Col className='product__photo'>
							<div className='photo-container'>
								<div className='photo-main'>
									<div className='controls'>
										<Tag color='#f50'>
											<Space>
												<Typography.Text
													strong
													style={{ color: 'white' }}
												>
													REF:
												</Typography.Text>
												<Typography.Text
													strong
													copyable={{
														text: articleById?.ref,
														tooltips: [
															'Copier',
															'Copié',
														],
													}}
													style={{ color: 'white' }}
												>
													{articleById?.ref}
												</Typography.Text>
											</Space>
										</Tag>
										{/* <i className='material-icons'>
										favorite_border
									</i> */}
									</div>
									<img
										src={
											articleById?.img
												? articleById?.img
												: images.DEFAULT_ARTICLE_PHOTO
										}
										alt='green apple slice'
									/>
								</div>
								{/* <Row
									gutter={8}
									justify='center'
									className='photo-album'
								>
									<Col>
										<Image
											width={90}
											height={70}
											src='https://www.caaquebec.com/fileadmin/images/Sur_la_route/Conseil/485-Pieces-autos-expliquees-Calipers.jpg'
											alt='green apple'
										/>
									</Col>
									<Col>
										<Image
											width={90}
											height={70}
											src='https://www.caaquebec.com/fileadmin/images/Sur_la_route/Conseil/485-Pieces-autos-expliquees-Calipers.jpg'
											alt='green apple'
										/>
									</Col>
								</Row> */}
							</div>
						</Col>
						<Col className='product__info'>
							<div className='title'>
								<Tag
									color='green'
									style={{
										marginLeft: '0',
										fontWeight: 'bold',
										float: 'right',
										position: 'relative',
										top: '-10px',
										right: '-34px',
									}}
								>
									<Typography.Text
										style={{
											color: '#333',
											fontSize: '14px',
										}}
									>
										{articleById?.marque}
									</Typography.Text>
								</Tag>
								<h1>{articleById?.description}</h1>
								<span>REF: {articleById?.ref}</span>
							</div>
							{/* <div className='price'>
								<span>{articleById?.unitPrice}</span>
								TND
							</div> */}
							{/* <div className='variant'>
							<h3>SELECT A COLOR</h3>
							<ul>
								<li>
									<img
										src='https://res.cloudinary.com/john-mantas/image/upload/v1537302064/codepen/delicious-apples/green-apple2.png'
										alt='green apple'
									/>
								</li>
								<li>
									<img
										src='https://res.cloudinary.com/john-mantas/image/upload/v1537302752/codepen/delicious-apples/yellow-apple.png'
										alt='yellow apple'
									/>
								</li>
								<li>
									<img
										src='https://res.cloudinary.com/john-mantas/image/upload/v1537302427/codepen/delicious-apples/orange-apple.png'
										alt='orange apple'
									/>
								</li>
								<li>
									<img
										src='https://res.cloudinary.com/john-mantas/image/upload/v1537302285/codepen/delicious-apples/red-apple.png'
										alt='#ed3b57 apple'
									/>
								</li>
							</ul>
						</div> */}
							<div className='description'>
								{/* <h3>DESCRIPTION:</h3>
								<Typography.Paragraph>
									{articleById?.description}
								</Typography.Paragraph> */}
								{/* <ul>
								<li>Apples are nutricious</li>
								<li>Apples may be good for weight loss</li>
								<li>Apples may be good for bone health</li>
								<li>
									They're linked to a lowest risk of diabetes
								</li>
							</ul> */}
							</div>
							{/* <CustomButton
								size='large'
								colorText='#fff'
								bgColor='#333'
								borderRadius='7px'
								className='buy--btn'
							>
								ADD TO CART
							</CustomButton> */}
						</Col>
					</Row>
				)}
			</Card>
			{/* </Badge.Ribbon> */}
		</SingleArticleContainer>
	)
}

export default SingleArticle
