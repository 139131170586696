import React, { useEffect, useState } from 'react'
import {
	Form,
	Button,
	Space,
	Radio,
	InputNumber,
	AutoComplete,
	Empty,
	Tag,
	Input,
	Divider,
	Row,
	Col,
} from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { TYPE_OFFER } from '../../../constants/type_offer'
import {
	getArticlesByFields,
	resetArticlesAutoComplete,
} from '../../../redux/article/actions'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { findIndex, isEmpty } from 'lodash'
import { OfferFormContainer } from './styles'
import { STATUS } from '../../../constants/status'
import { ROLES } from '../../../constants/roles'
import { advanceSearch } from '../../../constants/fn'

const OfferForm = ({ form, disabled, setDisabled, type }) => {
	//const [type, setType] = useState(type)
	let [params, setParams] = useState({
		search: '',
		index: '',
	})
	const dispatch = useDispatch()
	const { loadingGetArticles, user, articlesAutoComplete } = useSelector(
		(state) => ({
			loadingGetArticles: state.articles.loadingGetArticles,
			user: state.auth?.user,
			articlesAutoComplete: state.articles.articlesAutoComplete,
		}),
		shallowEqual
	)
	const onSearch = (index) => (currentSearch) => {
		params = {
			search: currentSearch,
			index,
		}
		let fieldsToSearchRef = []
		setParams(params)
		disabled = { ...disabled, [params.index]: false }
		setDisabled(disabled)
		advanceSearch(currentSearch, ['ref'], fieldsToSearchRef, false)

		currentSearch && currentSearch?.length > 2
			? dispatch(
					getArticlesByFields(
						{
							fields: fieldsToSearchRef,
							operatorBetweenFields: 'or',
							options: { all: true, page: 1, limit: 3 },
							sort: { sortOrder: 'desc', sortField: 'createdAt' },
							byUserId: '',
						},
						true
					)
			  )
			: dispatch(resetArticlesAutoComplete())
	}

	const renderItem = (ref, refOrigin, key) => ({
		key,
		value: ref,
		label: (
			<div
				key={ref + refOrigin}
				style={{
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				{ref}
				<Tag color={'orange'}>
					<span>{refOrigin}</span>
				</Tag>
			</div>
		),
	})
	const replaceValueInLineOffers = async (ref) => {
		const fields = form.getFieldsValue()
		const { lineOffers } = fields
		const currentArticle = articlesAutoComplete?.entities?.find(
			(item) => item?.ref == ref
		)
		var index = findIndex(lineOffers, ['ref', ref])
		if (currentArticle) {
			lineOffers.map((line) => {
				if (line?.ref == ref) {
					line.marque = currentArticle.marque
					line.description = currentArticle.description
					line.status = user.roles.includes(ROLES.ADMIN)
						? STATUS.ACTIVE
						: STATUS.PROCESSING
					line.article = currentArticle?._id
				}
				return line
			})
			disabled = { ...disabled, [params.index]: true }

			setDisabled(disabled)
		}
		/* if (currentArticle) {
			lineOffers.map((line)=>{
				if(line?.ref==ref){
					line.marque= currentArticle.marque ;
					line.description= currentArticle.description ;
					line.status= user.roles.includes(ROLES.ADMIN)
					? STATUS.ACTIVE
					: STATUS.PROCESSING ;
					line.article= currentArticle?._id
				}
				return line
			})
			disabled = { ...disabled, [params.index]: true }

			setDisabled(disabled)
		} else {
			lineOffers.map((line)=>{
				if(line?.ref==ref){
					line.marque= currentArticle.marque ;
					line.description= currentArticle.description ;
					line.status= user.roles.includes(ROLES.ADMIN)
					? STATUS.ACTIVE
					: STATUS.PROCESSING ;
					line.article= currentArticle?._id
				}
				return line
			})
			lineOffers.splice(index, 1, {
				//...lineOffers[index],
				ref: ref,
				marque: '',
				description: '',
				status: user.roles.includes(ROLES.ADMIN)
					? STATUS.ACTIVE
					: STATUS.PROCESSING,
				article: null,
			})
			//setDisabled({ ...disabled, [currentIndex]: false })
		} */
	}
	return (
		<OfferFormContainer>
			<Form
				form={form}
				name='dynamic_form_nest_item'
				onFinish={() => alert('rr')}
				initialValues={{
					type: type,
				}}
				autoComplete='off'
				onFieldsChange={(changedFields, allFields) => {
					const fieldNameChanged = changedFields[0]
					const fieldChanged = fieldNameChanged?.name[2]
					if (fieldChanged == 'ref') {
						replaceValueInLineOffers(fieldNameChanged.value)
					}
				}}
			>
				<Form.Item name='type' label='Opération'>
					<Radio.Group
						size='large'
						onChange={(e) => {
							//setType(e.target.value)
						}}
					>
						<Radio value={type}>
							{type == TYPE_OFFER.SELL ? 'Vente' : 'Achat'}
						</Radio>
					</Radio.Group>
				</Form.Item>
				<Divider />
				<Form.List name='lineOffers'>
					{(fields, { add, remove }) => {
						return (
							<>
								{fields?.map(({ key, ...field }) => (
									<div key={key}>
										<Row
											justify='end'
											style={{ marginBottom: '1%' }}
										>
											<Col>
												<Button
													//type='dashed'
													danger
													onClick={() =>
														remove(field.name)
													}
													icon={
														<MinusCircleOutlined />
													}
												>
													Supprimer
												</Button>
											</Col>
										</Row>

										<Row
											gutter={[4, 4]}
											style={{
												width: '100%',
											}}
											wrap
										>
											<Col xs={12} sm={12} md={8}>
												<Form.Item
													{...field}
													label='Référence'
													name={[field.name, 'ref']}
													style={{
														width: '100%',
													}}
													rules={[
														/* {
														required: true,
														message:
															'Veuillez saisir référence!',
													},
													{
														message:
															'Référence doit être supérieur à 2 caractéres',
														min: 3,
													}, */
														({
															getFieldValue,
														}) => ({
															validator(
																rule,
																value
															) {
																var error = ''
																if (
																	params.search ==
																	''
																) {
																	error =
																		'Veuillez saisir référence!'
																	return Promise.reject(
																		error
																	)
																}
																if (
																	params
																		.search
																		?.length <
																		3 &&
																	params
																		.search
																		?.length !=
																		0
																) {
																	error =
																		'Référence doit être supérieur à 2 caractéres'
																	return Promise.reject(
																		error
																	)
																}

																return Promise.resolve()
															},
														}),
													]}
												>
													<AutoComplete
														dropdownMatchSelectWidth={
															280
														}
														options={
															params.index ==
																key &&
															articlesAutoComplete?.entities?.map(
																(
																	{
																		ref,
																		_id,
																		refOrigin,
																	},
																	key
																) =>
																	renderItem(
																		ref,
																		refOrigin,
																		_id
																	)
															)
														}
														onSelect={(
															data,
															options
														) => {
															params = {
																search: data,
																index: key,
															}
															setParams(params)
														}}
														onSearch={onSearch(key)}
														placeholder='input here'
														/* notFoundContent={
												articlesAutoComplete?.entities &&
												search?.length > 2 ? (
													<Empty
														imageStyle={{
															height: 30,
														}}
														description={
															<span>
																Aucun Article
															</span>
														}
													/>
												) : null
											} */
													/>
												</Form.Item>
											</Col>
											<Form.Item
												{...field}
												name={[field.name, 'article']}
											>
												<Input
													hidden
													disabled
													defaultValue={''}
												/>
											</Form.Item>
											<Form.Item
												{...field}
												name={[field.name, 'status']}
											>
												<Input
													hidden
													disabled
													defaultValue={
														user.roles.includes(
															ROLES.ADMIN
														)
															? STATUS.ACTIVE
															: STATUS.PROCESSING
													}
												/>
											</Form.Item>

											<Col xs={12} sm={12} md={8}>
												<Form.Item
													{...field}
													label='marque'
													name={[
														field.name,
														'marque',
													]}
													rules={[
														{
															required: true,
															message:
																'Veuillez saisir la marque',
														},
													]}
												>
													<Input
														disabled={disabled[key]}
													/>
												</Form.Item>
											</Col>
											<Col xs={12} sm={12} md={8}>
												<Form.Item
													{...field}
													label='Designation'
													name={[
														field.name,
														'description',
													]}
													rules={[
														{
															required: true,
															message:
																'Veuillez saisir designation',
														},
													]}
												>
													<Input.TextArea
														disabled={disabled[key]}
													/>
												</Form.Item>
											</Col>
											<Col xs={24}>
												<Row
													gutter={[4, 4]}
													align='middle'
													justify='center'
												>
													{type ==
														TYPE_OFFER.SELL && (
														<Col>
															<Form.Item
																{...field}
																label='PU.TTC'
																name={[
																	field.name,
																	'unitPrice',
																]}
																rules={[
																	{
																		required: true,
																		message:
																			'Veuillez saisir prix unitaire TTC!',
																	},
																]}
															>
																<InputNumber
																	min={0}
																	precision={
																		3
																	}
																	addonAfter='TND'
																/>
															</Form.Item>
														</Col>
													)}
													<Col>
														<Form.Item
															{...field}
															label={
																type ==
																TYPE_OFFER.SELL
																	? 'Qte Disponible'
																	: 'Qte Demandée'
															}
															name={[
																field.name,
																'quantity',
															]}
															rules={[
																{
																	required: true,
																	message:
																		'Veuillez saisir quantité!',
																},
															]}
														>
															<InputNumber
																precision={0}
																min={0}
															/>
														</Form.Item>
													</Col>
												</Row>
											</Col>

											<Divider />
										</Row>
									</div>
								))}

								<Form.Item>
									<Button
										type='dashed'
										onClick={() => {
											add()
											dispatch(
												resetArticlesAutoComplete()
											)
										}}
										block
										icon={<PlusOutlined />}
									>
										Ajouter
									</Button>
								</Form.Item>
							</>
						)
					}}
				</Form.List>
			</Form>
		</OfferFormContainer>
	)
}

export default OfferForm
