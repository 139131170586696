import React from 'react'
import { Provider } from 'react-redux'
import configureStore from '../redux/store/configureStore'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider } from 'styled-components'

import { theme } from '../constants/colors'
const { persistor, store } = configureStore()

const GlobalStore = (props) => {
	return (
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<PersistGate loading={null} persistor={persistor}>
					{props.children}
				</PersistGate>
			</ThemeProvider>
		</Provider>
	)
}
export default GlobalStore
