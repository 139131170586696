import { Descriptions, Divider, Typography } from 'antd'
import { isArray, keys } from 'lodash'
import React from 'react'
import { has } from 'lodash'

function Verification({ data }) {
	const titles = {
		user: 'Info Responsable',
		company: 'Info Société',
		speciality: 'Spécialité',
	}
	return (
		<div>
			{keys(data).map((title, key) => (
				<Descriptions
					bordered
					key={key}
					column={{ sm: 2, xs: 1 }}
					title={
						<Divider key={key}>
							<Typography.Text strong>
								{titles[title]}
							</Typography.Text>
						</Divider>
					}
					size='small'
				>
					{data &&
						keys(data[title]).map((label, index) =>
							label == 'company' ||
							label == 'user' ||
							label == 'roles' ||
							label == '_id' ? null : data[title][label] &&
							  isArray(data[title][label]) ? (
								<Descriptions.Item
									key={label}
									label={
										label == 'parts'
											? 'piéces'.toUpperCase()
											: label.toUpperCase()
									}
								>
									{data[title][label].toString()}
								</Descriptions.Item>
							) : (
								<Descriptions.Item
									key={label}
									label={label.toUpperCase()}
								>
									{data[title][label]}
								</Descriptions.Item>
							)
						)}
				</Descriptions>
			))}
		</div>
	)
}

export default Verification
