export const START_LOADING_OFFERS = 'START_LOADING_OFFERS'
export const FETCH_OFFERS_SUCCESS = 'FETCH_OFFERS_SUCCESS'
export const FETCH_OFFERS_ERROR = 'FETCH_OFFERS_ERROR'

export const START_LOADING_CREATE_OFFER = 'START_LOADING_CREATE_OFFER'
export const CREATE_OFFER_SUCCESS = 'CREATE_OFFER_SUCCESS'
export const CREATE_OFFER_ERROR = 'CREATE_OFFER_ERROR'

export const START_LOADING_OFFER_BY_ID = 'START_LOADING_OFFER_BY_ID'
export const OFFER_BY_ID_SUCCESS = 'OFFER_BY_ID_SUCCESS'
export const OFFER_BY_ID_ERROR = 'OFFER_BY_ID_ERROR'

export const START_LOADING_DELETE_OFFER = 'START_LOADING_DELETE_OFFER'
export const DELETE_OFFER_SUCCESS = 'DELETE_OFFER_SUCCESS'
export const DELETE_OFFER_ERROR = 'DELETE_OFFER_ERROR'

export const START_LOADING_UPDATE_OFFER = 'START_LOADING_UPDATE_OFFER'
export const UPDATE_OFFER_SUCCESS = 'UPDATE_OFFER_SUCCESS'
export const UPDATE_OFFER_ERROR = 'UPDATE_OFFER_ERROR'
