import { STATUS } from '../../constants/status'
import { TYPE_OFFER } from '../../constants/type_offer'
import { createLinesOrder } from '../lineOrder/actions'
import { createOrder } from '../order/actions'
import {
	START_LOADING_ADD_CART,
	ADD_CART_SUCCESS,
	ADD_CART_ERROR,
	START_LOADING_DELETE_CART,
	DELETE_CART_SUCCESS,
	DELETE_CART_ERROR,
	START_LOADING_UPDATE_CART,
	UPDATE_CART_SUCCESS,
	UPDATE_CART_ERROR,
} from './types'

/* *************** Add To  CART ************** */
export const addToCart =
	({ companyByUserId, ...lineOfferOrder }) =>
	async (dispatch) => {
		try {
			await dispatch({
				type: START_LOADING_ADD_CART,
			})

			await dispatch({
				type: ADD_CART_SUCCESS,
				payload: lineOfferOrder,
			})
		} catch (error) {
			await dispatch({
				type: ADD_CART_ERROR,
				payload: error.message,
			})
		}
	}

/* *************** UPDATE CART ************** */
export const updateCart = (newLineOfferOrder) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_UPDATE_CART,
		})
		dispatch({
			type: UPDATE_CART_SUCCESS,
			payload: newLineOfferOrder,
		})
	} catch (error) {
		dispatch({
			type: UPDATE_CART_ERROR,
			payload: error.message,
		})
	}
}
/* *************** DELETE CART ************** */
export const deleteCart = (_id) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_DELETE_CART,
		})
		dispatch({
			type: DELETE_CART_SUCCESS,
			payload: { _id },
		})
	} catch (error) {
		dispatch({
			type: DELETE_CART_ERROR,
			payload: error.message,
		})
	}
}
