import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Layouts from '../components/Layouts'
//import NotFound from '../components/not-found/404'
//import CustomResult from '../pages/Results'
import { routes } from './routes'

const Routes = () => {
	return (
		<Layouts>
			<Switch>
				{routes.map((val, key) => (
					<Route strict exact {...val} key={key} />
				))}
				{/* <Route component={() => <CustomResult status={'404'} />} /> */}
				<Redirect to='/' exact />
			</Switch>
		</Layouts>
	)
}
export default Routes
