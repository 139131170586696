export const START_LOADING_CHATS = 'START_LOADING_CHATS'
export const FETCH_CHATS_SUCCESS = 'FETCH_CHATS_SUCCESS'
export const FETCH_CHATS_ERROR = 'FETCH_CHATS_ERROR'

export const START_LOADING_CREATE_CHAT = 'START_LOADING_CREATE_CHAT'
export const CREATE_CHAT_SUCCESS = 'CREATE_CHAT_SUCCESS'
export const CREATE_CHAT_ERROR = 'CREATE_CHAT_ERROR'

export const START_LOADING_DELETE_CHAT = 'START_LOADING_DELETE_CHAT'
export const DELETE_CHAT_SUCCESS = 'DELETE_CHAT_SUCCESS'
export const DELETE_CHAT_ERROR = 'DELETE_CHAT_ERROR'

export const START_LOADING_UPDATE_CHATS = 'START_LOADING_UPDATE_CHATS'
export const UPDATE_CHATS_SUCCESS = 'UPDATE_CHATS_SUCCESS'
export const UPDATE_CHATS_ERROR = 'UPDATE_CHATS_ERROR'

export const START_LOADING_UPDATE_LIST_CHAT = 'START_LOADING_UPDATE_LIST_CHAT'
export const UPDATE_LIST_CHAT_SUCCESS = 'UPDATE_LIST_CHAT_SUCCESS'
export const UPDATE_LIST_CHAT_ERROR = 'UPDATE_LIST_CHAT_ERROR'

export const UPDATE_CURRENT_CONV_SUCCESS = 'UPDATE_CURRENT_CONV_SUCCESS'

export const UPDATE_CHAT_BOXES_SUCCESS = 'UPDATE_CHAT_BOXES_SUCCESS'
export const ADD_CHAT_BOXES_SUCCESS = 'ADD_CHAT_BOXES_SUCCESS'
export const REMOVE_CHAT_BOXES_SUCCESS = 'REMOVE_CHAT_BOXES_SUCCESS'
