import React, { useContext, useEffect, useState } from 'react'
import HeaderTitle from '../../components/HeaderTitle'
import { Tabs } from 'antd'
import { OffersContainer } from './styles'
import ListOffers from './components/ListOffers'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { STATUS } from '../../constants/status'
import { ROLES } from '../../constants/roles'
import Contexts from '../../context'
import { getLineOffersByFields } from '../../redux/lineOffer/actions'
import { isEmpty, has } from 'lodash'
import { useHistory } from 'react-router'
import { useParams } from 'react-router'
import { TYPE_OFFER } from '../../constants/type_offer'

function Advertisement({ typeOffer = TYPE_OFFER.SELL }) {
	const dispatch = useDispatch()
	const history = useHistory()
	const { param } = useParams()
	let { variables, setVariables } = useContext(Contexts.VariablesContext)
	const user = useSelector((state) => state.auth?.user, shallowEqual)
	const [mode, setMode] = useState()
	let [filteredInfo, setFilteredInfo] = useState(null)
	const [sortedInfo, setSortedInfo] = useState(null)
	const [searchAll, setSearchAll] = useState('')
	let [params, setParams] = useState({
		idCompany: '',
		status: STATUS.ACTIVE,
		currentKeyTab: param ? param : '1',
	})

	const { companyByUserId } = useSelector(
		(state) => ({
			companyByUserId: state.auth.companyByUserId,
		}),
		shallowEqual
	)
	function changeTab(key) {
		let { idCompany, status, currentKeyTab } = params
		currentKeyTab = key
		idCompany = key == 2 && companyByUserId?._id
		if (key == 2 || key == 3) {
			setMode('edit')
		}
		if (key == 1) {
			setMode('')
			idCompany = ''
		}
		if (key == 3) {
			status = STATUS.PROCESSING
			idCompany = user?.roles.includes(ROLES.ADMIN)
				? ''
				: companyByUserId?._id
		} else {
			status = STATUS.ACTIVE
		}
		params = { idCompany, status, currentKeyTab }
		variables.fields = []
		variables.anotherFields = []
		variables.globalFields = []
		variables.byUserId = idCompany ? idCompany : ''
		variables.byNotUserId =
			currentKeyTab == '1' && user?.roles?.includes(ROLES.PROF)
				? companyByUserId?._id
				: ''
		variables.byType = typeOffer
		setFilteredInfo(null)
		setSortedInfo(null)
		setSearchAll('')
		setVariables(variables)
		setParams(params)
		dispatch(
			getLineOffersByFields(
				{
					status,
					currentKeyTab,
				},
				variables
			)
		)
	}
	useEffect(() => {
		!isEmpty(params) &&
			has(variables, 'byUserId') &&
			dispatch(
				getLineOffersByFields(
					{
						status: params.status,
						currentKeyTab: params.currentKeyTab,
					},
					{ ...variables, byType: typeOffer }
				)
			)
	}, [variables])
	useEffect(() => {
		let { idCompany, currentKeyTab, status } = params
		variables = {
			fields: [],
			anotherFields: [],
			globalFields: [],
			operatorBetweenFields: 'or',
			operatorBetweenAnotherFields: 'or',
			operatorBetweenGlobalFields: 'or',
			options: { all: false, page: 1, limit: 10 },
			sort: { sortOrder: 'desc', sortField: 'updatedAt' },
			byStatus: status,
			byType: typeOffer,
			byUserId: idCompany ? idCompany : '',
			byNotUserId:
				currentKeyTab == '1' && user?.roles?.includes(ROLES.PROF)
					? companyByUserId?._id
					: '',
		}
		setVariables(variables)
	}, [])
	useEffect(() => {
		changeTab(param)
	}, [param])
	return (
		<OffersContainer>
			<Tabs
				onChange={(key) => {
					typeOffer == TYPE_OFFER.SELL
						? history.push('/dash/offres/' + key)
						: history.push('/dash/achats/' + key)
				}}
				activeKey={param ? param : '1'}
				type='card'
			>
				<Tabs.TabPane
					tab='Toutes'
					key='1'
					style={{ backgroundColor: '#d7d7d7' }}
				>
					<ListOffers
						mode={mode}
						currentKeyTab={'1'}
						status={STATUS.ACTIVE}
						variables={variables}
						setVariables={setVariables}
						searchAll={searchAll}
						setSearchAll={setSearchAll}
						filteredInfo={filteredInfo}
						setFilteredInfo={setFilteredInfo}
						sortedInfo={sortedInfo}
						setSortedInfo={setSortedInfo}
					/>
				</Tabs.TabPane>
				{user?.roles.includes(ROLES.PROF) && (
					<Tabs.TabPane tab='Validés' key='2'>
						<ListOffers
							mode={mode}
							currentKeyTab={'2'}
							status={STATUS.ACTIVE}
							variables={variables}
							setVariables={setVariables}
							searchAll={searchAll}
							setSearchAll={setSearchAll}
							filteredInfo={filteredInfo}
							setFilteredInfo={setFilteredInfo}
							sortedInfo={sortedInfo}
							setSortedInfo={setSortedInfo}
						/>
					</Tabs.TabPane>
				)}
				<Tabs.TabPane tab='En attente' key='3'>
					<ListOffers
						mode={mode}
						currentKeyTab={'3'}
						status={STATUS.PROCESSING}
						variables={variables}
						setVariables={setVariables}
						searchAll={searchAll}
						setSearchAll={setSearchAll}
						filteredInfo={filteredInfo}
						setFilteredInfo={setFilteredInfo}
						sortedInfo={sortedInfo}
						setSortedInfo={setSortedInfo}
					/>
				</Tabs.TabPane>
			</Tabs>
		</OffersContainer>
	)
}

export default Advertisement
