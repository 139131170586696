import {
	Form,
	Modal,
	Typography,
	Row,
	Col,
	Input,
	Divider,
	InputNumber,
	Select,
	Badge,
	Button,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { UserDetailContainer } from './styles'
import { UserOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons'
import { updateUser } from '../../redux/user/actions'
import {
	getCompanyByIdUser,
	updateCompanyById,
} from '../../redux/company/actions'
import { updateProfile } from '../../redux/auth/actions'
import { marques } from '../../constants/variables'
import CustomtreeSelect from '../../components/CostumTreeSelect'
import { CustomButton } from '../../global/GlobalStyles'
import { useTheme } from 'styled-components'
import { withDigits } from '../../constants/fn'
import CustomSpin from '../../components/CustomSpin'
import { ROLES } from '../../constants/roles'
import { STATUS } from '../../constants/status'
import * as generator from 'generate-password'
import { isEmpty } from 'lodash'

const formItemLayout = {
	labelCol: { span: 16 },
	wrapperCol: { span: 24 },
}

function UserDetail({ userToEdit, setModalUserDetail, modalUserDetail }) {
	const dispatch = useDispatch()
	const [form] = Form.useForm()
	const [copyPassword, setCopyPassword] = useState('')
	const theme = useTheme()
	const status = [
		{ value: STATUS.ACTIVE, type: 'success', text: 'activé' },
		{ value: STATUS.INACTIVE, type: 'warning', text: 'désactivé' },
		{ value: STATUS.PROCESSING, type: 'processing', text: 'en attente' },
		{ value: STATUS.REFUSED, type: 'error', text: 'refusé' },
	]
	const {
		user,
		auth,
		companyByUserId,
		companyToEdit,
		loadingGetUserByID,
		loadingUpdateUser,
		loadingGetCompanyByUserId,
		loadingUpdateCompany,
	} = useSelector(
		(state) => ({
			auth: state.auth,
			user: userToEdit ? userToEdit : state.auth?.user,
			companyByUserId: state.auth?.companyByUserId,
			companyToEdit: state.companies.companyToEdit,
			loadingUpdateUser: state.users.loadingUpdateUser,
			loadingUpdateCompany: state.companies.loadingUpdateCompany,
			loadingGetCompanyByUserId:
				state.companies.loadingGetCompanyByUserId,
			loadingGetUserByID: state.users.loadingGetUserByID,
		}),
		shallowEqual
	)
	const [selectedValues, setSelectedValues] = useState(
		companyToEdit ? companyToEdit?.marques : companyByUserId?.marques
	)
	const handleOk = () => {
		form.submit()
		form.validateFields()
			.then((values) => {
				const {
					firstName,
					lastName,
					email,
					phone,
					codeClient,
					emailCompany,
					status,
					...company
				} = values
				console.log({ values })
				let dataUser = {
					_id: user?._id,
					firstName,
					lastName,
					email,
					phone,
					codeClient,
					status,
				}
				if (values?.password)
					dataUser = { ...dataUser, password: values?.password }

				dispatch(updateUser(dataUser))
				dispatch(
					updateCompanyById({
						...company,
						_id: !isEmpty(companyToEdit)
							? companyToEdit._id
							: companyByUserId._id,
						email: emailCompany,
					})
				)
				userToEdit ||
					dispatch(
						updateProfile({
							user: {
								firstName,
								lastName,
								email,
								phone,
								codeClient,
								fullName: `${firstName} ${lastName}`,
							},
							companyByUserId: {
								...company,
								email: emailCompany,
							},
						})
					)
				setModalUserDetail(false)
			})
			.catch((errorInfo) => {
				console.log({ errorInfo })
			})
	}
	useEffect(() => {
		form.setFieldsValue({
			marques: selectedValues,
		})
	}, [selectedValues])
	useEffect(() => {
		userToEdit && dispatch(getCompanyByIdUser({ user: user?._id }, true))
	}, [userToEdit, modalUserDetail])
	useEffect(() => {
		let company = userToEdit ? companyToEdit : companyByUserId
		form.setFieldsValue({
			...company,
			...user,
			emailCompany: company?.email,
		})
		modalUserDetail || form.resetFields()
	}, [companyToEdit, user, modalUserDetail])
	/*  */
	return (
		<UserDetailContainer>
			<Modal
				bodyStyle={{ overflowY: 'scroll', padding: '0.5% 2%' }}
				width={'90%'}
				title={
					<div
						style={{
							textAlign: 'center',
						}}
					>
						<Typography.Title level={4}>
							Mon Profile
						</Typography.Title>
					</div>
				}
				confirmLoading={loadingUpdateUser || loadingUpdateCompany}
				cancelText='Quitter'
				okText='Sauvegarder'
				onCancel={() => setModalUserDetail(false)}
				//onOk={handleOk}
				visible={modalUserDetail}
				footer={[
					<CustomButton
						size='large'
						colorText={theme.white}
						bgColor={'#ff4d4f'}
						borderRadius='7px'
						className='buy--btn'
						onClick={() => setModalUserDetail(false)}
					>
						<strong>Quitter</strong>
					</CustomButton>,
					<CustomButton
						loading={loadingUpdateUser || loadingUpdateCompany}
						size='large'
						colorText={theme.white}
						bgColor={theme.secondary}
						borderRadius='7px'
						className='buy--btn'
						onClick={handleOk}
					>
						<strong>Modifier</strong>
					</CustomButton>,
				]}
			>
				{loadingGetUserByID || loadingGetCompanyByUserId ? (
					<CustomSpin height={'20vh'} />
				) : (
					<>
						<Divider style={{ margin: '0.2em 0' }}>Société</Divider>
						<Form
							form={form}
							name='validate_other'
							{...formItemLayout}
							style={{
								width: '100%',
							}}
							layout={'vertical'}
							/* initialValues={{
						...user,
						 userToEdit ? {...companyByUserId} : ,
						emailCompany: companyByUserId?.email,
					}} */
							//onFinish={onFinish}
						>
							<Row gutter={[4, 0]} wrap>
								<>
									<Col sm={12} xs={24}>
										<Form.Item
											style={{
												marginBottom: '0.3em',
											}}
											label='Nom / Raison Social'
											name='title'
											rules={[
												{
													required: true,
													message:
														'Veuillez saisir Nom',
												},
											]}
										>
											<Input
												autoComplete='off'
												size='large'
											/>
										</Form.Item>
									</Col>
									<Col sm={12} xs={24}>
										<Form.Item
											style={{
												marginBottom: '0.3em',
											}}
											label='MatFiscal'
											name='matFiscal'
											rules={[
												/* {
											required: true,
											message:
												'Veuillez saisir matFiscal!',
										}, */
												{
													message:
														'Matricule Fiscal doit être supérieur à 6 caractéres',
													min: 7,
												},
											]}
										>
											<Input
												autoComplete='off'
												size='large'
											/>
										</Form.Item>
									</Col>
									<Col sm={12} xs={24}>
										<Form.Item
											style={{
												marginBottom: '0.3em',
											}}
											label='Tel'
											name='phoneNumber'
											rules={[
												{
													required: true,
													message:
														'Veuillez saisir reference!',
												},
											]}
										>
											<Input
												autoComplete='off'
												size='large'
											/>
										</Form.Item>
									</Col>
									<Col sm={12} xs={24}>
										<Form.Item
											style={{
												marginBottom: '0.3em',
											}}
											name='emailCompany'
											label='Email'
											rules={[
												{
													type: 'email',
												},
											]}
										>
											<Input
												autoComplete='off'
												size='large'
												prefix={
													<MailOutlined className='site-form-item-icon' />
												}
												placeholder='Email'
											/>
										</Form.Item>
									</Col>
									<Col sm={12} xs={24}>
										<Form.Item
											style={{
												marginBottom: '0.3em',
											}}
											label='Adresse'
											name='address'
											/* rules={[
										{
											required: true,
											message: 'Veuillez saisir adresse!',
										},
									]} */
										>
											<Input
												autoComplete='off'
												size='large'
											/>
										</Form.Item>
									</Col>
									<Col sm={12} xs={24}>
										<Form.Item
											label='Marques'
											name='marques'
											rules={[
												{
													required: true,
													message:
														'Veuillez saisir les marques!',
												},
											]}
										>
											<CustomtreeSelect
												selectedValues={selectedValues}
												setSelectedValues={
													setSelectedValues
												}
												data={marques}
											/>
										</Form.Item>
									</Col>
								</>
							</Row>
							<Divider style={{ margin: '0.2em 0' }}>
								Personnel
							</Divider>
							<Row gutter={[4, 4]} wrap>
								<Col sm={12} xs={24}>
									<Form.Item
										style={{
											marginBottom: '0.3em',
										}}
										name='firstName'
										label='Nom'
										/* rules={[
									{
										required: true,
										message: 'Veuillez saisir votre nom',
										type: 'string',
									},
								]} */
									>
										<Input
											autoComplete='off'
											size='large'
											prefix={
												<UserOutlined className='site-form-item-icon' />
											}
											placeholder='Nom'
										/>
									</Form.Item>
								</Col>
								<Col sm={12} xs={24}>
									<Form.Item
										name='codeClient'
										label='Code-Client'
									>
										<InputNumber
											disabled
											size='large'
											style={{ width: '100%' }}
											placeholder='Code-Client'
											formatter={(value) =>
												`${withDigits(value, 5)}`
											}
											parser={(value) => parseInt(value)}
										/>
									</Form.Item>
								</Col>
								{/* <Col sm={12} xs={24}>
							<Form.Item
								style={{
									marginBottom: '0.3em',
								}}
								name='lastName'
								label='Prénom'
								rules={[
									{
										required: true,
										message: 'Veuillez saisir votre prénom',
										type: 'string',
									},
								]}
							>
								<Input
								autoComplete='off'
									size='large'
									prefix={
										<UserOutlined className='site-form-item-icon' />
									}
									placeholder='Prénom'
								/>
							</Form.Item>
						</Col> */}
								<Col sm={12} xs={24}>
									<Form.Item
										style={{
											marginBottom: '0.3em',
										}}
										name='email'
										label='Email'
										rules={[
											{
												type: 'email',
											},
										]}
									>
										<Input
											autoComplete='off'
											size='large'
											prefix={
												<MailOutlined className='site-form-item-icon' />
											}
											placeholder='Email'
										/>
									</Form.Item>
								</Col>
								<Col sm={12} xs={24}>
									<Form.Item
										style={{
											marginBottom: '0.3em',
										}}
										name='phone'
										label='Tel'
										/* rules={[
									{
										required: true,
										message: 'Veuillez saisir votre tel',
										type: 'string',
									},
								]} */
									>
										<Input
											autoComplete='off'
											size='large'
											prefix={
												<PhoneOutlined className='site-form-item-icon' />
											}
											placeholder='Téléphone'
										/>
									</Form.Item>
								</Col>
								{console.log({ userToEdit, user })}
								{auth?.user?.roles?.includes(ROLES.ADMIN) && (
									<>
										<Col sm={12} xs={24}>
											<Form.Item
												label='Status'
												name='status'
												rules={[
													{
														required: true,
														message:
															'Veuillez saisir status',
														type: 'string',
													},
												]}
											>
												<Select size='large'>
													{status.map(
														(
															{
																type,
																text,
																value,
															},
															index
														) => (
															<Select.Option
																key={index}
																value={value}
															>
																<Badge
																	status={
																		type
																	}
																/>
																{text}
															</Select.Option>
														)
													)}
												</Select>
											</Form.Item>
										</Col>
										{userToEdit && (
											<Col sm={12} xs={24}>
												<Input.Group compact>
													<Form.Item
														label={'Mot de passe'}
														style={{
															width: 'calc(100% - 35%)',
														}}
														name='password'
														type='string'
														rules={[
															{
																min: 6,
																message:
																	'Le mot de passe doit avoir au moins 6 charactères!',
															},
														]}
													>
														<Input.Password
															autoComplete='new-password'
															size='large'
															//value={passGenerate}
															prefix={
																<>
																	<Typography.Text
																		copyable={{
																			text: copyPassword,
																			tooltips:
																				[
																					'Copier',
																					'Copié',
																				],
																		}}
																	></Typography.Text>
																</>
															}
															placeholder='Mot de passe'
														/>
													</Form.Item>
													<Button
														style={{
															width: '35%',
															verticalAlign:
																'bottom',
															marginBottom: '1%',
														}}
														size='large'
														type='primary'
														onClick={() => {
															const password =
																generator.generate(
																	{
																		length: 6,
																		numbers: true,
																	}
																)
															setCopyPassword(
																password
															)

															form.setFieldsValue(
																{
																	password:
																		password,
																}
															)
														}}
													>
														generate
													</Button>
												</Input.Group>
											</Col>
										)}
									</>
								)}
							</Row>
						</Form>
					</>
				)}
			</Modal>
		</UserDetailContainer>
	)
}

export default UserDetail
