import styled from 'styled-components'

export const OffersContainer = styled.div`
	.actions-btn {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
	.actions-btn > button {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
	.ant-typography-copy svg {
		fill: ${(props) => props.theme.copyColor};
	}
	.table-article {
		padding: 1rem;
		background-color: #fff;
	}
`
