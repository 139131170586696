import React from 'react'
import { Empty, List } from 'antd'
import HeaderTitle from '../../components/HeaderTitle'
import { NotificationImportantOutlined } from '@material-ui/icons'
import { shallowEqual, useSelector } from 'react-redux'
import { ROLES } from '../../constants/roles'
import {
	NOTIFICATIONS_CLIENT_URL,
	NOTIFICATIONS_URL,
} from '../../constants/paths'
import { Link } from 'react-router-dom'

function Notifications() {
	const user = useSelector((state) => state.auth?.user)
	const { loadingGetNotifications, ...notifs } = useSelector(
		(state) => ({
			loadingGetNotifications: state.auth.loadingGetNotifications,
			...state.notifs,
		}),
		shallowEqual
	)
	return (
		<>
			{/* <HeaderTitle title='Notications' withoutBreak /> */}
			<List
				bordered
				style={{
					margin: '1rem',
					backgroundColor: 'white',
				}}
				locale={{
					emptyText: (
						<Empty
							imageStyle={{
								height: 100,
							}}
							description={<span>Aucun Notications</span>}
						/>
					),
				}}
				itemLayout='horizontal'
				dataSource={notifs?.entities}
				renderItem={(item) => (
					<List.Item>
						<List.Item.Meta
							key={item._id}
							avatar={
								<NotificationImportantOutlined
									style={{ color: '#ff4d4f' }}
								/>
							}
							title={
								<Link
									to={
										user?.roles?.includes(ROLES.CLIENT)
											? NOTIFICATIONS_CLIENT_URL
											: NOTIFICATIONS_URL
									}
								>
									{item.message}
								</Link>
							}
							//description='description description description'
						/>
					</List.Item>
				)}
			/>
		</>
	)
}

export default Notifications
