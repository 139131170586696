import styled from 'styled-components'

export const HomeContainer = styled.div`
	padding: 2%;
	padding-top: 0;
	.ant-list-pagination {
		display: flex;
		justify-content: center;
		align-items: center;
	}
`
