import { concat, merge } from 'lodash'
import { initialStatePagination } from '../../constants/variables'
import {
	FETCH_USERS_SUCCESS,
	FETCH_USERS_ERROR,
	CREATE_USER_SUCCESS,
	CREATE_USER_ERROR,
	CREATE_PROF_SUCCESS,
	CREATE_PROF_ERROR,
	UPDATE_USER_SUCCESS,
	UPDATE_USER_ERROR,
	DELETE_USER_SUCCESS,
	DELETE_USER_ERROR,
	START_LOADING_USERS,
	START_LOADING_CREATE_USER,
	START_LOADING_CREATE_PROF,
	START_LOADING_UPDATE_USER,
	START_LOADING_DELETE_USER,
	START_LOADING_USER_BY_ID,
	USER_BY_ID_SUCCESS,
	USER_BY_ID_ERROR,
} from './types'
const initialState = merge(
	{
		userById: {},
		loadingGetUsers: false,
		loadingCreateUser: false,
		loadingCreateProf: false,
		loadingDeleteUser: false,
		loadingUpdateUser: false,
		error: '',
	},
	initialStatePagination
)

export default function userReducer(state = initialState, action) {
	switch (action.type) {
		/* ****************** GET USERS ************************ */
		case START_LOADING_USERS:
			return {
				...state,
				loadingGetUsers: true,
			}
		case FETCH_USERS_SUCCESS:
			return {
				...state,
				...action.payload,
				loadingGetUsers: false,
				error: '',
			}
		case FETCH_USERS_ERROR:
			return {
				...state,
				loadingGetUsers: false,
				error: action.payload,
			}

		/* ****************** GET USER BY ID ************************ */
		case START_LOADING_USER_BY_ID:
			return {
				...state,
				loadingGetUserByID: true,
			}
		case USER_BY_ID_SUCCESS:
			return {
				...state,
				userById: action.payload,
				loadingGetUserByID: false,
				error: '',
			}
		case USER_BY_ID_ERROR:
			return {
				...state,
				loadingGetUserByID: false,
				error: action.payload,
			}
		/* ****************** CREATE USER ************************ */
		case START_LOADING_CREATE_USER:
			return {
				...state,
				loadingCreateUser: true,
			}
		case CREATE_USER_SUCCESS:
			return {
				...state,
				entities: [action.payload, ...state.entities],
				loadingCreateUser: false,
				error: '',
			}
		case CREATE_USER_ERROR:
			return {
				...state,
				loadingCreateUser: false,
				error: action.payload,
			}
		/* ****************** CREATE PROF ************************ */
		case START_LOADING_CREATE_PROF:
			return {
				...state,
				loadingCreateProf: true,
			}
		case CREATE_PROF_SUCCESS:
			return {
				...state,
				entities: [action.payload, ...state.entities],
				loadingCreateProf: false,
				error: '',
			}
		case CREATE_PROF_ERROR:
			return {
				...state,
				loadingCreateProf: false,
				error: action.payload,
			}
		/* ****************** UPDATE USER ************************ */
		case START_LOADING_UPDATE_USER:
			return {
				...state,
				loadingUpdateUser: true,
			}
		case UPDATE_USER_SUCCESS:
			return {
				...state,
				entities: [
					...state.entities.map((user) => {
						if (user._id === action.payload._id)
							return action.payload
						else return user
					}),
				],
				loadingUpdateUser: false,
				error: '',
			}
		case UPDATE_USER_ERROR:
			return {
				...state,
				loadingUpdateUser: false,
				error: action.payload,
			}
		/* ****************** DELETE USER ************************ */
		case START_LOADING_DELETE_USER:
			return {
				...state,
				loadingDeleteUser: true,
			}
		case DELETE_USER_SUCCESS:
			return {
				...state,
				loadingDeleteUser: false,
				entities: [
					...state.entities.filter(
						(user) => user._id !== action.payload._id
					),
				],
				error: '',
			}
		case DELETE_USER_ERROR:
			return {
				...state,
				loadingDeleteUser: false,
				error: action.payload,
			}
		default:
			return state
	}
}
