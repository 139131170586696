import { has } from 'lodash'
import React from 'react'
import { TYPE_OFFER } from '../../constants/type_offer'
import {
	Button,
	Col,
	Descriptions,
	Form,
	Input,
	InputNumber,
	Row,
	Tooltip,
} from 'antd'
import { CheckOutlined } from '@material-ui/icons'
import { PrimaryButton } from '../../global/GlobalStyles'
function RenderInputEditListOffers({
	type = 'text',
	propsForm = {},
	save,
	toggleEdit,
	inputRef,
	record,
	qtePriceCommandes,
}) {
	const addonAfter = (toggleEdit, save, record = {}) => (
		<>
			<Row gutter={[4, 4]} justify='center' align='middle' wrap={false}>
				{record?.type == TYPE_OFFER.PURCHASE ? <Col>TND</Col> : ''}
				<Col style={{ display: 'flex' }} onClick={save}>
					<Tooltip
						title={record?.reserved ? 'Modifier' : 'Comfirmer'}
					>
						<PrimaryButton
							className='group-addon'
							size='small'
							style={{
								//backgroundColor: '#08e708',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
							icon={
								<CheckOutlined
									fontSize={'small'}
									htmlColor='#fff'
								/>
							}
						/>
					</Tooltip>
				</Col>
			</Row>
		</>
	)
	return (
		<Row align='middle' justify='space-between' wrap={false}>
			{type == 'number' ? (
				<Form.Item
					style={{
						margin: 0,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
					/* label={
						record?.type == TYPE_OFFER.PURCHASE
							? 'Prix à proposer'
							: 'Qte à demander'
					} */
					{...propsForm}
				>
					<InputNumber
						ref={inputRef}
						style={{
							width:
								record?.type == TYPE_OFFER.PURCHASE ? 170 : 140,
						}}
						precision={record?.type == TYPE_OFFER.PURCHASE ? 3 : 0}
						/* className={
							has(qtePriceCommandes, record?._id)
								? qtePriceCommandes[record?._id] > 0
									? record?.type == TYPE_OFFER.PURCHASE
										? 'input-purchase'
										: 'input-sell'
									: ''
								: ''
						} */
						onPressEnter={save}
						defaultValue={
							has(qtePriceCommandes, record?._id)
								? qtePriceCommandes[record?._id]
								: 1
						}
						//onBlur={save}
						keyboard={true}
						//addonBefore='+'
						addonAfter={addonAfter(toggleEdit, save, record)}
						min={1}
					/>
				</Form.Item>
			) : (
				<Form.Item
					style={{
						margin: 0,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
					{...propsForm}
				>
					<Input
						ref={inputRef}
						onPressEnter={save}
						//onBlur={save}
						keyboard={true}
						//addonBefore='+'
						addonAfter={addonAfter(toggleEdit, save, record)}
						defaultValue={1}
						min={1}
					/>
				</Form.Item>
			)}
		</Row>
	)
}

export default RenderInputEditListOffers
