import styled from 'styled-components'
export const GlobalContainer = styled.div`
	overflow: hidden;
	main {
		background-color: #fff;
		overflow: auto;
	}
	.trigger {
		padding: 0 24px;
		font-size: 18px;
		line-height: 64px;
		cursor: pointer;
		transition: color 0.3s;
	}

	.trigger:hover {
		color: #1890ff;
	}

	.logo {
		/* height: 32px;
		margin: 16px; */
	}

	.site-layout .site-layout-background {
		//background-color: #c9bfbf;
	}
	.ant-menu-inline .ant-menu-item::after {
		border-right: none !important;
	}
	@media only screen and (max-width: 670px) {
		.ant-table-filter-column,
		.ant-table-column-sorters {
			justify-content: center;
			flex-direction: column;
			align-items: center;
		}
	}
	.ant-table-filter-trigger {
		margin: 0;
	}
	@media only screen and (max-width: 850px) {
		.ant-table-column-sorters {
			justify-content: center;
			flex-direction: column;
			align-items: center;
		}
	}

	/* 	.input-purchase input {
		//background-color: #efbb4c;
		background-color: ${(props) => props.theme.sellTag};
		font-weight: 600;
	}
	.input-sell input {
		background-color: ${(props) => props.theme.purchaseTag};
		//background-color: #4a842e;
		color: ghostwhite;
		font-weight: 600;
	} */
`
