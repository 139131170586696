import React, { useState } from 'react'
import { TreeSelect, Grid } from 'antd'
import './index.css'
const CustomtreeSelect = ({ data, selectedValues, setSelectedValues }) => {
	const [searchValue, setSearchValue] = useState('')
	const allIds = data.map(({ value }) => value)
	const { useBreakpoint } = Grid
	const { lg } = useBreakpoint()
	return (
		<TreeSelect
			allowClear={true}
			size='large'
			placeholder='Marques'
			treeCheckable={true}
			showCheckedStrategy={TreeSelect.SHOW_CHILD}
			onChange={(ids) => setSelectedValues(ids)}
			value={selectedValues}
			onSearch={(searchValue) => setSearchValue(searchValue)}
			maxTagCount={lg ? 4 : 2}
			maxTagPlaceholder={(omittedValues) =>
				`+ ${omittedValues.length} Marques ...`
			}
			treeData={[
				{
					title:
						selectedValues?.length === data?.length ? (
							<span
								onClick={() => setSelectedValues([])}
								style={{
									display: 'inline-block',
									color: '#286FBE',
									cursor: 'pointer',
								}}
							>
								Désélectionner tout
							</span>
						) : (
							<span
								onClick={() => setSelectedValues(allIds)}
								style={{
									display: 'inline-block',
									color: '#286FBE',
									cursor: 'pointer',
								}}
							>
								Sélectionner tout
							</span>
						),
					value: 'xxx',
					disableCheckbox: true,
					disabled: true,
				},
				...data,
			]}
		/>
	)
}

export default CustomtreeSelect
