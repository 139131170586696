import { gql } from '@apollo/client'
export const OFFERS_BY_FIELDS_QUERY = gql`
	query getOffersByFields(
		$fields: [FieldInput!]!
		$operatorBetweenFields: String
		$options: Options
		$sort: Sort
		$byUserId: String!
	) {
		findOffersByField(
			fields: $fields
			operatorBetweenFields: $operatorBetweenFields
			options: $options
			sort: $sort
			byUserId: $byUserId
		) {
			entities {
				_id
				numRef
				type
				marque
				ref
				description
			}
			totalCount
			totalPages
			page
			limit
			hasNextPage
			hasPreviousPage
		}
	}
`

export const OFFERS_BY_FIELDS_MORE_QUERY = gql`
	query getOffersByFieldsMore(
		$fields: [FieldInput!]!
		$operatorBetweenFields: String
		$options: Options
		$sort: Sort
		$byUserId: String!
	) {
		findOffersByFieldMore(
			fields: $fields
			operatorBetweenFields: $operatorBetweenFields
			options: $options
			sort: $sort
			byUserId: $byUserId
		) {
			entities {
				_id
				title
				numRef
				type
				company {
					title
					matFiscal
				}
			}
			totalCount
			totalPages
			page
			limit
			hasNextPage
			hasPreviousPage
		}
	}
`
export const OFFER_BY_ID_QUERY = gql`
	query getOfferById($id: String!) {
		getOfferById(id: $id) {
			_id
			ref
			marque
			description
			img
			company {
				title
				matFiscal
			}
		}
	}
`

export const CREATE_OFFER_MUTATION = gql`
	mutation createOffer(
		$title: String
		$type: TypeOfferOrder!
		$company: String!
	) {
		createOffer(input: { title: $title, type: $type, company: $company }) {
			_id
			title
			numRef
			type
			company {
				title
				matFiscal
			}
		}
	}
`

export const DELETE_OFFER_BY_ID_MUTATION = gql`
	mutation deleteOffer($_id: String!) {
		deleteOffer(input: { _id: $_id })
	}
`

export const OFFERS_QUERY = gql`
	query getOffers {
		findAllOffers {
			_id
			title
			numRef
			type
			company {
				title
				matFiscal
			}
		}
	}
`

export const UPLOAD_MULTIPLE_OFFERS_MUTATION = gql`
	mutation uploadMultipleOffers($file: Upload!) {
		uploadMultipleOffers(file: $file)
	}
`
