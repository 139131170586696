import { Button, Layout } from 'antd'

import styled from 'styled-components'

const { Header: Head, Content, Footer } = Layout

export const CustomHeader = styled(Head)`
	background-color: ${(props) => props.theme.secondary};
	padding: 0;
	height: 4.5em;
	.ant-menu-submenu > .ant-menu {
		background-color: red;
		border-radius: 4px;
	}
	.ant-menu {
		background: transparent;
	}
	.menu-btn {
		margin-left: auto;
	}
	/* div {
		align-items: center;
	} */
	.badge-notif,
	.badge-cart {
		vertical-align: middle;
	}
`
export const CustomFooter = styled(Footer)`
	display: flex;
	justify-content: center;

	background-color: ${(props) => props.theme.dark};
`
export const HamburgerButton = styled(Button)`
	display: none;
	@media only screen and (max-width: 649px) {
		display: block;
	}
`
export const CustomContent = styled(Content)`
	background-color: ${(props) => props.theme.primary};
`

export const CustomLayout = styled(Layout)`
	background-color: ${(props) => props.theme.primary};
`

export const Container = styled.div`
	//padding: 1rem;
	justify-content: center;
	height: inherit;
	//background-color: ${(props) => props.theme.gray_1};
	.ant-card-bordered {
		border: 1px solid #bed9f5;
	}
	.ant-card-head {
		border-bottom: 1px solid #bed9f5;
	}

	.list-groups {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
	.list-groups > div {
		cursor: pointer;
		width: 300px;
		margin-right: 8px;
		margin-bottom: 8px;
		text-align: center;
	}
`
