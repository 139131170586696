import React, { useEffect, useState } from 'react'
import CustomTable from '../../../components/CustomTable'
import {
	AutoComplete,
	Button,
	Col,
	Form,
	Grid,
	Image,
	Input,
	InputNumber,
	Modal,
	Row,
	Space,
	Tag,
	Tooltip,
	Typography,
	Upload,
	Empty,
	message,
	Badge,
} from 'antd'
import { CustomButton, PrimaryButton } from '../../../global/GlobalStyles'
import { AddCircleOutline, CloudDownloadOutlined } from '@material-ui/icons'
import { UploadOutlined, SearchOutlined } from '@ant-design/icons'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import Paragraph from 'antd/lib/typography/Paragraph'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import ArticleForm from '../../../components/Forms/ArticleForm'
import {
	advanceSearch,
	advanceWord,
	withHighlighter,
} from '../../../constants/fn'
import images from '../../../constants/images'

import {
	createArticles,
	deleteArticle,
	deleteArticles,
	getArticlesByFields,
	resetArticlesAutoComplete,
	updateArticle,
} from '../../../redux/article/actions'
import { UPLOAD_IMAGE_MUTATION } from '../../../queries/upload'
import { UPLOAD_MULTIPLE_ARTICLES_MUTATION } from '../../../queries/article'
import { isEmpty } from 'lodash'
import { statusList } from '../../../constants/variables'
import { ROLES } from '../../../constants/roles'
import { useTheme } from 'styled-components'
import SearchBar from '../../../components/SearchBar'

function ListArticles({ _id, companyByUserId }) {
	const dispatch = useDispatch()
	const theme = useTheme()
	const [uploadImage] = useMutation(UPLOAD_IMAGE_MUTATION)
	const [uploadExcel, { loading: loadingUpload }] = useMutation(
		UPLOAD_MULTIPLE_ARTICLES_MUTATION
	)
	const [searchAll, setSearchAll] = useState('')
	const [marque, setMarque] = useState('')
	let [currentPage, setCurrentPage] = useState(1)
	const [ref, setRef] = useState('')
	const [search, setSearch] = useState()
	const [description, setDescription] = useState('')
	const [file, setFile] = useState('')
	const { useBreakpoint } = Grid
	const { md, xs } = useBreakpoint()
	let [filteredInfo, setFilteredInfo] = useState(null)
	const [sortedInfo, setSortedInfo] = useState(null)
	const [searchText, setSearchText] = useState('')
	const [searchedColumn, setSearchedColumn] = useState('')

	const [isModalVisible, setIsModalVisible] = useState(false)
	let [variables, setVariables] = useState({})
	const [form] = Form.useForm()

	const {
		user,
		articlesAutoComplete,
		loadingGetArticles,
		loadingCreateArticle,
		loadingDeleteArticles,
		loadingUpdateArticle,
		...articles
	} = useSelector((state) => ({
		user: state.auth?.user,
		articlesAutoComplete: state.articles.articlesAutoComplete,
		loadingGetArticles: state.articles.loadingGetArticles,
		loadingCreateArticle: state.articles.loadingCreateArticle,
		loadingDeleteArticles: state.articles.loadingDeleteArticles,
		loadingUpdateArticle: state.articles.loadingUpdateArticle,
		...state.articles,
	}))

	const showModal = () => {
		form.resetFields()
		setIsModalVisible(true)
	}
	useEffect(() => {
		dispatch(getArticlesByFields())
	}, [_id])

	const handleOk = async () => {
		form.submit()
		form.validateFields()
			.then((values) => {
				const company = companyByUserId?._id
				const newArticles = []
				values?.newArticles.map((article) => {
					/* let file = article?.file
						? article?.file?.file?.originFileObj
						: null */
					newArticles.push({
						...article,
						//file,
						company,
					})
				})
				dispatch(createArticles(newArticles))
				/* uploadImage({
					variables: { file: img.originFileObj },
				}).then((data) => {
					dispatch(
						createArticles({
							marque,
							ref,
							description,
							company,
							img: 'https://www.caaquebec.com/fileadmin/images/Sur_la_route/Conseil/485-Pieces-autos-expliquees-Calipers.jpg',
						})
					)
				}) */
				dispatch(getArticlesByFields())
				setIsModalVisible(false)
			})
			.catch((errorInfo) => {
				console.log({ errorInfo })
			})
	}

	const handleCancel = () => {
		setIsModalVisible(false)
	}

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm()
	}

	const handleReset = (clearFilters) => {
		clearFilters()
	}

	const filterClick = (selectedKeys, dataIndex, confirm) => (e) => {
		confirm({ closeDropdown: false })
	}

	const onChangePage = (page) => {
		//setCurrentPage(page);
	}

	function onShowSizeChange(current, pageSize) {
		//setLimit(pageSize);
	}

	const handleChangeTable = (pagination, filters, sorter) => {
		const currentPage = pagination?.current // currentPage
		setCurrentPage(currentPage)
		const limit = pagination?.pageSize //limit
		const sortField = sorter.order ? sorter.columnKey : 'createdAt'
		const sortOrder = sorter.order ? sorter.order : 'descend'
		let fields = []
		Object.keys(filters).map((field) => {
			if (filters[field]) {
				/* const isStatusOrType =
					field == 'status' || field == 'type' ? true : false
				filters[field].map((searchText) => {
					fields.push({
						field,
						fieldValue: searchText ? searchText : '',
						specific: isStatusOrType,
					})
				}) */
				filters[field].map((searchText) => {
					let fieldsValue = advanceWord(searchText, false)
					fieldsValue.map((val) => {
						fields.push({
							field,
							fieldValue: val,
							specific: false,
						})
					})
				})
			}
		})
		variables.anotherFields = []
		fields.map((val) => {
			variables.anotherFields.push([val])
		})
		filteredInfo = filters
		setFilteredInfo(filters)
		setSortedInfo(sorter)
		variables = {
			...variables,
			operatorBetweenFields: 'or',
			operatorBetweenAnotherFields: 'or',
			operatorBetweenGlobalFields: 'or',
			options: { all: false, page: currentPage, limit },
			sort: { sortOrder: sortOrder.replace('end', ''), sortField },
			byUserId: '',
		}
		setVariables(variables)
		dispatch(getArticlesByFields(variables))
	}

	const handleSave = (row) => {
		setMarque({ ...marque, [row?._id]: row?.marque })
		setRef({ ...ref, [row?._id]: row?.ref })
		setDescription({ ...description, [row?._id]: row?.description })
		//setFile({ ...img, [row?._id]: row?.img })
	}
	const handleSaveRow = async (form, record) => {
		let row = await form.validateFields()
		let file = row?.file?.file?.originFileObj
		row.file = isEmpty(file) ? null : file
		await dispatch(
			updateArticle({
				_id: record._id,
				...row,
			})
		)
		message.success('Article a été modifié')
		form.setFieldsValue({
			description: '',
			file: '',
			marque: '',
			ref: '',
			refOrigin: '',
		})
	}
	const onDeleteArticles = (selectedRowKeys, setSelectedRowKeys) => () => {
		dispatch(deleteArticles(selectedRowKeys))
		setSelectedRowKeys([])
		const empty = selectedRowKeys.length == articles.entities.length
		if (empty) {
			currentPage = currentPage - 1
			variables = {
				...variables,
				options: {
					...variables.options,
					page: currentPage == 0 ? 1 : currentPage,
				},
			}
			if (!isEmpty(variables)) {
				dispatch(getArticlesByFields(variables))
			} else {
				dispatch(getArticlesByFields())
			}
			/* 	variables = !isEmpty(variables)
				? {
						...variables,
						options: {
							...variables.options,
							page: currentPage == 0 ? 1 : currentPage,
						},
				  }
				: {
						fields: [],
						operatorBetweenFields: 'and',
						options: {
							all: false,
							page: 1,
							limit: 10,
						},
						sort: {
							sortOrder: 'desc',
							sortField: 'createdAt',
						},
						byUserId: '',
				  } */
		}
	}

	let fieldsToSearch = []
	const onSearchAll = (value) => {
		setSearchAll(value)
		advanceSearch(
			value,
			['marque', 'ref', 'refOrigin', 'description'],
			fieldsToSearch
		)
		variables = {
			...variables,
			byUserId: '',
			globalFields: [fieldsToSearch],
			operatorBetweenFields: 'or',
			operatorBetweenAnotherFields: 'or',
			operatorBetweenGlobalFields: 'or',
			options: { all: false, page: currentPage, limit: 10 },
			sort: { sortOrder: 'desc', sortField: 'updatedAt' },
		}
		setVariables(variables)
		dispatch(getArticlesByFields(variables))
		fieldsToSearch = []
	}
	const onChangeAll = (e) => {
		setSearchAll(e.target.value)
		if (isEmpty(e.target.value)) {
			setFilteredInfo(null)
			setSortedInfo(null)
			variables = {
				...variables,
				fields: [],
				anotherFields: [],
				globalFields: [],
				operatorBetweenFields: 'or',
				operatorBetweenAnotherFields: 'or',
				operatorBetweenGlobalFields: 'or',
				options: {
					all: false,
					page: 1,
					limit: 10,
				},
				sort: {
					sortOrder: 'desc',
					sortField: 'updatedAt',
				},
			}
			setVariables(variables)
			dispatch(getArticlesByFields(variables))
		}

		fieldsToSearch = []
		//setFieldsToSearch([])
	}
	const renderInputEditRow =
		(type = 'text', propsForm = {}) =>
		(record) => {
			switch (type) {
				case 'number':
					return (
						<>
							<Form.Item
								style={{
									margin: 0,
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
								{...propsForm}
							>
								<InputNumber
									/* 	className={
								record?.quantity > 0 ? 'input-purchase' : ''
							} */

									onPressEnter={() => {}}
									defaultValue={record?.quantity}
									//onBlur={save}
									keyboard={true}
									//addonBefore='+'
									/* addonAfter={addonAfter(
							() => {},
							() => {},
							record
						)} */
									//defaultValue={0}
									min={0}
								/>
							</Form.Item>
						</>
					)
					break
				case 'file':
					return (
						<Form.Item
							style={{
								margin: 0,
							}}
							{...propsForm}
						>
							<Upload
								name='file'
								maxCount={1}
								type='file'
								accept='.png,.jpg,.jpeg,svg'
							>
								<Button icon={<UploadOutlined />}>
									Upload
								</Button>
							</Upload>
						</Form.Item>
					)
					break
				default:
					return (
						<Form.Item
							style={{
								margin: 0,
							}}
							{...propsForm}
						>
							<Input />
						</Form.Item>
					)
					break
			}
		}

	const renderBefore = (
		hasSelected,
		loadingSelect,
		selectedRowKeys,
		setSelectedRowKeys
	) =>
		selectedRowKeys.length > 0 && (
			<div style={{ marginBottom: 16 }}>
				<Button
					type='primary'
					onClick={() => setSelectedRowKeys([])}
					disabled={!hasSelected}
				>
					Désélectionner
				</Button>
				<Button
					type='primary'
					onClick={onDeleteArticles(
						selectedRowKeys,
						setSelectedRowKeys
					)}
					disabled={!hasSelected}
					loading={loadingDeleteArticles}
					danger
				>
					Supprimer
				</Button>
				<span style={{ marginLeft: 8 }}>
					{hasSelected
						? `Sélectionné ${selectedRowKeys.length} offres`
						: ''}
				</span>
			</div>
		)

	const columnsData = [
		{
			title: 'Marque',
			dataIndex: 'marque',
			key: 'marque',
			width: 150,
			withSearchColumn: true,
			filteredValue: filteredInfo?.marque || null,
			editable: true,
			inputEditable: {
				renderInput: renderInputEditRow('text', {
					name: 'marque',
					rules: [
						{
							required: true,
							message: `Veuillez saisir marque!`,
						},
					],
				}),
				toggleEdit: (form, record) => {
					form.setFieldsValue({
						marque: marque[record?._id]
							? marque[record?._id]
							: record.marque,
					})
				},
			},
			render: (row) =>
				withHighlighter({
					text: row,
					searchText: filteredInfo?.marque
						? [
								...advanceWord(
									filteredInfo?.marque?.join('').toString()
								),
								...advanceWord(searchAll),
						  ]
						: advanceWord(searchAll),
				}),
		},
		{
			title: 'Réference',
			dataIndex: 'ref',
			key: 'ref',
			width: 150,
			withSearchColumn: true,
			filteredValue: filteredInfo?.ref || null,
			editable: true,
			inputEditable: {
				renderInput: renderInputEditRow('text', {
					name: 'ref',
					rules: [
						{
							required: true,
							message: `Veuillez saisir réference!`,
						},
						{
							message:
								'Référence doit être supérieur à 2 caractéres',
							min: 3,
						},
					],
				}),
				toggleEdit: (form, record) => {
					form.setFieldsValue({
						ref: ref[record?._id] ? ref[record?._id] : record.ref,
					})
				},
			},
			render: (row, record) => (
				<Badge
					size='middle'
					dot={user?.roles?.includes(ROLES.ADMIN) ? true : false}
					status={statusList[record?.status]?.type}
				>
					<Tag color={'warning'}>
						<Paragraph
							style={{ marginBottom: '0rem' }}
							copyable={{
								text: row?.toUpperCase(),
								tooltips: ['Copier', 'Copié'],
							}}
						>
							{withHighlighter({
								text: row?.toUpperCase(),
								searchText: filteredInfo?.ref
									? [
											...advanceWord(
												filteredInfo?.ref
													?.join('')
													.toString()
											),
											...advanceWord(searchAll),
									  ]
									: advanceWord(searchAll),
							})}
						</Paragraph>
					</Tag>
				</Badge>
			),
		},
		{
			title: 'Réference Origin',
			dataIndex: 'refOrigin',
			key: 'refOrigin',
			width: 150,
			withSearchColumn: true,
			filteredValue: filteredInfo?.refOrigin || null,
			editable: true,
			inputEditable: {
				renderInput: renderInputEditRow('text', {
					name: 'refOrigin',
					rules: [],
				}),
				toggleEdit: (form, record) => {
					form.setFieldsValue({
						ref: ref[record?._id]
							? ref[record?._id]
							: record.refOrigin,
					})
				},
			},
			render: (row) => (
				<Tag color={'warning'}>
					<Paragraph
						style={{ marginBottom: '0rem' }}
						copyable={{
							text: row?.toUpperCase(),
							tooltips: ['Copier', 'Copié'],
						}}
					>
						{withHighlighter({
							text: row?.toUpperCase(),
							searchText: filteredInfo?.refOrigin
								? [
										...advanceWord(
											filteredInfo?.refOrigin
												?.join('')
												.toString()
										),
										...advanceWord(searchAll),
								  ]
								: advanceWord(searchAll),
						})}
					</Paragraph>
				</Tag>
			),
		},
		{
			title: 'Désignation',
			dataIndex: 'description',
			key: 'description',
			width: 300,
			withSearchColumn: true,
			filteredValue: filteredInfo?.description || null,
			editable: true,
			inputEditable: {
				renderInput: renderInputEditRow('text', {
					name: 'description',
					rules: [
						{
							required: true,
							message: `Veuillez saisir designation!`,
						},
					],
				}),
				toggleEdit: (form, record) => {
					form.setFieldsValue({
						description: description[record?._id]
							? description[record?._id]
							: record.description,
					})
				},
			},
			render: (row, record) =>
				withHighlighter({
					text: row,
					searchText: filteredInfo?.description
						? [
								...filteredInfo?.description,
								...advanceWord(searchAll),
						  ]
						: advanceWord(searchAll),
				}),
		},
		{
			title: 'Photo',
			dataIndex: 'img',
			key: 'img',
			width: 130,
			height: 100,
			ellipsis: {
				showTitle: false,
			},
			filteredValue: filteredInfo?.img || null,
			editable: true,
			inputEditable: {
				renderInput: renderInputEditRow('file', {
					name: 'file',
				}),
				toggleEdit: (form, record) => {
					/* form.setFieldsValue({
						img: img[record?._id] ? img[record?._id] : record.img,
					}) */
				},
			},
			render: (row) => (
				<div style={{ display: 'flex' }}>
					<Image
						preview={true}
						src={row ? row : images.DEFAULT_ARTICLE_PHOTO}
						width={60}
					/>
				</div>
			),
		},
	]
	return (
		<>
			<div className='table-article'>
				<SearchBar
					withoutSticky={true}
					onSearch={onSearchAll}
					onChange={onChangeAll}
					value={searchAll}
					enterButton={
						<PrimaryButton icon={<SearchOutlined />}>
							Chercher
						</PrimaryButton>
					}
					placeholder={
						'Recherche marque/ référence/ référence_origin/ désignation'
					}
				/>
				<Row
					type='flex'
					justify='space-between'
					align='center'
					style={{ margin: '10px 0px' }}
				>
					<Col>
						<PrimaryButton
							onClick={() => {
								setFilteredInfo(null)
								setSortedInfo(null)
								dispatch(getArticlesByFields())
							}}
						>
							Réinitialiser les recherches
						</PrimaryButton>
					</Col>
					<Col>
						<Row gutter={[4, 4]}>
							<Col>
								<Upload
									name='file'
									onChange={({
										file: { originFileObj, status },
									}) => {
										if (status !== 'uploading') {
											uploadExcel({
												variables: {
													file: originFileObj,
												},
											}).then((data) => {
												message.success(
													'Le fichier a été importé'
												)
												dispatch(getArticlesByFields())
											})
										}
									}}
									showUploadList={false}
									maxCount={1}
									type='file'
									accept='.xlsx,.xls,.csv'
								>
									<Tooltip title='Import Articles'>
										<CustomButton
											loading={loadingUpload}
											style={{
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
											}}
											colorText='#ed3b57'
											bgColor='#fff'
											colorHoverIcon='#fff'
											icon={
												<CloudDownloadOutlined
													style={{
														marginRight: '1%',
													}}
													htmlColor='#ed3b57'
												/>
											}
										>
											{md ? 'Importer' : ''}
										</CustomButton>
									</Tooltip>
								</Upload>
							</Col>
							<Col>
								<Tooltip title='Ajouter un article'>
									<CustomButton
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										}}
										onClick={showModal}
										colorText='#08a908'
										bgColor='#fff'
										colorHoverIcon='#fff'
										icon={
											<AddCircleOutline htmlColor='#08a908' />
										}
									>
										{md ? 'Ajouter' : ''}
									</CustomButton>
								</Tooltip>
							</Col>
						</Row>
					</Col>
				</Row>
				<CustomTable
					data={articles?.entities}
					loading={loadingGetArticles || loadingUpdateArticle}
					handleChangeTable={handleChangeTable}
					handleSearch={handleSearch}
					handleReset={handleReset}
					filterClick={filterClick}
					searchText={searchText}
					searchedColumn={searchedColumn}
					handleSave={handleSave}
					handleSaveRow={handleSaveRow}
					renderBefore={renderBefore}
					rowKey='_id'
					editableRow
					locale={{
						emptyText: (
							<Empty
								imageStyle={{
									height: 100,
								}}
								description={<span>Aucun Articles</span>}
							/>
						),
					}}
					pagination={{
						size: xs ? 'small' : 'middle',
						position: xs ? ['bottomLeft'] : ['bottomRight'],
						total: articles?.totalCount,
						defaultPageSize: 10,
						showSizeChanger: true,
						//showQuickJumper: true,
						pageSizeOptions: [3, 5, 10, 20, 100],
						onShowSizeChange,
						showTotal: (total) => (
							<Typography.Text
								strong
							>{`Total ${total} articles`}</Typography.Text>
						),
						onChange: onChangePage,
					}}
					columnsData={columnsData}
				/>
			</div>

			<Modal
				className='modal'
				width={'90%'}
				title='Ajouter article'
				visible={isModalVisible}
				cancelText='Quitter'
				okText='Ajouter'
				okButtonProps={{
					htmlType: 'submit',
				}}
				//onOk={handleOk}
				confirmLoading={loadingCreateArticle}
				onCancel={handleCancel}
				footer={[
					<CustomButton
						size='large'
						colorText={theme.white}
						bgColor={'#ff4d4f'}
						borderRadius='7px'
						className='buy--btn'
						onClick={handleCancel}
					>
						<strong>Quitter</strong>
					</CustomButton>,
					<CustomButton
						loading={loadingCreateArticle}
						size='large'
						colorText={theme.white}
						bgColor={theme.secondary}
						borderRadius='7px'
						className='buy--btn'
						onClick={handleOk}
					>
						<strong>Ajouter</strong>
					</CustomButton>,
				]}
			>
				<Row type='flex' justify='center' align='center'>
					<Space>
						<ArticleForm form={form} />
					</Space>
				</Row>
			</Modal>
		</>
	)
}

export default ListArticles
