import React, { useEffect, useRef, useState } from 'react'
import {
	ArrowDropDown,
	ArrowDropUp,
	Clear,
	RemoveCircle,
	RemoveCircleOutline,
	Send,
} from '@material-ui/icons'
import InputMessage from './InputMessage'
import MessageList from './MessageList'
import TypingIndicator from './TypingIndicator'
import $ from 'jquery'
import { removeChatBoxes } from '../../../redux/chat/actions'
import { useDispatch } from 'react-redux'
import DropZone from '../../DropZone'
import { Badge, Col, Row, Typography } from 'antd'
import { has, isEmpty } from 'lodash'
const ChatBox = ({
	ownerId,
	owner,
	ownerAvatar,
	currentConverId,
	currentUserConver,
	setCurrentsConverIds,
	currentsConverIds,
	countsNotReadAdmin,
	chatOne,
}) => {
	const dispatch = useDispatch()
	const [isLoading, setIsLoading] = useState(false)
	const [list, setList] = useState([])
	const [reduced, setReduced] = useState(false)
	const reduce = useRef()
	const closed = useRef()
	const reduceChatBox = () => {
		//$(reduce.current).slideToggle('fast')
		$(reduce.current).slideToggle('slow', function () {
			setReduced(!reduced)
		})
	}
	const closeChatBox = (event) => {
		!chatOne ? $(closed.current).hide() : $('.chatApp__conv').toggle('fast')
		if (has(currentsConverIds, currentConverId) && !chatOne) {
			setCurrentsConverIds({
				...currentsConverIds,
				[currentConverId]: false,
			})
			dispatch(removeChatBoxes(currentConverId))
		}
	}
	const remove = (index) => (e) =>
		setList(list.filter((val, key) => key != index))

	return (
		<DropZone
			getCurrentFile={(files) => {
				files.map((file) => list.push(file))
				setList([...list])
			}}
			className={'chatApp__conv'}
			ref={closed}
		>
			<div className='chat-head'>
				<Badge
					count={
						!has(countsNotReadAdmin, currentUserConver?._id)
							? ''
							: countsNotReadAdmin[currentUserConver._id]
									?.length > 0
							? countsNotReadAdmin[currentUserConver._id]?.length
							: ''
					}
				/>
				<div className={'chatApp__convTitle'} onClick={reduceChatBox}>
					{chatOne
						? currentUserConver
							? currentUserConver?.title
							: 'Distri-park'
						: currentUserConver?.title}
				</div>
				{(chatOne && !isEmpty(currentUserConver)) || (
					<div>
						{!chatOne ? (
							reduced ? (
								<ArrowDropUp
									style={{ cursor: 'pointer' }}
									htmlColor='#fff'
									onClick={reduceChatBox}
								/>
							) : (
								<ArrowDropDown
									style={{ cursor: 'pointer' }}
									onClick={reduceChatBox}
									htmlColor='#fff'
								/>
							)
						) : (
							''
						)}

						<Clear
							htmlColor='#fff'
							onClick={closeChatBox}
							style={{ cursor: 'pointer' }}
						/>
					</div>
				)}
			</div>
			<div className='chat-body' ref={reduce}>
				<MessageList
					ownerId={ownerId}
					currentConverId={
						!chatOne
							? currentConverId
							: currentUserConver
							? currentUserConver?._id
							: ownerId
					}
					chatOne={chatOne}
					currentUserConver={currentUserConver}
				/>
				<div className={'chatApp__convSendMessage clearfix'}>
					{/* 	<TypingIndicator owner={owner} isTyping={isTyping} /> */}
					<InputMessage
						ownerId={ownerId}
						owner={owner}
						ownerAvatar={ownerAvatar}
						currentConverId={currentConverId}
						currentUserConver={currentUserConver}
						chatOne={chatOne}
						list={list}
						setList={setList}
					/>
				</div>
			</div>
			<Row
				style={{
					overflow: 'scroll',
					maxHeight: '10vh',
					backgroundColor: '#fff',
				}}
				wrap={true}
				align='middle'
				justify='space-around'
			>
				{list.map((val, index) => (
					<Col
						key={index}
						style={{
							width: '40%',
							display: 'flex',
						}}
					>
						<span
							style={{
								color: 'blue',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}
						>
							{val.name}
						</span>
						<div
							style={{
								cursor: 'pointer',
								marginRight: '0.2em',
							}}
							onClick={remove(index)}
						>
							<RemoveCircleOutline
								fontSize='small'
								htmlColor='#ff4d4f'
							/>
						</div>
					</Col>
				))}
			</Row>
		</DropZone>
	)
}
export default ChatBox
