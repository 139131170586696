import { apolloClient } from '../../graphql'
import {
	NOTIFICATIONS_BY_FIELDS_QUERY,
	UPDATE_NOTIFICATIONS_BY_ID_QUERY,
} from '../../queries/notification'

import {
	START_LOADING_NOTIFICATIONS,
	FETCH_NOTIFICATIONS_SUCCESS,
	FETCH_NOTIFICATIONS_ERROR,
	START_LOADING_UPDATE_NOTIFICATIONS,
	UPDATE_NOTIFICATIONS_SUCCESS,
	UPDATE_NOTIFICATIONS_ERROR,
} from './types'

/* *************** NOTIFICATIONS BY FIELDS ************** */
export const getNotificationsByFields = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_NOTIFICATIONS,
		})
		const res = await apolloClient.query({
			query: NOTIFICATIONS_BY_FIELDS_QUERY,
			variables,
			fetchPolicy: 'network-only',
		})
		dispatch({
			type: FETCH_NOTIFICATIONS_SUCCESS,
			payload: res.data?.findNotificationsByField,
		})
	} catch (error) {
		dispatch({
			type: FETCH_NOTIFICATIONS_ERROR,
			payload: error.message,
		})
	}
}
/* *************** CREATE NOTIFICATION ************** */
/* export const createNotification = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_CREATE_NOTIFICATION,
		})

		const res = await apolloClient.mutate({
			mutation: CREATE_NOTIFICATION_QUERY,
			variables,
		})

		dispatch({
			type: CREATE_NOTIFICATION_SUCCESS,
			payload: res.data.createNotification,
		})
	} catch (error) {
		dispatch({
			type: CREATE_NOTIFICATION_ERROR,
			payload: error.message,
		})
	}
}
 */
/* *************** CREATE Prof ************** */
/* export const createProf = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_CREATE_PROF,
		})

		const res = await apolloClient.mutate({
			mutation: CREATE_PROF_QUERY,
			variables,
		})

		dispatch({
			type: CREATE_PROF_SUCCESS,
			payload: res.data.createProf,
		})
	} catch (error) {
		dispatch({
			type: CREATE_PROF_ERROR,
			payload: error.message,
		})
	}
} */

/* *************** UPDATE NOTIFICATION ************** */
export const updateNotificationsByIds = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_UPDATE_NOTIFICATIONS,
		})
		const res = await apolloClient.mutate({
			mutation: UPDATE_NOTIFICATIONS_BY_ID_QUERY,
			variables,
		})
		dispatch({
			type: UPDATE_NOTIFICATIONS_SUCCESS,
			payload: res.data.updateNotificationsByIds,
		})
	} catch (error) {
		dispatch({
			type: UPDATE_NOTIFICATIONS_ERROR,
			payload: error.message,
		})
	}
}
/* *************** DELETE NOTIFICATION ************** */
/* export const deleteNotification = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_DELETE_NOTIFICATION,
		})
		const res = await apolloClient.mutate({
			mutation: DELETE_NOTIFICATION_BY_ID_QUERY,
			variables,
		})

		dispatch({
			type: DELETE_NOTIFICATION_SUCCESS,
			payload: variables,
		})
	} catch (error) {
		dispatch({
			type: DELETE_NOTIFICATION_ERROR,
			payload: error.message,
		})
	}
} */
