import styled from 'styled-components'

export const SliderContainer = styled.div`
	margin-bottom: 1%;
	h3 {
		height: 160px;
		color: #fff;
		line-height: 160px;
		text-align: center;
		background: #364d79;
	}
	.ant-image-img {
		height: 60vh;
		object-fit: cover;
	}
	.slick-dots-bottom {
		background-color: rgb(0 0 0 / 45%);
		padding: 1rem;
		border-radius: 10px;
		display: block;
	}
	@media only screen and (max-width: 750px) {
		.ant-image-img {
			height: 35vh;
		}
	}
`
