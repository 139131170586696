import React, { useState } from 'react'
import CustomTable from '../../../components/CustomTable'
import {
	Badge,
	Button,
	Col,
	Empty,
	Form,
	Grid,
	message,
	Modal,
	Row,
	Space,
	Switch,
	Tooltip,
	Typography,
} from 'antd'
import { CustomButton, PrimaryButton } from '../../../global/GlobalStyles'
import {
	AddCircleOutline,
	CheckOutlined,
	CloseOutlined,
} from '@material-ui/icons'
//import { UploadOutlined, PlusOutlined } from '@ant-design/icons'
//import { UploadOutlined } from '@material-ui/icons'
import { SearchOutlined } from '@ant-design/icons'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import OfferForm from '../../../components/Forms/OfferForm'
//import { LazyLoadImage } from 'react-lazy-load-image-component'
import {
	advanceSearch,
	advanceWord,
	withDigits,
	withHighlighter,
} from '../../../constants/fn'
import { createOffer } from '../../../redux/offer/actions'
import { statusList, TypeOfferFr } from '../../../constants/variables'
import SearchBar from '../../../components/SearchBar'
import { TYPE_OFFER } from '../../../constants/type_offer'
import {
	deleteLinesOffer,
	getLineOffersByFields,
	updateLineOffer,
} from '../../../redux/lineOffer/actions'
import { ROLES } from '../../../constants/roles'
import {
	getArticlesByFields,
	resetArticlesAutoComplete,
} from '../../../redux/article/actions'
import { addToCart, updateCart } from '../../../redux/cart/actions'
import { has, isEmpty } from 'lodash'
import { STATUS } from '../../../constants/status'
import RenderInputEditListOffers from '../../../components/RenderInputEditListOffers'
import RenderInputEditRowListOffers from '../../../components/RenderInputEditRowListOffers'
import { useTheme } from 'styled-components'
import { createLinesOrder } from '../../../redux/lineOrder/actions'
import DetailOffer from './DetailOffer'
import moment from 'moment'

function ListOffers({
	mode,
	status,
	currentKeyTab,
	variables,
	setVariables,
	searchAll,
	setSearchAll,
	filteredInfo,
	setFilteredInfo,
	sortedInfo,
	setSortedInfo,
}) {
	//let { variables, setVariables } = useContext(Contexts.VariablesContext)
	const theme = useTheme()
	const dispatch = useDispatch()
	let [disabled, setDisabled] = useState({})
	const [currentLineOffre, setCurrentLineOffre] = useState({})
	//const [search, setSearch] = useState('')
	let paramsEditRow = {}
	let fieldsToSearch = []
	const { useBreakpoint } = Grid
	const { md, xs } = useBreakpoint()
	const [currentEditRecordRow, setCurrentEditRecordRow] = useState(null)
	const [currentEditFormRow, setCurrentEditFormRow] = useState(null)
	let [currentPage, setCurrentPage] = useState(1)
	const [searchText, setSearchText] = useState('')
	const [searchedColumn, setSearchedColumn] = useState('')
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [isModalDetailVisible, setIsModalDetailVisible] = useState(false)

	const [form] = Form.useForm()

	const {
		loadingGetLineOffers,
		loadingCreateLinesOffer,
		loadingDeleteLinesOffer,
		companyByUserId,
		user,
		articlesAutoComplete,
		loadingUpdateLineOffer,
		qtePriceCommandes,
		...lineOffers
	} = useSelector(
		(state) => ({
			user: state.auth.user,
			companyByUserId: state.auth.companyByUserId,
			loadingCreateLinesOffer: state.lineOffers.loadingCreateLinesOffer,
			loadingGetLineOffers: state.lineOffers?.loadingGetLineOffers,
			loadingDeleteLinesOffer: state.lineOffers.loadingDeleteLinesOffer,
			loadingDelteLinesOffer: state.lineOffers.loadingDelteLinesOffer,
			articlesAutoComplete: state.articles.articlesAutoComplete,
			loadingUpdateLineOffer: state.articles.loadingUpdateLineOffer,
			qtePriceCommandes: state.cart.qtePriceCommandes,
			...state.lineOffers,
		}),
		shallowEqual
	)
	const onDeleteLinesOffer = (selectedRowKeys, setSelectedRowKeys) => () => {
		dispatch(deleteLinesOffer(selectedRowKeys, companyByUserId?._id))
		setSelectedRowKeys([])
		const empty = selectedRowKeys.length == lineOffers?.entities.length
		if (empty) {
			currentPage = currentPage - 1
			variables = {
				...variables,
				options: {
					...variables.options,
					page: currentPage == 0 ? 1 : currentPage,
				},
			}
			setVariables(variables)
		}
	}
	const renderBefore = (
		hasSelected,
		loadingSelect,
		selectedRowKeys,
		setSelectedRowKeys
	) => {
		return (
			selectedRowKeys.length > 0 && (
				<div style={{ marginBottom: 16 }}>
					<Button
						type='primary'
						onClick={() => setSelectedRowKeys([])}
						disabled={!hasSelected}
					>
						Désélectionner
					</Button>
					<Button
						type='primary'
						onClick={onDeleteLinesOffer(
							selectedRowKeys,
							setSelectedRowKeys
						)}
						disabled={!hasSelected}
						loading={loadingDeleteLinesOffer}
						danger
					>
						Supprimer
					</Button>
					<span style={{ marginLeft: 8 }}>
						{hasSelected
							? `Sélectionné ${selectedRowKeys.length} offres`
							: ''}
					</span>
				</div>
			)
		)
	}

	const showModal = () => {
		form.resetFields()
		setIsModalVisible(true)
	}
	const handleOk = async () => {
		form.submit()
		form.validateFields()
			.then(async (values) => {
				if (!isEmpty(values?.lineOffers)) {
					const company = companyByUserId?._id
					const { type, lineOffers } = values
					lineOffers.map((line) => {
						line.company = company
						if (!line.article) {
							line.status = STATUS.PROCESSING
						}
						return line
					})
					await dispatch(
						createOffer({
							type,
							company,
							lineOffers,
						})
					)
					await dispatch(
						getLineOffersByFields(
							{
								status,
								currentKeyTab,
							},
							variables
						)
					)
				}
				setIsModalVisible(false)
				setDisabled({})
			})
			.catch((errorInfo) => {
				console.log({ errorInfo })
			})
	}

	const handleCancel = () => {
		setIsModalVisible(false)
	}

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm()
	}

	const handleReset = (clearFilters) => {
		clearFilters()
	}

	const filterClick = (selectedKeys, dataIndex, confirm) => (e) => {
		confirm({ closeDropdown: false })
	}

	const onChange = (page) => {
		//setCurrentPage(page);
	}

	function onShowSizeChange(current, pageSize) {
		//setLimit(pageSize);
	}
	const handleSaveRow = async (form, record) => {
		const row = await form.validateFields()

		const article = articlesAutoComplete?.entities?.find(
			(item) => row?.ref === item.ref
		)
		const {
			quantity,
			unitPrice,
			unitPriceFinal,
			description,
			marque,
			ref,
			refOrigin,
		} = row
		let newArticle = {
			_id: article ? article?._id : record.idArticle,
			description,
			marque,
			ref,
			refOrigin,
		}
		if (user?.roles?.includes(ROLES.ADMIN)) {
			newArticle = {
				...newArticle,
				status: STATUS.ACTIVE,
			}
		}
		/* if (user?.roles?.includes(ROLES.PROF) && record?.ref != ref) {
			newArticle = {
				...newArticle,
				status: STATUS.PROCESSING,
			}
		} */
		await dispatch(
			updateLineOffer({
				_id: record._id,
				article: article ? article?._id : record.idArticle,
				quantity,
				unitPrice,
				unitPriceFinal,
				status: user?.roles?.includes(ROLES.ADMIN)
					? STATUS.ACTIVE
					: STATUS.PROCESSING,
				newArticle,
			})
		)
		message.success(
			`Votre ${
				TypeOfferFr[record?.type]
			} avec article ${ref} depuis l'offre ${withDigits(
				record?.numRef
			)} a été modifié et publié`
		)
	}
	const toggleEditRow = async (record, form) => {
		//const index = lineOffers?.entities.findIndex((item) => key === item._id)
		setCurrentEditRecordRow(record)
		setCurrentEditFormRow(form)
		dispatch(resetArticlesAutoComplete())
	}
	const onSearch = (currentSearch) => {
		//setSearch(currentSearch)
		let fieldsToSearchRef = []
		advanceSearch(currentSearch, ['ref'], fieldsToSearchRef)
		currentSearch && currentSearch?.length > 2
			? dispatch(
					getArticlesByFields(
						{
							fields: fieldsToSearchRef,
							operatorBetweenFields: 'or',
							options: { all: true, page: 1, limit: 10 },
							sort: { sortOrder: 'desc', sortField: 'updatedAt' },
							byUserId: '',
						},
						true
					)
			  )
			: dispatch(resetArticlesAutoComplete())
		fieldsToSearchRef = []
	}
	const handleChangeTable = (pagination, filters, sorter) => {
		const currentPage = pagination?.current // currentPage
		setCurrentPage(currentPage)
		const limit = pagination?.pageSize //limit
		const sortField = sorter.order ? sorter.columnKey : 'updatedAt'
		const sortOrder = sorter.order ? sorter.order : 'descend'
		let fields = []
		Object.keys(filters).map((field) => {
			if (filters[field]) {
				/* const isStatusOrType =
					field == 'status' || field == 'type' ? true : false
				filters[field].map((searchText) => {
					fields.push({
						field,
						fieldValue: searchText ? searchText : '',
						specific: isStatusOrType,
					})
				}) */

				filters[field].map((searchText) => {
					let fieldsValue = advanceWord(searchText, false)
					fieldsValue.map((val) => {
						fields.push({
							field,
							fieldValue: val,
							specific: false,
						})
					})
				})
			}
		})
		variables.anotherFields = []
		fields.map((val) => {
			variables.anotherFields.push([val])
		})
		variables = {
			...variables,
			//fields,
			//anotherFields: [fields],
			operatorBetweenFields: 'or',
			operatorBetweenAnotherFields: 'or',
			operatorBetweenGlobalFields: 'or',
			options: { all: false, page: currentPage, limit },
			sort: { sortOrder: sortOrder.replace('end', ''), sortField },
		}
		setVariables(variables)
		filteredInfo = filters
		setFilteredInfo(filters)
		setSortedInfo(sorter)
	}

	const handleSave = async (row) => {
		console.log({ row })
		let quantity = row?.quantity
		let unitPriceFinal = row?.unitPriceFinal
		row.qtePriceCommande = row.qtePriceCommande ? row.qtePriceCommande : 1
		const {
			_id,
			description,
			marque,
			numRef,
			//quantity,
			createdAt,
			ref,
			refOrigin,
			type,
			//unitPriceFinal,
			company,
		} = row
		if (has(qtePriceCommandes, _id)) {
			if (type == TYPE_OFFER.SELL) {
				quantity = row?.qtePriceCommande
					? row?.qtePriceCommande
					: qtePriceCommandes[_id]
			} else {
				unitPriceFinal = row?.qtePriceCommande
					? row?.qtePriceCommande
					: qtePriceCommandes[_id]
			}
		} else {
			if (type == TYPE_OFFER.SELL) {
				quantity = row?.qtePriceCommande
			} else {
				unitPriceFinal = row?.qtePriceCommande
			}
		}
		//const unitPriceFinal =
		//qtePriceCommandes
		//setqtePriceCommandes({ ...qtePriceCommandes, [_id]: qteCommande })
		message.success(
			type == TYPE_OFFER.SELL
				? quantity == 0
					? `Supprimer référence #${ref} depuis panier`
					: `Votre demande a été enregistré dans votre panier avec succés`
				: `Modifier prix à ${unitPriceFinal} dt`
		)
		dispatch(
			addToCart({
				_id,
				description,
				marque,
				numRef,
				qtePriceCommande:
					type == TYPE_OFFER.SELL ? quantity : unitPriceFinal,
				quantity: row?.quantity,
				createdAt,
				ref,
				refOrigin,
				type,
				unitPriceFinal: row?.unitPriceFinal,
				company: company?._id,
				companyByUserId,
			})
		)
		if (type == TYPE_OFFER.PURCHASE) {
			const variables = {
				lineOrders: [
					{
						quantity: quantity ? quantity : 1,
						unitPrice: unitPriceFinal ? unitPriceFinal : 1,
						type:
							type == TYPE_OFFER.SELL
								? TYPE_OFFER.PURCHASE
								: TYPE_OFFER.SELL,
						lineOffer: _id,
						company: company?._id,
						orderedBy: companyByUserId?._id,
					},
				],
				numRefOffer: numRef,
				ref,
				unitPriceFinal: row?.qtePriceCommande,
				company: company?._id,
				orderedBy: companyByUserId?._id,
				status: STATUS.PROCESSING,
			}
			await dispatch(createLinesOrder(variables.lineOrders))
		}
	}
	const renderInputEditRow =
		(type = 'text', propsForm = {}) =>
		(record) => {
			return (
				<RenderInputEditRowListOffers
					type={type}
					propsForm={propsForm}
					record={record}
					dataAuto={articlesAutoComplete?.entities}
					currentEditFormRow={currentEditFormRow}
					onSearch={onSearch}
				/>
			)
		}
	const renderInputEdit =
		(type = 'text', propsForm = {}) =>
		(save, toggleEdit, inputRef, record) => {
			return (
				<RenderInputEditListOffers
					type={type}
					propsForm={propsForm}
					save={save}
					toggleEdit={toggleEdit}
					inputRef={inputRef}
					record={record}
					qtePriceCommandes={qtePriceCommandes}
				/>
			)
		}
	const onChangeStatus = (record) => async (checked, event) => {
		const {
			type,
			numRef,
			quantity,
			unitPrice,
			description,
			marque,
			ref,
			refOrigin,
		} = record
		let newArticle = {
			_id: record.idArticle,
			description,
			marque,
			ref,
			refOrigin,
		}
		if (user?.roles?.includes(ROLES.ADMIN)) {
			newArticle = {
				...newArticle,
				status: STATUS.ACTIVE,
			}
		}
		await dispatch(
			updateLineOffer({
				_id: record._id,
				article: record.idArticle,
				quantity,
				unitPrice,
				status: checked ? STATUS.ACTIVE : STATUS.PROCESSING,
				newArticle,
			})
		)
		message.success(
			checked
				? `Votre ${
						TypeOfferFr[type]
				  } avec article ${ref} depuis l'offre ${withDigits(
						numRef
				  )} a été publié`
				: `Votre ${
						TypeOfferFr[type]
				  } avec article ${ref} depuis l'offre ${withDigits(
						numRef
				  )} a été  non publié`
		)
	}
	/* ***************** Search Barrrr ********************* */
	const onSearchAll = (value) => {
		setSearchAll(value)
		advanceSearch(
			value,
			['marque', 'ref', 'refOrigin', 'description'],
			fieldsToSearch
		)
		variables = {
			...variables,
			byStatus: status,
			//fields: variables.fields,
			byUserId: variables?.byUserId,
			globalFields: [fieldsToSearch],
			operatorBetweenFields: 'or',
			operatorBetweenAnotherFields: 'or',
			operatorBetweenGlobalFields: 'or',
			options: { all: false, page: currentPage, limit: 10 },
			sort: { sortOrder: 'desc', sortField: 'updatedAt' },
		}
		setVariables(variables)
		fieldsToSearch = []
	}
	const onChangeAll = (e) => {
		setSearchAll(e.target.value)
		if (isEmpty(e.target.value)) {
			setFilteredInfo(null)
			setSortedInfo(null)
			variables = {
				...variables,
				fields: [],
				anotherFields: [],
				globalFields: [],
				operatorBetweenFields: 'or',
				operatorBetweenAnotherFields: 'or',
				operatorBetweenGlobalFields: 'or',
				options: {
					all: false,
					page: 1,
					limit: 10,
				},
				sort: {
					sortOrder: 'desc',
					sortField: 'updatedAt',
				},
			}
			setVariables(variables)
		}
		fieldsToSearch = []
		//setFieldsToSearch([])
	}

	/* *********************--------------****************** */

	const columnsData = [
		{
			title: 'Ref Offre',
			dataIndex: 'numRef',
			key: 'numRef',
			width: 130,
			withSearchColumn: true,
			fixed: xs ? '' : 'left',
			filteredValue: filteredInfo?.numRef || null,
			render: (row, record) => (
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					{withHighlighter({
						text: withDigits(row),
						searchText: filteredInfo?.numRef,
					})}
					{user?.roles?.includes(ROLES.ADMIN) && (
						<Switch
							style={{ display: 'flex', width: 'fit-content' }}
							size='small'
							checkedChildren={<CheckOutlined fontSize='small' />}
							unCheckedChildren={
								<CloseOutlined fontSize='small' />
							}
							defaultChecked={
								record?.status == STATUS.ACTIVE ? true : false
							}
							onChange={onChangeStatus(record)}
						/>
					)}
					{user?.roles?.includes(ROLES.ADMIN) ? (
						<Typography.Text disabled style={{ fontSize: '0.8em' }}>
							{moment(record?.createdAt).format('L [à] HH:mm')}
						</Typography.Text>
					) : null}
				</div>
			),
		},
		{
			title: 'Four',
			//dataIndex: ['company', 'title'],
			dataIndex: 'four',
			key: 'four',
			width: 150,
			hide: user?.roles?.includes(ROLES.ADMIN) ? false : true,
			withSearchColumn: true,
			filteredValue: filteredInfo?.four || null,
			render: (row, record) => (
				<>
					<Typography.Text strong>
						{withHighlighter({
							text: row,
							searchText: filteredInfo?.four,
						})}
					</Typography.Text>
					<br />
					<Typography.Text>
						{record?.company?.phoneNumber}
					</Typography.Text>
				</>
			),
		},

		/* {
			title: 'Opération',
			dataIndex: 'type',
			key: 'type',
			width: 180,
			withSelectedSearchColumn: true,
			//fixed: 'left',
			filterMultiple: false,
			filters: [
				{
					text: TypeOfferFr[TYPE_OFFER.SELL],
					value: TYPE_OFFER.SELL,
					key: 'sell',
				},
				{
					text: TypeOfferFr[TYPE_OFFER.PURCHASE],
					value: TYPE_OFFER.PURCHASE,
					key: 'achat',
				},
			],
			filteredValue: filteredInfo?.type || null,
			//sortOrder: sortedInfo?.columnKey === "type" && sortedInfo?.order,
			render: (row, record) => (
				<Badge
					size='middle'
					dot
					status={statusList[record?.status]?.type}
				>
					<div
						style={{
							color:
								row == TYPE_OFFER.SELL
									? theme?.purchaseTag
									: theme?.sellTag,
						}}
					>
						{TypeOfferFr[row]}
					</div>
				</Badge>
			),
		}, */
		{
			title: 'Marque',
			dataIndex: 'marque',
			key: 'marque',
			width: 100,
			withSearchColumn: true,
			filteredValue: filteredInfo?.marque || null,
			editable:
				user?.roles?.includes(ROLES.ADMIN) && mode == 'edit'
					? true
					: false,
			/* editable:
				has(currentEditRecordRow, 'statusArticle') &&
				currentEditRecordRow?.statusArticle == STATUS.PROCESSING
					? true
					: user?.roles?.includes(ROLES.ADMIN) && mode == 'edit'
					? true
					: false, */
			inputEditable: {
				renderInput: renderInputEditRow('text', {
					name: 'marque',
					rules: [
						{
							required: true,
							message: 'Veuillez saisir marque!',
						},
					],
				}),
			},
			render: (row) =>
				withHighlighter({
					text: row,
					searchText: filteredInfo?.marque
						? [
								...advanceWord(
									filteredInfo?.marque?.join('').toString()
								),
								...advanceWord(searchAll),
						  ]
						: advanceWord(searchAll),
				}),
		},
		{
			title: 'Réference',
			dataIndex: 'ref',
			key: 'ref',
			width: 150,
			withSearchColumn: true,
			filteredValue: filteredInfo?.ref || null,
			editable:
				user?.roles?.includes(ROLES.ADMIN) && mode == 'edit'
					? true
					: false,
			/* editable:
				currentEditRecord?.type == TYPE_OFFER.SELL && _id
					? true
					: false, */
			inputEditable: {
				renderInput: renderInputEditRow('autoComplete', {
					name: 'ref',
					rules: [
						{
							required: true,
							message: 'Veuillez saisir référence!',
						},
						{
							message:
								'Référence doit être supérieur à 2 caractéres',
							min: 3,
						},
					],
				}),
			},
			render: (row, record) => (
				<Badge
					size='middle'
					dot={
						user?.roles?.includes(ROLES.ADMIN) &&
						record?.statusArticle == STATUS.PROCESSING
							? true
							: false
					}
					status={statusList[record?.statusArticle]?.type}
				>
					{/* <Tag color={theme?.refTag}>
						<Paragraph
							strong
							style={{ marginBottom: '0rem', color: 'white' }}
							copyable={{
								text: row?.toUpperCase(),
								tooltips: ['Copier', 'Copié'],
							}}
						> */}
					{withHighlighter({
						text: row?.toUpperCase(),
						searchText: filteredInfo?.ref
							? [
									...advanceWord(
										filteredInfo?.ref?.join('').toString()
									),
									...advanceWord(searchAll),
							  ]
							: advanceWord(searchAll),
					})}
					{/* </Paragraph>
					</Tag> */}
				</Badge>
			),
		},
		{
			title: 'Ref Origin',
			dataIndex: 'refOrigin',
			key: 'refOrigin',
			width: 150,
			withSearchColumn: true,
			filteredValue: filteredInfo?.refOrigin || null,
			//editable: true,
			editable:
				user?.roles?.includes(ROLES.ADMIN) && mode == 'edit'
					? true
					: false,
			inputEditable: {
				renderInput: renderInputEditRow('text', {
					name: 'refOrigin',
					rules: [
						({ getFieldValue }) => ({
							validator(rule, value) {
								var error = ''
								const refOrigin =
									currentEditFormRow.getFieldValue(
										'refOrigin'
									)
								if (
									refOrigin?.length < 3 &&
									refOrigin?.length != 0
								) {
									error =
										'Référence doit être supérieur à 2 caractéres'
									return Promise.reject(error)
								}

								return Promise.resolve()
							},
						}),
					],
				}),
			},
			render: (row) => (
				<>
					{/* <Tag color={theme?.refOriginTag}>
					<Paragraph
						strong
						style={{ marginBottom: '0rem', color: theme.white }}
						copyable={{
							text: row?.toUpperCase(),
							tooltips: ['Copier', 'Copié'],
						}}
					> */}
					{row
						? withHighlighter({
								text: row?.toUpperCase(),
								searchText: filteredInfo?.refOrigin
									? [
											...filteredInfo?.refOrigin,
											...advanceWord(searchAll),
									  ]
									: advanceWord(searchAll),
						  })
						: '____'}
					{/* 	</Paragraph>
				</Tag> */}
				</>
			),
		},
		{
			title: 'Désignation',
			dataIndex: 'description',
			key: 'description',
			width: 300,
			withSearchColumn: true,
			filteredValue: filteredInfo?.description || null,
			editable:
				user?.roles?.includes(ROLES.ADMIN) && mode == 'edit'
					? true
					: false,
			inputEditable: {
				renderInput: renderInputEditRow('textArea', {
					name: 'description',
					rules: [
						{
							required: true,
							message: 'Veuillez saisir designation!',
						},
					],
				}),
			},
			render: (row, record) => (
				<a
					//to={ARTICLE_DETAIL_URL.replace(':_id', record?.idArticle)}
					style={{ textDecoration: 'underline' }}
					onClick={() => {
						setCurrentLineOffre(record)
						setIsModalDetailVisible(true)
					}}
				>
					{withHighlighter({
						text: row,
						searchText: filteredInfo?.description
							? [
									...filteredInfo?.description,
									...advanceWord(searchAll),
							  ]
							: advanceWord(searchAll),
					})}
				</a>
			),
		},
		{
			title:
				variables.byType == TYPE_OFFER.SELL ? 'Qte dispo' : 'Qte dem',
			dataIndex: 'quantity',
			key: 'quantity',
			width: 140,
			//withSearchColumn: true,
			filteredValue: filteredInfo?.quantity || null,
			//editable: true,
			sorter: (a, b) => a.quantity.length - b.quantity.length,
			sortDirections: ['descend', 'ascend'],
			sortOrder:
				sortedInfo?.columnKey === 'quantity' && sortedInfo?.order,
			editable: mode == 'edit' ? true : false,
			inputEditable: {
				renderInput: renderInputEditRow('number', {
					name: 'quantity',
					rules: [
						({ getFieldValue }) => ({
							validator(rule, value) {
								var error = ''
								const quantity =
									currentEditFormRow.getFieldValue('quantity')
								if (
									quantity?.length < 3 &&
									quantity?.length != 0
								) {
									error =
										'Référence doit être supérieur à 2 caractéres'
									return Promise.reject(error)
								}

								return Promise.resolve()
							},
						}),
					],
				}),
			},
			render: (row) => (
				<>
					{row
						? withHighlighter({
								text: row,
								searchText: filteredInfo?.quantity
									? [
											...filteredInfo?.quantity,
											...advanceWord(searchAll),
									  ]
									: advanceWord(searchAll),
						  })
						: '____'}
				</>
			),
		},
		{
			title: 'PU.TTC',
			dataIndex: 'unitPrice',
			key: 'unitPrice',
			width: 100,
			//withSearchColumn: true,
			filteredValue: filteredInfo?.unitPrice || null,
			//editable: true,
			sorter: (a, b) => a.unitPrice.length - b.unitPrice.length,
			sortDirections: ['descend', 'ascend'],
			sortOrder:
				sortedInfo?.columnKey === 'unitPrice' && sortedInfo?.order,
			editable: mode == 'edit' ? true : false,
			hide:
				variables.byType === TYPE_OFFER.PURCHASE
					? true
					: currentKeyTab != '1' || user?.roles?.includes(ROLES.ADMIN)
					? false
					: true,
			inputEditable: {
				renderInput: renderInputEditRow('number', {
					name: 'unitPrice',
					rules: [
						({ getFieldValue }) => ({
							validator(rule, value) {
								var error = ''
								const unitPrice =
									currentEditFormRow.getFieldValue(
										'unitPrice'
									)
								if (
									unitPrice?.length < 3 &&
									unitPrice?.length != 0
								) {
									error =
										'Référence doit être supérieur à 2 caractéres'
									return Promise.reject(error)
								}

								return Promise.resolve()
							},
						}),
					],
				}),
			},
			render: (row) => (
				<>
					{withHighlighter({
						text: row,
						searchText: filteredInfo?.unitPrice
							? [
									...filteredInfo?.unitPrice,
									...advanceWord(searchAll),
							  ]
							: advanceWord(searchAll),
					})}
					dt
				</>
			),
		},
		{
			title: user?.roles?.includes(ROLES.PROF)
				? 'PU.TTC'
				: 'PU.TTC Final',
			dataIndex: 'unitPriceFinal',
			key: 'unitPriceFinal',
			width: 100,
			//withSearchColumn: true,
			filteredValue: filteredInfo?.unitPriceFinal || null,
			//editable: true,
			sorter: (a, b) => a.unitPriceFinal.length - b.unitPriceFinal.length,
			sortDirections: ['descend', 'ascend'],
			sortOrder:
				sortedInfo?.columnKey === 'unitPriceFinal' && sortedInfo?.order,
			editable: mode == 'edit' ? true : false,
			hide:
				variables.byType === TYPE_OFFER.PURCHASE
					? true
					: currentKeyTab == '1' || user?.roles?.includes(ROLES.ADMIN)
					? false
					: true,
			inputEditable: {
				renderInput: renderInputEditRow('number', {
					name: 'unitPriceFinal',
					rules: [
						({ getFieldValue }) => ({
							validator(rule, value) {
								var error = ''
								const unitPriceFinal =
									currentEditFormRow.getFieldValue(
										'unitPriceFinal'
									)
								if (
									unitPriceFinal?.length < 3 &&
									unitPriceFinal?.length != 0
								) {
									error =
										'Référence doit être supérieur à 2 caractéres'
									return Promise.reject(error)
								}

								return Promise.resolve()
							},
						}),
					],
				}),
			},
			render: (row, record) => (
				<>
					{withHighlighter({
						text: row,
						searchText: filteredInfo?.unitPriceFinal
							? [
									...filteredInfo?.unitPriceFinal,
									...advanceWord(searchAll),
							  ]
							: advanceWord(searchAll),
					})}
					dt
				</>
			),
		},
		{
			title:
				variables.byType === TYPE_OFFER.SELL
					? 'Qte à commander'
					: 'Prix à proposer',
			key: 'qtePriceCommande',
			fixed: 'right',
			dataIndex: 'qtePriceCommande',
			width:
				variables.byType === TYPE_OFFER.SELL
					? xs
						? 100
						: 160
					: xs
					? 180
					: 190,
			hide:
				currentKeyTab !== '1'
					? true
					: user?.roles?.includes(ROLES.ADMIN)
					? true
					: false,
			inputEditable: {
				name: 'qtePriceCommande',
				renderInput:
					mode == 'edit'
						? renderInputEditRow('number')
						: renderInputEdit('number', {
								name: 'qtePriceCommande',
								className: 'input-qtePrCm',
						  }),
				alwaysEditing: mode == 'edit' ? false : true,
				toggleEdit: (form, record) => {
					form.setFieldsValue({
						[qtePriceCommandes]: record[qtePriceCommandes],
					})
				},
			},
			editable: true,
		},
	]
	if (mode == 'edit') {
		paramsEditRow = {
			handleSave,
			handleSaveRow,
			editableRow: true,
		}
	} else {
		paramsEditRow = { scroll: { x: 600 } }
	}
	return (
		<>
			<div className='table-advs'>
				<SearchBar
					withoutSticky={true}
					onSearch={onSearchAll}
					onChange={onChangeAll}
					value={searchAll}
					enterButton={
						<PrimaryButton icon={<SearchOutlined />}>
							Chercher
						</PrimaryButton>
					}
					placeholder={
						'Recherche marque/ référence/ référence_origin/ désignation'
					}
				/>
				<Row
					type='flex'
					justify='space-between'
					align='center'
					style={{ margin: '10px 0px' }}
				>
					<Col>
						<PrimaryButton
							onClick={() => {
								setFilteredInfo(null)
								setSortedInfo(null)
								variables = {
									...variables,
									anotherFields: [],
									operatorBetweenFields: 'or',
									operatorBetweenAnotherFields: 'or',
									operatorBetweenGlobalFields: 'or',
									options: {
										all: false,
										page: 1,
										limit: 10,
									},
									sort: {
										sortOrder: 'desc',
										sortField: 'updatedAt',
									},
								}
								setVariables(variables)
							}}
						>
							Réinitialiser les recherches de tableau
						</PrimaryButton>
					</Col>
					{user?.roles.includes(ROLES.PROF) && (
						<Col>
							<Row gutter={[4, 4]}>
								{/* user?.roles?.includes(ROLES.ADMIN) && (
								<Col>
									<Upload
										name='file'
										onChange={({
											file: { originFileObj },
										}) => {
											uploadExcel({
												variables: {
													file: originFileObj,
												},
											}).then((data) => {
												dispatch(
													getLineOffersByFields(
														{
															status,
															currentKeyTab,
														},
														variables
													)
												)
											})
										}}
										showUploadList={false}
										maxCount={1}
										type='file'
									>
										<Tooltip title='Import Offers'>
											<CustomButton
												style={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
												}}
												colorText='#ed3b57'
												bgColor='#fff'
												colorHoverIcon='#fff'
												icon={
													<CloudDownloadOutlined
														style={{
															marginRight: '1%',
														}}
														htmlColor='#ed3b57'
													/>
												}
											>
												{md ? 'Importer' : ''}
											</CustomButton>
										</Tooltip>
									</Upload>
								</Col>
							) */}

								<Col>
									<Tooltip
										title={
											variables.byType === TYPE_OFFER.SELL
												? 'Ajouter Offer'
												: 'Ajouter Achat'
										}
									>
										<CustomButton
											style={{
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
											}}
											onClick={showModal}
											colorText='#08a908'
											bgColor='#fff'
											colorHoverIcon='#fff'
											icon={
												<AddCircleOutline htmlColor='#08a908' />
											}
										>
											{md ? 'Ajouter' : ''}
										</CustomButton>
									</Tooltip>
								</Col>
							</Row>
						</Col>
					)}
				</Row>
				<CustomTable
					className={
						lineOffers?.entities[0]?.type == TYPE_OFFER.PURCHASE
							? 'purchase-table'
							: 'sell-table'
					}
					mode={mode}
					roles={user?.roles}
					rowClassName={(record, index) => {
						return record?.type == TYPE_OFFER.PURCHASE &&
							record?.reserved == true
							? 'purchase-color-row'
							: 'sell-color-row'
					}}
					locale={{
						triggerDesc: 'Descendant',
						triggerAsc: 'Ascendant ',
						cancelSort: 'Annuler',
						emptyText: (
							<Empty
								imageStyle={{
									height: 100,
								}}
								description={<span>Aucun Offres</span>}
							/>
						),
					}}
					data={lineOffers?.entities}
					loading={loadingGetLineOffers || loadingUpdateLineOffer}
					handleChangeTable={handleChangeTable}
					handleSearch={handleSearch}
					handleReset={handleReset}
					filterClick={filterClick}
					searchText={searchText}
					searchedColumn={searchedColumn}
					handleSave={handleSave}
					toggleEditRow={toggleEditRow}
					renderBefore={renderBefore}
					rowKey='_id'
					{...paramsEditRow}
					pagination={{
						size: xs ? 'small' : 'middle',
						position: xs ? ['bottomLeft'] : ['bottomRight'],
						total: lineOffers?.totalCount,
						defaultPageSize: 10,
						showSizeChanger: true,
						//showQuickJumper: true,
						pageSizeOptions: [5, 10, 20, 100],
						onShowSizeChange,
						showTotal: (total) => (
							<Typography.Text
								strong
							>{`Total ${total} offres`}</Typography.Text>
						),
						onChange,
					}}
					columnsData={columnsData}
					//expandable={{ expandedRowRender: () => <p>hhhh</p> }}
				/>
				{/*  <Table
      className="components-table-demo-nested"
      columns={columns1}
      expandable={{ expandedRowRender=()=> }}
      dataSource={data1}
    /> */}
			</div>

			<Modal
				className='modal'
				title='Ajouter offer'
				visible={isModalVisible}
				cancelText='Quitter'
				okText='Sauvegarder'
				width={'90%'}
				confirmLoading={loadingCreateLinesOffer}
				onCancel={handleCancel}
				footer={[
					<CustomButton
						size='large'
						colorText={theme.white}
						bgColor={'#ff4d4f'}
						borderRadius='7px'
						className='buy--btn'
						onClick={handleCancel}
					>
						<strong>Quitter</strong>
					</CustomButton>,
					<CustomButton
						loading={loadingCreateLinesOffer}
						size='large'
						colorText={theme.white}
						bgColor={theme.secondary}
						borderRadius='7px'
						className='buy--btn'
						onClick={handleOk}
					>
						<strong>Sauvegarder</strong>
					</CustomButton>,
				]}
			>
				<Row type='flex' justify='center' align='center'>
					<Space>
						<OfferForm
							form={form}
							disabled={disabled}
							setDisabled={setDisabled}
							type={variables?.byType}
							//linesOffer={lineOffers?.entities}
						/>
					</Space>
				</Row>
			</Modal>
			<DetailOffer
				lineOffer={currentLineOffre}
				isModalDetailVisible={isModalDetailVisible}
				setIsModalDetailVisible={setIsModalDetailVisible}
			/>
		</>
	)
}
export default ListOffers
