import React, { useEffect } from 'react'
import { Steps } from 'antd'
import { StepsContainer } from './style'
import UserForm from '../../components/Forms/UserForm'
import { CustomButton } from '../../global/GlobalStyles'
import { has, isEmpty } from 'lodash'
import Verification from './Verification'
import CompanyForm from '../../components/Forms/CompanyForm'
import CustomSpin from '../../components/CustomSpin'
import { ROLES } from '../../constants/roles'
import { shallowEqual, useSelector } from 'react-redux'
import { withDigits } from '../../constants/fn'

const { Step } = Steps

const StepsCreateCompany = ({
	current,
	setCurrent,
	stepsData,
	setStepsData,
	form,
	onSubmit,
	loadinConfirm,
	mode,
	roles,
	loading,
	currentType,
	setCurrentType,
}) => {
	const { isAuthenticated, user } = useSelector(
		(state) => ({
			isAuthenticated: state.auth.isAuthenticated,
			user: state.auth.user,
		}),
		shallowEqual
	)
	const onChangeSteps = (curr) => {
		form.validateFields()
			.then((values) => {
				// Here make api call of something else
				const steps = {
					...stepsData,
					[keys[current]]: values,
				}
				if (has(steps, 'user')) {
					steps.user.codeClient = withDigits(steps.user.codeClient, 5)
				}
				setStepsData(steps)
				switch (curr) {
					case 0:
						setCurrent(curr)
						break
					case 1:
						has(steps, 'user') && setCurrent(curr)
						break
					case 2:
						has(steps, 'user') &&
							has(steps, 'company') &&
							setCurrent(curr)
						break
					case 3:
						has(steps, 'user') &&
							has(steps, 'company') &&
							setCurrent(curr)
						break
					default:
						break
				}
				//setCurrent(current + 1)
			})
			.catch((err) => console.log(err))
	}
	const keys = ['company', 'user']
	const next = () => {
		form.validateFields()
			.then((values) => {
				// Here make api call of something else
				if (has(values, 'codeClient')) {
					values.codeClient = withDigits(values.codeClient, 5)
					console.log(withDigits(values.codeClient, 5))
				}
				stepsData = {
					...stepsData,
					[keys[current]]: values,
				}
				if (!isAuthenticated) {
					stepsData = {
						...stepsData,
						user: {
							password: values?.password,
							firstName: values?.title,
							phone: values?.phoneNumber,
							lastName: '‎',
							email: values?.email,
						},
					}
					setStepsData(stepsData)
					onSubmit(stepsData)
				} else {
					setStepsData(stepsData)
					setCurrent(current + 1)
				}
			})
			.catch((err) => console.log(err))
	}

	const prev = () => {
		setCurrent(current - 1)
	}
	const steps = [
		{
			title: 'Info Société',
			content: (
				<CompanyForm
					form={form}
					formItemLayout={{
						labelCol: { span: 16 },
						wrapperCol: { span: 24 },
					}}
					stepsData={stepsData}
					setStepsData={setStepsData}
					currentType={currentType}
					setCurrentType={setCurrentType}
				/>
			),
		},
		/* {
			title: 'Spécialité',
			content: <SpecialityForm form={form} />,
		}, */
	]
	if (user?.roles?.includes(ROLES.ADMIN)) {
		steps.push(
			{
				title: 'Info Responsable',
				content: (
					<UserForm
						form={form}
						withoutPassword={isAuthenticated}
						formItemLayout={{
							labelCol: { span: 16 },
							wrapperCol: { span: 24 },
						}}
						matFiscal={stepsData?.company?.matFiscal}
						mode={mode}
						roles={roles}
						isProfSelected={true}
					/>
				),
			},
			{
				title: 'Vérification',
				content: <Verification data={stepsData} />,
			}
		)
	}
	useEffect(() => {
		if (has(stepsData, keys[current])) {
			!isEmpty(stepsData[keys[current]]) &&
				form.setFieldsValue(stepsData[keys[current]])
		}
	}, [current])
	return (
		<StepsContainer current={current}>
			{loading ? (
				<CustomSpin />
			) : (
				<>
					<Steps
						size='small'
						current={current}
						onChange={onChangeSteps}
					>
						{steps.map((item) => (
							<Step key={item.title} title={item.title} />
						))}
					</Steps>
					<div className='steps-content'>
						{steps[current]?.content}
					</div>
					<div className='steps-action'>
						{current > 0 && (
							<CustomButton
								//onClick={onSubmit}
								borderRadius='10px'
								colorText='white'
								bgColor='#0854a5'
								style={{ margin: '0 8px' }}
								onClick={() => {
									//onSubmit()
									prev()
								}}
							>
								Précédent
							</CustomButton>
						)}

						{current === steps.length - 1 && (
							<>
								<CustomButton
									onClick={() => {
										!isAuthenticated ? next() : onSubmit()
									}}
									borderRadius='10px'
									colorText='white'
									bgColor='#0bb900'
									loading={loadinConfirm}
								>
									Confirmer
								</CustomButton>
							</>
						)}
						{current < steps.length - 1 && (
							<CustomButton
								onClick={() => next()}
								borderRadius='10px'
								colorText='white'
								bgColor='#0854a5'
							>
								Suivant
							</CustomButton>
						)}
					</div>
				</>
			)}
		</StepsContainer>
	)
}

export default StepsCreateCompany
