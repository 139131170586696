import { useEffect, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { addChatBoxes, getChatsByFields } from '../../../redux/chat/actions'
import CustomSpin from '../../CustomSpin'
import MessageItem from './MessageItem'

const MessageList = ({ ownerId, currentConverId, chatOne }) => {
	const {
		loadingGetChats,
		chatBoxes,
		messages,
		hasNextPageBox,
		hasNextPage,
		conversations,
	} = useSelector(
		(state) => ({
			loadingGetChats: state.chats.loadingGetChats,

			chatBoxes: state.chats.chatBoxes,
			conversations: state.chats,
			messages: state.chats?.chatBoxes[currentConverId]?.entities,
			hasNextPageBox:
				state.chats?.chatBoxes[currentConverId]?.hasNextPage,
			hasNextPage: state.chats?.hasNextPage,
		}),
		shallowEqual
	)
	let [page, setPage] = useState(1)
	const dispatch = useDispatch()
	const timelineRef = useRef()
	const scrollToBottom = () => {
		timelineRef.current.scrollTo(0, timelineRef.current.scrollHeight)
	}
	const fetch = () => {
		page = page + 1
		setPage(page)
	}
	useEffect(() => {
		chatOne
			? dispatch(
					getChatsByFields({
						fields: [],
						operatorBetweenFields: 'or',
						options: { all: false, page, limit: 10 },
						sort: { sortOrder: 'desc', sortField: 'createdAt' },
						byUserId: currentConverId,
					})
			  )
			: dispatch(
					addChatBoxes(currentConverId, {
						fields: [],
						operatorBetweenFields: 'or',
						options: { all: false, page, limit: 10 },
						sort: { sortOrder: 'desc', sortField: 'createdAt' },
						byUserId: currentConverId,
					})
			  )
	}, [page, currentConverId])
	return (
		<div>
			<div
				id={'list-conn'}
				className={'chatApp__convTimeline'}
				ref={timelineRef}
			>
				{loadingGetChats ? (
					<CustomSpin height={'40vh'} />
				) : (
					<InfiniteScroll
						dataLength={
							chatOne
								? conversations?.entities.length
								: chatBoxes[currentConverId]?.entities.length
						}
						next={fetch}
						style={{
							display: 'flex',
							flexDirection: 'column-reverse',
						}} //To put endMessage and loader to the top.
						inverse={true}
						hasMore={chatOne ? hasNextPage : hasNextPageBox}
						loader={<CustomSpin height={'5vh'} />}
						scrollableTarget='list-conn'
					>
						{chatOne
							? conversations?.entities.map((item, index) => (
									<>
										<MessageItem
											key={index}
											ownerId={ownerId}
											sender={item.senderId}
											/* senderAvatar={
										'https://i.pravatar.cc/150?img=14'
									} */
											message={item.message}
											files={item.files}
											date={item?.createdAt}
										/>
									</>
							  ))
							: chatBoxes[currentConverId]?.entities.map(
									(item, index) => (
										<>
											<MessageItem
												key={index}
												ownerId={ownerId}
												sender={item.senderId}
												date={item?.createdAt}
												/* senderAvatar={
											'https://i.pravatar.cc/150?img=14'
										} */
												message={item.message}
												files={item.files}
											/>
										</>
									)
							  )}
					</InfiniteScroll>
				)}
			</div>
		</div>
	)
}

export default MessageList
