import { concat, merge } from 'lodash'
import { initialStatePagination } from '../../constants/variables'
import {
	FETCH_ARTICLES_SUCCESS,
	FETCH_ARTICLES_ERROR,
	CREATE_ARTICLE_SUCCESS,
	CREATE_ARTICLE_ERROR,
	UPDATE_ARTICLE_SUCCESS,
	UPDATE_ARTICLE_ERROR,
	DELETE_ARTICLES_SUCCESS,
	DELETE_ARTICLES_ERROR,
	START_LOADING_ARTICLES,
	START_LOADING_CREATE_ARTICLE,
	START_LOADING_UPDATE_ARTICLE,
	START_LOADING_DELETE_ARTICLES,
	START_LOADING_ARTICLE_BY_ID,
	ARTICLE_BY_ID_SUCCESS,
	ARTICLE_BY_ID_ERROR,
	RESET_ARTICLES_AUTOCOMPLETE,
} from './types'
const initialState = merge(
	{
		articleById: {},
		articlesAutoComplete: [],
		loadingGetArticles: false,
		loadingCreateArticle: false,
		loadingGetArticleById: false,
		loadingDeleteArticles: false,
		loadingUpdateArticle: false,
		error: '',
	},
	initialStatePagination
)

export default function articleReducer(state = initialState, action) {
	switch (action.type) {
		/* ****************** GET ARTICLES ************************ */
		case START_LOADING_ARTICLES:
			return {
				...state,
				loadingGetArticles: true,
			}
		case RESET_ARTICLES_AUTOCOMPLETE:
			return {
				...state,
				articlesAutoComplete: [],
			}
		case FETCH_ARTICLES_SUCCESS:
			const { data, autoComplete } = action.payload
			return autoComplete
				? {
						...state,
						articlesAutoComplete: data,
						loadingGetArticles: false,
						error: '',
				  }
				: {
						...state,
						...data,
						loadingGetArticles: false,
						error: '',
				  }
		case FETCH_ARTICLES_ERROR:
			return {
				...state,
				loadingGetArticles: false,
				error: action.payload,
			}
		/* ****************** CREATE ARTICLE ************************ */
		case START_LOADING_CREATE_ARTICLE:
			return {
				...state,
				loadingCreateArticle: true,
			}
		case CREATE_ARTICLE_SUCCESS:
			return {
				...state,
				entities: [action.payload, ...state.entities],
				//entities: [action.payload, ...state.entities],
				loadingCreateArticle: false,
				error: '',
			}
		case CREATE_ARTICLE_ERROR:
			return {
				...state,
				loadingCreateArticle: false,
				error: action.payload,
			}

		/* ****************** ARTICLE BY ID ************************ */
		case START_LOADING_ARTICLE_BY_ID:
			return {
				...state,
				loadingGetArticleById: true,
			}
		case ARTICLE_BY_ID_SUCCESS:
			return {
				...state,
				articleById: action.payload,
				loadingGetArticleById: false,
				error: '',
			}
		case ARTICLE_BY_ID_ERROR:
			return {
				...state,
				loadingGetArticleById: false,
				error: action.payload,
			}
		/* ****************** UPDATE ARTICLE ************************ */
		case START_LOADING_UPDATE_ARTICLE:
			return {
				...state,
				loadingUpdateArticle: true,
			}
		case UPDATE_ARTICLE_SUCCESS:
			return {
				...state,
				entities: [
					...state.entities.map((article) => {
						if (article._id === action.payload._id)
							return action.payload
						else return article
					}),
				],
				loadingUpdateArticle: false,
				error: '',
			}
		case UPDATE_ARTICLE_ERROR:
			return {
				...state,
				loadingUpdateArticle: false,
				error: action.payload,
			}
		/* ****************** DELETE ARTICLE ************************ */
		case START_LOADING_DELETE_ARTICLES:
			return {
				...state,
				loadingDeleteArticles: true,
			}
		case DELETE_ARTICLES_SUCCESS:
			return {
				...state,
				loadingDeleteArticles: false,
				entities: [
					...state.entities.filter((article) => {
						return !action.payload.includes(article._id)
					}),
				],
				error: '',
			}
		case DELETE_ARTICLES_ERROR:
			return {
				...state,
				loadingDeleteArticles: false,
				error: action.payload,
			}
		default:
			return state
	}
}
