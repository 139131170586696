import React, { useState, useEffect, useContext } from 'react'
import { List, message, Avatar, Skeleton, Divider, Badge } from 'antd'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useTheme } from 'styled-components'
import { ListProfContainer } from '../styles'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { getUsersByFileds } from '../../../redux/user/actions'
import images from '../../../constants/images'
import { getCompaniesByFileds } from '../../../redux/company/actions'
import userEvent from '@testing-library/user-event'
import { updateCurrentConv } from '../../../redux/chat/actions'
import SearchBar from '../../../components/SearchBar'
import { has, isEmpty } from 'lodash'
import { withHighlighter } from '../../../constants/fn'
import { SearchOutlined } from '@material-ui/icons'
import { PrimaryButton } from '../../../global/GlobalStyles'
import Contexts from '../../../context'
import { ROLES } from '../../../constants/roles'

const ListProf = ({ collapsed, setCurrentComapnyConv }) => {
	let [page, setPage] = useState(1)
	const { setFocusMessage, focusMessage } = useContext(
		Contexts.FocusMessageContext
	)
	let [variables, setVariables] = useState({
		fields: [],
		operatorBetweenFields: 'or',
		options: { all: false, page, limit: 6 },
		sort: { sortOrder: 'desc', sortField: 'createdAtChat' },
	})
	const dispatch = useDispatch()
	const theme = useTheme()
	const [searchText, setSearchText] = useState('')
	const {
		loadingGetCompanies,
		companies,
		hasNextPage,
		countsNotReadAdmin,
		user,
		companyByUserId,
	} = useSelector(
		(state) => ({
			loadingGetCompanies: state.companies.loadingGetCompanies,
			companies: state.companies.entities,
			hasNextPage: state.companies.hasNextPage,
			countsNotReadAdmin: state.chats.countsNotReadAdmin,
			user: state.auth?.user,
			companyByUserId: state.auth?.companyByUserId,
		}),
		shallowEqual
	)
	const loadMoreData = () => {
		console.log({ page1: page })
		page = page + 1
		setPage(page)
		console.log({ page })
		variables = {
			...variables,
			options: { all: false, page, limit: 6 },
		}
		setVariables(variables)
		//	dispatch(getUsersByFileds())
		//dispatch(getCompaniesByFileds(variables))
	}
	const onSearch = (value) => {
		setSearchText(value)
		page = 1
		setPage(page)
		variables = {
			...variables,
			options: { all: false, page, limit: 6 },
			fields: [
				{ field: 'title', fieldValue: value, specific: false },
				{ field: 'email', fieldValue: value, specific: false },
				{
					field: 'phoneNumber',
					fieldValue: value,
					specific: false,
				},
			],
		}
		setVariables(variables)
		//dispatch(getCompaniesByFileds(variables))
	}
	const onChange = (e) => {
		if (isEmpty(e.target.value)) {
			page = 1
			setPage(page)
			setSearchText()
			variables = {
				...variables,
				options: { all: false, page, limit: 6 },
				fields: [],
			}
			setVariables(variables)
			//dispatch(getCompaniesByFileds(variables))
		}
	}
	/* 	useEffect(() => {
		dispatch(getCompaniesByFileds(variables))
	}, []) */

	useEffect(() => {
		dispatch(getCompaniesByFileds(variables))
	}, [variables])

	return (
		<>
			<div style={{ margin: '1% 0' }}>
				<SearchBar
					onSearch={onSearch}
					onChange={onChange}
					placeholder={'chercher client'}
					enterButton={<PrimaryButton icon={<SearchOutlined />} />}
				/>
			</div>
			<ListProfContainer collapsed={collapsed} id='list-prof'>
				<InfiniteScroll
					dataLength={companies.length}
					next={loadMoreData}
					hasMore={hasNextPage}
					loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
					//endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
					scrollableTarget='list-prof'
				>
					<List
						loading={loadingGetCompanies}
						dataSource={companies}
						renderItem={(company, key) => (
							<List.Item
								key={key}
								onClick={() => {
									setCurrentComapnyConv(company)
									user?.roles.includes(ROLES.ADMIN) &&
										setFocusMessage({
											receiverId: companyByUserId?._id,
											...focusMessage,
										})
									dispatch(updateCurrentConv(company))
								}}
							>
								<List.Item.Meta
									avatar={
										<Badge dot status='success'>
											<Avatar
												src={
													images.DEFAULT_ARTICLE_PHOTO
												}
												size='large'
											/>
										</Badge>
									}
									title={
										<>
											<div
												style={{
													color: '#fff',
													fontSize: '1rem',
													display: 'flex',
													justifyContent:
														'space-between',
												}}
												href='https://ant.design'
											>
												<span>
													{withHighlighter({
														text: company?.title,
														searchText: searchText,
													})}
												</span>
												<Badge
													count={
														has(
															countsNotReadAdmin,
															company._id
														) &&
														countsNotReadAdmin[
															company._id
														]?.length > 0
															? countsNotReadAdmin[
																	company._id
															  ]?.length
															: ''
													}
												/>
											</div>
											<div style={{ color: theme.white }}>
												{withHighlighter({
													text: company?.email,
													searchText: searchText,
												})}
											</div>
											<div style={{ color: theme.white }}>
												{withHighlighter({
													text: company?.phoneNumber,
													searchText: searchText,
												})}
											</div>
										</>
									}
									description={
										<div style={{ color: theme.secondary }}>
											{company.type}
										</div>
									}
								/>
								{/* <div>Content</div> */}
							</List.Item>
						)}
					/>
				</InfiniteScroll>
			</ListProfContainer>
		</>
	)
}

export default ListProf
