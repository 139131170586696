import {
	concat,
	find,
	findIndex,
	isEmpty,
	isFinite,
	merge,
	sumBy,
} from 'lodash'
import { TYPE_OFFER } from '../../constants/type_offer'
import { initialStatePagination } from '../../constants/variables'
import {
	ADD_CART_SUCCESS,
	ADD_CART_ERROR,
	UPDATE_CART_SUCCESS,
	UPDATE_CART_ERROR,
	DELETE_CART_SUCCESS,
	DELETE_CART_ERROR,
	START_LOADING_ADD_CART,
	START_LOADING_UPDATE_CART,
	START_LOADING_DELETE_CART,
} from './types'
const initialState = merge(
	{
		count: 0,
		qtePriceCommandes: {},
		priceCommandes: {},
		loadingADDCart: false,
		loadingDeleteCart: false,
		loadingUpdateCart: false,
		error: '',
	},
	initialStatePagination
)

export default function cartReducer(state = initialState, action) {
	switch (action.type) {
		/* ****************** ADD CART ************************ */
		case START_LOADING_ADD_CART:
			return {
				...state,
				loadingADDCart: true,
			}
		case ADD_CART_SUCCESS:
			const exist = find(state.entities, ['_id', action.payload._id])
			var index = findIndex(state.entities, ['_id', action.payload._id])
			if (!isEmpty(exist)) {
				if (action.payload?.qtePriceCommande == 0)
					state.entities = state.entities.filter(
						({ _id }) => _id !== action.payload._id
					)
				else state.entities.splice(index, 1, action.payload)
			}

			const entities =
				action.payload?.type == TYPE_OFFER.PURCHASE
					? state.entities
					: exist
					? state.entities
					: [...state.entities, action.payload]
			return {
				...state,
				entities,
				loadingADDCart: false,
				qtePriceCommandes: {
					...state.qtePriceCommandes,
					[action.payload._id]: action.payload?.qtePriceCommande,
				},
				count: sumBy(
					entities.filter(({ type }) => type !== TYPE_OFFER.PURCHASE),
					function (o) {
						return o.qtePriceCommande
					}
				),
				error: '',
			}
		case ADD_CART_ERROR:
			return {
				...state,
				loadingADDCart: false,
				error: action.payload,
			}

		/* ****************** UPDATE CART ************************ */
		case START_LOADING_UPDATE_CART:
			return {
				...state,
				loadingUpdateCart: true,
			}
		case UPDATE_CART_SUCCESS:
			state.qtePriceCommandes[action.payload?._id] =
				action.payload?.qtePriceCommande
			return {
				...state,
				entities: [
					...state.entities.map((lineOfferOrder) => {
						if (lineOfferOrder._id === action.payload._id)
							return action.payload
						else return lineOfferOrder
					}),
				],
				loadingUpdateCart: false,
				error: '',
			}
		case UPDATE_CART_ERROR:
			return {
				...state,
				loadingUpdateCart: false,
				error: action.payload,
			}
		/* ****************** DELETE CART ************************ */
		case START_LOADING_DELETE_CART:
			return {
				...state,
				loadingDeleteCart: true,
			}
		case DELETE_CART_SUCCESS:
			return {
				...state,
				loadingDeleteCart: false,
				entities: [
					...state.entities.filter(
						(cart) => cart._id !== action.payload._id
					),
				],
				error: '',
			}
		case DELETE_CART_ERROR:
			return {
				...state,
				loadingDeleteCart: false,
				error: action.payload,
			}
		default:
			return state
	}
}
