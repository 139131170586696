export const START_LOADING_ORDERS = 'START_LOADING_ORDERS'
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS'
export const FETCH_ORDERS_ERROR = 'FETCH_ORDERS_ERROR'

export const START_LOADING_CREATE_ORDER = 'START_LOADING_CREATE_ORDER'
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS'
export const CREATE_ORDER_ERROR = 'CREATE_ORDER_ERROR'

export const START_LOADING_ORDER_BY_ID = 'START_LOADING_ORDER_BY_ID'
export const ORDER_BY_ID_SUCCESS = 'ORDER_BY_ID_SUCCESS'
export const ORDER_BY_ID_ERROR = 'ORDER_BY_ID_ERROR'

export const START_LOADING_DELETE_ORDER = 'START_LOADING_DELETE_ORDER'
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS'
export const DELETE_ORDER_ERROR = 'DELETE_ORDER_ERROR'

export const START_LOADING_UPDATE_ORDER = 'START_LOADING_UPDATE_ORDER'
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS'
export const UPDATE_ORDER_ERROR = 'UPDATE_ORDER_ERROR'
