import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import $, { event } from 'jquery'
const DropZoneContainer = styled.div`
	.detected {
		border: 1px dashed ${(props) => props.theme.secondary};
		border-radius: 10px;
	}
`
function DropZone({ children, getCurrentFile, className }) {
	const zone = useRef()
	const getFile = (ev) => {
		ev.preventDefault()
		let file = ''
		let files = []
		if (ev.dataTransfer.items) {
			for (var i = 0; i < ev.dataTransfer.items.length; i++) {
				if (ev.dataTransfer.items[i].kind === 'file') {
					file = ev.dataTransfer.items[i].getAsFile()
					files.push(file)
				}
			}
		} else {
			for (var i = 0; i < ev.dataTransfer.files.length; i++) {
				const file = ev.dataTransfer.files
			}
		}
		getCurrentFile(files)
		return file
	}
	useEffect(() => {
		let dropzone = $('.dragdrop-area')
		let drag_events_counter = 0
		$(zone.current).on('dragover dragenter dragleave drop', function (e) {
			if (e.type == 'dragover') {
				e.preventDefault()
			} else if (e.type == 'dragenter') {
				e.preventDefault()
				drag_events_counter++
				$(this).find(dropzone).addClass('detected')
			} else if (e.type == 'dragleave') {
				drag_events_counter--
				if (drag_events_counter === 0) {
					$(this).find(dropzone).removeClass('detected')
				}
			} else if (e.type == 'drop') {
				e.preventDefault()
				drag_events_counter = 0
				$(this).find(dropzone).removeClass('detected')
			}
		})
	}, [])
	return (
		<DropZoneContainer ref={zone} onDrop={getFile} className={className}>
			<div className='dragdrop-area'>
				<div className='drop hidden'>{children}</div>
			</div>
		</DropZoneContainer>
	)
}

export default DropZone
