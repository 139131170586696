import React from 'react'
import { Carousel, Image } from 'antd'
import { SliderContainer } from './style'

function CustomSlider() {
	return (
		<SliderContainer>
			<Carousel
				autoplay
				speed={1000}
				//autoplaySpeed={3000}
				//cssEase={'linear'}
				dots
				swipe
				arrows={true}
				className={'center'}
				infinite={true}
				//lazyLoad={true}
				adaptiveHeight={true}
				slidesToShow={3}
				//slidesToScroll={2}
				//initialSlide={0}
				responsive={[
					{
						breakpoint: 1024,
						settings: {
							slidesToShow: 2,
							//slidesToScroll: 2,
							infinite: true,
							dots: true,
						},
					},
					{
						breakpoint: 600,
						settings: {
							slidesToShow: 2,
							//slidesToScroll: 2,
							//initialSlide: 2,
						},
					},
					{
						breakpoint: 480,
						settings: {
							slidesToShow: 1,
							//slidesToScroll: 1,
						},
					},
				]}
			>
				<div>
					<Image
						style={{ padding: '0.5em' }}
						preview={false}
						width={'100%'}
						src='https://toutespieces.com/wp-content/uploads/2021/01/pieces-detachees-origines-1.jpg'
					/>
				</div>
				<div>
					<Image
						style={{ padding: '0.5em' }}
						preview={false}
						width={'100%'}
						src='https://www.senegalaise-automobile.com/themes/lasa/images/pieces-de-rechange.png'
					/>
				</div>
				<div>
					<Image
						style={{ padding: '0.5em' }}
						preview={false}
						width={'100%'}
						src='https://cdn.lelynx.fr/wp-content/uploads/2020/02/GettyImages-1034249292.jpg'
					/>
				</div>
				<div>
					<Image
						style={{ padding: '0.5em' }}
						preview={false}
						width={'100%'}
						src='https://www.senegalaise-automobile.com/themes/lasa/images/pieces-de-rechange.png'
					/>
				</div>
				<div>
					<Image
						style={{ padding: '0.5em' }}
						preview={false}
						width={'100%'}
						src='https://cdn.lelynx.fr/wp-content/uploads/2020/02/GettyImages-1034249292.jpg'
					/>
				</div>
				<div>
					<Image
						style={{ padding: '0.5em' }}
						preview={false}
						width={'100%'}
						src='https://www.senegalaise-automobile.com/themes/lasa/images/pieces-de-rechange.png'
					/>
				</div>
				<div>
					<Image
						style={{ padding: '0.5em' }}
						preview={false}
						width={'100%'}
						src='https://cdn.lelynx.fr/wp-content/uploads/2020/02/GettyImages-1034249292.jpg'
					/>
				</div>
			</Carousel>
		</SliderContainer>
	)
}

export default CustomSlider
