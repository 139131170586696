export const START_LOADING_COMPANIES = 'START_LOADING_COMPANIES'
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS'
export const FETCH_COMPANIES_ERROR = 'FETCH_COMPANIES_ERROR'

export const ADD_TO_COMPANIES_SUCCESS = 'ADD_TO_COMPANIES_SUCCESS'

export const START_LOADING_CREATE_COMPANY = 'START_LOADING_CREATE_COMPANY'
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS'
export const CREATE_COMPANY_ERROR = 'CREATE_COMPANY_ERROR'

export const START_LOADING_DELETE_COMPANY = 'START_LOADING_DELETE_COMPANY'
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS'
export const DELETE_COMPANY_ERROR = 'DELETE_COMPANY_ERROR'

export const START_LOADING_UPDATE_COMPANY_BY_ID =
	'START_LOADING_UPDATE_COMPANY_BY_ID'
export const UPDATE_COMPANY_BY_ID_SUCCESS = 'UPDATE_COMPANY_BY_ID_SUCCESS'
export const UPDATE_COMPANY_BY_ID_ERROR = 'UPDATE_COMPANY_BY_ID_ERROR'

export const START_LOADING_COMPANY_BY_USER_ID =
	'START_LOADING_COMPANY_BY_USER_ID'
export const FETCH_COMPANY_BY_USER_ID_SUCCESS =
	'FETCH_COMPANY_BY_USER_ID_SUCCESS'
export const FETCH_COMPANY_BY_USER_ID_ERROR = 'FETCH_COMPANY_BY_USER_ID_ERROR'
