export const START_LOADING_ADD_CART = 'START_LOADING_ADD_CART'
export const ADD_CART_SUCCESS = 'ADD_CART_SUCCESS'
export const ADD_CART_ERROR = 'ADD_CART_ERROR'

export const START_LOADING_DELETE_CART = 'START_LOADING_DELETE_CART'
export const DELETE_CART_SUCCESS = 'DELETE_CART_SUCCESS'
export const DELETE_CART_ERROR = 'DELETE_CART_ERROR'

export const START_LOADING_UPDATE_CART = 'START_LOADING_UPDATE_CART'
export const UPDATE_CART_SUCCESS = 'UPDATE_CART_SUCCESS'
export const UPDATE_CART_ERROR = 'UPDATE_CART_ERROR'
