import { concat, has, map, merge } from 'lodash'
import { detectBrowser, getOS } from '../../constants/fn'
import images from '../../constants/images'
import { initialStatePagination } from '../../constants/variables'
import {
	FETCH_CHATS_SUCCESS,
	FETCH_CHATS_ERROR,
	CREATE_CHAT_SUCCESS,
	CREATE_CHAT_ERROR,
	UPDATE_CHATS_SUCCESS,
	UPDATE_CHATS_ERROR,
	DELETE_CHAT_SUCCESS,
	DELETE_CHAT_ERROR,
	START_LOADING_CHATS,
	START_LOADING_CREATE_CHAT,
	START_LOADING_UPDATE_CHATS,
	START_LOADING_DELETE_CHAT,
	START_LOADING_UPDATE_LIST_CHAT,
	UPDATE_LIST_CHAT_SUCCESS,
	UPDATE_LIST_CHAT_ERROR,
	UPDATE_CURRENT_CONV_SUCCESS,
	ADD_CHAT_BOXES_SUCCESS,
	REMOVE_CHAT_BOXES_SUCCESS,
} from './types'
const initialState = merge(
	{
		loadingGetChats: false,
		loadingCreateChat: false,
		loadingDeleteChat: false,
		loadingUpdateChat: false,
		loadingUpdateListChat: false,
		countsNotRead: [],
		countsNotReadAdmin: {},
		chatBoxes: {},
		currentConvId: '',
		error: '',
	},
	initialStatePagination
)

export default function chatReducer(state = initialState, action) {
	switch (action.type) {
		/* ****************** GET CHATS ************************ */
		case START_LOADING_CHATS:
			return {
				...state,
				loadingGetChats: true,
			}
		case FETCH_CHATS_SUCCESS:
			let countsNotReadAdmin = {}
			action.payload?.countsNotReadAdmin.length > 0
				? action.payload?.countsNotReadAdmin.map((count) => {
						countsNotReadAdmin = {
							...countsNotReadAdmin,
							[count?._id]: count?.countsNotRead,
						}
				  })
				: (countsNotReadAdmin = {})
			return {
				...state,
				...action.payload,
				entities:
					action.payload?.page == 1
						? [...action.payload.entities]
						: [...state.entities, ...action.payload.entities],
				loadingGetChats: false,
				countsNotRead: action.payload?.countsNotRead,
				countsNotReadAdmin,
				error: '',
			}
		case FETCH_CHATS_ERROR:
			return {
				...state,
				data: [],
				loadingGetChats: false,
				error: action.payload,
			}
		/* ****************** CREATE CHAT ************************ */
		case START_LOADING_CREATE_CHAT:
			return {
				...state,
				loadingCreateChat: true,
			}
		case CREATE_CHAT_SUCCESS:
			return {
				...state,
				//entities: [action.payload, ...state.entities],
				newChat: action.payload,
				loadingCreateChat: false,
				error: '',
			}
		case CREATE_CHAT_ERROR:
			return {
				...state,
				loadingCreateChat: false,
				error: action.payload,
			}

		/* ****************** UPDATE CHAT ************************ */

		case UPDATE_CURRENT_CONV_SUCCESS:
			return {
				...state,
				currentConvId: action.payload,
				loadingUpdateChat: false,
				error: '',
			}

		/* ****************** UPDATE CHAT ************************ */
		case START_LOADING_UPDATE_CHATS:
			return {
				...state,
				loadingUpdateChat: true,
			}
		case UPDATE_CHATS_SUCCESS:
			let keySenderId = ''
			map(state.countsNotReadAdmin, (value, key) => {
				if (!keySenderId) {
					keySenderId = value.includes(action.payload[0]) ? key : ''
				}
			})
			return {
				...state,
				entities: [
					...state.entities.map((chat) => {
						if (action.payload?.includes(chat._id))
							return { ...chat, is_read: true }
						else return chat
					}),
				],
				countsNotRead: [],
				countsNotReadAdmin: {
					...state.countsNotReadAdmin,
					[keySenderId]: [],
				},
				loadingUpdateChat: false,
				error: '',
			}
		case UPDATE_CHATS_ERROR:
			return {
				...state,
				loadingUpdateChat: false,
				error: action.payload,
			}
		/* ****************** DELETE CHAT ************************ */
		case START_LOADING_DELETE_CHAT:
			return {
				...state,
				loadingDeleteChat: true,
			}
		case DELETE_CHAT_SUCCESS:
			return {
				...state,
				loadingDeleteChat: false,
				entities: [
					...state.entities.filter(
						(chat) => chat._id !== action.payload._id
					),
				],
				error: '',
			}
		case DELETE_CHAT_ERROR:
			return {
				...state,
				loadingDeleteChat: false,
				error: action.payload,
			}
		/* ****************** DELETE CHAT ************************ */
		case START_LOADING_UPDATE_LIST_CHAT:
			return {
				...state,
				loadingUpdateListChat: true,
			}
		case UPDATE_LIST_CHAT_SUCCESS:
			const audio = new Audio(images.BEEP)
			const { newMessage, focusMessage } = action.payload
			const existInChatboxes =
				has(
					state.chatBoxes,
					`${newMessage?.senderId?._id}_${newMessage?.receiverId?._id}`
				) ||
				has(
					state.chatBoxes,
					`${newMessage?.receiverId?._id}_${newMessage?.senderId?._id}`
				)
			if (!focusMessage?.status) {
				if (focusMessage?.receiverId == newMessage?.receiverId?._id) {
					if (detectBrowser() == 'Chrome') {
						audio.play()
					}
					if (focusMessage?.isAdmin) {
						state.countsNotReadAdmin = {
							...state.countsNotReadAdmin,
							[newMessage?.senderId?._id]: has(
								state.countsNotReadAdmin,
								newMessage?.senderId?._id
							)
								? [
										...state.countsNotReadAdmin[
											newMessage?.senderId?._id
										],
										newMessage?._id,
								  ]
								: [newMessage?._id],
						}
					} else {
						state.countsNotRead = [
							...state.countsNotRead,
							newMessage?._id,
						]
					}
				}
			}
			if (existInChatboxes) {
				const id = has(
					state.chatBoxes,
					`${newMessage?.senderId?._id}_${newMessage?.receiverId?._id}`
				)
					? `${newMessage?.senderId?._id}_${newMessage?.receiverId?._id}`
					: `${newMessage?.receiverId?._id}_${newMessage?.senderId?._id}`
				state.chatBoxes[id] = {
					...state.chatBoxes[id],
					entities: [newMessage, ...state.chatBoxes[id]?.entities],
				}
			}
			if (state.currentConvId) {
				if (
					[
						newMessage?.receiverId?._id,
						newMessage?.senderId?._id,
					].includes(state.currentConvId?._id)
				) {
					state.entities = [newMessage, ...state.entities]
				}
			} else {
				state.entities = [newMessage, ...state.entities]
			}
			return {
				...state,
				loadingUpdateListChat: false,
				error: '',
			}
		case UPDATE_LIST_CHAT_ERROR:
			return {
				...state,
				loadingUpdateListChat: false,
				error: action.payload,
			}
		case ADD_CHAT_BOXES_SUCCESS:
			let chatBoxes = state.chatBoxes
			if (has(chatBoxes, action.payload?.currentConverId)) {
				let entities = [
					...chatBoxes[action.payload?.currentConverId].entities,
					...action.payload?.data.entities,
				]
				const {
					hasNextPage,
					hasPreviousPage,
					limit,
					page,
					totalCount,
					totalPages,
				} = action.payload?.data
				chatBoxes[action.payload?.currentConverId] = {
					...chatBoxes[action.payload?.currentConverId],
					entities,
					hasNextPage,
					hasPreviousPage,
					limit,
					page,
					totalCount,
					totalPages,
				}
			} else {
				chatBoxes = {
					[action.payload?.currentConverId]: action.payload?.data,
					...chatBoxes,
				}
			}
			return {
				...state,
				chatBoxes,
			}
		case REMOVE_CHAT_BOXES_SUCCESS:
			delete state.chatBoxes[action?.payload]
			return {
				...state,
			}

		default:
			return state
	}
}
