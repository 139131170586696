import { Button, Input } from 'antd'
import React, { useEffect, useState } from 'react'

import { ClearOutlined } from '@ant-design/icons'
import { SearchBarContainer } from './styles'
import { CustomButton, PrimaryButton } from '../../global/GlobalStyles'

const SearchBar = ({ clear, withoutSticky, ...props }) => {
	const [top, setTop] = useState('')
	/* useEffect(() => {
		setTop(`${document?.getElementById('header-title').offsetHeight - 1}px`)
	}, []) */
	return (
		<SearchBarContainer withoutSticky={withoutSticky} top={top}>
			<div className='search-content'>
				<Input.Search
					{...props}
					className='test'
					allowClear
					size='large'
				/>
				{/* <PrimaryButton icon={<ClearOutlined />} onClick={clear}>
					Effacer
				</PrimaryButton> */}
			</div>
		</SearchBarContainer>
	)
}
export default SearchBar
