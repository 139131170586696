import {
	CheckCircle,
	RemoveCircle,
	EditRounded,
	Clear,
} from '@material-ui/icons'
import { Popconfirm, Tooltip, Typography } from 'antd'
import React, { useContext } from 'react'
import { useTheme } from 'styled-components'
import { STATUS } from '../../../constants/status'
import Contexts from '../../../context'
import { CustomButton } from '../../../global/GlobalStyles'
import moment from 'moment'

function GroupeButtons({
	confirmCommande = () => {},
	deleteCommande = () => {},
	item,
	isAdmin = false,
}) {
	const theme = useTheme()
	const { setEditingItem, editingItem } = useContext(
		Contexts.EditingInputContext
	)
	return (
		<div
			style={{
				display: 'flex',
				flexWrap: 'wrap',
				justifyContent: 'center',
				alignItems: 'center',
				margin: '10px',
			}}
		>
			<Tooltip title='Confirmer'>
				<Popconfirm
					title='Vous êtes sûr de vouloir confirmer ?'
					onConfirm={(e) => {
						confirmCommande(item)
					}}
					okText='Oui'
					cancelText='Non'
				>
					<CustomButton
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
						//onClick={showModal}
						margin='0.1em'
						colorText='#08a908'
						bgColor='#fff'
						colorHoverIcon='#fff'
						icon={<CheckCircle htmlColor='#08a908' />}
					/>
				</Popconfirm>
			</Tooltip>
			<Tooltip title='Annuler'>
				<Popconfirm
					title='Vous êtes sûr de vouloir annuler ?'
					onConfirm={(e) => {
						deleteCommande(item)
					}}
					okText='Oui'
					cancelText='Non'
				>
					<CustomButton
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
						margin='0.1em'
						//onClick={showModal}
						colorText='#ff4d4f'
						bgColor='#fff'
						colorHoverIcon='#fff'
						icon={
							!editingItem ? (
								<RemoveCircle htmlColor='#ff4d4f' />
							) : (
								<Clear htmlColor='#ff4d4f' />
							)
						}
					/>
				</Popconfirm>
			</Tooltip>
			{isAdmin ? (
				!editingItem ? (
					<Tooltip title='Modifier'>
						<Popconfirm
							title='Vous êtes sûr de vouloir modifier ?'
							onConfirm={() => {
								setEditingItem(item)
							}}
							okText='Oui'
							cancelText='Non'
						>
							<CustomButton
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
								margin='0.1em'
								colorText={theme?.secondary}
								bgColor='#fff'
								colorHoverIcon='#fff'
								icon={
									<EditRounded htmlColor={theme?.secondary} />
								}
							/>
						</Popconfirm>
					</Tooltip>
				) : (
					''
				)
			) : (
				''
			)}
			<Typography.Text
				disabled
				style={{ width: '100%', marginTop: '2%', fontSize: '1em' }}
			>
				{moment(item?.createdAt).format('L [à] HH:mm')}
			</Typography.Text>
		</div>
	)
}

export default GroupeButtons
