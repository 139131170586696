import React, { useRef, useState } from 'react'
import {
	Input,
	Button,
	Space,
	Image,
	Popconfirm,
	Typography,
	Form,
	Row,
	Col,
} from 'antd'
import Highlighter from 'react-highlight-words'
import { SearchOutlined } from '@ant-design/icons'
import { CustomTableStyle } from '../../global/GlobalStyles'
import { EditableCell, EditableRow, EditableAllCell } from '../EditableCell'
//import { EditableCell, EditableRow } from '../EditableCell'
import { isArray } from 'lodash'
import { CustomtableContainer } from './style'
import { ROLES } from '../../constants/roles'
//import { SearchOutlined } from '@material-ui/icons'

const CustomTable = ({
	data,
	handleSearch = () => {},
	filterClick,
	handleReset = () => {},
	handleChangeTable = () => {},
	showSorterTooltip,
	handleSave = () => {},
	handleSaveRow = () => {},
	toggleEditRow = () => {},
	renderBefore = () => {},
	editableRow,
	pagination,
	loading,
	columnsData,
	mode,
	roles,
	...props
}) => {
	const [form] = Form.useForm()
	//const [data, setData] = useState(dataSource)
	const [editingKey, setEditingKey] = useState('')
	const [selectedRowKeys, setSelectedRowKeys] = useState([])
	const [loadingSelect, setLoading] = useState(false)
	const isEditing = (record) => record?._id === editingKey
	const searchInput = useRef(null)

	const getColumnSearchProps = (dataIndex, render = (text) => text) => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
		}) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={searchInput}
					/*  ref={node => {
            searchInput = node;
          }} */
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) =>
						setSelectedKeys(e.target.value ? [e.target.value] : [])
					}
					onPressEnter={() =>
						handleSearch(selectedKeys, confirm, dataIndex)
					}
					style={{ marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type='primary'
						onClick={() =>
							handleSearch(selectedKeys, confirm, dataIndex)
						}
						icon={<SearchOutlined />}
						size='small'
						style={{ width: 90 }}
					>
						Chercher
					</Button>
					<Button
						onClick={() => handleReset(clearFilters)}
						size='small'
						style={{ width: 90 }}
					>
						Réinitialiser
					</Button>
					<Button
						type='link'
						size='small'
						onClick={filterClick(selectedKeys, dataIndex, confirm)}
					>
						Filtrer
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => (
			<SearchOutlined style={{ color: filtered ? '#000' : null }} />
		),
		/*  onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "", */
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => searchInput?.current.focus(), 100)
			}
		},
	})

	const getColumnSelectedSearchProps = (
		dataIndex,
		filters,
		render = (text) => text
	) => ({
		filters,
		/* onFilter: (value, record) => {
      console.log({ value, record });
      return record[dataIndex].indexOf(value) === 0;
    }, */
	})
	const edit = (record) => {
		toggleEditRow(record, form)
		form.setFieldsValue({
			...record,
		})
		setEditingKey(record._id)
	}
	const save = async (record) => {
		try {
			toggleEditRow(record, form)
			handleSaveRow(form, record)
			/* if (index > -1) {
				const item = data[index]
				data.splice(index, 1, { ...item, ...row })
				setEditingKey('')
			} else {
				data.push(row)
				setEditingKey('')
			} */
			setEditingKey('')
		} catch (errInfo) {
			console.log('Validate Failed:', errInfo)
		}
	}
	const getColumns = (data) => {
		const columns = []
		data?.map(
			({ withSelectedSearchColumn, withSearchColumn, ...val }, index) => {
				let propsColumn = { ...val }
				const keyData = isArray(val?.dataIndex)
					? val?.dataIndex[0]
					: val?.dataIndex

				if (withSearchColumn) {
					propsColumn = {
						...propsColumn,
						...getColumnSearchProps(keyData),
					}
				}
				if (withSelectedSearchColumn) {
					propsColumn = {
						...propsColumn,
						...getColumnSelectedSearchProps(keyData, val?.filters),
					}
				}
				if (!val?.hide) {
					return columns.push(propsColumn)
				}
			}
		)
		editableRow &&
			columns.push({
				title: 'Actions',
				dataIndex: 'action',
				fixed: 'right',
				width: 100,
				render: (_, record) => {
					const editable = isEditing(record)
					return editable ? (
						<>
							<Row gutter={[2, 2]}>
								<Col>
									<Popconfirm
										title='Vous êtes sûr de vouloir enregistrer ?'
										onConfirm={() => {
											save(record)
										}}
										okText='Oui'
										cancelText='Non'
									>
										<Typography.Link>
											Enregistrer
										</Typography.Link>
									</Popconfirm>
								</Col>
								<Col>
									<Popconfirm
										title='Vous êtes sûr de vouloir annuler ?'
										onConfirm={() => {
											setEditingKey('')
										}}
										okText='Oui'
										cancelText='Non'
									>
										<Typography.Link>
											Annuler
										</Typography.Link>
									</Popconfirm>
								</Col>
							</Row>
						</>
					) : (
						<Typography.Link
							disabled={editingKey !== ''}
							onClick={() => edit(record)}
						>
							Modifier
						</Typography.Link>
					)
				},
			})
		return columns
	}
	const components = {
		body: editableRow
			? {
					cell: EditableAllCell,
			  }
			: {
					row: EditableRow,
					cell: EditableCell,
			  },
	}
	const columns = getColumns(columnsData).map((col) => {
		if (!col.editable) {
			return col
		}

		return editableRow
			? {
					...col,
					onCell: (record) => ({
						record,
						dataIndex: col.dataIndex,
						title: col.title,
						inputEditable: col.inputEditable,
						editing: isEditing(record),
					}),
			  }
			: {
					...col,
					onCell: (record) => {
						return {
							record,
							editable: col.editable,
							dataIndex: col.dataIndex,
							title: col.title,
							inputEditable: col.inputEditable,
							handleSave,
						}
					},
			  }
	})

	const onSelectChange = (selectedRowKeys, row) => {
		setSelectedRowKeys(selectedRowKeys)
	}

	const hasSelected = selectedRowKeys.length > 0
	return (
		<CustomtableContainer>
			{renderBefore(
				hasSelected,
				loadingSelect,
				selectedRowKeys,
				setSelectedRowKeys
			)}

			<Form form={form} component={false}>
				<CustomTableStyle
					rowSelection={
						roles?.includes(ROLES.ADMIN)
							? {
									selectedRowKeys,
									onChange: onSelectChange,
							  }
							: mode == 'edit'
							? {
									selectedRowKeys,
									onChange: onSelectChange,
							  }
							: null
					}
					components={components}
					padding='3px 16px'
					showSorterTooltip={showSorterTooltip}
					onChange={handleChangeTable}
					loading={loading}
					pagination={{ ...pagination }}
					columns={columns}
					dataSource={data}
					scroll={{ x: true, y: '65vh' }}
					scrollToFirstRowOnChange={true}
					{...props}
				/>
			</Form>
		</CustomtableContainer>
	)
}

export default CustomTable
