import React from 'react'
import { OffersContainer } from './styles'
import ListArticles from './components/ListArticles'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

function Articles() {
	const dispatch = useDispatch()
	const user = useSelector((state) => state.auth?.user, shallowEqual)
	const { companyByUserId } = useSelector(
		(state) => ({
			companyByUserId: state.auth.companyByUserId,
		}),
		shallowEqual
	)

	return (
		<OffersContainer>
			{/* <HeaderTitle
				title='Votre Articles'
				withoutBreak
				renderAfter={() => (
					
				)}
			/> */}
			<ListArticles _id={user?._id} companyByUserId={companyByUserId} />
		</OffersContainer>
	)
}

export default Articles
