export const START_LOADING_ARTICLES = 'START_LOADING_ARTICLES'
export const FETCH_ARTICLES_SUCCESS = 'FETCH_ARTICLES_SUCCESS'
export const FETCH_ARTICLES_ERROR = 'FETCH_ARTICLES_ERROR'
export const RESET_ARTICLES_AUTOCOMPLETE = 'RESET_ARTICLES_AUTOCOMPLETE'

export const START_LOADING_CREATE_ARTICLE = 'START_LOADING_CREATE_ARTICLE'
export const CREATE_ARTICLE_SUCCESS = 'CREATE_ARTICLE_SUCCESS'
export const CREATE_ARTICLE_ERROR = 'CREATE_ARTICLE_ERROR'

export const START_LOADING_ARTICLE_BY_ID = 'START_LOADING_ARTICLE_BY_ID'
export const ARTICLE_BY_ID_SUCCESS = 'ARTICLE_BY_ID_SUCCESS'
export const ARTICLE_BY_ID_ERROR = 'ARTICLE_BY_ID_ERROR'

export const START_LOADING_DELETE_ARTICLES = 'START_LOADING_DELETE_ARTICLES'
export const DELETE_ARTICLES_SUCCESS = 'DELETE_ARTICLES_SUCCESS'
export const DELETE_ARTICLES_ERROR = 'DELETE_ARTICLES_ERROR'

export const START_LOADING_UPDATE_ARTICLE = 'START_LOADING_UPDATE_ARTICLE'
export const UPDATE_ARTICLE_SUCCESS = 'UPDATE_ARTICLE_SUCCESS'
export const UPDATE_ARTICLE_ERROR = 'UPDATE_ARTICLE_ERROR'
