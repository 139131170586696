import React, { useContext, useState, useEffect, useRef } from 'react'
import { Input, Form, InputNumber, Row, Col } from 'antd'
import { EditableCellContainer } from './style'
//import { CloseCircleOutlined } from '@ant-design/icons'
import { CustomButton } from '../../global/GlobalStyles'
import { CheckOutlined, CloseRounded } from '@material-ui/icons'
import { TYPE_OFFER } from '../../constants/type_offer'

const EditableContext = React.createContext(null)

export const EditableRow = ({ index, ...props }) => {
	const [form] = Form.useForm()
	return (
		<Form form={form} component={false}>
			<EditableContext.Provider value={form}>
				<tr {...props} />
			</EditableContext.Provider>
		</Form>
	)
}

export const EditableCell = ({
	title,
	editable,
	children,
	dataIndex,
	record,
	inputEditable,
	handleSave,
	...restProps
}) => {
	const [editing, setEditing] = useState(false)
	const inputRef = useRef(null)
	const form = useContext(EditableContext)
	useEffect(() => {
		if (editing) {
			inputRef.current.focus()
		}
	}, [editing])

	const toggleEdit = () => {
		setEditing(!editing)
		inputEditable?.toggleEdit(form, record)
		/* form.setFieldsValue({
			[dataIndex]: record[dataIndex],
		}) */
	}

	const save = async () => {
		try {
			const values = await form.validateFields()
			toggleEdit()
			values.qtePriceCommande
				? handleSave({ ...record, ...values })
				: handleSave({
						...record,
						...values,
						qtePriceCommande:
							record?.type === TYPE_OFFER.PURCHASE
								? record?.unitPrice
								: null,
				  })
		} catch (errInfo) {
			console.log('Save failed:', errInfo)
		}
	}

	let childNode = children

	if (editable) {
		childNode = inputEditable.alwaysEditing ? (
			inputEditable?.renderInput(save, toggleEdit, inputRef, record)
		) : editing ? (
			inputEditable?.renderInput(save, toggleEdit, inputRef, record)
		) : (
			<div
				className='editable-cell-value-wrap'
				style={{
					paddingRight: 24,
				}}
				onClick={toggleEdit}
			>
				{children}
			</div>
		)
	}

	return (
		<EditableCellContainer {...restProps}>
			{childNode}
		</EditableCellContainer>
	)
}

export const EditableAllCell = ({
	editing,
	dataIndex,
	title,
	inputEditable,
	record,
	index,
	children,
	...restProps
}) => {
	const inputNode = inputEditable?.renderInput(record)
	return <td {...restProps}>{editing ? inputNode : children}</td>
}
