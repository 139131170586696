import React from 'react'
import { HomeContainer } from './styles'
import CustomSlider from '../../components/Slider'
import { ROLES } from '../../constants/roles'
import CustomResult from '../../pages/Results'
function Home() {
	return (
		<>
			<CustomSlider />
			<HomeContainer>
				<CustomResult status={ROLES.CLIENT} />
			</HomeContainer>
		</>
	)
}

export default Home
