import { gql } from '@apollo/client'
export const ARTICLES_BY_FIELDS_QUERY = gql`
	query getArticlesByFields(
		$fields: [FieldInput!]
		$othersField: [FieldInput!]
		$anotherFields: [[FieldInput!]!]
		$globalFields: [[FieldInput!]!]
		$operatorBetweenFields: String
		$operatorBetweenGlobalFields: String
		$options: Options
		$sort: Sort
		$byUserId: String!
	) {
		findArticlesByField(
			fields: $fields
			othersField: $othersField
			anotherFields: $anotherFields
			globalFields: $globalFields
			operatorBetweenFields: $operatorBetweenFields
			operatorBetweenGlobalFields: $operatorBetweenGlobalFields
			options: $options
			sort: $sort
			byUserId: $byUserId
		) {
			entities {
				_id
				ref
				refOrigin
				marque
				description
				img
				status
				company {
					title
					matFiscal
				}
			}
			totalCount
			totalPages
			page
			limit
			hasNextPage
			hasPreviousPage
		}
	}
`

export const ARTICLES_BY_FIELDS_MORE_QUERY = gql`
	query getArticlesByFieldsMore(
		$fields: [FieldInput!]!
		$operatorBetweenFields: String
		$options: Options
		$sort: Sort
		$byUserId: String!
	) {
		findArticlesByFieldMore(
			fields: $fields
			operatorBetweenFields: $operatorBetweenFields
			options: $options
			sort: $sort
			byUserId: $byUserId
		) {
			entities {
				_id
				ref
				marque
				description
				img
				company {
					title
					matFiscal
				}
			}
			totalCount
			totalPages
			page
			limit
			hasNextPage
			hasPreviousPage
		}
	}
`
export const ARTICLE_BY_ID_QUERY = gql`
	query getArticleById($id: String!) {
		getArticleById(id: $id) {
			_id
			ref
			marque
			description
			img
			status
		}
	}
`

export const CREATE_ARTICLES_QUERY = gql`
	mutation createArticles($newArticles: [CreateArticleInput!]!) {
		createArticles(input: { newArticles: $newArticles }) {
			_id
			ref
			marque
			description
			img
			status
			company {
				_id
			}
		}
	}
`

export const DELETE_ARTICLE_BY_ID_MUTATION = gql`
	mutation deleteArticle($_id: String!) {
		deleteArticle(input: { _id: $_id })
	}
`

export const DELETE_ARTICLES_BY_ID_MUTATION = gql`
	mutation deleteArticles($ids: [String!]!) {
		deleteArticles(input: { ids: $ids })
	}
`

export const ARTICLES_QUERY = gql`
	query getArticles {
		findAllArticles {
			marque
			ref
			refOrigin
			description
			img
			company {
				title
				matFiscal
			}
		}
	}
`

export const UPDATE_ARTICLE_BY_ID_MUTATION = gql`
	mutation updateArticle(
		$_id: String!
		$marque: String
		$ref: String
		$refOrigin: String
		$description: String
		$img: String
		$file: Upload
		$status: Statuss
		$company: String
	) {
		updateArticle(
			input: {
				_id: $_id
				marque: $marque
				ref: $ref
				refOrigin: $refOrigin
				description: $description
				img: $img
				status: $status
				company: $company
			}
			file: $file
		) {
			_id
			ref
			refOrigin
			marque
			description
			img
			status
			company {
				title
				matFiscal
			}
		}
	}
`

export const UPLOAD_MULTIPLE_ARTICLES_MUTATION = gql`
	mutation uploadMultipleArticles($file: Upload!) {
		uploadMultipleArticles(file: $file)
	}
`
