import { concat, merge } from 'lodash'
import { initialStatePagination } from '../../constants/variables'
import {
	FETCH_OFFERS_SUCCESS,
	FETCH_OFFERS_ERROR,
	CREATE_OFFER_SUCCESS,
	CREATE_OFFER_ERROR,
	UPDATE_OFFER_SUCCESS,
	UPDATE_OFFER_ERROR,
	DELETE_OFFER_SUCCESS,
	DELETE_OFFER_ERROR,
	START_LOADING_OFFERS,
	START_LOADING_CREATE_OFFER,
	START_LOADING_UPDATE_OFFER,
	START_LOADING_DELETE_OFFER,
	START_LOADING_OFFER_BY_ID,
	OFFER_BY_ID_SUCCESS,
	OFFER_BY_ID_ERROR,
} from './types'
const initialState = merge(
	{
		offerById: {},
		loadingGetOffers: false,
		loadingCreateOffer: false,
		loadingGetOfferById: false,
		loadingDeleteOffer: false,
		loadingUpdateOffer: false,
		error: '',
	},
	initialStatePagination
)

export default function offerReducer(state = initialState, action) {
	switch (action.type) {
		/* ****************** GET OFFERS ************************ */
		case START_LOADING_OFFERS:
			return {
				...state,
				loadingGetOffers: true,
			}
		case FETCH_OFFERS_SUCCESS:
			return {
				...state,
				...action.payload,
				loadingGetOffers: false,
				error: '',
			}
		case FETCH_OFFERS_ERROR:
			return {
				...state,
				loadingGetOffers: false,
				error: action.payload,
			}
		/* ****************** CREATE OFFER ************************ */
		case START_LOADING_CREATE_OFFER:
			return {
				...state,
				loadingCreateOffer: true,
			}
		case CREATE_OFFER_SUCCESS:
			return {
				...state,
				entities: [action.payload, ...state.entities],
				//entities: [action.payload, ...state.entities],
				loadingCreateOffer: false,
				error: '',
			}
		case CREATE_OFFER_ERROR:
			return {
				...state,
				loadingCreateOffer: false,
				error: action.payload,
			}

		/* ****************** OFFER BY ID ************************ */
		case START_LOADING_OFFER_BY_ID:
			return {
				...state,
				loadingGetOfferById: true,
			}
		case OFFER_BY_ID_SUCCESS:
			return {
				...state,
				offerById: action.payload,
				loadingGetOfferById: false,
				error: '',
			}
		case OFFER_BY_ID_ERROR:
			return {
				...state,
				loadingGetOfferById: false,
				error: action.payload,
			}
		/* ****************** UPDATE OFFER ************************ */
		case START_LOADING_UPDATE_OFFER:
			return {
				...state,
				loadingUpdateOffer: true,
			}
		case UPDATE_OFFER_SUCCESS:
			return {
				...state,
				entities: [
					...state.entities.map((offer) => {
						if (offer._id === action.payload._id)
							return action.payload
						else return offer
					}),
				],
				loadingUpdateOffer: false,
				error: '',
			}
		case UPDATE_OFFER_ERROR:
			return {
				...state,
				loadingUpdateOffer: false,
				error: action.payload,
			}
		/* ****************** DELETE OFFER ************************ */
		case START_LOADING_DELETE_OFFER:
			return {
				...state,
				loadingDeleteOffer: true,
			}
		case DELETE_OFFER_SUCCESS:
			return {
				...state,
				loadingDeleteOffer: false,
				entities: [
					...state.entities.filter(
						(offer) => offer._id !== action.payload._id
					),
				],
				error: '',
			}
		case DELETE_OFFER_ERROR:
			return {
				...state,
				loadingDeleteOffer: false,
				error: action.payload,
			}
		default:
			return state
	}
}
