import { STATUS } from '../../constants/status'
import { apolloClient } from '../../graphql'
import {
	CREATE_ARTICLES_QUERY,
	DELETE_ARTICLES_BY_ID_MUTATION,
	UPDATE_ARTICLE_BY_ID_MUTATION,
	ARTICLES_BY_FIELDS_QUERY,
	ARTICLE_BY_ID_QUERY,
} from '../../queries/article'

import {
	START_LOADING_ARTICLES,
	FETCH_ARTICLES_SUCCESS,
	FETCH_ARTICLES_ERROR,
	START_LOADING_CREATE_ARTICLE,
	CREATE_ARTICLE_SUCCESS,
	CREATE_ARTICLE_ERROR,
	START_LOADING_DELETE_ARTICLES,
	DELETE_ARTICLES_SUCCESS,
	DELETE_ARTICLES_ERROR,
	START_LOADING_UPDATE_ARTICLE,
	UPDATE_ARTICLE_SUCCESS,
	UPDATE_ARTICLE_ERROR,
	START_LOADING_ARTICLE_BY_ID,
	ARTICLE_BY_ID_SUCCESS,
	ARTICLE_BY_ID_ERROR,
	RESET_ARTICLES_AUTOCOMPLETE,
} from './types'

/* *************** ARTICLES BY FIELDS ************** */
export const resetArticlesAutoComplete = () => async (dispatch) => {
	try {
		dispatch({
			type: RESET_ARTICLES_AUTOCOMPLETE,
		})
	} catch (error) {}
}
export const getArticlesByFields =
	(
		variables = {
			fields: [],
			anotherFields: [],
			globalFields: [],
			operatorBetweenFields: 'or',
			operatorBetweenAnotherFields: 'or',
			operatorBetweenGlobalFields: 'or',
			options: {
				all: false,
				page: 1,
				limit: 10,
			},
			sort: {
				sortOrder: 'desc',
				sortField: 'updatedAt',
			},
			byUserId: '',
		},
		autoComplete = false
	) =>
	async (dispatch) => {
		try {
			dispatch({
				type: START_LOADING_ARTICLES,
			})
			/* if (autoComplete) {
				variables.fields = [
					...variables.fields,
					{ field: 'status', fieldValue: STATUS.ACTIVE },
				]
				variables.operatorBetweenFields = 'and'
			} */
			const res = await apolloClient.query({
				query: ARTICLES_BY_FIELDS_QUERY,
				variables,
				fetchPolicy: 'network-only',
			})
			dispatch({
				type: FETCH_ARTICLES_SUCCESS,
				payload: { data: res.data?.findArticlesByField, autoComplete },
			})
		} catch (error) {
			dispatch({
				type: FETCH_ARTICLES_ERROR,
				payload: error.message,
			})
		}
	}
/* *************** CREATE ARTICLE ************** */
export const createArticles = (newArticles) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_CREATE_ARTICLE,
		})
		const res = await apolloClient.mutate({
			mutation: CREATE_ARTICLES_QUERY,
			variables: {
				newArticles,
			},
		})

		dispatch({
			type: CREATE_ARTICLE_SUCCESS,
			payload: res.data.createArticles,
		})
	} catch (error) {
		dispatch({
			type: CREATE_ARTICLE_ERROR,
			payload: error.message,
		})
	}
}

/* *************** ARTICLE BY ID ************** */
export const getArticleById = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_ARTICLE_BY_ID,
		})
		const res = await apolloClient.query({
			query: ARTICLE_BY_ID_QUERY,
			variables,
		})
		dispatch({
			type: ARTICLE_BY_ID_SUCCESS,
			payload: res.data.getArticleById,
		})
	} catch (error) {
		dispatch({
			type: ARTICLE_BY_ID_ERROR,
			payload: error.message,
		})
	}
}

/* *************** UPDATE ARTICLE ************** */
export const updateArticle = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_UPDATE_ARTICLE,
		})
		const res = await apolloClient.mutate({
			mutation: UPDATE_ARTICLE_BY_ID_MUTATION,
			variables,
		})
		dispatch({
			type: UPDATE_ARTICLE_SUCCESS,
			payload: res.data.updateArticle,
		})
	} catch (error) {
		dispatch({
			type: UPDATE_ARTICLE_ERROR,
			payload: error.message,
		})
	}
}
/* *************** DELETE ARTICLE ************** */
export const deleteArticle = (variables) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_DELETE_ARTICLES,
		})
		const res = await apolloClient.mutate({
			mutation: DELETE_ARTICLES_BY_ID_MUTATION,
			variables,
		})

		dispatch({
			type: DELETE_ARTICLES_SUCCESS,
			payload: variables,
		})
	} catch (error) {
		dispatch({
			type: DELETE_ARTICLES_ERROR,
			payload: error.message,
		})
	}
}

/* *************** DELETE ARTICLES ************** */
export const deleteArticles = (ids) => async (dispatch) => {
	try {
		dispatch({
			type: START_LOADING_DELETE_ARTICLES,
		})
		const res = await apolloClient.mutate({
			mutation: DELETE_ARTICLES_BY_ID_MUTATION,
			variables: {
				ids,
			},
		})

		dispatch({
			type: DELETE_ARTICLES_SUCCESS,
			payload: res.data.deleteArticles,
		})
	} catch (error) {
		dispatch({
			type: DELETE_ARTICLES_ERROR,
			payload: error.message,
		})
	}
}
