import React, { useState } from 'react'
import { Layout } from 'antd'
import ListProf from './components/ListProf'
import { ChatContainer } from './styles'
import ChatRoom from '../../components/ChatRoom'
const { Sider } = Layout
const Chat = () => {
	const [collapsed, setCollapsed] = useState(false)
	const [currentComapnyConv, setCurrentComapnyConv] = useState('')
	const onCollapse = (collapsed) => {
		setCollapsed(collapsed)
	}
	return (
		<ChatContainer collapsed={collapsed}>
			<Layout style={{ minHeight: '100%' }}>
				<Sider
					collapsible
					collapsed={collapsed}
					onCollapse={onCollapse}
					width={300}
				>
					{/* <div className='logo'>bbbb</div> */}
					<ListProf
						collapsed={collapsed}
						setCurrentComapnyConv={setCurrentComapnyConv}
					/>
				</Sider>
				{currentComapnyConv?._id && (
					<ChatRoom chatOne currentUserConver={currentComapnyConv} />
				)}
			</Layout>
		</ChatContainer>
	)
}

export default Chat
